import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { ComponentDefinitionModel } from '@app/construct-steps/models/component-definition.model';
import { ComponentTranslationModel } from '@components/component/models/component-translation.model';

import * as ComponentActions from './actions';
import * as fromComponent from './reducers';
import { TagApiModel } from '../tags/tags.models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComponentFacadeService {
  constructor(private store$: Store<any>) {}

  getAllComponents(): Observable<ComponentModel[]> {
    return this.store$.pipe(select(fromComponent.selectAllComponents));
  }

  getAllowedComponentIds(): Observable<number[]> {
    return this.store$.pipe(select(fromComponent.selectAllowedComponentIds));
  }

  getAllowedComponents(): Observable<ComponentModel[]> {
    return combineLatest([this.getAllComponents(), this.getAllowedComponentIds()])
      .pipe(
        map(([components, allowedIds]) => [...components].filter(c => allowedIds.includes(c.id)))
      );
  }

  getAllowedComponentsLoadError(): Observable<boolean> {
    return this.store$.pipe(select(fromComponent.selectAllowedComponentsLoadError));
  }

  getComponentLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromComponent.selectComponentLoading));
  }

  getComponentLoaded(): Observable<boolean> {
    return this.store$.pipe(select(fromComponent.selectComponentLoaded));
  }

  getComponentError(): Observable<Error> {
    return this.store$.pipe(select(fromComponent.selectComponentError));
  }

  getActiveComponentId(): Observable<number> {
    return this.store$.pipe(select(fromComponent.selectActiveComponentId));
  }

  getActiveComponent(): Observable<ComponentModel> {
    return this.store$.pipe(select(fromComponent.selectActiveComponent));
  }

  getComponentUpdating(): Observable<boolean> {
    return this.store$.pipe(select(fromComponent.selectComponentUpdating));
  }

  getUpdatedComponent(): Observable<ComponentModel> {
    return this.store$.pipe(select(fromComponent.selectUpdatedComponent));
  }

  getComponentsByName(componentName: string|string[]): Observable<ComponentModel[]> {
    return this.store$.pipe(select(fromComponent.selectComponentsByName(), { componentName }));
  }

  loadComponents(event: EventModel, authorizedUserGroups: TagApiModel[] | null) {
    this.store$.dispatch(ComponentActions.loadComponents({ event, authorizedUserGroups }));
  }

  createComponents(event: EventModel, definitions: ComponentDefinitionModel[]) {
    this.store$.dispatch(ComponentActions.createComponents({ event, definitions }));
  }

  insertComponents(event: EventModel, definitions: ComponentDefinitionModel[], at: number) {
    this.store$.dispatch(ComponentActions.insertComponents({ event, definitions, at }));
  }

  updateComponent(event: EventModel, component: ComponentModel, translations: ComponentTranslationModel[]) {
    this.store$.dispatch(ComponentActions.updateComponent({ event, component, translations }));
  }

  updateOnboarding(event: EventModel, component: ComponentModel, translations: ComponentTranslationModel[]) {
    this.store$.dispatch(ComponentActions.updateOnboarding({ event, component, translations }));
  }

  updateComponentAdditionalInfo(event: EventModel, component: ComponentModel, additionalInfo: any) {
    this.store$.dispatch(ComponentActions.updateComponentAdditionalInfo({ event, component, additionalInfo }));
  }

  reorderComponents(event: EventModel, components: ComponentModel[]) {
    this.store$.dispatch(ComponentActions.reorderComponents({ event, components }));
  }

  changeComponentVisibility(event: EventModel, component: ComponentModel, visible: boolean) {
    this.store$.dispatch(ComponentActions.changeComponentVisibility({ event, component, visible }));
  }

  setComponentFirstToOpen(event: EventModel, component: ComponentModel) {
    this.store$.dispatch(ComponentActions.setComponentFirstToOpen({ event, component }));
  }

  deleteComponent(event: EventModel, component: ComponentModel) {
    this.store$.dispatch(ComponentActions.deleteComponent({ event, component }));
  }

  selectComponent(componentId: number) {
    this.store$.dispatch(ComponentActions.selectComponent({ componentId }));
  }

  // update single additional_info properties

  updateAgendaDefaultViewType(eventId: number, componentId: number, viewType: 0 | 1): void {
    this.store$.dispatch(ComponentActions.updateAgendaDefaultViewType({ eventId, componentId, viewType }));
  }
}

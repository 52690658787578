export type Collection<T> = T[];

export type CompareWith<T> = (a: T, b: T) => boolean;

export function push<T>(collection: Collection<T>, entity: T): Collection<T> {
  const mutableCollection = [...collection, entity];
  return mutableCollection;
}

export function concat<T>(collection: Collection<T>, partial: Collection<T>): Collection<T> {
  const mutableCollection = [...collection, ...partial];
  return mutableCollection;
}

export function insert<T>(collection: Collection<T>, entity: T, atIndex: number): Collection<T> {
  const mutableCollection = [...collection];
  mutableCollection.splice(atIndex, 0, entity);

  return mutableCollection;
}

export function put<T>(collection: Collection<T>, partial: Collection<T>, atIndex: number): Collection<T> {
  const mutableCollection = [...collection];
  mutableCollection.splice(atIndex, 0, ...partial);

  return mutableCollection;
}

export function swap<T>(collection: Collection<T>, entity: T, compareWith: CompareWith<T> = defaultCompareWith): Collection<T> {
  const mutableCollection = [...collection];
  const entityIndex = mutableCollection.findIndex(
    collectionEntity => compareWith(collectionEntity, entity)
  );

  if (entityIndex !== -1) {
    mutableCollection[entityIndex] = entity;
  }

  return mutableCollection;
}

export function update<T>(collection: Collection<T>, partial: Collection<T>, compareWith: CompareWith<T> = defaultCompareWith): Collection<T> {
  const mutableCollection = [...collection];

  for (const entity of partial) {
    const entityIndex = mutableCollection.findIndex(
      collectionEntity => compareWith(collectionEntity, entity)
    );

    if (entityIndex !== -1) {
      mutableCollection[entityIndex] = entity;
    }
  }

  return mutableCollection;
}

export function remove<T>(collection: Collection<T>, entity: T, compareWith: CompareWith<T> = defaultCompareWith): Collection<T> {
  const mutableCollection = [...collection];
  const entityIndex = mutableCollection.findIndex(
    collectionEntity => compareWith(collectionEntity, entity)
  );

  if (entityIndex !== -1) {
    mutableCollection.splice(entityIndex, 1);
  }

  return mutableCollection;
}

export function removeAt<T>(collection: Collection<T>, index: number): Collection<T> {
  const mutableCollection = [...collection];

  if (index > -1 && index < mutableCollection.length) {
    mutableCollection.splice(index, 1);
  }

  return mutableCollection;
}

export function erase<T>(collection: Collection<T>, partial: Collection<T>, compareWith: CompareWith<T> = defaultCompareWith): Collection<T> {
  const mutableCollection = [...collection];

  for (const entity of partial) {
    const entityIndex = mutableCollection.findIndex(
      collectionEntity => compareWith(collectionEntity, entity)
    );

    if (entityIndex !== -1) {
      mutableCollection.splice(entityIndex, 1);
    }
  }

  return mutableCollection;
}

export function shuffle<T>(collection: Collection<T>): Collection<T> {
  const mutableCollection = [...collection];

  for (let i = mutableCollection.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    [mutableCollection[i], mutableCollection[j]] =
      [mutableCollection[j], mutableCollection[i]];
  }

  return mutableCollection;
}

export function reorder<T>(collection: Collection<T>, from: number, to: number): Collection<T> {
  const mutableCollection = [...collection];

  const reorderedEntities = mutableCollection.splice(from, 1);
  mutableCollection.splice(to, 0, ...reorderedEntities);

  return mutableCollection;
}

export function defaultCompareWith<T>(a: T, b: T): boolean {
  return (a === b);
}

import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class LineChartProviderService {

  private defaultConfig: LineChartConfiguration = {
    yBeginAtZero: true,
    yDashedGrid: false,
    smoothLines: true,
    datalineDashed: false,
    gradient: true,
    colors: ['#00aeef', '#00aeef']
  }

  getConfiguration(config?: LineChartConfigurationSetup): LineChartConfiguration {
    return { ...this.defaultConfig, ...config };
  }

  constructor() {}
}

export interface LineChartConfigurationSetup {
  yBeginAtZero?: boolean; // always show 0 on Y axis
  yDashedGrid?: boolean; // dashed grid lines Y
  smoothLines?: boolean; // smooth dataset line (without corners)
  datalineDashed?: boolean; // dashed dataset line
  gradient?: boolean; // multicolor line
  colors?: string[]; // colors palette
}

export interface LineChartConfiguration {
  yBeginAtZero: boolean;
  yDashedGrid: boolean;
  smoothLines: boolean;
  datalineDashed: boolean;
  gradient: boolean;
  colors: string[];
}

export interface LineChartData {
  data: number[];
  label?: string;
  translateLabel?: boolean;
}
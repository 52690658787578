export const ComponentNames = {
  UNDEFINED: 'UndefinedComponent',
  AGENDA: 'AgendaComponent',
  ATTENDEES: 'AttendeesComponent',
  BANNER: 'BannerComponent',
  BUSINESS_MATCHING: 'BusinessMatchingComponent',
  QR_CODE: 'QrCodeComponent',
  EVENT_INFO: 'EventInfoComponent',
  EXHIBITOR: 'ExhibitorComponent',
  FEED_WALL: 'FeedWallComponent',
  INTERACTIVE_MAP: 'InteractiveMapComponent',
  LIVE_WEBINAR_5: 'LiveWebinar5Component',
  LIVE_WEBINAR_100: 'LiveWebinar100Component',
  LIVE_WEBINAR_500: 'LiveWebinar500Component',
  NEWS_SOCIAL_MEDIA: 'NewsSocialMediaComponent',
  PARTNER: 'PartnerComponent',
  PHOTO_BOOTH: 'PhotoBoothComponent',
  PUSH: 'PushComponent',
  AUDIO_VISUAL: 'AudioVisualComponent',
  SPEAKER: 'SpeakerComponent',
  SURVEY_QUIZ: 'SurveyQuizComponent',
  TREASURE_HUNT: 'TreasureHuntComponent',
  VENUE: 'VenueComponent',
  WWW_RESOURCES: 'WwwResourcesComponent',
  BOOKING: 'BookingComponent',
  HELP_DESK: 'HelpDeskComponent',
  EVENT_TICKET: 'EventTicketComponent',
  EVENT_DISCOUNTS: 'EventDiscounts',
  EVENT_TICKETS_FORM: 'EventTicketsForm',
  EVENT_CONSENTS: 'EventConsents',
  MODERATOR_COMPONENT: 'ModeratorComponent',
  FORM: 'FormComponent',
  LEAD_SCAN_COMPONENT: 'LeadScanComponent',

  // LOCAL
  APPLICATION_TAGS: 'ApplicationTags',
  APPLICATION_USERS: 'ApplicationUsers',
  CUSTOM_EMAILS: 'CustomEmails',
  TAG_DATABASE: 'TagDatabaseComponent',
  TICKET_STATS: 'TicketStatsComponent',
  ADMIN: 'AdminComponent',
  INBOX: 'InboxComponent',
  COUPONS: 'CouponsComponent',

  // UNDER DEVELOPMENT
  GAMIFICATION: 'GamificationComponent',
  BEACON: 'BeaconComponent',

  // UTILS
  USER: 'Users',
}
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import { Observable, Subscription, combineLatest } from 'rxjs';

import { COMPONENT_REGISTRY } from '@components/component/providers/component-outlet.service';
import { EventFacadeService } from '@store/features/event/event-facade.service';
import { ApplicationFacadeService } from '@store/features/application/application-facade.service';
import { ComponentService } from '@shared/providers/component.service';
import { ComponentFacadeService } from '@store/features/component/component-facade.service';
import { AppMenuView, BackendHeaderService } from '@app/backend/services/backend-header.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel, FirstToOpenMode } from '@shared/models/component.model';

import { nextTick } from '@utils/next-tick.util';
import { map } from 'rxjs/operators';
import { ComponentNames } from '@utils/component-names.util';
import { TranslateService } from '@ngx-translate/core';
import { isUserRoleComponentHidden } from '@shared/constants/user-role-permissions';

export const applicationUsers: ComponentModel = {
  id: -2, componentName: ComponentNames.APPLICATION_USERS, eventId: -1,
  label: 'component.comp_label_users', additionalInfo: null, defaultComponent: false,
  iconId: -2, order: -2, uuid: '', visibleInApp: false, firstToOpenMode: FirstToOpenMode.Disabled,
};

export const ticketStats: ComponentModel = {
  id: -5, componentName: ComponentNames.TICKET_STATS, eventId: -1,
  label: 'component.comp_label_ticket_stats', additionalInfo: null, defaultComponent: false,
  iconId: -5, order: -5, uuid: '', visibleInApp: false, firstToOpenMode: FirstToOpenMode.Disabled,
};

export const applicationTags: ComponentModel = {
  id: -1, componentName: ComponentNames.APPLICATION_TAGS, eventId: -1,
  label: 'component.comp_label_tags', additionalInfo: null, defaultComponent: false,
  iconId: -1, order: -1, uuid: '', visibleInApp: false, firstToOpenMode: FirstToOpenMode.Disabled,
};

export const customEmails: ComponentModel = {
  id: -3, componentName: ComponentNames.CUSTOM_EMAILS, eventId: -1,
  label: 'component.comp_label_custom_emails', additionalInfo: null, defaultComponent: false,
  iconId: -3, order: -3, uuid: '', visibleInApp: false, firstToOpenMode: FirstToOpenMode.Disabled,
};

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit, OnDestroy {
  event$: Observable<EventModel>;
  components$: Observable<ComponentModel[]>;
  allComponents$: Observable<ComponentModel[]>;
  activeComponent$: Observable<ComponentModel>;

  menuExpanded$: Observable<boolean>;

  isLoading$: Observable<boolean>;

  private subs = new Subscription();

  constructor(private eventFacade: EventFacadeService,
    private applicationFacade: ApplicationFacadeService,
    private componentService: ComponentService,
    private componentFacade: ComponentFacadeService,
    private backendHeader: BackendHeaderService,
    private i18n: TranslateService,
    @Inject(COMPONENT_REGISTRY) private componentRegistry: any[]) {}

  ngOnInit() {
    this.event$ = this.eventFacade.getActiveEvent();
    this.components$ = this.componentService.getComponents();

    this.setAllComponents();

    this.activeComponent$ = combineLatest([this.allComponents$, this.componentFacade.getActiveComponentId()]).pipe(
      map(([components, activeId]) => {
        return components.find(component => component.id === activeId)
      }
    ));

    this.subs.add(this.i18n.onLangChange.subscribe(() => {
      this.setAllComponents();
    }));

    this.menuExpanded$ = this.applicationFacade.getMenuExpanded();

    this.isLoading$ = this.applicationFacade.getLoading();

    nextTick(() => this.backendHeader.setActiveMenu(AppMenuView.APPLICATION));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


  setAllComponents() {
    this.allComponents$ = combineLatest([this.event$, this.components$])
      .pipe(
        map(([event, components]) => {
          let allComponents = [...this.constComponentsWithTranslations, ...this.filterComponents([...components])];
          let accessedComponents = [];
          allComponents.forEach(comp => {
            !isUserRoleComponentHidden(event, comp.componentName) && accessedComponents.push(comp);
          });
          return accessedComponents;
        })
      );
  }

  get constComponentsWithTranslations(): ComponentModel[] {
    return [applicationTags, applicationUsers, ticketStats, customEmails]
      .map(component => ({ ...component, label: this.i18n.instant(component.label) }));
  }

  onMenuToggle(ev: Event) {
    this.applicationFacade.toggleMenu();
  }

  private filterComponents(components: ComponentModel[]): ComponentModel[] {
    const availableComponents = this.componentRegistry.map(c => c.name);
    return components.filter(c => availableComponents.includes(c.componentName));
  }
}

import { createAction, props } from '@ngrx/store';

import { TimezoneModel } from '../models/timezone.model';

export const loadTimezones = createAction(
  '[Timezone/Backend] Load Timezones'
);

export const loadTimezonesSuccess = createAction(
  '[Timezone/API] Load Timezones Success',
  props<{ timezones: TimezoneModel[] }>()
);

export const loadTimezonesFailure = createAction(
  '[Timezone/API] Load Timezones Failure',
  props<{ error: Error }>()
);

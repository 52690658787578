import { Injectable } from '@angular/core';

import { Observable, defer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FeedWallThreadAdapterService } from './feed-wall-thread-adapter.service';
import { FeedWallThreadStoreService } from './feed-wall-thread-store.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { FeedWallChannelModel } from './models/feed-wall-channel.model';
import { FeedWallThreadModel } from './models/feed-wall-thread.model';

@Injectable({
  providedIn: 'root'
})
export class FeedWallThreadService {
  protected currentEvent: EventModel;
  protected currentComponent: ComponentModel;
  protected currentChannel: FeedWallChannelModel;

  constructor(private adapter: FeedWallThreadAdapterService, private store: FeedWallThreadStoreService) {}

  getWelcomeThread(): Observable<FeedWallThreadModel> {
    return this.store.getWelcomeThread();
  }

  getFeedWallThreadById(threadId: number): Observable<FeedWallThreadModel> {
    return this.store.getFeedWallThreadById(threadId);
  }

  getOrderedFeedWallThreads(): Observable<FeedWallThreadModel[]> {
    return this.store.getOrderedFeedWallThreads();
  }

  getFeedWallThreads(): Observable<FeedWallThreadModel[]> {
    return this.store.getFeedWallThreads();
  }

  loadFeedWallChannel(event: EventModel, component: ComponentModel, channel: FeedWallChannelModel): Observable<FeedWallThreadModel[]> {
    return defer(() => this.loadFeedWallThreadsFor(event, component, channel));
  }

  createFeedWallThread(thread: FeedWallThreadModel): Observable<FeedWallThreadModel> {
    return this.adapter.createFeedWallThread(this.currentEvent, this.currentComponent, this.currentChannel, thread).pipe(
      tap(createdThread => this.store.pushEntity(createdThread))
    );
  }

  updateFeedWallThread(thread: FeedWallThreadModel): Observable<FeedWallThreadModel> {
    return this.adapter.updateFeedWallThread(this.currentEvent, this.currentComponent, this.currentChannel, thread).pipe(
      tap(updatedChannel => this.store.updateEntity(updatedChannel))
    );
  }

  deleteFeedWallThread(thread: FeedWallThreadModel): Observable<boolean> {
    return this.adapter.deleteFeedWallThread(this.currentEvent, this.currentComponent, this.currentChannel, thread).pipe(
      tap(success => success && this.store.removeEntity(thread))
    );
  }

  private loadFeedWallThreadsFor(event: EventModel, component: ComponentModel,
    channel: FeedWallChannelModel): Observable<FeedWallThreadModel[]> {

    this.reset();

    return this.adapter.loadFeedWallThreads(event, component, channel).pipe(
      tap(threads => this.setup(event, component, channel, threads))
    );
  }

  private setup(event: EventModel, component: ComponentModel, channel: FeedWallChannelModel, threads: FeedWallThreadModel[]) {
    this.currentEvent = event;
    this.currentComponent = component;
    this.currentChannel = channel;
    this.store.setup(threads);
  }

  private reset() {
    this.currentEvent = null;
    this.currentComponent = null;
    this.currentChannel = null;
    this.store.reset();
  }
}

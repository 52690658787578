<div class="validation-message"
  [class.validation]="type === ValidationMessageType.Validation"
  [class.error]="type === ValidationMessageType.Error"
  [class.info]="type === ValidationMessageType.Info">
  <div class="validation-message__left">
    <div class="icon mr-2">
      <span *ngIf="type === ValidationMessageType.Validation">!</span>
      <span *ngIf="type === ValidationMessageType.Error">!</span>
      <span *ngIf="type === ValidationMessageType.Info">i</span>
    </div>
  </div>
  <div>
    <p>
      <ng-content></ng-content>
    </p>
  </div>
</div>
<div class="chart-container">
  <canvas
  baseChart
  class="chart"
  [data]="data"
  [type]="type"
  [options]="options"
  [plugins]="plugins">
</canvas>

<div class="donut-inner">
  <ng-content></ng-content>
</div>
</div>
<button
  class="btn social-button"
  [class.disabled]="disabled"
  [class]="activeType.class"
  (click)="!disabled && appClick.emit(type)">
  <app-icon [icon]="activeType.icon"></app-icon>
  <span *ngIf="type === SignInStrategyType.Facebook">{{ 'buttons.sign_in_facebook' | translate }}</span>
  <span *ngIf="type === SignInStrategyType.Apple">{{ 'buttons.sign_in_apple' | translate }}</span>
  <span *ngIf="type === SignInStrategyType.MagicLink">{{ 'login.sign_in_with_magic_link' | translate }}</span>
  <span *ngIf="type === SignInStrategyType.Password">{{ 'login.sign_in_with_password' | translate }}</span>
</button>
import { ConsentApiModel } from "../api/consent.api-model";
import { ConsentModel } from "../consent.model";
import { mapConsentDisplayConfigModel } from "./consent-display.mapper";

export function mapConsentModel(model: ConsentApiModel): ConsentModel {
  return {
    id: model.id,
    fileUrl: model.file_url,
    versionNumber: model.version_number,
    changelog: model.changelog,
    consentType: model.consent_type,
    eventConsentType: model.event_consent_type,
    required: model.required,
    displayConfig: mapConsentDisplayConfigModel(model.display_config),
    displayConfigLocale: model.display_config_locale,
    displayType: model.display_type,
  }
}
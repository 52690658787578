import { Component, OnChanges, OnInit, Input, SimpleChanges, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable } from 'rxjs';

import { CurrencyService } from '@shared/providers/currency.service';

import { CurrencyModel } from '@shared/models/currency.model';
import { take, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-price-field',
  templateUrl: './price-field.component.html',
  styleUrls: ['./price-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PriceFieldComponent),
      multi: true
    }
  ]
})
export class PriceFieldComponent implements OnChanges, OnInit, ControlValueAccessor {
  @Input() currencyCode: string;
  @Input() disabled = false;
  @Output() onInput: EventEmitter<any>;

  currency$: Observable<CurrencyModel>;

  priceControl: FormControl;

  private onChange: any;
  private onTouched: any;

  constructor(private currencyService: CurrencyService) {
    this.priceControl = new FormControl();
    this.onInput = new EventEmitter<any>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('currencyCode' in changes) {
      this.currency$ = this.currencyService.getCurrencyByCode(this.currencyCode);
      this.propagateChanges();
    }
  }

  ngOnInit() {
    this.priceControl.valueChanges.subscribe(
      () => this.propagateChanges()
    );
  }

  handleInputEvent(event: any): void {
    this.onInput.emit(event);
  }

  private propagateChanges() {
    const price = parseFloat(this.priceControl.value);
    if (this.priceControl.enabled) {
      this.currency$.pipe(
        take(1), map(currency => price * Math.pow(10, currency.decimal_separator))
      ).subscribe(newPrice => this.onChange && this.onChange(Math.round(newPrice)));
    }
  }

  writeValue(obj: any): void {
    const price = parseFloat(`${obj}`.replace(',', '.'));

    this.currency$.pipe(
      take(1), map(currency => price / Math.pow(10, currency.decimal_separator))
    ).subscribe(newPrice => {
      this.priceControl.setValue(newPrice)
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    if (disabled) {
      this.priceControl.disable();
    } else {
      this.priceControl.enable();
    }
  }
}

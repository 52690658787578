import { Component, OnChanges, Input } from '@angular/core';

import { GoogleApiService } from '@shared/providers/google-api.service';

@Component({
  selector: 'app-mobile-map',
  templateUrl: './mobile-map.component.html',
  styleUrls: ['./mobile-map.component.scss']
})
export class MobileMapComponent implements OnChanges {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() width: number;
  @Input() height: number;

  mapImageUrl: string;
  markerImageUrl: string;

  constructor(private googleApiService: GoogleApiService) {
    this.latitude = 0;
    this.longitude = 0;
    this.width = 100;
    this.height = 100;

    this.markerImageUrl = `${document.location.origin}/assets/img/preview/marker.png`;

    // temporary use external service
    this.markerImageUrl = `https://i.imgur.com/zxgjOIi.png`;
  }

  ngOnChanges() {
    this.width = Math.floor(+this.width || 0);
    this.height = Math.floor(+this.height || 0);

    const center = `${this.latitude + 0.002},${this.longitude + 0.015}`;
    const size = `${this.width}x${this.height}`;

    const markers = [
      `icon:${this.markerImageUrl}|${this.latitude},${this.longitude}`
    ];

    const style = [
      'feature:poi|visibility:off',
      'landscape.man_made|color:0xf5f5f5',
      'landscape.natural|color:0xf5f5f5',
      'landscape.natural|element:geometry.stroke|color:0xffffff',
      'landscape.natural|element:labels.text.fill|color:0x787878',
      'feature:road|color:0xffffff',
      'feature:road.highway|color:0xd9d9d9',
      'feature:road|element:labels.text.fill|color:0x787878',
      'feature:water|color:0xc9c9c9'
    ];

    this.googleApiService.getStaticMapImageUrl({
      center, size, markers, style, maptype: 'roadmap', zoom: '13'
    }).subscribe(imageUrl => (this.mapImageUrl = imageUrl));
  }
}

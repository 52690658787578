import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { API_BASE_URL } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, headers?: any, options?: Object): Observable<T> {
    const endpoint = this.getEndpointFor(url);
    return this.httpClient.get<T>(endpoint, { ...options, headers });
  }

  post<T>(url: string, body: any): Observable<T> {
    const endpoint = this.getEndpointFor(url);
    return this.httpClient.post<T>(endpoint, body);
  }

  patch<T>(url: string, body: any): Observable<T> {
    const endpoint = this.getEndpointFor(url);
    return this.httpClient.patch<T>(endpoint, body);
  }

  delete<T>(url: string): Observable<T> {
    const endpoint = this.getEndpointFor(url);
    return this.httpClient.delete<T>(endpoint);
  }

  private getEndpointFor(url: string) {
    if (url[0] === '/') {
      return `${API_BASE_URL}${url}`;
    } else {
      return `${API_BASE_URL}/${url}`;
    }
  }
}

import { Subject, Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  private message: Subject<ToastMessage> = new Subject<ToastMessage>();

  constructor() {}

  setMessage(title: string, content: string, type: ToastMessageType, expirationTime: number, img?: string, navigate?: string, navigationData?: any): void {
    const toastMessage: ToastMessage = {
      title,
      content,
      type,
      expirationTime,
      expirationDate: Math.floor(new Date().getTime()/1000.0) + expirationTime,
      img: img || null,
      navigate: navigate || null,
      navigationData: navigationData || null
    }
    this.message.next(toastMessage);
  }

  getMessage(): Observable<ToastMessage> {
    return this.message.asObservable();
  }

}

export interface ToastMessage {
  title: string;
  type: ToastMessageType;
  expirationTime: number;
  content: string;
  expirationDate: number;
  img?: string;
  navigate?: string;
  navigationData: any;
}


export enum ToastMessageType {
  Info = 'info',
  warn = 'warn',
  error = 'error',
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
})
export class HelpButtonComponent implements OnInit {
  @Input() url: string;
  @Input() withText = false;
  @Input() buttonTranslationKey?: string;

  constructor() {}

  ngOnInit(): void {}

  handleClick(): void {
    window.open(this.url);
  }

  get buttonTextKey(): string {
    return this.buttonTranslationKey || 'buttons.help_center';
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Im port stripe lib as a side effect
import '@stripe/stripe-js';

import Gleap from 'gleap';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    Gleap.initialize("0nHhamSE5j0Yone1mgWF56Xg7FmCu2Pf");
    Gleap.clearIdentity();
    Gleap.clearCustomData();
  })
  .catch(err => console.error(err));

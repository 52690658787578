import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-batch-action',
  templateUrl: './batch-action.component.html',
  styleUrls: ['./batch-action.component.scss']
})
export class BatchActionComponent {
  @Input() icon: string;
  @Input() disabled: boolean;
}

import { Action, createReducer, createSelector, on } from '@ngrx/store';

import * as EventActions from '../actions/event.actions';
import { clearEventState } from '../actions/event.actions';

export const stateKey = 'payment';

export interface State {
  initializing: boolean;
  paying: boolean;
  clientSecret: string;
  currency: string;
  total: number;
  totalWithTax: number;
  error: Error;
}

export const initialState: State = {
  initializing: false,
  paying: false,
  clientSecret: null,
  currency: null,
  total: null,
  totalWithTax: null,
  error: null,
};

export const paymentReducer = createReducer(
  initialState,
  on(
    EventActions.initPayment,
    state => ({ ...initialState, initializing: true }),
  ),
  on(
    EventActions.initPaymentSuccess,
    EventActions.initPaymentFailure,
    state => ({ ...state, initializing: false })
  ),
  on(
    EventActions.initPaymentSuccess,
    (state, { currency, total, totalWithTax, clientSecret }) =>
      ({ ...state, currency, total, totalWithTax, clientSecret }),
  ),
  on(
    EventActions.proceedPayment,
    state => ({ ...state, paying: true })
  ),
  on(
    EventActions.proceedPaymentSuccess,
    EventActions.proceedPaymentFailure,
    state => ({ ...state, paying: false })
  ),
  on(
    EventActions.initPaymentFailure,
    EventActions.proceedPaymentFailure,
    (state, { error }) => ({ ...state, error })
  ),

  on(clearEventState, (state) => ({ ...initialState })),
);

export function reducer(state: State, action: Action): State {
  return paymentReducer(state, action);
}

export const selectInitializing = (state: State) => state.initializing;

export const selectPaying = (state: State) => state.paying;

export const selectClientSecret = (state: State) => state.clientSecret;

export const selectCurrency = (state: State) => state.currency;

export const selectTotal = (state: State) => state.total;

export const selectTotalWithTax = (state: State) => state.totalWithTax;

export const selectError = (state: State) => state.error;

export const selectTax = createSelector(
  selectTotal,
  selectTotalWithTax,
  (total, withTax) => withTax - total
);

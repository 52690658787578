import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromConsent from './reducers/consent.reducer';
import * as fromConsentEvent from './reducers/consent-event.reducer';

export const featureKey = 'consentsFeature';

export interface State {
  [fromConsent.childKey]: fromConsent.State;
  [fromConsentEvent.childKey]: fromConsentEvent.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromConsent.childKey]: fromConsent.reducer,
  [fromConsentEvent.childKey]: fromConsentEvent.reducer,
}

export const selectFeatureKey = createFeatureSelector<State>(featureKey);

export const selectConsentFeature = createSelector(
  selectFeatureKey,
  state => state[fromConsent.childKey]
);

export const selectConsentEventFeature = createSelector(
  selectFeatureKey,
  state => state[fromConsentEvent.childKey]
);

// CONSENT - SELECTORS START

export const selectConsentLoading = createSelector(
  selectConsentFeature,
  fromConsent.selectLoading
);

export const selectConsentLoaded = createSelector(
  selectConsentFeature,
  fromConsent.selectLoaded
);

export const selectConsentAccepting = createSelector(
  selectConsentFeature,
  fromConsent.selectAccepting
);

export const selectConsentError = createSelector(
  selectConsentFeature,
  fromConsent.selectError
);

export const selectConsentEntities = createSelector(
  selectConsentFeature,
  fromConsent.selectEntities
);

// CONSENT - SELECTORS END

// CONSENT EVENT - SELECTORS START

export const selectConsentEventLoading = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectLoading
);

export const selectConsentEventLoaded = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectLoaded
);

export const selectConsentEventSaving = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectSaving
);

export const selectConsentEventCreating = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectCreating
);

export const selectConsentEventUpdating = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectUpdating
);

export const selectConsentEventTranslationsLoading = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectTranslationsLoading
);

export const selectConsentEventTranslationsCreating = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectTranslationsCreating
);

export const selectConsentEventTranslationsUpdating = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectTranslationsUpdating
);

export const selectConsentEventError = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectError
);

export const selectConsentEventEntities = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectEntities
);

export const selectConsentEventTranslations = createSelector(
  selectConsentEventFeature,
  fromConsentEvent.selectTranslations
);

export const selectConsentEventSchemaTranslations = (schemaId: number) => createSelector(
  selectConsentEventFeature,
  state => fromConsentEvent.selectSchemaTranslations(state, schemaId)
);

export const selectConsentSchemaPublishing = (schemaId: number) => createSelector(
  selectConsentEventFeature,
  state => fromConsentEvent.selectPublishing(state, schemaId)
);

export const selectConsentSchemaUnpublishing = (schemaId: number) => createSelector(
  selectConsentEventFeature,
  state => fromConsentEvent.selectUnpublishing(state, schemaId)
);

export const selectConsentSchemaPublishingError = (schemaId: number) => createSelector(
  selectConsentEventFeature,
  state => fromConsentEvent.selectPublishingError(state, schemaId)
);

export const selectConsentSchemaUnpublishingError = (schemaId: number) => createSelector(
  selectConsentEventFeature,
  state => fromConsentEvent.selectUnpublishingError(state, schemaId)
);

// CONSENT EVENT - SELECTORS END

import { createReducer, on } from '@ngrx/store';

import { initialState, TokenStatus } from './token.state';
import * as tokenActions from './token.actions';

export const tokenFeatureKey = 'token';

export const tokenReducer = createReducer(
  initialState,
  on(
    tokenActions.tokenRetrieve, //
    () => ({ ...initialState, tokenStatus: TokenStatus.Retrieving })
  ),
  on(
    tokenActions.tokenRefresh, //
    state => ({ ...state, tokenStatus: TokenStatus.Refreshing })
  ),
  on(
    tokenActions.tokenRetrieveSuccess, //
    tokenActions.tokenRefreshSuccess,
    tokenActions.tokenSetup,
    (state, { token }) => ({ ...initialState, tokenStatus: TokenStatus.Authenticated, token })
  ),
  on(
    tokenActions.tokenRetrieveFailure, //
    tokenActions.tokenRefreshFailure,
    (state, { error }) => ({ ...initialState, error })
  ),
  on(
    tokenActions.tokenClear, //
    () => ({ ...initialState })
  )
);

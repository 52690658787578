import { Component, Input, ViewChild, HostListener, ElementRef, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-copy-input',
  templateUrl: './copy-input.component.html',
  styleUrls: ['./copy-input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CopyInputComponent), multi: true },
  ]
})
export class CopyInputComponent implements ControlValueAccessor{
  @Input() value: string;
  @Input() placeholder: string = 'External link to copy';
  @Input() readOnly: boolean = true;

  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
  @ViewChild('buttonElement', { static: true }) buttonElement: ElementRef;

  constructor(private renderer: Renderer2) {}

  @HostListener('click', ['$event'])
  onHostClick() {
    this.inputElement.nativeElement.select();
    document.execCommand('copy');
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.inputElement.nativeElement, 'disabled', isDisabled);
    this.renderer.setProperty(this.buttonElement.nativeElement, 'disabled', isDisabled);
  }
}

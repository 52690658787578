import { createAction, props } from '@ngrx/store';

import { WwwResourceModel, WwwResourceTranslationModel } from '@components/www-resource/models/www-resource.model';

export const modalCreateWwwResource = createAction(
  '[WwwResource/Modal] Modal Create WwwResources',
  props<{ wwwResource: WwwResourceModel, translations: WwwResourceTranslationModel[] }>()
);

export const modalCreateWwwResourceSuccess = createAction(
  '[WwwResource/API] Modal Create WwwResources Success',
  props<{ wwwResource: WwwResourceModel }>()
);

export const modalCreateWwwResourceFailure = createAction(
  '[WwwResource/API] Modal Create WwwResources Failure',
  props<{ error: Error }>()
);

export const modalUpdateWwwResource = createAction(
  '[WwwResource/Modal] Modal Update WwwResources',
  props<{ wwwResource: WwwResourceModel, translations: WwwResourceTranslationModel[] }>()
);

export const modalUpdateWwwResourceSuccess = createAction(
  '[WwwResource/API] Modal Update WwwResources Success',
  props<{ wwwResource: WwwResourceModel }>()
);

export const modalUpdateWwwResourceFailure = createAction(
  '[WwwResource/API] Modal Update WwwResources Failure',
  props<{ error: Error }>()
);

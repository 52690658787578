import { Directive, OnInit, OnDestroy, Input } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CheckboxGroupService } from '@shared/providers/checkbox-group.service';

@Directive({
  selector: '[appCheckboxControl]'
})
export class CheckboxControlDirective implements OnInit, OnDestroy {
  @Input() appCheckboxControl: any;

  private teardown$: Subject<void>;

  constructor(private checkboxGroup: CheckboxGroupService, private checkboxRef: MatCheckbox) {
    this.teardown$ = new Subject<void>();
  }

  get data(): any {
    return this.appCheckboxControl;
  }

  get checked(): boolean {
    return this.checkboxRef.checked;
  }

  get indeterminate(): boolean {
    return this.checkboxRef.indeterminate;
  }

  ngOnInit() {
    this.checkboxGroup.addControl(this);

    this.checkboxRef.change.pipe(takeUntil(this.teardown$)).subscribe(
      () => this.checkboxGroup.update()
    );
  }

  ngOnDestroy() {
    this.checkboxGroup.removeControl(this);

    this.teardown$.next();
    this.teardown$.complete();
  }

  check() {
    this.checkboxRef.checked = true;
    this.checkboxGroup.update();
  }

  uncheck() {
    this.checkboxRef.checked = false;
    this.checkboxGroup.update();
  }
}

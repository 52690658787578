import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { EventStatus } from '../models/event.model';

import * as fromLanguage from '@store/features/language/reducers';

import * as fromEvent from './event.reducer';
import * as fromEventLanguage from './event-language.reducer';
import * as fromEventTranslation from './event-translation.reducer';
import * as fromSummary from './summary.reducer';
import * as fromPayment from './payment.reducer';

export const featureKey = 'eventFeature';

export interface State {
  [fromEvent.featureKey]: fromEvent.State;
  [fromEventLanguage.featureKey]: fromEventLanguage.State;
  [fromEventTranslation.featureKey]: fromEventTranslation.State;
  [fromSummary.stateKey]: fromSummary.State;
  [fromPayment.stateKey]: fromPayment.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromEvent.featureKey]: fromEvent.reducer,
  [fromEventLanguage.featureKey]: fromEventLanguage.reducer,
  [fromEventTranslation.featureKey]: fromEventTranslation.reducer,
  [fromSummary.stateKey]: fromSummary.reducer,
  [fromPayment.stateKey]: fromPayment.reducer,
};

export const selectEventFeature = createFeatureSelector<State>(featureKey);

export const selectEventState = createSelector(
  selectEventFeature,
  state => state ? state[fromEvent.featureKey] : null
);

export const selectActiveEvent = createSelector(
  selectEventState,
  fromEvent.selectActiveEvent
);

export const selectEventLoading = createSelector(
  selectEventState,
  fromEvent.selectEventLoading
);

export const selectEventLoaded = createSelector(
  selectEventState,
  fromEvent.selectEventLoaded
);

export const selectEventActiveId = createSelector(
  selectEventState,
  fromEvent.selectActiveEventId
);

export const selectEventSaving = createSelector(
  selectEventState,
  fromEvent.selectEventSaving
);

export const selectEventPublishing = createSelector(
  selectEventState,
  fromEvent.selectEventPublishing
);

export const selectEventUnpublishing = createSelector(
  selectEventState,
  fromEvent.selectEventUnpublishing
);

export const selectEventError = createSelector(
  selectEventState,
  fromEvent.selectEventError
);

export const selectEventLanguageState = createSelector(
  selectEventFeature,
  state => state[fromEventLanguage.featureKey]
);

export const selectAllEventLanguages = createSelector(
  selectEventLanguageState,
  fromEventLanguage.selectAllEventLanguages
);

export const selectAllEventLanguagesWithNames = createSelector(
  selectAllEventLanguages,
  fromLanguage.selectLanguageNames,
  (languages, names) => languages.map(l => ({ ...l, name: names[l.code] }))
);

export const selectDefaultEventLanguage = createSelector(
  selectEventLanguageState,
  fromEventLanguage.selectDefaultEventLanguage
);

export const selectDefaultEventLanguageWithName = createSelector(
  selectDefaultEventLanguage,
  fromLanguage.selectLanguageNames,
  (language, names) => language && ({ ...language, name: names[language.code] })
);

export const selectEventLanguageLoading = createSelector(
  selectEventLanguageState,
  fromEventLanguage.selectEventLanguageLoading
);

export const selectEventTranslationState = createSelector(
  selectEventFeature,
  state => state[fromEventTranslation.featureKey]
);

export const selectEventTranslationEntities = createSelector(
  selectEventTranslationState,
  fromEventTranslation.selectEventTranslationEntities
);

export const selectAllEventTranslations = createSelector(
  selectEventTranslationState,
  fromEventTranslation.selectAllEventTranslations
);

export const selectEventTranslationLoading = createSelector(
  selectEventTranslationState,
  fromEventTranslation.selectEventTranslationLoading
);

export const selectEventTranslationError = createSelector(
  selectEventTranslationState,
  fromEventTranslation.selectEventTranslationError
);

export const selectSummaryState = createSelector(
  selectEventFeature,
  state => state[fromSummary.stateKey]
);

export const selectSummaryLoading = createSelector(
  selectSummaryState,
  fromSummary.selectLoading
);

export const selectSummaryLoaded = createSelector(
  selectSummaryState,
  fromSummary.selectLoaded
);

export const selectSummaryError = createSelector(
  selectSummaryState,
  fromSummary.selectError
);

export const selectSummaryPlan = createSelector(
  selectSummaryState,
  fromSummary.selectPlan
);

export const selectCurrency = createSelector(
  selectSummaryState,
  fromSummary.selectCurrency
);

export const selectTotalPrice = createSelector(
  selectSummaryState,
  fromSummary.selectTotal
);

export const selectContentUploadPrice = createSelector(
  selectSummaryState,
  fromSummary.selectContentUploadPrice
);

export const selectDedicatedAppPrice = createSelector(
  selectSummaryState,
  fromSummary.selectDedicatedAppPrice
);

export const selectPriceTags = createSelector(
  selectSummaryState,
  fromSummary.selectPriceTags
);

export const selectPaymentState = createSelector(
  selectEventFeature,
  state => state[fromPayment.stateKey]
);

export const selectPaymentInitializing = createSelector(
  selectPaymentState,
  fromPayment.selectInitializing
);

export const selectPaymentPaying = createSelector(
  selectPaymentState,
  fromPayment.selectPaying
);

export const selectPaymentClientSecret = createSelector(
  selectPaymentState,
  fromPayment.selectClientSecret
);

export const selectPaymentCurrency = createSelector(
  selectPaymentState,
  fromPayment.selectCurrency
);

export const selectPaymentTotal = createSelector(
  selectPaymentState,
  fromPayment.selectTotal
);

export const selectPaymentTotalWithTax = createSelector(
  selectPaymentState,
  fromPayment.selectTotalWithTax
);

export const selectPaymentTax = createSelector(
  selectPaymentState,
  fromPayment.selectTax
);

export const selectPaymentError = createSelector(
  selectPaymentState,
  fromPayment.selectError
);

<ng-content></ng-content>

<div class="user-profile" title="{{ 'user_select.click_to_select' | translate }}" (click)="toggleDropdown()">
  <ng-container *ngIf="eventUser && !static; else noFromUser">
    <app-avatar [picture]="eventUser.picture" [firstName]="eventUser.firstName" [lastName]="eventUser.lastName"></app-avatar>
    <p>{{ eventUser.firstName }} {{ eventUser.lastName }}</p>
  </ng-container>

  <ng-template #noFromUser>
    <p>{{ 'user_select.click_to_select' | translate }}</p>
  </ng-template>
</div>

<app-user-select-dropdown *ngIf="showDropdown" [event]="event" (selectUser)="selectUser($event)"></app-user-select-dropdown>

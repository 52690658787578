import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { AgendaDayModel } from '@components/agenda/models/agenda-day.model';

import * as AgendaDayActions from '../actions/agenda-day.actions';
import { selectEvent } from '@store/features/event/actions';

export const featureKey = 'agendaDay';

export interface State extends EntityState<AgendaDayModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
  activeId: number;
  updatedId: number;
}

export const adapter = createEntityAdapter<AgendaDayModel>({
  sortComparer: (a, b) => Date.parse(a.date) - Date.parse(b.date),
});

export const initialState: State = {
  ...adapter.getInitialState(),
  loading: false,
  loaded: false,
  error: null,
  activeId: 0,
  updatedId: 0,
};

export const agendaDayReducer = createReducer(
  initialState,
  on(AgendaDayActions.loadAgendaDays, state => ({
    ...initialState,
    loading: true,
  })),
  on(AgendaDayActions.loadAgendaDaysSuccess, (state, { days }) =>
    adapter.setAll(days, {
      ...state,
      loading: false,
      loaded: true,
      activeId: days[0] && days[0].id,
    })
  ),
  on(AgendaDayActions.loadAgendaDaysFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    AgendaDayActions.createAgendaDay,
    AgendaDayActions.deleteAgendaDay,
    state => ({ ...state, error: null })
  ),
  on(AgendaDayActions.createAgendaDaySuccess, (state, { day }) =>
    adapter.addOne(day, { ...state, activeId: day.id, updatedId: day.id })
  ),
  on(AgendaDayActions.deleteAgendaDaySuccess, (state, { day }) => {
    const mutated = adapter.removeOne(day.id, { ...state, updatedId: day.id });
    const days = (mutated.ids as any[]).map(id => mutated.entities[id]);

    // Select first day on active removed one
    if (!mutated.entities[mutated.activeId]) {
      mutated.activeId = days[0] && days[0].id;
    }

    return mutated;
  }),
  on(
    AgendaDayActions.deleteAgendaDayFailure,
    AgendaDayActions.deleteAgendaDayFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(AgendaDayActions.selectAgendaDay, (state, { dayId }) => ({
    ...state,
    activeId: dayId,
  })),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return agendaDayReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectAgendaDayIds = selectIds;

export const selectAgendaDayEntiites = selectEntities;

export const selectAllAgendaDays = selectAll;

export const selectAgendaDayLoading = (state: State) => state.loading;

export const selectAgendaDayLoaded = (state: State) => state.loaded;

export const selectAgendaDayError = (state: State) => state.error;

export const selectActiveAgendaDayId = (state: State) => state.activeId;

export const selectActiveAgendaDay = createSelector(
  selectAgendaDayEntiites,
  selectActiveAgendaDayId,
  (entities, activeId) => entities[activeId]
);

export const selectUpdatedAgendaDayId = (state: State) => state.updatedId;

export const selectUpdatedAgendaDay = createSelector(
  selectAgendaDayEntiites,
  selectUpdatedAgendaDayId,
  (entities, updatedId) => entities[updatedId]
);

import { Component, OnInit } from '@angular/core';
import { AppLang } from '@app/backend/models/AppLang';
import { LangService, UserLang } from '@shared/providers/lang.service';

@Component({
  selector: 'app-application-language-selector',
  templateUrl: './application-language-selector.component.html',
  styleUrls: ['./application-language-selector.component.scss']
})
export class ApplicationLanguageSelectorComponent implements OnInit {

  langs: Array<AppLang> = [
    {
      lang: 'en',
      friendly_lang: 'ENG',
      img: './assets/img/icons/united-kingdom.svg',
    },
    {
      lang: 'pl',
      friendly_lang: 'PL',
      img: './assets/img/icons/poland.svg',
    }
  ];

  currentLang: AppLang = this.langs[0];

  constructor(private langService: LangService) { }

  ngOnInit(): void {
    this.loadLangIntoTemplate();
  }

  loadLangIntoTemplate(): void {
    const currentLang = this.langService.getCurrentUserLang();
    this.langs.map(lang => {
      if(lang.lang === currentLang) {
        this.currentLang = lang;
      }
    });
  }

  setLang(lang: AppLang): void {
    const changedLang: UserLang = lang.lang as UserLang;
    this.langService.setCurrentUserLang(changedLang);
    this.loadLangIntoTemplate();
  }

}

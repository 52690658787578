import { Component, AfterViewInit, OnChanges, Input, HostBinding, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-aspect-canvas',
  templateUrl: './aspect-canvas.component.html',
  styleUrls: ['./aspect-canvas.component.scss']
})
export class AspectCanvasComponent implements AfterViewInit {
  @Input() width: number;
  @Input() height: number;
  @Input() sizeUnit: string;

  @HostBinding('style.transform') get _transform() {
    return `scale(${this.scale})`;
  }

  @HostBinding('style.width') get _width() {
    return `${this.width}${this.sizeUnit}`;
  }

  @HostBinding('style.height') get _height() {
    return `${this.height}${this.sizeUnit}`;
  }

  scale: number;

  constructor(private elementRef: ElementRef) {
    this.width = 100;
    this.height = 100;
    this.sizeUnit = 'pt';
    this.scale = 1;
  }

  ngAfterViewInit() {
    // defer aspect ratio update
    setTimeout(() => this.update(), 0);
  }

  update() {
    const parentBoundings = this.getParentBounds();

    if (parentBoundings) {
      const { width, height } = parentBoundings;
      const aspectRatio = this.getAspectRatioFor(width, height);

      this.scale = aspectRatio;
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(ev: Event) {
    this.update();
  }

  private getParentBounds() {
    const parentElement: HTMLElement = this.elementRef.nativeElement.parentNode;
    const boundings = parentElement && parentElement.getBoundingClientRect();

    return boundings;
  }

  private getAspectRatioFor(width: number, height: number) {
    const hostWidth = this.elementRef.nativeElement.offsetWidth;
    const hostHeight = this.elementRef.nativeElement.offsetHeight;

    return Math.min(width / hostWidth, height / hostHeight);
  }
}

function convertToNumber(value: any) {
  // convert string into number
  if (typeof value === 'string') {
    return (+value) || 0;
  }

  return value;
}

import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EventLanguageModel } from '@store/features/event/models/event-language.model';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageSwitchComponent),
      multi: true
    }
  ]
})
export class LanguageSwitchComponent implements ControlValueAccessor {
  @Input() languages: EventLanguageModel[];
  @Input() activeLanguage: EventLanguageModel;

  @Output() appLanguageChange: EventEmitter<EventLanguageModel>;

  private onChange: (value: EventLanguageModel[]) => void;
  private onTouched: () => void;

  constructor() {
    this.appLanguageChange = new EventEmitter();
  }

  isDefault(language: EventLanguageModel) {
    const active = this.activeLanguage;
    return active && active.code === language.code;
  }

  onLanguageChange(ev: Event, language: EventLanguageModel) {
    this.appLanguageChange.emit(language);
  }

  writeValue(languages: EventLanguageModel[]): void {
    this.languages = languages;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

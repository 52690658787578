import { Directive, OnDestroy, AfterContentInit, ContentChildren, QueryList } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';

import { FormContainerService } from '@shared/providers/form-container.service';

import { FormValidateDirective } from './form-validate.directive';

@Directive({
  selector: '[appFormContainer]',
  providers: [
    FormContainerService,
  ]
})
export class FormContainerDirective implements OnDestroy, AfterContentInit {
  @ContentChildren(FormValidateDirective, { descendants: true }) formValidators: QueryList<FormValidateDirective>;

  private teardown$: Subject<void>;

  constructor(private formContainer: FormContainerService) {
    this.teardown$ = new Subject();
  }

  ngOnDestroy() {
    this.teardown$.next();
    this.teardown$.complete();
  }

  ngAfterContentInit() {
    this.formValidators.changes.pipe(takeUntil(this.teardown$), startWith(this.formValidators)).subscribe(
      validators => this.populateFormValidators()
    );
  }

  private populateFormValidators() {
    const validators = this.formValidators.toArray();
    this.formContainer.setValidators(validators);
  }
}

import { GetVenueIconModel } from "@components/venue/models/venue.model";
import { Action, createReducer, on } from "@ngrx/store";
import * as IconActions from '@store/features/venue/actions/icon.actions';

export interface State {
  loading: boolean;
  error: Error;
  icons: GetVenueIconModel[];
}

export const initialState = {
  loading: false,
  error: null,
  icons: []
};

export const iconReducer = createReducer(
  initialState,
  on(IconActions.loadVenueIcons, state => ({ ...state, loading: true })),
  on(IconActions.loadVenueIconsSuccess, (state, { icons }) => ({ ...state, loading: false, icons })),
  on(IconActions.loadVenueIconsFailure, (state, { error }) => ({ ...state, loading: false, error }))
);

export function reducer(state: State, action: Action): State {
  return iconReducer(state, action);
}

export const selectError = (state: State) => state.error;
export const selectLoading = (state: State) => state.loading;
export const selectIcons = (state: State) => state.icons;
<div class="form-group">
  <div class=" input-group input-group-sm mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text" id="inputGroup-sizing-default">
        <img class="logo" src="{{iconUrl}}" alt="">
      </span>
    </div>
    <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
      placeholder="{{placeholder}}">
  </div>
</div>

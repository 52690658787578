import { Injectable } from '@angular/core';

import { Observable, concat, of, throwError, EMPTY } from 'rxjs';
import { map, filter, concatMap, switchMap, reduce, defaultIfEmpty } from 'rxjs/operators';

import { FullApiService } from '@shared/providers/full-api.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SurveyQuizModel } from '@components/survey-quiz/models/survey-quiz.model';
import { SurveyQuizQuestionModel } from '@components/survey-quiz/models/survey-quiz-question.model';

import {
  SurveyQuizAnswerModel,
  SurveyQuizAnswerPostModel,
  SurveyQuizAnswerPatchModel
} from '@components/survey-quiz/models/survey-quiz-answer.model';

import {
  DiffResult,
  DiffResultAction,
  diff
} from '@utils/diff.util';

@Injectable({
  providedIn: 'root'
})
export class SurveyQuizAnswerProviderService {
  constructor(private apiService: FullApiService) {}

  loadSurveyQuizAnswers(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel,
    surveyQuizQuestion: SurveyQuizQuestionModel): Observable<SurveyQuizAnswerModel[]> {

    return this.apiService.getSurveyQuestionAnswers(event.id, component.id, surveyQuiz.id, surveyQuizQuestion.id);
  }

  createSurveyQuizAnswer(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel,
    surveyQuizQuestion: SurveyQuizQuestionModel, surveyQuizAnswer: SurveyQuizAnswerModel): Observable<SurveyQuizAnswerModel> {

    const SurveyQuizAnswerData = mapSurveyQuizAnswerPost(surveyQuizAnswer);
    return this.apiService.addSurveyQuestionAnswer(
      event.id, component.id, surveyQuiz.id, surveyQuizQuestion.id, SurveyQuizAnswerData
    );
  }

  updateSurveyQuizAnswer(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel,
    surveyQuizQuestion: SurveyQuizQuestionModel, surveyQuizAnswer: SurveyQuizAnswerModel): Observable<SurveyQuizAnswerModel> {

    const surveyQAnswerData = mapSurveyQuizAnswerPatch(surveyQuizAnswer);
    return this.apiService.updateSurveyQuestionAnswer(
      event.id, component.id, surveyQuiz.id, surveyQuizQuestion.id, surveyQuizAnswer.id, surveyQAnswerData
    );
  }

  deleteSurveyQuizAnswer(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel,
    surveyQuizQuestion: SurveyQuizQuestionModel, surveyQuizAnswer: SurveyQuizAnswerModel): Observable<boolean> {

    return this.apiService.removeSurveyQuestionAnswer(
      event.id, component.id, surveyQuiz.id, surveyQuizQuestion.id, surveyQuizAnswer.id
    ).pipe(
      map(({ success }) => success)
    );
  }

  diffSurveyQuizAnswers(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestion: SurveyQuizQuestionModel,
    from: SurveyQuizAnswerModel[], to: SurveyQuizAnswerModel[]): Observable<SurveyQuizAnswerModel[]> {

    const diffResults = diff(from, to, (a, b) => (a.id === b.id));

    const addObservables = this.makeRootDiffObservableFrom(
      event, component, surveyQuiz, surveyQuizQuestion, diffResults, DiffResultAction.Create
    );

    const updateObservables = this.makeRootDiffObservableFrom(
      event, component, surveyQuiz, surveyQuizQuestion, diffResults, DiffResultAction.Update
    );

    const removeObservables = this.makeRootDiffObservableFrom(
      event, component, surveyQuiz, surveyQuizQuestion, diffResults, DiffResultAction.Delete
    );

    return concat(addObservables, updateObservables, removeObservables).pipe(
      reduce((acc, surveyQuizAnswer) => ([...acc, surveyQuizAnswer]), []),
      defaultIfEmpty([])
    );
  }

  private makeRootDiffObservableFrom(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestion: SurveyQuizQuestionModel,
    diffResults: DiffResult<SurveyQuizAnswerModel>[], action: DiffResultAction): Observable<SurveyQuizAnswerModel> {

    return of(...diffResults).pipe(
      filter(result => (result.action === action)),
      concatMap(result => this.makeDiffObservableFrom(event, component, surveyQuiz, surveyQuizQuestion, result)),
    );
  }

  private makeDiffObservableFrom(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestion: SurveyQuizQuestionModel,
    diffResult: DiffResult<SurveyQuizAnswerModel>): Observable<SurveyQuizAnswerModel> {

    if (diffResult.action === DiffResultAction.Create) {
      return this.createSurveyQuizAnswer(event, component, surveyQuiz, surveyQuizQuestion, diffResult.to);
    } else if (diffResult.action === DiffResultAction.Update) {
      return this.updateSurveyQuizAnswer(event, component, surveyQuiz, surveyQuizQuestion, diffResult.to);
    } else if (diffResult.action === DiffResultAction.Delete) {
      return this.deleteSurveyQuizAnswer(event, component, surveyQuiz, surveyQuizQuestion, diffResult.from).pipe(
        switchMap(() => EMPTY)
      );
    }

    return throwError(new Error(`Unknown diff action '${diffResult.action}'`));
  }
}

function mapSurveyQuizAnswerPost(surveyQuizAnswer: SurveyQuizAnswerModel): SurveyQuizAnswerPostModel {
  return {
    mode: surveyQuizAnswer.mode, order: surveyQuizAnswer.order,
    correct: surveyQuizAnswer.correct, answer: surveyQuizAnswer.answer
  };
}

function mapSurveyQuizAnswerPatch(surveyQuizAnswer: SurveyQuizAnswerModel): SurveyQuizAnswerPatchModel {
  return {
    mode: surveyQuizAnswer.mode, order: surveyQuizAnswer.order,
    correct: surveyQuizAnswer.correct, answer: surveyQuizAnswer.answer
  };
}

<div class="dialog-header">
  <h2 mat-dialog-title>{{ 'dl.dl_builder' | translate }}</h2>
  <button class="btn btn-outline-danger clear-button" (click)="clear()">
    <img src="/assets/icons/rubber.svg" alt="Clear filters" />
    <span>{{ 'application_users.clear_filters' | translate }}</span>
  </button>
</div>
<ng-container appFormContainer>
  <mat-dialog-content>
    <div class="col-12 form-group mb-3">

      <div *ngIf="error && submitted" class="alert alert-danger" role="alert">
        {{ error }}
      </div>

      <!-- DL main place (type) selector -->
      <app-dropdown-with-search
        [required]="true"
        [selected]="dlService.selectedDlType$ | async"
        [options]="dlService.dlTypes$ | async"
        [label]="'dl.choose_dl_place_label' | translate"
        [i18n]="true"
        (onSelect)="selectDlType($event)">
      </app-dropdown-with-search>

      <!-- component selector -->
      <div class="dropdown-container" *ngIf="(dlService.isComponentDropdownVisible$ | async) && (dlService.selectedDlType$ | async)">
        <app-dropdown-with-search [required]="true" [selected]="dlService.selectedComponentOption$ | async" [options]="dlService.components$ | async" [label]="'dl.choose_component_label' | translate" (onSelect)="selectComponent($event)"></app-dropdown-with-search>
      </div>

      <!-- DL dynamic properties -->
      <div class="dropdown-container" *ngFor="let dropdown of dlService.dynamicDropdowns$ | async; let index = index" [attr.data-index]="index">
        <app-dropdown-with-search [customTemplate]="((dlService.selectedDlType$ | async).value === feedWallConst) && index === 1" [required]="dropdown.required" [selected]="getSelectedDropdownItem(index) | async" [disabled]="isDynamicDropdownDisabled(index)" [label]="dropdown.label" [options]="dropdown.options | async" (onSelect)="selectDynamicOpt($event, index)">
          <ng-template let-option #item *ngIf="((dlService.selectedDlType$ | async).value === feedWallConst) && index === 1">
            <div class="feedwall-thread">
              <p>{{ option.value.user.first_name }} {{ option.value.user.last_name }}</p>
              <p>{{ getCuttedMessageString(option.value.message) }}</p>
              <p>{{ option.value.created_at | date: 'shortDate' }}</p>
            </div>
          </ng-template>
        </app-dropdown-with-search>
      </div>

    </div>
  </mat-dialog-content>
</ng-container>
<mat-dialog-actions>
  <button type="button" class="btn mr-2" mat-dialog-close>{{ 'buttons.cancel' | translate }}</button>
  <button type="button" class="btn btn-primary" (click)="save($event)">{{ 'buttons.save_changes' | translate }}</button>
</mat-dialog-actions>

<app-spinner-overlay *ngIf="dlService.isLoading$ | async"></app-spinner-overlay>

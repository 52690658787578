import { Observable, of, concat, throwError, EMPTY } from 'rxjs';
import { switchMap, concatMap, filter, reduce } from 'rxjs/operators';

export enum DiffResultAction {
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}

export interface DiffResult<T> {
  action: DiffResultAction;
  from: T;
  to: T;
}

export interface DiffResultActionMap<T> {
  [DiffResultAction.Create]: (result: DiffResult<T>) => Observable<T>;
  [DiffResultAction.Update]: (result: DiffResult<T>) => Observable<T>;
  [DiffResultAction.Delete]: (result: DiffResult<T>) => Observable<any>;
}

export type CompareFunc<T> = (a: T, b: T) => boolean;

export function diff<T>(from: T[], to: T[], hasSameId: (from: T, to: T) => boolean): DiffResult<T>[] {
  return to.map(dest => diffElement(dest, from, hasSameId)).concat(diffReduce(from, to, hasSameId));
}

function diffElement<T>(dest: T, sourceArray: T[], hasSameId: (from: T, to: T) => boolean): DiffResult<T> {
  const foundElement = sourceArray.find(element => hasSameId(element, dest));

  if (foundElement) {
    return { action: DiffResultAction.Update, from: foundElement, to: dest };
  } else {
    return { action: DiffResultAction.Create, from: null, to: dest };
  }
}

function diffReduce<T>(from: T[], to: T[], hasSameId: (from: T, to: T) => boolean): DiffResult<T>[] {
  return from.filter(source => !to.some(dest => hasSameId(source, dest))).map<DiffResult<T>>(
    removedElement => ({ action: DiffResultAction.Delete, from: removedElement, to: null })
  );
}

export function diffEntities<T>(from: T[], to: T[], compareFunc: CompareFunc<T>, actionMap: DiffResultActionMap<T>): Observable<T[]> {
  const diffResults = diff(from, to, compareFunc);

  const addObservables = buildDiffEntities(actionMap, diffResults, DiffResultAction.Create);
  const updateObservables = buildDiffEntities(actionMap, diffResults, DiffResultAction.Update);
  const removeObservables = buildDiffEntities(actionMap, diffResults, DiffResultAction.Delete);

  return concat(addObservables, updateObservables, removeObservables).pipe(
    reduce((acc, partnerContact) => ([...acc, partnerContact]), [])
  );
}

function buildDiffEntities<T>(actionMap: DiffResultActionMap<T>, diffResults: DiffResult<T>[], action: DiffResultAction): Observable<T> {
  return of(...diffResults).pipe(
    filter(result => (result.action === action)),
    concatMap(result => buildDiffEntity(actionMap, result)),
  );
}

function buildDiffEntity<T>(actionMap: DiffResultActionMap<T>, diffResult: DiffResult<T>): Observable<T> {
  if (diffResult.action === DiffResultAction.Create) {
    return actionMap[DiffResultAction.Create](diffResult);
  } else if (diffResult.action === DiffResultAction.Update) {
    return actionMap[DiffResultAction.Update](diffResult);
  } else if (diffResult.action === DiffResultAction.Delete) {
    return actionMap[DiffResultAction.Delete](diffResult).pipe(
      switchMap(() => EMPTY)
    );
  }

  return throwError(new Error(`Unknown diff action '${diffResult.action}'`));
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const restrictedTagValues = [
  'All users',
  'Logged in users',
  'Not logged in users',
  'No ticket'
];

export function restrictedTagValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputValue = control.value ? control.value.toLowerCase() : '';
    const restricted = restrictedTagValues.some(value => value.toLowerCase() === inputValue);
    
    return restricted ? { restrictedTag: { value: control.value } } : null;
  };
}
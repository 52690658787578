import { createFeatureSelector, createSelector, ActionReducerMap } from '@ngrx/store';

import * as fromTags from './tags.reducer';
import * as fromAccessGroup from './access-group.reducer';

export { initialState, reducer } from './tags.reducer';

export const featureKey = 'tagsFeature';

export interface State {
  [fromTags.featureKey]: fromTags.State;
  [fromAccessGroup.featureKey]: fromAccessGroup.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromTags.featureKey]: fromTags.reducer,
  [fromAccessGroup.featureKey]: fromAccessGroup.reducer,
}

export const selectFeatureKey = createFeatureSelector<State>(featureKey);

export const selectTagsFeature = createSelector(
  selectFeatureKey,
  state => state[fromTags.featureKey]
);

export const selectAccessGroupFeature = createSelector(
  selectFeatureKey,
  state => state[fromAccessGroup.featureKey]
);

// APPLICATION TAGS SELECTORS
const selectTagsLoading = createSelector(
  selectTagsFeature,
  fromTags.selectLoading
);

const selectTags = createSelector(
  selectTagsFeature,
  fromTags.selectTags
);

const selectUserTags = createSelector(
  selectTags,
  tags => tags.userTags
);

const selectUserGroupTags = createSelector(
  selectTags,
  tags => tags.userGroupTags
);

const selectAgendaPathTags = createSelector(
  selectTags,
  tags => tags.agendaPathTags
);

const selectAgendaPlaceTags = createSelector(
  selectTags,
  tags => tags.agendaPlaceTags
);

const selectExhibitorTags = createSelector(
  selectTags,
  tags => tags.exhibitorTags
);

const selectError = createSelector(
  selectTagsFeature,
  fromTags.selectError
);

const selectReordering = createSelector(
  selectTagsFeature,
  fromTags.selectReordering
);

const selectUserGroups = createSelector(
  selectTagsFeature,
  fromTags.selectUserGroups
);

const selectUserGroupsByUUID = (uuid: string) => createSelector(
  selectTagsFeature,
  fromTags.selectUserGroupsByUUID(uuid)
);

const selectLoadingAuthorizedUser = createSelector(
  selectTagsFeature,
  fromTags.selectLoadingAuthorizedUser
);

export const ApplicationTagsSelectors = {
  isLoading: selectTagsLoading,
  getTags: selectTags,
  getUserTags: selectUserTags,
  getUserGroupTags: selectUserGroupTags,
  getAgendaPathTags: selectAgendaPathTags,
  getAgendaPlaceTags: selectAgendaPlaceTags,
  getExhibitorTags: selectExhibitorTags,
  getError: selectError,
  getReordering: selectReordering,
  getUserGroups: selectUserGroups,
  getUserGroupsByUUID: selectUserGroupsByUUID,
  getLoadingAuthorizedUser: selectLoadingAuthorizedUser,
}


// ACCESS GROUPS SELECTORS
const selectAccessGroupsLoading = createSelector(
  selectAccessGroupFeature,
  fromAccessGroup.selectLoading
);

const selectComponentsAccessGroups = createSelector(
  selectAccessGroupFeature,
  fromAccessGroup.selectComponents
);

const selectComponentAccessGroupsById = (id: number) => createSelector(
  selectAccessGroupFeature,
  fromAccessGroup.selectComponentById(id)
);

const selectItemsAccessGroups = createSelector(
  selectAccessGroupFeature,
  fromAccessGroup.selectItems
);

const selectItemAccessGroupsById = (id: number) => createSelector(
  selectAccessGroupFeature,
  fromAccessGroup.selectItemById(id)
);

const selectAccessGroupsError = createSelector(
  selectAccessGroupFeature,
  fromAccessGroup.selectError
);

const selectAuthorizedUserAccessGroups = createSelector(
  selectAccessGroupFeature,
  fromAccessGroup.selectAuthorizedUserAccessGroups
);

const selectAuthorizedUserAccessGroupsLoading = createSelector(
  selectAccessGroupFeature,
  fromAccessGroup.selectAuthorizedUserAccessGroupsLoading
);

const selectAuthorizedUserAccessGroupsError = createSelector(
  selectAccessGroupFeature,
  fromAccessGroup.selectAuthorizedUserAccessGroupsError
);

export const AccessGroupSelectors = {
  isLoading: selectAccessGroupsLoading,
  getComponents: selectComponentsAccessGroups,
  getComponentById: selectComponentAccessGroupsById,
  getItems: selectItemsAccessGroups,
  getItemById: selectItemAccessGroupsById,
  getError: selectAccessGroupsError,
  getAuthorizedUserAccessGroups: selectAuthorizedUserAccessGroups,
  getAuthorizedUserAccessGroupsLoading: selectAuthorizedUserAccessGroupsLoading,
  getAuthorizedUserAccessGroupsError: selectAuthorizedUserAccessGroupsError,
}
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-progress',
  templateUrl: './spinner-progress.component.html',
  styleUrls: ['./spinner-progress.component.scss']
})
export class SpinnerProgressComponent {
  @Input() color: 'dark' | 'primary' | 'default' = 'default';
}

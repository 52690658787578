import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FullApiService } from '@shared/providers/full-api.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SocialMediumModel, SocialMediumPostModel, SocialMediumPatchModel } from '@components/social-medium/models/social-medium.model';

@Injectable({
  providedIn: 'root'
})
export class SocialMediumProviderService {
  constructor(private apiService: FullApiService) {}

  loadSocialMedia(event: EventModel, component: ComponentModel): Observable<SocialMediumModel[]> {
    return this.apiService.getSocialMedia(event.id, component.id);
  }

  createSocialMedium(event: EventModel, component: ComponentModel, socialMedium: SocialMediumModel): Observable<SocialMediumModel> {
    const socialMediumData = mapSocialMediumPost(socialMedium);
    return this.apiService.createSocialMedium(event.id, component.id, socialMediumData);
  }

  updateSocialMedium(event: EventModel, component: ComponentModel, socialMedium: SocialMediumModel): Observable<SocialMediumModel> {
    const socialMediumData = mapSocialMediumPatch(socialMedium);
    return this.apiService.updateSocialMedium(event.id, component.id, socialMedium.id, socialMediumData);
  }

  deleteSocialMedium(event: EventModel, component: ComponentModel, socialMedium: SocialMediumModel): Observable<boolean> {
    return this.apiService.deleteSocialMedium(event.id, component.id, socialMedium.id).pipe(
      map(({ success }) => success)
    );
  }
}

function mapSocialMediumPost(socialMedium: SocialMediumModel): SocialMediumPostModel {
  return {
    name: socialMedium.name, link: socialMedium.link, type: socialMedium.social_media_type
  };
}

function mapSocialMediumPatch(socialMedium: SocialMediumModel): SocialMediumPatchModel {
  return {
    name: socialMedium.name, link: socialMedium.link
  };
}

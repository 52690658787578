import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackendHeaderService {
  private activeMenu$: BehaviorSubject<string>;

  constructor() {
    this.activeMenu$ = new BehaviorSubject('dashboard');
  }

  getActiveMenu(): Observable<string> {
    return this.activeMenu$.asObservable();
  }

  setActiveMenu(activeMenu: AppMenuView) {
    this.activeMenu$.next(activeMenu);
  }
}

export enum AppMenuView {
  DASHBOARD = 'dashboard',
  CONSTRUCTOR = 'constructor',
  APPLICATION = 'application',
  LANDING_PAGE = 'landingPage',
}
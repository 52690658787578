import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_AUTH_BASE_URL } from "@env/environment";
import { Observable } from "rxjs";
import { ConsentSchemaApiModel, ConsentSchemaCreateApiModel, ConsentSchemaUpdateApiModel } from "../models/api/consent-schema.api-model";
import { ConsentSchemaTranslationApiModel, ConsentSchemaTranslationCreateApiModel, ConsentSchemaTranslationUpdateApiModel } from "../models/api/consent-schema-translation.api-model";

@Injectable()
export class ConsentEventApiService {
  constructor(private http: HttpClient) { }

  getEventConsentSchemas(eventId: number): Observable<ConsentSchemaApiModel[]> {
    return this.http.get<ConsentSchemaApiModel[]>(`${getBaseUrl()}?event_id=${eventId}`);
  }

  createEventConsentSchema(eventId: number, payload: ConsentSchemaCreateApiModel): Observable<ConsentSchemaApiModel> {
    return this.http.post<ConsentSchemaApiModel>(`${getBaseUrl()}`, { ...payload, event_id: eventId });
  }

  updateEventConsentSchema(eventId: number, schemaId: number, payload: ConsentSchemaUpdateApiModel): Observable<ConsentSchemaApiModel> {
    return this.http.put<ConsentSchemaApiModel>(`${getBaseUrl()}/${schemaId}`, { ...payload, event_id: eventId });
  }

  loadConsentSchemaTranslations(eventId: number, schemaId: number, schemaVersionId: number): Observable<ConsentSchemaTranslationApiModel[]> {
    return this.http.get<ConsentSchemaTranslationApiModel[]>(`${getBaseUrl()}/${schemaId}/consent_versions/${schemaVersionId}/consent_version_translations?event_id=${eventId}`);
  }

  publishConsentSchema(eventId: number, consentSchemaId: number): Observable<void> {
    return this.http.post<void>(`${getBaseUrl()}/${consentSchemaId}/publish?event_id=${eventId}`, { published: true });
  }

  unpublishConsentSchema(eventId: number, consentSchemaId: number): Observable<void> {
    return this.http.post<void>(`${getBaseUrl()}/${consentSchemaId}/publish?event_id=${eventId}`, { published: false });
  }

  publishSchemaVersion(eventId: number, schemaId: number, schemaVersionId: number): Observable<void> {
    return this.http.post<void>(`${getBaseUrl()}/${schemaId}/consent_versions/${schemaVersionId}/publish?event_id=${eventId}`, { published: true });
  }

  createConsentSchemaTranslation(eventId: number, schemaId: number, schemaVersionId: number, payload: ConsentSchemaTranslationCreateApiModel): Observable<ConsentSchemaTranslationApiModel> {
    const formData: FormData = new FormData();

    if (payload.file) {
      formData.append('file', payload.file, payload.file.name);
    }

    Object.keys(payload).forEach(key => {
      if (key === 'consent_checkbox_config') {
        formData.append(key, JSON.stringify(payload[key]));
      }
      else if (key !== 'file') {
        formData.append(key, payload[key]);
      }
    });

    formData.append('event_id', eventId.toString());

    return this.http.post<ConsentSchemaTranslationApiModel>(
      `${getBaseUrl()}/${schemaId}/consent_versions/${schemaVersionId}/consent_version_translations`,
      formData
    );
  }

  updateConsentSchemaTranslation(
    eventId: number,
    schemaId: number,
    schemaVersionId: number,
    translationId: number,
    payload: ConsentSchemaTranslationUpdateApiModel
  ): Observable<ConsentSchemaTranslationApiModel> {
    const formData = new FormData();

    // Dodajemy plik jeśli istnieje
    if (payload.file) {
      formData.append('file', payload.file, payload.file.name);
    }

    // Tworzymy kopię payload bez pliku
    const { file, ...payloadWithoutFile } = payload;

    // Dodajemy resztę danych jako JSON string
    formData.append('data', JSON.stringify({
      ...payloadWithoutFile,
      event_id: eventId
    }));

    return this.http.put<ConsentSchemaTranslationApiModel>(
      `${getBaseUrl()}/${schemaId}/consent_versions/${schemaVersionId}/consent_version_translations/${translationId}`,
      formData
    );
  }
}

const getBaseUrl = () => `${API_AUTH_BASE_URL}/admin/consent_schemas`;

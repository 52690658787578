<div class="tag-control">
  <app-dropdown-with-search
    [label]="placeholder"
    [options]="tagOptions"
    [default]="selectedOption"
    [disabled]="readonly"
    [staticLabel]="staticLabel"
    (onSelect)="handleTagSelect($event)">
  </app-dropdown-with-search>
  <button class="btn btn-outline-secondary tag-select-btn" type="button" (click)="onEdit()">
    <app-icon icon="/assets/icons/tag.svg"></app-icon>
  </button>
</div>
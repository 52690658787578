import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTimezone from '@store/features/timezone/reducers';
import * as fromComponent from '@store/features/component/reducers';
import * as fromApplication from './application.reducer';

export const featureKey = 'applicationFeature';

export { State, initialState, reducer } from './application.reducer';

export const selectApplicationFeature = createFeatureSelector<fromApplication.State>(featureKey);

export const selectMenuExpanded = createSelector(
  selectApplicationFeature,
  fromApplication.selectMenuExpanded
);

export const selectLoading = createSelector(
  fromTimezone.selectTimezoneLoading,
  fromComponent.selectComponentLoading,
  (l1, l2) => l1 || l2
);

import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ImageCropperComponent } from "./image-cropper.component";
import { AttachmentModel } from "@shared/models/attachment.model";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImageCropperService {
  constructor(private dialog: MatDialog) {}

  open(attachment: AttachmentModel | File): Observable<File> {
    const dialog = this.dialog.open(ImageCropperComponent, {
      disableClose: true,
      panelClass: 'confirm-dialog-container',
      maxWidth: '600px',
      maxHeight: '600px',
      data: {
        attachment,
      }
    });

    return dialog.afterClosed()
      .pipe(
        map(results => results && results.file)
      );
  }
}

export interface CropperDialogConfig {
  aspectRadio: number | null;
  informationTemplate: TemplateRef<any> | null;
  fileOutputFormat: string | null;
}
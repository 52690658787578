import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalService } from '@shared/components/confirm-modal/confirm-modal.service';
import { take, map, first, tap, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Injectable } from "@angular/core";
import { ComponentModel } from "@shared/models/component.model";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { EventModel } from "../event/models/event.model";
import { AccessGroupResponse } from "./access-group-api.service";

import { TagApiModel } from "./tags.models";
import { AccessGroupDeleteModel, AccessGroupItemDeleteModel, AccessGroupItemPostModel, AccessGroupType } from "./access-group.models";
import { AccessGroupPostModel } from "@shared/models/access-group.model";
import { AccessGroupActions } from './actions/access-group.actions';
import { AccessGroupSelectors } from './reducers';
import { sortByTagType } from '@utils/sort-by-tag-type.util';
import { TagsProviderService } from './tags-provider.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGroupProviderService {

  cancelSave$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  reloadChildItems$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private store$: Store,
    private confirmModal: ConfirmModalService,
    private tagsProvider: TagsProviderService,
    private i18n: TranslateService
  ) {}

  // GROUPS
  loadComponents(event: EventModel, componentIds: number[]): Observable<AccessGroupResponse> {
    if(this.emptyRequestHandler(event, componentIds)) return;
    if (componentIds && componentIds.length > 0) {
      this.store$.dispatch(AccessGroupActions.loadComponents(event, componentIds));

      return this.getComponentAccessGroups().pipe(
        first(data => !!data),
      );
    } else {
      return of({});
    }
  }

  loadAuthorizedUserAccessGroups(event: EventModel, uuid: string): void {
    if(!event) return;
    this.store$.dispatch(AccessGroupActions.loadAuthorizedUserAccessGroups(event, uuid));
  }

  addComponentAccessGroup(event: EventModel, component: ComponentModel, tag: TagApiModel): void {
    if(this.emptyRequestHandler(event, component)) return;
    const payload: AccessGroupPostModel = {
      component_id: component.id,
      tag_id: tag.id,
      access_type: AccessGroupType.Component
    };
    this.store$.dispatch(AccessGroupActions.addComponentAccessGroup(event, payload));
  }

  deleteComponentAccessGroup(event: EventModel, component: ComponentModel, tag: TagApiModel): void {
    if(this.emptyRequestHandler(event, component)) return;
    const payload: AccessGroupDeleteModel = {
      component_id: component.id,
      tag_id: tag.id,
    };
    this.store$.dispatch(AccessGroupActions.removeComponentAccessGroup(event, payload));
  }

  editComponentAccessGroups(event: EventModel, component: ComponentModel, tags: TagApiModel[]): Observable<TagApiModel[]> {
    combineLatest([
      this.getComponentAccessGroupsById(component.id),
      this.tagsProvider.getUserGroupTags(),
    ])
      .pipe(take(1))
      .subscribe(([accessGroups, eventAccessGroups]) => {
        const results = diffAccessGroups(accessGroups, tags);
        if(results.toRemove.length === 0) {
          results.toAdd.forEach(tag => this.addComponentAccessGroup(event, component, tag));
        } else {
          // confimation needed for component access group delete
          this.confirmModal.open({
            title: this.i18n.instant('application_tags.confirmation_required'),
            desc: this.i18n.instant('application_tags.confirmation_description')
          }, false).afterClosed()
            .pipe(first())
            .subscribe(confirmed => {
              if(confirmed) {
                results.toRemove.forEach(tag => this.deleteComponentAccessGroup(event, component, tag));
                results.toAdd.forEach(tag => this.addComponentAccessGroup(event, component, tag));
              } else {
                this.cancelSave$.next(true);
              }
            });
        }
        if(results.toAdd.length === 0 && results.toRemove.length === 0) {
          this.refreshComponentTags(component, tags);
        }
      });

    return this.getComponentAccessGroups()
      .pipe(first(data => {
        const stateTagIds = data[component.id].map(t => t.id);
        const localTagIds = tags.map(t => t.id);
        if (areArraysEqual(stateTagIds, localTagIds)) {
          return true;
        }
      }))
      .pipe(
        map(data => data[component.id]),
        tap(() => this.reloadChildItems$.next(true))
      )
  }

  private refreshComponentTags(component: ComponentModel, tags: TagApiModel[]) {
    this.store$.dispatch(AccessGroupActions.refreshComponentAccessGroups(component, tags));
  }

  // ITEMS
  loadItems(event: EventModel, component: ComponentModel, itemIds: number[]): Observable<AccessGroupResponse> {
    if(itemIds && itemIds.length === 0) {
      return of({['empty']: null});
    }
    if(this.emptyRequestHandler(event, component)) return;
    this.store$.dispatch(AccessGroupActions.loadItems(event, component.id, itemIds));
    return this.getItemAccessGroups().pipe(
      first(data => !!data),
    )
  }

  loadItem(event: EventModel, component: ComponentModel, itemIds: number[]): Observable<AccessGroupResponse> {
    if(itemIds && itemIds.length === 0) {
      return of({['empty']: null});
    }
    if(this.emptyRequestHandler(event, component)) return;
    this.store$.dispatch(AccessGroupActions.loadItem(event, component.id, itemIds));
    return this.getItemAccessGroups().pipe(
      first(data => !!data),
    )
  }

  addItemAccessGroup(event: EventModel, component: ComponentModel, target: { id: number }, tag: TagApiModel): void {
    if(this.emptyRequestHandler(event, component)) return;
    const payload: AccessGroupItemPostModel = {
      component_id: component.id,
      target_id: target.id,
      tag_id: tag.id,
      access_type: AccessGroupType.Item,
    };
    this.store$.dispatch(AccessGroupActions.addItemAccessGroup(event, payload));
  }

  deleteAccessGroupItem(event: EventModel, component: ComponentModel, item: { id: number } ,tag: TagApiModel): void {
    if(this.emptyRequestHandler(event, component)) return;
    const payload: AccessGroupItemDeleteModel = {
      component_id: component.id,
      item_id: item.id,
      tag_id: tag.id,
    };
    this.store$.dispatch(AccessGroupActions.removeItemAccessGroup(event, payload));
  }

  editAccessGroupItem(event: EventModel, component: ComponentModel, item: { id: number }, tags: TagApiModel[], skipQuestion = false): Observable<TagApiModel[]> {
    combineLatest([
      this.getItemAccessGroupsByItemId(item.id),
      this.hasComponentSettedChildGroups(component, tags),
      this.tagsProvider.getUserGroupTags(),
    ]).pipe(first())
      .subscribe(([accessGroups, componentTagsToAdd, eventUserGroups]) => {
        // at adding group to child which is not assigned to component
        // componentTagsToAdd should return array of missing groups

        const process = () => {
          this.editComponentAccessGroups(event, component, componentTagsToAdd)
          .pipe(first(compSavedTags => !!compSavedTags))
          .subscribe(compSavedTags => {
            // added returning list of component groups which were missing
            // if added groups amount is same as componentTagsToAdd amount, we known the adding component
            // groups operation has been triggered and handled successfully - then we can add groups to child item
            const added = compSavedTags.filter(tag => componentTagsToAdd.map(compTag => compTag.id === tag.id));
            if(added.length === componentTagsToAdd.length) {
              const results = diffAccessGroups(accessGroups, tags);
              results.toAdd.forEach(tag => this.addItemAccessGroup(event, component, item, tag));
              results.toRemove.forEach(tag => this.deleteAccessGroupItem(event, component, item, tag));
            } else {
              this.cancelSave$.next(true);
              this.setError(this.i18n.instant('application_tags.error_adding_component_groups'));
            }
        })};

        if(componentTagsToAdd.length > 0) {
          if (skipQuestion) {
            process();
          } else {
            this.confirmModal.open({
              title: this.i18n.instant('application_tags.adding_child_tag_confirm_title'),
              desc: this.i18n.instant('application_tags.adding_child_tag_confirm_desc')
            }).afterClosed().subscribe(result => {
              if(result) {
                process();
              } else {
                this.cancelSave$.next(true);
              }
            });
          }
        } else {
          const results = diffAccessGroups(accessGroups, tags);
          results.toAdd.forEach(tag => this.addItemAccessGroup(event, component, item, tag));
          results.toRemove.forEach(tag => this.deleteAccessGroupItem(event, component, item, tag));
        }
      });

    return this.getItemAccessGroups().pipe(
      first(data => {
        const stateIds = data[item.id] ? data[item.id].map(t => t.id) : null;
        const localIds = tags.map(t => t.id);
        if (stateIds === null) return true;
        if (areArraysEqual(stateIds, localIds)) {
          return data[item.id]?.length === tags?.length;
        }
      }),
      map(data => data[item.id]),
    );
  }

  hasComponentSettedChildGroups(component: ComponentModel, childTags: TagApiModel[]): Observable<TagApiModel[]> {
    return this.getComponentAccessGroupsById(component.id)
      .pipe(first((componentTags) => !!componentTags))
      .pipe(map((componentTags) => {
        const hasDifferences = this.getComponentDifferences(componentTags, childTags);
        if(hasDifferences.length > 0) {
          const componentTagsToSend = [...componentTags, ...hasDifferences];
          return componentTagsToSend;
        } else {
          return [];
        }
    }));
  }

  setError(message?: string): void {
    if(!message) {
      this.store$.dispatch(AccessGroupActions.setItemsError(null));
    } else {
      this.store$.dispatch(AccessGroupActions.setItemsError(new Error(message)));
    }
  }


  // GROUP - STORE SELECTORS
  getComponentAccessGroups(): Observable<AccessGroupResponse> {
    return this.store$.pipe(select(AccessGroupSelectors.getComponents));
  }

  getComponentAccessGroupsById(componentId: number): Observable<TagApiModel[]> {
    if (!componentId) return of(null);
    return this.store$.pipe(select(AccessGroupSelectors.getComponentById(componentId)))
      .pipe(
        filter(tags => !!tags),
        map(tags => [...tags].sort(sortByTagType))
      );
  }

  // ITEM - STORE SELECTORS
  getItemAccessGroups(): Observable<AccessGroupResponse> {
    return this.store$.pipe(select(AccessGroupSelectors.getItems));
  }

  getItemAccessGroupsByItemId(itemId: number): Observable<TagApiModel[]> {
    return this.store$.pipe(select(AccessGroupSelectors.getItemById(itemId)))
      .pipe(
        map(tags => !!tags ? [...tags].sort(sortByTagType) : [])
      );
  }

  // OTHER - SELECTORS
  getAccessGroupsError(): Observable<Error> {
    return this.store$.pipe(select(AccessGroupSelectors.getError));
  }

  getAccessGroupsLoading(): Observable<boolean> {
    return this.store$.pipe(select(AccessGroupSelectors.isLoading));
  }


  // AUTHORIZED USER ACCESS GROUPS - SELECTORS

  getAuthorizedUserAccessGroups(): Observable<TagApiModel[]> {
    return this.store$.pipe(select(AccessGroupSelectors.getAuthorizedUserAccessGroups));
  }

  getAuthorizedUserAccessGroupsLoading(): Observable<boolean> {
    return this.store$.pipe(select(AccessGroupSelectors.getAuthorizedUserAccessGroupsLoading));
  }

  getAuthorizedUserAccessGroupsError(): Observable<Error | null> {
    return this.store$.pipe(select(AccessGroupSelectors.getAuthorizedUserAccessGroupsError));
  }


  // OTHER - HELPERS
  private getComponentDifferences(component: TagApiModel[], child: TagApiModel[]): TagApiModel[] {
    return child.filter(child => {
      return !component.some(component => {
        return component.id === child.id;
      });
    });
  }

  private emptyRequestHandler(frParam: any, secParam: any): boolean {
    if(!frParam || !secParam) return true;
    return false;
  }
}

function areArraysEqual(array1: number[], array2: number[]) {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every(element => array2.includes(element));
};

export function diffAccessGroups(from: TagApiModel[], to: TagApiModel[]): { toAdd: TagApiModel[], toRemove: TagApiModel[] } {
  if(!from) {
    return { toAdd: to, toRemove: [] };
  }
  const toAdd = to
    .filter(tag => !from.find(t => t.id === tag.id))

  const toRemove = from
    .filter(tag => !to.find(t => t.id === tag.id))

  return { toAdd, toRemove };
}

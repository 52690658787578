import { createAction, props } from '@ngrx/store';

import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';

export const exportAgendaRatings = createAction(
  '[Agenda/Agenda Export Modal] Export Agenda Ratings'
);

export const exportAgendaRatingsSuccess = createAction(
  '[Agenda/API] Export Agenda Ratings Success',
  props<{ exportedData: string }>()
);

export const exportAgendaRatingsFailure = createAction(
  '[Agenda/API] Export Agenda Ratings Failure',
  props<{ error: Error }>()
);

export const exportAgendaDiscussion = createAction(
  '[Agenda/Agenda Export Modal] Export Agenda Discussion'
);

export const exportAgendaDiscussionSuccess = createAction(
  '[Agenda/API] Export Agenda Discussion Success',
  props<{ exportedData: string }>()
);

export const exportAgendaDiscussionFailure = createAction(
  '[Agenda/API] Export Agenda Discussion Failure',
  props<{ error: Error }>()
);

export const exportAgendaAttendees = createAction(
  '[Agenda/Agenda Export Modal] Export Agenda Attendees',
  props<{ session: AgendaSessionModel }>()
);

export const exportAgendaAttendeesSuccess = createAction(
  '[Agenda/API] Export Agenda Attendees Success',
  props<{ exportedData: string }>()
);

export const exportAgendaAttendeesFailure = createAction(
  '[Agenda/API] Export Agenda Attendees Failure',
  props<{ error: Error }>()
);

export const exportCheckedInAttendees = createAction(
  '[Agenda/Agenda Export Modal] Export Agenda Checked In Attendees'
);

export const exportCheckedInAttendeesSuccess = createAction(
  '[Agenda/Agenda Export Modal] Export Agenda Checked In Attendees Success',
  props<{ exportedData: string }>()
);

export const exportCheckedInAttendeesFailure = createAction(
  '[Agenda/Agenda Export Modal] Export Agenda Checked In Attendees Failure',
  props<{ error: Error }>()
);

export const exportSelfQrCodes = createAction(
  '[Agenda/Agenda Export Modal] Export Agenda Self Qr Codes'
);

export const exportSelfQrCodesSuccess = createAction(
  '[Agenda/Agenda Export Modal] Export Agenda Self Qr Codes Success',
  props<{ exportedData: Blob }>()
);

export const exportSelfQrCodesFailure = createAction(
  '[Agenda/Agenda Export Modal] Export Agenda Self Qr Codes Failure',
  props<{ error: Error }>()
);
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, OnDestroy, ContentChild, TemplateRef, NgZone, ChangeDetectorRef, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenuTrigger } from '@angular/material/menu';
import { Event } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-with-search',
  templateUrl: './dropdown-with-search.component.html',
  styleUrls: ['./dropdown-with-search.component.scss'],
  providers: [
    ScrollDispatcher,
  ],
})
export class DropdownWithSearchComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @ContentChild('item', { static: false }) headerTemplateRef: TemplateRef<any>;
  @ViewChild('menuTrigger') menuTrigger: ElementRef;
  @ViewChild('menuContent') menuContent: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  
  @Input() selected = null;
  checked: DropdownOption[] = [];

  @Input() label?: string;
  @Input() options?: DropdownOption[];
  @Input() optionsAsync?: Observable<DropdownOption[]>;
  @Input() searchActive = true;
  @Input() staticLabel = false;
  @Input() disabled = false;
  @Input() default = null;
  @Input() required = false;
  @Input() customTemplate = false;
  @Input() loading = false;
  @Input() apiSearch = false;
  @Input() customLabel: string | null = null;
  @Input() transparent = true;

  // translate "name" property from injected DropdownOption model
  @Input() i18n = false;

  // for checkbox mode
  @Input() checkboxMode = false;
  @Input() checkedData = [];

  @Output() onSelect: EventEmitter<DropdownOption>;
  @Output() onCheck: EventEmitter<DropdownOption[]>;
  @Output() onScrollToBottom: EventEmitter<void>;
  @Output() onSearchValueChanged: EventEmitter<string>;

  search: string = '';

  optionsToDisplay: DropdownOption[];

  private subs = new Subscription();
  private teardown$ = new Subject<void>();

  get labelToDisplay(): string {
    if (this.staticLabel) {
      return this.label || this.txService.instant('others.choose');
    }
    if (this.customLabel) {
      return this.customLabel;
    }
    if (this.selected) {
      if (this.i18n || this.selected.i18n) {
        return  this.selected.name
          ? this.txService.instant(this.selected.name)
          : this.txService.instant(this.selected.label);
      }
      return this.selected.name || this.selected.label;
    }
    return this.label || this.txService.instant('others.choose');
  }

  getOptionNameToDisplay(option: any): string {
    if (option.i18n || this.i18n) {
      return this.txService.instant(option.name)
      || this.txService.instant(option.value)
      || this.txService.instant(option.label);
    }
    return option.name || option.value || option.label;
  }

  constructor(
    private ngZone: NgZone,
    private scrollDispatcher: ScrollDispatcher,
    private txService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {
    this.onSelect = new EventEmitter<DropdownOption>();
    this.onScrollToBottom = new EventEmitter<void>();
    this.onSearchValueChanged = new EventEmitter<string>();

    // checkbox mode
    this.onCheck = new EventEmitter<DropdownOption[]>();
  }

  ngAfterViewInit(): void {
    this.subs.add(this.trigger.menuOpened.subscribe(() => {
      const menuContent = (this.menuContent as any).nativeElement as HTMLElement;
      let menuWidth = '100%';
      
      if (this.menuTrigger) {
        const triggerDOM = (this.menuTrigger as any)._element.nativeElement as HTMLElement;
        if (triggerDOM) {
          menuWidth = `${triggerDOM.getBoundingClientRect().width}px`;
        }
      }

      if (menuContent) {
        this.setStyleWithImportant(menuContent, 'width', menuWidth);
      }
    }));
  }

  ngOnInit(): void {
    if(this.checkboxMode) {
      this.checked = this.checkedData;
    } else {

      // standard mode
      this.optionsToDisplay = this.options;
      if(this.default) {
        this.select(this.default);
      }
      if(this.optionsAsync) {
        this.subs.add(this.optionsAsync.subscribe(options => {
          this.options = options;
          this.optionsToDisplay = this.options;
        }));
      }
    }

    // Load next batch on scroll
    this.scrollDispatcher.scrolled(400).pipe(
      filter(scrollable => !!scrollable),
      takeUntil(this.teardown$),
    ).subscribe((scrollable: CdkScrollable) =>
      this.ngZone.run(() => this.onScroll(scrollable))
    );

  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(this.optionsAsync) {
      this.subs.add(this.optionsAsync.subscribe(options => {
        if(!options.includes(this.selected)) {
          this.selected = null;
        }
        this.onSearch();
      }));
    } else {
      if(this.options && (this.options.length > 0 && !this.options?.find(option => option?.id === this.selected?.id))) {
        this.selected = null;
      }
      this.onSearch();
    }

    if (changes.default) {
      this.select(changes.default.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.teardown$.next();
  }

  onScroll(scrollable: CdkScrollable): void {
    const bottom = scrollable.measureScrollOffset('bottom');
    if (bottom < 1) {
      this.onScrollToBottom.emit();
    }
  }

  public reset(value?: DropdownOption): void {
    this.search = '';
    if(value) {
      this.selected = value;
    } else {
      this.selected = null;
      this.checked = [];
      this.onCheck.emit(this.checked);
    }
    this.onSearch();
  }

  // checkbox mode
  isChecked(option: DropdownOption): boolean {
    return this.checked?.includes(option);
  }

  // checkbox mode
  onCheckChange(event: MatCheckboxChange | Event, option: DropdownOption): void {
    // for item click
    if(!event.hasOwnProperty('checked')) {
      const checked = this.checked.includes(option);
      if(checked) {
        this.checked = this.checked.filter(o => o.id !== option.id);
      } else {
        this.checked.push(option);
      }
      this.onCheck.emit(this.checked);
    } else {
    // for checkbox click
      if((event as MatCheckboxChange).checked) {
        this.checked.push(option);
      } else {
        this.checked = this.checked.filter(o => o.id !== option.id);
      }
      this.onCheck.emit(this.checked);
    }
  }

  // standard mode
  select(option: DropdownOption, emit = true): void {
    if (emit) {
      this.onSelect.emit(option);
    }
    this.search = '';
    if(!(this.staticLabel)) {
      this.selected = option;
    } else {
      this.selected = null;
    }
    this.changeDetectorRef.detectChanges();
  }

  onSearch(): void {
    this.onSearchValueChanged.emit(this.search);
    if(this.search.length > 0) {
      if(this.apiSearch) {
        this.optionsToDisplay = this.options;
      } else {
        this.optionsToDisplay = this.options.filter(option => option.name.toLowerCase().includes(this.search.toLowerCase()));
      }
    } else {
      this.optionsToDisplay = this.options;
    }
  }

  // standard mode
  isActive(option: DropdownOption): boolean {
    if(this.options[0]?.id) {
      return this.selected ? this.selected.id === option.id : false;
    }
    return this.selected ? this.selected.name === option.name : false;
  }

  // standard mode
  resetSelect(): void {
    this.selected = null;
    this.onSelect.emit(null);
  }

  trackById(index: number, option: DropdownOption) {
    return option.id;
  }

  private setStyleWithImportant(element: HTMLElement, styleName: string, value: string) {
    element.style.setProperty(styleName, value, 'important');
  }

}

export interface DropdownOption {
  name: string;
  id: number;
  value?: any;
  icon?: string;
  i18n?: boolean;
}

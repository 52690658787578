<div class="col-md-6 form-group" [formGroup]="pickerForm">
  <label>{{ 'date_time_picker.date' | translate }} <span class="required-star">*</span></label>

  <div class="date-picker">
    <app-date-picker formControlName="date"></app-date-picker>
  </div>

  <div *ngIf="false" class="form-control-feedback">
    {{ 'date_time_picker.date_req' | translate }}
  </div>
</div>

<div class="col-md-6 form-group" [formGroup]="pickerForm">
  <label>{{ 'date_time_picker.time' | translate }} <span class="required-star">*</span></label>

  <div class="time-picker">
    <app-time-picker formControlName="time"></app-time-picker>
  </div>

  <div *ngIf="false" class="form-control-feedback">
    {{ 'date_time_picker.time_req' | translate }}
  </div>
</div>

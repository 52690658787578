import { UserApiModel } from "@shared/models/user.api-model";
import { EventUserSetup, UserBatchActionStatus, UsersTableColumn } from "../models/user.models";
import { Pagination } from "@shared/components/paginator/paginator.component";
import { EventUserSetupMeta } from "@shared/models/event-user-meta.api-model";
import { EventUserTagApiModel } from "@shared/models/event-user-tag.api-model";
import { UserUtils } from "../user.utils";
import { Action, createReducer, on } from "@ngrx/store";
import { UserAction } from "../actions/user.actions";
import { EventUserApiModel } from "@shared/models/event-user.api-model";
import { selectEvent } from "@store/features/event/actions";

export const featureKey = 'applicationUsers';

const userUtils = new UserUtils();

export interface State {
  loading: boolean;
  updating: boolean;
  error: Error;

  checkedViewActive: boolean;
  columns: UsersTableColumn[];
  entitiesSetup: EventUserSetup;
  pagination: Pagination;
  searchTerm: string;
  searchBy: string[];
  filtersOpen: boolean;
  mobileFiltersActive: boolean;
  areFiltered: boolean;


  users: EventUserApiModel[];
  meta: EventUserSetupMeta;
  userTags: EventUserTagApiModel;
  checkedUsers: EventUserApiModel[];

  usersActionResults: UserBatchActionStatus[];
  userActionError: Error;
  userActionSuccess: string;

  singleUser: UserApiModel;
  adminUser: EventUserApiModel;
}

export const initialState: State = {
  loading: false,
  updating: false,
  error: null,

  checkedViewActive: false,
  columns: userUtils.getAllColumns().filter(c => c.isDefault === true),
  entitiesSetup: userUtils.getDefaultApplicationUsersSetup(),
  pagination: null,
  searchTerm: null,
  searchBy: [],
  filtersOpen: false,
  mobileFiltersActive: false,
  areFiltered: false,

  users: [],
  meta: null,
  userTags: {},
  checkedUsers: [],

  usersActionResults: null,
  userActionError: null,
  userActionSuccess: null,

  singleUser: null,
  adminUser: null,
}

export const userReducer = createReducer(
  initialState,
  on(
    UserAction.setTableColumns,
    (state, { columns }) => ({ ...state, columns: [...columns].sort((a, b) => a.id - b.id) }),
  ),
  on(
    UserAction.setCheckedViewActive,
    (state, { active }) => ({ ...state, checkedViewActive: active }),
  ),
  on(
    UserAction.setEntitiesSetup,
    (state, { setup }) => ({ ...state, entitiesSetup: setup }),
  ),
  on(
    UserAction.paginatorRefresh,
    (state, { pagination }) => ({ ...state, pagination }),
  ),
  on(
    UserAction.setSearchTerm,
    (state, { term }) => ({ ...state, searchTerm: term }),
  ),
  on(
    UserAction.setSearchBy,
    (state, { searchBy }) => ({ ...state, searchBy }),
  ),
  on(
    UserAction.setError,
    (state, { error }) => ({ ...state, error }),
  ),
  on(
    UserAction.setFiltersOpen,
    (state, { open }) => ({ ...state, filtersOpen: open }),
  ),
  on(
    UserAction.setMobileFiltersActive,
    (state, { active }) => ({ ...state, mobileFiltersActive: active }),
  ),

  on(
    UserAction.loadUsersBySetup,
    (state, { setup }) => ({ ...state, loading: true, entitiesSetup: setup }),
  ),
  on(
    UserAction.loadUsersBySetupSuccess,
    (state, { data }) => ({ ...state, loading: false, error: null, users: data.users, meta: data.meta }),
  ),
  on(
    UserAction.loadUsersBySetupFailure,
    (state, { error }) => ({ ...state, loading: false, error }),
  ),

  on(
    UserAction.resendUsersEventTicketEmail,
    UserAction.sendUsersInvitations,
    UserAction.sendUsersMarketingEmail,
    UserAction.checkInUser,
    UserAction.checkInUsers,
    UserAction.cancelCheckInUser,
    UserAction.cancelCheckInUsers,
    UserAction.acceptUserRequest,
    UserAction.downloadUserQrCodes,
    UserAction.removeUser,
    (state) => ({ ...state, updating: true, userActionError: null, usersActionResults: null }),
  ),

  // without raport
  on(
    UserAction.checkInUserSuccess,
    UserAction.cancelCheckInUserSuccess,
    UserAction.downloadUserQrCodesSuccess,
    UserAction.sendUsersInvitationsSuccess,
    UserAction.resendUsersEventTicketEmailSuccess,
    (state) => ({ ...state, updating: false, userActionError: null }),
  ),

  on(
    UserAction.acceptUserRequestSuccess,
    (state, { user }) => ({ ...state, updating: false, userActionError: null, users: [...state.users].map(u => u.user_identity_token === user.user_identity_token ? user : u) }),
  ),

  // with raport
  on(
    UserAction.sendUsersMarketingEmailSuccess,
    UserAction.checkInUsersSuccess,
    UserAction.cancelCheckInUsersSuccess,
    (state, { results }) => ({ ...state, updating: false, userActionError: null, usersActionResults: results }),
  ),

  on(
    UserAction.resendUsersEventTicketEmailFailure,
    UserAction.sendUsersInvitationsFailure,
    UserAction.sendUsersMarketingEmailFailure,
    UserAction.checkInUserFailure,
    UserAction.checkInUsersFailure,
    UserAction.cancelCheckInUserFailure,
    UserAction.cancelCheckInUsersFailure,
    UserAction.acceptUserRequestFailure,
    UserAction.downloadUserQrCodesFailure,
    UserAction.removeUserFailure,
    (state, { error }) => ({ ...state, updating: false, userActionError: error, }),
  ),

  on(
    UserAction.removeUserSuccess,
    (state, { uuid }) => ({
      ...state,
      updating: false,
      error: null,
      users: state.users.filter(u => u.user_identity_token !== uuid),
      checkedUsers: state.checkedUsers.filter(u => u.user_identity_token !== uuid),
    }),
  ),

  on(
    UserAction.resetUsersActionResults,
    (state) => ({ ...state, usersActionResults: null })
  ),
  on(
    UserAction.resetUserActionError,
    (state) => ({ ...state, userActionError: null })
  ),

  on(
    UserAction.loadUserTags,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    UserAction.loadUserTagsSuccess,
    (state, { data }) => ({ ...state, loading: false, error: null, userTags: data }),
  ),
  on(
    UserAction.loadUserTagsFailure,
    (state, { error }) => ({ ...state, loading: false, error }),
  ),

  on(
    UserAction.loadSingleUser,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    UserAction.loadSingleUserSuccess,
    (state, { user }) => ({ ...state, loading: false, error: null, singleUser: user }),
  ),
  on(
    UserAction.loadSingleUserFailure,
    (state, { error }) => ({ ...state, loading: false, error }),
  ),

  on(
    UserAction.loadUserAdmin,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    UserAction.loadUserAdminSuccess,
    (state, { user, addToUsersTable }) => ({ ...state, loading: false, adminUser: user, users: (addToUsersTable ? [user, ...state.users] : [...state.users]) }),
  ),
  on(
    UserAction.loadUserAdminFailure,
    (state, { error }) => ({ ...state, loading: false, error }),
  ),

  on(
    UserAction.setCheckedUsers,
    (state, { users }) => ({ ...state, checkedUsers: users }),
  ),
  on(
    UserAction.addCheckedUsers,
    (state, { users }) => ({ ...state, checkedUsers: [...state.checkedUsers, ...users] }),
  ),
  on(
    UserAction.deleteCheckedUsers,
    (state, { users }) => {
      const checked = [...state.checkedUsers];
      const userIdentityTokensToDelete = new Set(users.map(user => user.user_identity_token));
      const updatedCheckedUsers = checked.filter(checkedUser => !userIdentityTokensToDelete.has(checkedUser.user_identity_token));
      return { ...state, checkedUsers: updatedCheckedUsers };
    },
  ),

  on(
    UserAction.setUserActionSuccess,
    (state, { translationKey }) => ({ ...state, userActionSuccess: translationKey }),
  ),

  on(
    UserAction.reset,
    (state) => ({ ...initialState }),
  ),

  on(
    UserAction.setAreFiltered,
    (state, { areFiltered }) => ({ ...state, areFiltered }),
  ),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);


export function reducer(state: State, action: Action): State {
  return userReducer(state, action);
}

export const selectLoading = (state: State) => state.loading;
export const selectUpdating = (state: State) => state.updating;
export const selectError = (state: State) => state.error;

export const selectCheckedViewActive = (state: State) => state.checkedViewActive;
export const selectColumns = (state: State) => state.columns;
export const selectEntitiesSetup = (state: State) => state.entitiesSetup;
export const selectPagination = (state: State) => state.pagination;
export const selectSearchTerm = (state: State) => state.searchTerm;
export const selectSearchBy = (state: State) => state.searchBy;
export const selectFiltersOpen = (state: State) => state.filtersOpen;
export const selectMobileFiltersActive = (state: State) => state.mobileFiltersActive;

export const selectUsers = (state: State) => state.users;
export const selectMeta = (state: State) => state.meta;
export const selectUserTags = (state: State) => state.userTags;
export const selectCheckedUsers = (state: State) => state.checkedUsers;

export const selectUsersActionResults = (state: State) => state.usersActionResults;
export const selectUserActionError = (state: State) => state.userActionError;
export const selectUserActionSuccess = (state: State) => state.userActionSuccess;

export const selectSingleUser = (state: State) => state.singleUser;
export const selectAdminUser = (state: State) => state.adminUser;

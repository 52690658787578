import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { IconModel } from '@shared/models/icon.model';

import * as IconActions from './actions/icon.actions';
import * as fromIcon from './reducers';

@Injectable()
export class IconFacadeService {
  constructor(private store$: Store<any>) {}

  getIconEntities(): Observable<Dictionary<IconModel>> {
    return this.store$.pipe(select(fromIcon.selectIconEntities));
  }

  getAllIcons(): Observable<IconModel[]> {
    return this.store$.pipe(select(fromIcon.selectAllIcons));
  }

  getRecommendedIcons(componentName: string): Observable<IconModel[]> {
    return this.store$.pipe(select(fromIcon.selectRecommendedIcons, { componentName }));
  }

  getIconLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromIcon.selectIconLoading));
  }

  getIconLoaded(): Observable<boolean> {
    return this.store$.pipe(select(fromIcon.selectIconLoaded));
  }

  getIconError(): Observable<Error> {
    return this.store$.pipe(select(fromIcon.selectIconError));
  }

  loadIcons() {
    this.store$.dispatch(IconActions.loadIcons());
  }
}

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { ComponentTranslationModel } from '@components/component/models/component-translation.model';

import * as ComponentActions from '../actions/component.actions';
import * as ComponentTranslationActions from '../actions/component-translation.actions';

export const featureKey = 'componentTranslation';

export interface State extends EntityState<ComponentTranslationModel> {
  loading: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<ComponentTranslationModel>({
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const componentTranslationReducer = createReducer(
  initialState,
  on(ComponentTranslationActions.loadComponentTranslation, state => ({
    ...state,
    loading: true,
  })),
  on(
    ComponentTranslationActions.loadComponentTranslationSuccess,
    (state, { translation }) =>
      adapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    ComponentTranslationActions.loadComponentTranslationFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(ComponentActions.updateComponentSuccess, (state, { translations }) =>
    adapter.upsertMany(translations, state)
  )
);

export function reducer(state: State, action: Action): State {
  return componentTranslationReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectComponentTranslationIds = selectIds;

export const selectComponentTranslationEntities = selectEntities;

export const selectAllComponentTranslations = selectAll;

export const selectComponentTranslationLoading = (state: State) =>
  state.loading;

export const selectComponentTranslationError = (state: State) => state.error;

export const selectComponentTranslations = () =>
  createSelector(
    selectAllComponentTranslations,
    (translations, { componentId, language }) =>
      translations.filter(
        translation => translation.componentId === componentId
      )
  );

export const selectComponentTranslation = () =>
  createSelector(selectComponentTranslations(), (translations, { language }) =>
    translations.find(translation => translation.language === language)
  );

import { Injectable } from "@angular/core";
import { ConsentApiService } from "../apis/consent-api.service";
import { ConsentModel } from "../models/consent.model";
import { map } from "rxjs/operators";
import { mapConsentModel } from "../models/mappers/consent.mapper";
import { Observable } from "rxjs";
import { ConsentUserAcceptModel } from "../models/consent-user-accept.model";
import { mapConsentUserAcceptModel } from "../models/mappers/consent-user-accept.mapper";

@Injectable()
export class ConsentAdapterService {
  constructor(private api: ConsentApiService) {}

  getUserAppConsents(langKey?: string): Observable<ConsentModel[]> {
    return this.api.getUserAppConsents(langKey)
      .pipe(
        map(consents => consents.map(mapConsentModel)),
      );
  }

  acceptConsents(payload: ConsentUserAcceptModel[]): Observable<number[]> {
    const adaptedPayload = payload.map(mapConsentUserAcceptModel);
    return this.api.acceptConsents(adaptedPayload).pipe(
      map(() => payload.map(p => p.consentSchemaId))
    );
  }
}

import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, ViewChild, Input, Inject, TemplateRef, NgZone, ApplicationRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject, Observable, from, merge } from 'rxjs';
import { switchMap, map, toArray, take, takeUntil, filter, mapTo, shareReplay, tap, distinctUntilChanged, skip } from 'rxjs/operators';

import { EventLanguageFacadeService } from '@store/features/event/event-language-facade.service';
import { ImportLoaderService, ImportMetadata } from '@shared/providers/import-loader.service';
import { ImportMatcherService, ImportMatchResult } from '@shared/providers/import-matcher.service';

import { ImportResultModel, ImportResultStatus } from '@shared/models/import-result.model';
import { EventLanguageModel } from '@store/features/event/models/event-language.model';

import * as FileSaver from 'file-saver';

export interface ImportModalData {
  title: string;
  columns: string[];
  worksheetName?: string;
  templateFilename?: string;
  intercomUrls?: ImportModalIntercomUrls;
}

export interface ImportModalIntercomUrls {
  en: string;
  pl: string;
}

export interface ImportColumnStatus {
  column: string;
  status: boolean | null;
}

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss']
})
export class ImportModalComponent implements OnInit, OnDestroy {
  @ViewChild('resultModalTemplate', { static: true }) resultModalTemplate: TemplateRef<any>;

  @Input() getImporting: () => Observable<boolean>;
  @Input() getResults: () => Observable<ImportResultModel[]>;
  @Input() getError: () => Observable<Error>;
  @Input() runImporter: (file: File, language: string) => void;

  get title(): string {
    const data = this.dialogData;
    return data && data.title;
  }

  get columns(): string[] {
    const data = this.dialogData;
    return data && data.columns;
  }

  metadata$: Observable<ImportMetadata>;
  matchResult$: Observable<ImportMatchResult>;
  columnStatuses$: Observable<ImportColumnStatus[]>;

  hasFile$: Observable<boolean>;
  isValid$: Observable<boolean>;

  isImporting$: Observable<boolean>;
  results$: Observable<ImportResultModel[]>;
  importError$: Observable<Error>;
  error$: Observable<Error>;

  languages$: Observable<EventLanguageModel[]>;
  defaultLanguage$: Observable<EventLanguageModel>;

  languageControl: FormControl = new FormControl();

  templateUrl: SafeUrl;
  templateCSVUrl: SafeUrl;
  templateFilename: string = 'import-template.xlsx';
  templateCSVFilename: string = 'import-template.csv';

  private importFile$: BehaviorSubject<File> = new BehaviorSubject(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: ImportModalData,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private applicationRef: ApplicationRef,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private eventLanguageFacade: EventLanguageFacadeService,
    private importLoader: ImportLoaderService,
    private importMatcher: ImportMatcherService,
    private i18n: TranslateService
  ) {}

  ngOnInit() {
    this.metadata$ = this.importFile$.pipe(
      switchMap(file => this.importLoader.loadMetadata(file)),
      shareReplay(1),
    );

    this.matchResult$ = this.metadata$.pipe(
      switchMap(meta => this.importMatcher.match(this.dialogData, meta))
    );

    this.columnStatuses$ = this.matchResult$.pipe(
      switchMap(result => this.buildColumnStatusFor(result))
    );

    this.hasFile$ = this.importFile$.pipe(
      map(file => !!file)
    );

    this.isValid$ = this.matchResult$.pipe(
      map(result => result && result.valid),
      distinctUntilChanged(),
      // Ugly fix for detection changes as workaround for MatDialog internal bug
      tap(() => setTimeout(() => this.applicationRef.tick(), 50))
    );

    this.isImporting$ = this.getImporting();
    this.results$ = this.getResults();

    this.importError$ = this.getError().pipe(
      filter(error => !!error),
      shareReplay(1),
    );

    this.error$ = merge(
      this.hasFile$.pipe(
        filter(hasFile => !hasFile),
        mapTo(new Error(this.i18n.instant('import_modal.please_choose_csv')))
      ),
      this.matchResult$.pipe(
        filter(result => !!result),
        filter(result => !result.valid),
        mapTo(new Error(this.i18n.instant('import_modal.missing_req_cols')))
      ),
    ).pipe(
      shareReplay(1),
    );

    this.languages$ = this.eventLanguageFacade.getAllEventLanguagesWithNames();
    this.defaultLanguage$ = this.eventLanguageFacade.getDefaultEventLanguage();

    this.defaultLanguage$.pipe(take(1)).subscribe(
      language => this.languageControl.setValue(language.code)
    );

    // Listen for results from the importer and open results modal
    this.results$.pipe(takeUntil(this.dialogRef.beforeClosed())).subscribe(
      results => this.openResultsModal(results)
    );

    // Check for template filename
    if (this.dialogData.templateFilename) {
      this.templateFilename = this.dialogData.templateFilename;
    }

    // Generate XLSX template
    // Run the task outside Angular as ExcelJS overrides Zone's Promise implementation
    this.ngZone.runOutsideAngular(() => {
      this.buildXlsxTemplate().then(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);

        this.ngZone.run(() => {
          if (this.templateUrl) {
            URL.revokeObjectURL(String(this.templateUrl));
          }

          this.templateUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        });
      });
    });

    const blob = new Blob([this.buildCSVTemplate()], { type: 'text/csv;charset=utf-8' });
    const url =  URL.createObjectURL(blob);
    URL.revokeObjectURL(String(this.templateCSVUrl));
    this.templateCSVUrl = this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getColumnsToDisplay(): Observable<ImportColumnStatus[]> {
    return this.columnStatuses$;
  }

  ngOnDestroy() {
    if (this.templateUrl) {
      URL.revokeObjectURL(String(this.templateUrl));
    }
  }

  getStatusCssClass(status: ImportResultStatus): string {
    if (status === ImportResultStatus.Success) {
      return 'text-success';
    } else if (status === ImportResultStatus.Warning) {
      return 'text-warning';
    } else if (status === ImportResultStatus.Failed) {
      return 'text-danger';
    }
  }

  hasColumn(...columnNames: string[]): boolean {
    const columns = this.dialogData.columns || [];
    return columns.some(col => columnNames.includes(col));
  }

  onFileInputChange(ev: Event) {
    const file = (ev.target as HTMLInputElement).files[0];

    this.importFile$.next(file);
  }

  onImportClick(ev: Event) {
    const file = this.importFile$.value;
    const language = this.languageControl.value;

    this.runImporter(file, language);
  }

  downloadTemplate() {
    this.buildXlsxTemplate().then(data => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs([blob], 'import-template.xlsx');
    });
  }

  compareWithLanguage(a: EventLanguageModel, b: EventLanguageModel): boolean {
    return a && b && a.code === b.code;
  }

  private buildColumnStatusFor(result: ImportMatchResult): Observable<ImportColumnStatus[]> {
    return from(this.dialogData.columns).pipe(
      map(column => ({ column, status: result ? result.columns.includes(column) : null })),
      toArray()
    );
  }

  private openResultsModal(results: ImportResultModel[]) {
    if (this.canCloseModalFor(results)) {
      this.dialogRef.close();
    }

    this.dialog.open(this.resultModalTemplate, {
      width: '500px', height: '400px', data: results
    });
  }

  private canCloseModalFor(results: ImportResultModel[]): boolean {
    if (!results || !results.length) {
      return true;
    }

    const allowedStatus = [ImportResultStatus.Success, ImportResultStatus.Warning];
    if (results.every(result => allowedStatus.includes(result.status))) {
      return true;
    }

    return false;
  }

  private buildCSVTemplate(): string {
    const columns = this.dialogData.columns;
    let csvString = '';
    columns.forEach(c => {
      csvString += `${c},`
    });
    return csvString;
  }

  private buildXlsxTemplate() {
    const columns = this.dialogData.columns;
    const worksheetName = this.dialogData.worksheetName || this.i18n.instant('import_modal.template');

    return this.createTemporaryWorkbook().then((workbook) => {
      const worksheet = workbook.addWorksheet(worksheetName);

      columns.forEach((column, i) =>
        worksheet.getCell(1, i + 1).value = column
      );

      return workbook.xlsx.writeBuffer();
    });
  }

  private createTemporaryWorkbook() {
    return import('exceljs').then(ExcelJS => {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'Meeting Application';
      workbook.created = new Date();

      return workbook;
    });
  }
}

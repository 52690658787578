import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { MobilePreviewService, MobilePreviewContext } from '@shared/providers/mobile-preview.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-preview',
  templateUrl: './mobile-preview.component.html',
  styleUrls: ['./mobile-preview.component.scss']
})
export class MobilePreviewComponent {
  context$: Observable<MobilePreviewContext>;

  constructor(private previewService: MobilePreviewService) {
    this.context$ = previewService.getContext();
  }

  getBannerUrl(): Observable<string> {
    return this.context$.pipe(
      map(context => context.banner && context.banner.file_thumbnail_750_url)
    );
  }

  getLogoUrl(): Observable<string> {
    return this.context$.pipe(
      map(context => context.logo && context.logo.file_thumbnail_750_url)
    );
  }
}

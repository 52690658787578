import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TokenService } from '@app/core/services/token.service';
import { RouterAuthorizedView, RouterProviderService } from '@shared/providers/router-provider.service';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private routerProvider: RouterProviderService, private tokenService: TokenService) {}

  canActivate(): Observable<boolean> {
    return this.tokenService.getIsAuthenticated().pipe(
      map(authenticated => {
        if (authenticated) {
          this.routerProvider.toAuthorizedView(RouterAuthorizedView.DASHBOARD);
          return false;
        }

        return true;
      })
    );
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { bookingDayActions } from '@components/booking/store/actions/booking-day.actions';
import { BookingDay } from '@components/booking/store/models/booking-session.model';

export const featureKey = 'bookingDays';

export interface State {
  loading: boolean;
  error: Error;
  activeId: number;
  saving: boolean;
  bookingDays: BookingDay[];
}

export const initialState = {
  loading: false,
  error: null,
  activeId: 1,
  saving: false,
  bookingDays: [],
};

export const bookingDaysReducer = createReducer(
  initialState,
  on(bookingDayActions.loadBookingDays,
    state => ({ ...initialState, loading: true })),

  on(bookingDayActions.loadBookingDaysSuccess,
    (state, { bookingDays }) => {
      const sortedDays = sortBookingDays(bookingDays);
      return {
        ...state,
        loading: false,
        bookingDays: sortedDays,
        activeId: sortedDays[0] ? sortedDays[0].id : null,
      };
    }),

  on(bookingDayActions.loadBookingDaysFailure,
    (state, { error }) => ({ ...state, loading: false, error })),

  on(bookingDayActions.addBookingDay,
    state => ({ ...state, saving: true })),

  on(bookingDayActions.addBookingDaySuccess,
    (state, { bookingDay }) => ({
      ...state,
      saving: false,
      bookingDays: [...state.bookingDays, bookingDay],
      activeId: bookingDay.id
    })),

  on(bookingDayActions.addBookingDayFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingDayActions.updateBookingDay,
    state => ({ ...state, saving: true })),

  on(bookingDayActions.updateBookingDaySuccess,
    (state, { bookingDay }) => ({ ...state, saving: false, bookingDays: state.bookingDays.map(bd => bd.id === bookingDay.id ? bookingDay : bd) })),

  on(bookingDayActions.updateBookingDayFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingDayActions.deleteBookingDay,
    state => ({ ...state, saving: true })),

  on(bookingDayActions.deleteBookingDaySuccess,
    (state, { bookingDay }) => ({ ...state, saving: false, bookingDays: [...state.bookingDays.filter(bd => bd.id !== bookingDay.id)] })),

  on(bookingDayActions.deleteBookingDayFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingDayActions.setBookingDayActiveId,
    (state, { id }) => ({ ...state, activeId: id })),
);

export function reducer(state: State, action: Action): State {
  return bookingDaysReducer(state, action);
}

export const selectAllBookingDays = (state: State) => state.bookingDays;
export const selectBookingDayById = (state: State, id: number) => state.bookingDays.find(bookingDay => bookingDay.id === id);
export const selectActiveBookingDayId = (state: State) => state.activeId;
export const selectActiveBookingDay = (state: State) => selectBookingDayById(state, selectActiveBookingDayId(state));

function sortBookingDays(days: BookingDay[]): BookingDay[] {
  return [...days].sort((a, b) => new Date(a.onlyDate).getTime() - new Date(b.onlyDate).getTime()); 
}

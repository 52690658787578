import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { ComponentModel } from '@shared/models/component.model';
import { PartnerModel } from '@components/partner/models/partner.model';

import * as PartnerActions from './actions/partner.actions';
import * as fromPartner from './reducers';

@Injectable({
  providedIn: 'root'
})
export class PartnerFacadeService {
  constructor(private store$: Store<any>) {}

  getAllPartners(): Observable<PartnerModel[]> {
    return this.store$.pipe(select(fromPartner.selectAllPartners));
  }

  getPartners(component: ComponentModel): Observable<PartnerModel[]> {
    return this.store$.pipe(
      select(fromPartner.selectPartners, {
        componentId: component.id,
        categoryId: null,
      })
    );
  }

  getPartnersByCategory(
    component: ComponentModel,
    categoryId: number
  ): Observable<PartnerModel[]> {
    return this.store$.pipe(
      select(fromPartner.selectPartnersByCategory(), {
        componentId: component.id,
        categoryId,
      })
    );
  }

  getPartnerLoading(component: ComponentModel): Observable<boolean> {
    return this.store$.pipe(
      select(fromPartner.selectPartnerLoading, { componentId: component.id })
    );
  }

  getPartnerLoaded(component: ComponentModel): Observable<boolean> {
    return this.store$.pipe(
      select(fromPartner.selectPartnerLoaded, { componentId: component.id })
    );
  }

  loadPartners(component: ComponentModel) {
    this.store$.dispatch(PartnerActions.loadPartners({ component }));
  }

  deletePartner(component: ComponentModel, partner: PartnerModel) {
    this.store$.dispatch(PartnerActions.deletePartner({ component, partner }));
  }

  reorderPartners(component: ComponentModel, partners: PartnerModel[]) {
    this.store$.dispatch(
      PartnerActions.reorderPartners({ component, partners })
    );
  }
}

import { Component, Input, Output, ViewChild, EventEmitter, ElementRef, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-category-name',
  templateUrl: './category-name.component.html',
  styleUrls: ['./category-name.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategoryNameComponent),
      multi: true
    }
  ]
})
export class CategoryNameComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() isDisabled = false;
  @Input() draggable = false;

  @Output() appBlur: EventEmitter<Event>;
  @Output() blur: EventEmitter<Event>;

  @ViewChild('nativeInput', { static: true }) nativeInput: ElementRef<HTMLInputElement>;

  @HostBinding('attr.editing') get _editing() {
    return this.nativeInput.nativeElement === document.activeElement ? '' : null;
  }

  set value(newValue: string) {
    this.nativeInput.nativeElement.value = newValue;

    if (this.onChange) {
      this.onChange(this.nativeInput.nativeElement.value);
    }
  }

  get value(): string {
    return this.nativeInput.nativeElement.value;
  }

  private onChange: (value) => {};
  private onTouched: () => {};

  constructor() {
    this.appBlur = new EventEmitter<Event>();
    this.blur = this.appBlur;
  }

  edit(clearValue?: boolean) {
    const len = this.value.length;

    this.nativeInput.nativeElement.focus();
    this.nativeInput.nativeElement.setSelectionRange(len, len);

    if (clearValue) {
      this.value = '';
    }
  }

  onValueChange(ev: Event) {
    if (this.onChange) {
      this.onChange(this.nativeInput.nativeElement.value);
    }
  }

  onBlur(ev: Event) {
    this.appBlur.emit(ev);

    if (this.onTouched) {
      this.onTouched();
    }
  }

  onKeydown(ev: KeyboardEvent) {
    // eslint-disable-next-line import/no-deprecated
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      this.nativeInput.nativeElement.blur();
    }
  }

  writeValue(value: string): void {
    this.nativeInput.nativeElement.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.nativeInput.nativeElement.disabled = isDisabled;
  }
}

import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-action-menu-item',
  templateUrl: './action-menu-item.component.html',
  styleUrls: ['./action-menu-item.component.scss']
})
export class ActionMenuItemComponent {
  @Input() icon: string;

  @HostBinding('class.dropdown-item') get _dropdownItem(): boolean {
    return true;
  }
}

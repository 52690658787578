import { Injectable } from '@angular/core';
import { CurrencyProviderService } from './currency-provider.service';
import { CurrencyStoreService } from './currency-store.service';
import { Observable } from 'rxjs';
import { CurrencyModel } from '@shared/models/currency.model';
import { tap, map } from 'rxjs/operators';

export const popularCurrencies = ['EUR', 'GBP', 'PLN', 'USD'];

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  constructor(private currencyProvider: CurrencyProviderService, private currencyStore: CurrencyStoreService) {}

  getCurrencyByCode(code: string): Observable<CurrencyModel> {
    return this.currencyStore.getCurrencyByCode(code);
  }

  getPopularCurrencies(): Observable<CurrencyModel[]> {
    return this.getSortedCurrencies().pipe(
      map(currencies => currencies.filter(currency => popularCurrencies.includes(currency.code)))
    );
  }

  getOtherCurrencies(): Observable<CurrencyModel[]> {
    return this.getSortedCurrencies().pipe(
      map(currencies => currencies.filter(currency => !popularCurrencies.includes(currency.code)))
    );
  }

  getSortedCurrencies(): Observable<CurrencyModel[]> {
    return this.currencyStore.getSortedCurrencies();
  }

  getCurrencies(): Observable<CurrencyModel[]> {
    return this.currencyStore.getCurrencies();
  }

  loadCurrencies(): Observable<CurrencyModel[]> {
    return this.currencyProvider.getCurrencies().pipe(
      tap(currencies => this.currencyStore.setup(currencies))
    );
  }
}

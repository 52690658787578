
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollectionBaseService } from '@shared/providers/collection-base.service';

import { FeedWallChannelModel } from './models/feed-wall-channel.model';

@Injectable({
  providedIn: 'root'
})
export class FeedWallChannelStoreService extends CollectionBaseService<FeedWallChannelModel> {
  constructor() {
    super([]);
  }

  getFeedWallChannelById(channelId: number): Observable<FeedWallChannelModel> {
    const findById = (channels: FeedWallChannelModel[], searchId: number) => {
      return channels.find(channel => channel.id === searchId);
    };

    return this.getFeedWallChannels().pipe(
      map(channels => findById(channels, channelId))
    );
  }

  getSortedFeedWallChannels(): Observable<FeedWallChannelModel[]> {
    const sortByName = (channels: FeedWallChannelModel[]) => {
      return channels.sort((a, b) => a.name.localeCompare(b.name));
    };

    return this.getFeedWallChannels().pipe(
      map(channels => sortByName(channels))
    );
  }

  getFeedWallChannels(): Observable<FeedWallChannelModel[]> {
    return this.getEntities();
  }

  updateEntity(entity: FeedWallChannelModel): void {
    super.updateEntity(entity, (a, b) => (a.id === b.id));
  }

  removeEntity(entity: FeedWallChannelModel): void {
    super.removeEntity(entity, (a, b) => (a.id === b.id));
  }
}

import { ApplicationTagsModalService } from '@components/application-tags/application-tags-modal.service';
import { Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ComponentModel } from '@shared/models/component.model';
import { Observable, Subscription } from 'rxjs';
import { IconFacadeService } from '@store/features/icon/icon-facade.service';
import { map } from 'rxjs/operators';
import { IconModel } from '@shared/models/icon.model';
import { ComponentNames } from '@utils/component-names.util';
import { RouterProviderService } from '@shared/providers/router-provider.service';
import { USER_ROLE_PERMISSIONS, isUserRoleAllowed } from '@shared/constants/user-role-permissions';
import { EventFacadeService } from '@store/features/event/event-facade.service';

const builtInIcons: { [id: string]: IconModel } = {
  '-3': { id: -3, url: '/assets/icons/ticket.svg', recommendedFor: [] },
  '-4': { id: -4, url: '/assets/icons/tag.svg', recommendedFor: [] },
};

@Component({
  selector: 'app-builder-component',
  templateUrl: './builder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuilderComponent implements OnDestroy {

  @Input()
  componentModel: ComponentModel;

  @Input()
  addedMode = false;

  @Input()
  editMode = true;

  @Input()
  deleteMode = true;

  @Input()
  initComponent = false;

  @Input()
  active = false;

  @Input()
  navigateOnClick = false;

  @Input()
  eventId: number;

  @Output()
  addComponentRequest = new EventEmitter();

  @Output()
  deleteComponentRequest = new EventEmitter<ComponentModel>();

  @Output()
  toggleShowComponentRequest = new EventEmitter<ComponentModel>();

  @Output()
  editComponentRequest = new EventEmitter<ComponentModel>();

  isAllowedToDeleteComponent$: Observable<boolean>;

  public price = 22.22;

  private subs = new Subscription();

  constructor(
    private iconFacade: IconFacadeService,
    private applicationTagsModalService: ApplicationTagsModalService,
    private routerProvider: RouterProviderService,
    private eventFacade: EventFacadeService,
  ) {
    this.isAllowedToDeleteComponent$ = this.eventFacade.getActiveEvent().pipe(
      map(event => isUserRoleAllowed(event, USER_ROLE_PERMISSIONS.DELETE_COMPONENT))
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getComponentIcon(component: ComponentModel): Observable<string> {
    return this.iconFacade.getIconEntities().pipe(
      map(icons => icons[component.iconId] || builtInIcons[component.iconId]),
      map(icon => icon && icon.url)
    );
  }

  changeMode() {
    this.addedMode = !this.addedMode;
  }

  toggleShowComponent(component: ComponentModel) {
    this.toggleShowComponentRequest.emit(component);
  }

  addComponent() {
    this.addComponentRequest.emit();
  }

  deleteComponent(component: ComponentModel) {
    this.deleteComponentRequest.emit(component);
  }

  getComponentModel(): ComponentModel {
    return this.componentModel;
  }

  editComponent(component: ComponentModel) {
    this.editComponentRequest.emit(component);
  }

  isAlwaysInvisible(component: ComponentModel): boolean {
    const invisibleComponents = [ComponentNames.BANNER, ComponentNames.PUSH];
    return invisibleComponents.includes(component.componentName);
  }

  onChooseGroupClick(component: ComponentModel) {
    this.applicationTagsModalService.openEditDialog(component);
  }

  handleClick(): void {
    if (!this.navigateOnClick || !this.eventId || !this.componentModel || this.active) {
      return;
    }
    this.routerProvider.toEventConstructorComponent(this.eventId, this.componentModel.id);
  }
}

import { Subscription, Observable } from 'rxjs';
import { DeeplinkBuilderService} from './deeplink-builder.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DropdownOption } from '../dropdown-with-search/dropdown-with-search.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentNames } from '@utils/component-names.util';

@Component({
  selector: 'app-deeplink-builder-modal',
  templateUrl: './deeplink-builder-modal.component.html',
  styleUrls: ['./deeplink-builder-modal.component.scss'],
})
export class DeeplinkBuilderModalComponent implements OnInit, OnDestroy {

  private subs = new Subscription();
  submitted = false;
  error: string | null;

  constructor(
    private dialogRef: MatDialogRef<DeeplinkBuilderModalComponent>,
    public dlService: DeeplinkBuilderService,
  ) {}

  ngOnInit(): void {
    this.subs.add(this.dlService.isNotValid().subscribe(result => {
      this.error = result;
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  clear(): void {
    this.dlService.reset();
  }

  selectDlType(option: DropdownOption) {
    this.dlService.selectDlType(option);
    this.submitted = false;
  }

  selectComponent(option: DropdownOption): void {
    this.dlService.onComponentSelected(option);
    this.submitted = false;
  }

  selectDynamicOpt(option: DropdownOption, index: number): void {
    this.dlService.onDynamicDropdownSelected(option, index);
    this.submitted = false;
  }

  getSelectedDropdownItem(index: number): Observable<DropdownOption> {
    if(index === 0) {
      return this.dlService.selectedFirstLevel$;
    }
    return this.dlService.selectedSecondLevel$;
  }

  // disable dropdown element if (n - 1) dropdown is not selected
  isDynamicDropdownDisabled(dynamicDropdownIndex: number): boolean {
    return this.dlService.isDynamicDropdownDisabled(dynamicDropdownIndex)
  }

  save(_: Event) {
    this.submitted = true;
    if(this.error) {
      return;
    }
    if(this.dlService.tryToSave()) {
      this.dialogRef.close();
    }
  }

  get feedWallConst() {
    return ComponentNames.FEED_WALL
  }

  getCuttedMessageString(message: string): string {
    if(message.length > 30) {
      return message.slice(0, 30) + '...';
    } else {
      return message;
    }
  }
}

import { Injectable } from '@angular/core';

import { Observable, defer } from 'rxjs';
import { tap, take, switchMap } from 'rxjs/operators';

import { SurveyQuizAnswerProviderService } from '@components/survey-quiz/providers/survey-quiz-answer-provider.service';
import { SurveyQuizAnswerStoreService } from '@components/survey-quiz/providers/survey-quiz-answer-store.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SurveyQuizModel } from '@components/survey-quiz/models/survey-quiz.model';
import { SurveyQuizQuestionModel } from '@components/survey-quiz/models/survey-quiz-question.model';
import { SurveyQuizAnswerModel } from '@components/survey-quiz/models/survey-quiz-answer.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyQuizAnswerService {
  protected currentEvent: EventModel;
  protected currentComponent: ComponentModel;
  protected currentSurveyQuiz: SurveyQuizModel;
  protected currentSurveyQuizQuestion: SurveyQuizQuestionModel;

  constructor(private surveyQuizAnswerProvider: SurveyQuizAnswerProviderService,
    private surveyQuizAnswerStore: SurveyQuizAnswerStoreService) {}

  getOrderedSurveyQuizAnswers(): Observable<SurveyQuizAnswerModel[]> {
    return this.surveyQuizAnswerStore.getOrderedSurveyQuizAnswers();
  }

  getSurveyQuizAnswers(): Observable<SurveyQuizAnswerModel[]> {
    return this.surveyQuizAnswerStore.getSurveyQuizAnswers();
  }

  loadSurveyQuizQuestion(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestion: SurveyQuizQuestionModel): Observable<SurveyQuizAnswerModel[]> {

    return defer(() => this.loadSurveyQuizAnswersFor(event, component, surveyQuiz, surveyQuizQuestion));
  }

  createSurveyQuizAnswer(surveyQuizAnswer: SurveyQuizAnswerModel): Observable<SurveyQuizAnswerModel> {
    return this.surveyQuizAnswerProvider.createSurveyQuizAnswer(
      this.currentEvent, this.currentComponent, this.currentSurveyQuiz, this.currentSurveyQuizQuestion, surveyQuizAnswer
    ).pipe(
      tap(createdSurveyQuizAnswer => this.surveyQuizAnswerStore.pushEntity(createdSurveyQuizAnswer))
    );
  }

  updateSurveyQuizAnswer(surveyQuizAnswer: SurveyQuizAnswerModel): Observable<SurveyQuizAnswerModel> {
    return this.surveyQuizAnswerProvider.updateSurveyQuizAnswer(
      this.currentEvent, this.currentComponent, this.currentSurveyQuiz, this.currentSurveyQuizQuestion, surveyQuizAnswer
    ).pipe(
      tap(createdSurveyQuizAnswer => this.surveyQuizAnswerStore.pushEntity(createdSurveyQuizAnswer))
    );
  }

  deleteSurveyQuizAnswer(surveyQuizAnswer: SurveyQuizAnswerModel): Observable<boolean> {
    return this.surveyQuizAnswerProvider.deleteSurveyQuizAnswer(
      this.currentEvent, this.currentComponent, this.currentSurveyQuiz, this.currentSurveyQuizQuestion, surveyQuizAnswer
    ).pipe(
      tap(success => success && this.surveyQuizAnswerStore.removeEntity(surveyQuizAnswer))
    );
  }

  diffSurveyQuizAnswers(diffWith: SurveyQuizAnswerModel[]): Observable<SurveyQuizAnswerModel[]> {
    const providerDiffObservable = (originalSurveyQuizAnswers: SurveyQuizAnswerModel[]) => {
      return this.surveyQuizAnswerProvider.diffSurveyQuizAnswers(
        this.currentEvent, this.currentComponent, this.currentSurveyQuiz,
        this.currentSurveyQuizQuestion, originalSurveyQuizAnswers, diffWith
      );
    };

    return this.getSurveyQuizAnswers().pipe(take(1)).pipe(
      switchMap(surveyQuizAnswers => providerDiffObservable(surveyQuizAnswers)),
      tap(surveyQuizAnswers => this.surveyQuizAnswerStore.setup(surveyQuizAnswers))
    );
  }

  private loadSurveyQuizAnswersFor(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestion: SurveyQuizQuestionModel): Observable<SurveyQuizAnswerModel[]> {

    this.reset();

    return this.surveyQuizAnswerProvider.loadSurveyQuizAnswers(event, component, surveyQuiz, surveyQuizQuestion).pipe(
      tap(surveyQuizAnswers => this.setup(event, component, surveyQuiz, surveyQuizQuestion, surveyQuizAnswers))
    );
  }

  private setup(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestion: SurveyQuizQuestionModel, surveyQuizAnswers: SurveyQuizAnswerModel[]) {

    this.currentEvent = event;
    this.currentComponent = component;
    this.currentSurveyQuiz = surveyQuiz;
    this.currentSurveyQuizQuestion = surveyQuizQuestion;
    this.surveyQuizAnswerStore.setup(surveyQuizAnswers);
  }

  private reset() {
    this.currentEvent = null;
    this.currentComponent = null;
    this.currentSurveyQuiz = null;
    this.currentSurveyQuizQuestion = null;
    this.surveyQuizAnswerStore.reset();
  }
}

import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-progress',
  templateUrl: './modal-progress.component.html',
  styleUrls: ['./modal-progress.component.scss']
})
export class ModalProgressComponent {
  @Input() title: string;
  @Input() message: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data && data.title;
    this.message = data && data.message;
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTimezone from './timezone.reducer';

export const featureKey = 'timezoneFeature';

export { State, initialState, reducer } from './timezone.reducer';

export const selectTimezoneFeature = createFeatureSelector<fromTimezone.State>(featureKey);

export const selectTimezoneIds = createSelector(
  selectTimezoneFeature,
  fromTimezone.selectTimezoneIds
);

export const selectTimezoneEntities = createSelector(
  selectTimezoneFeature,
  fromTimezone.selectTimezoneEntities
);

export const selectAllTimezones = createSelector(
  selectTimezoneFeature,
  fromTimezone.selectAllTimezones
);

export const selectTimezoneLoading = createSelector(
  selectTimezoneFeature,
  fromTimezone.selectTimezoneLoading
);

export const selectTimezoneLoaded = createSelector(
  selectTimezoneFeature,
  fromTimezone.selectTimezoneLoaded
);

export const selectTimezoneError = createSelector(
  selectTimezoneFeature,
  fromTimezone.selectTimezoneError
);

export const selectSortedTimezones = createSelector(
  selectTimezoneFeature,
  fromTimezone.selectSortedTimezones
);

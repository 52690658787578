import { Component, Input, OnDestroy, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TagApiModel } from '@store/features/tags/tags.models';
import { Subscription } from 'rxjs';
import { DropdownOption } from '../dropdown-with-search/dropdown-with-search.component';
import { TagDatabaseModalService } from '@components/tag-database/tag-database-modal.service';

@Component({
  selector: 'app-tag-control',
  templateUrl: './tag-control.component.html',
  styleUrls: ['./tag-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagControlComponent),
      multi: true
    }
  ]
})
export class TagControlComponent implements OnInit, OnDestroy {
  @Input() tags: TagApiModel[] = [];
  @Input() placeholder: string;
  @Input() readonly = false;
  @Input() staticLabel = false;

  selectControl: FormControl;

  get selected(): TagApiModel {
    return this.selectControl.value;
  }

  get tagOptions(): DropdownOption[] {
    return this.tags?.map(tag => ({ id: tag.id, value: tag, name: tag.value }));
  }

  get selectedOption(): DropdownOption | null {
    return this.selectControl.value ? ({ id: this.selectControl.value.id, value: this.selectControl.value, name: this.selectControl.value.value }) : null;
  }

  private onChange: (value: TagApiModel) => void;
  private onTouched: () => void;

  private subs = new Subscription();

  constructor(
    private i18n: TranslateService,
    private tagDatabaseModalService: TagDatabaseModalService,
  ) {
    this.tags = [];
    this.placeholder = this.i18n.instant('others.choose_tag');
    this.selectControl = new FormControl();
  }

  ngOnInit() {
    this.subs.add(this.selectControl.valueChanges.subscribe(
      selectedTag => this.onChange && this.onChange(selectedTag)
    ));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('tags' in changes) {
      const tag = this.selectControl.value;
      if (tag && !this.tags.some(t => t.id === tag.id)) {
        this.selectControl.setValue(null);
      }
    }
  }

  onEdit() {
    this.tagDatabaseModalService.openEditDialog();
  }

  reset(): void {
    this.selectControl.setValue(null, { emitEvent: false });
  }

  handleTagSelect(option: DropdownOption | null): void {
    this.writeValue(option ? option.value : null);
  }

  writeValue(selectTag: TagApiModel): void {
    this.selectControl.setValue(selectTag);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }

}

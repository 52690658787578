import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentModel } from '@shared/models/component.model';
import { ComponentUtilsFacadeService } from '@store/features/component/component-utils-facade.service';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { EventLanguageFacadeService } from '@store/features/event/event-language-facade.service';
import { EventLanguageModel } from '@store/features/event/models/event-language.model';
import { FormControl, FormGroup } from '@angular/forms';
import { filter, first, map, take, tap } from 'rxjs/operators';
import { ModelForm } from '@utils/model-form.util';
import { OnboardingTranslatableFields } from './component-onboarding-dialog-provider.service';
import { ComponentBookingUtilsModel, ComponentBookingUtilsPatchModel, ComponentBusinessMatchingUtilsModel, ComponentBusinessMatchingUtilsPatchModel, ComponentTreasureHuntUtilsModel, ComponentTreasureHuntUtilsPatchModel, ComponentUtilsType } from '@store/features/component/models/component-utils.model';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-component-onboarding-dialog',
  templateUrl: './component-onboarding-dialog.component.html',
  styleUrls: ['./component-onboarding-dialog.component.scss']
})
export class ComponentOnboardingDialogComponent implements OnInit, OnDestroy {

  // injected
  component: ComponentModel;

  loading$: Observable<boolean>;
  updating$: Observable<boolean>;
  error$: Observable<Error>;
  processingUpdateRequestsAmount$: Observable<number>;

  isSaving: boolean;

  readonly$: Observable<boolean>;

  languages$: Observable<EventLanguageModel[]>;
  activeLanguage$: BehaviorSubject<EventLanguageModel>;


  onBoardingForm: FormGroup;
  translationForms: FormGroup;
  activeTranslation$: Observable<FormGroup>;

  utils$: Observable<ComponentOnboardingTypes>;
  utilsTranslations$: Observable<{ [key: string]: ComponentUtilsType }>;

  get isOnboardingHidden(): boolean {
    return !this.onBoardingForm.controls['onboardingShow'].value;
  }

  private subs = new Subscription();

  constructor(
    private componentUtilsFacade: ComponentUtilsFacadeService,
    private eventLanguageFacade: EventLanguageFacadeService,
    private dialogRef: MatDialogRef<ComponentOnboardingDialogComponent>,
  ) {
    this.activeLanguage$ = new BehaviorSubject(null);
    this.utils$ = this.componentUtilsFacade.getUtils() as Observable<ComponentOnboardingTypes>;
    this.utilsTranslations$ = this.componentUtilsFacade.getUtilsTranslations();
    this.translationForms = new FormGroup({});

    this.onBoardingForm = new FormGroup({
      onboardingShow: new FormControl(false),
      onboardingImage: new FormControl(null),
      // (+) translatable fields (name & description)
    });

    this.loading$ = this.componentUtilsFacade.getLoading();
    this.updating$ = this.componentUtilsFacade.getUpdating();
    this.languages$ = this.eventLanguageFacade.getAllEventLanguagesWithNames();
    this.error$ = this.componentUtilsFacade.getError();
    this.processingUpdateRequestsAmount$ = this.componentUtilsFacade.getProcessingUpdateRequestsAmount();

    this.isSaving = false;
    this.readonly$ = this.activeLanguage$.pipe(
      map(lang=> !lang.default)
    );
  }

  ngOnInit(): void {
    this.subs.add(this.eventLanguageFacade.getDefaultEventLanguage().pipe(take(1)).subscribe(
      language => this.activeLanguage$.next(language)
    ));

    this.subs.add(this.eventLanguageFacade.getAllEventLanguages().pipe(take(1)).subscribe(
      languages => languages.map(language => this.getTranslationFormFor(language))
    ));

    this.activeTranslation$ = this.activeLanguage$.pipe(
      map(language => this.getTranslationFormFor(language))
    );

    this.subs.add(this.utils$
      .pipe(first(utils => !!utils))
      .subscribe(utils => this.populate(utils)));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.componentUtilsFacade.clearState();
  }

  isLanguageActive(language: EventLanguageModel) {
    return this.activeLanguage$.value.code === language.code;
  }

  onLanguageChange(ev: Event, language: EventLanguageModel) {
    if(!language) return;
    if (this.activeLanguage$.value.code !== language.code) {
      this.activeLanguage$.next(language);
    }
  }

  save(): void {
    this.subs.add(combineLatest([this.utils$, this.utilsTranslations$, this.eventLanguageFacade.getAllEventLanguages()])
      .pipe(take(1))
      .subscribe(([utils, translations, langs]) => {
        this.isSaving = true;
        langs.forEach(lang => {
          const translationValues = this.translationForms.value[lang.code];
          const translationsFromState = translations[lang.code];
          const payload: ComponentOnboardingPatchTypes = {
            ...utils,
            ...translationsFromState,
            ...this.onBoardingForm.value,
            ...translationValues,
          }
          this.componentUtilsFacade.patch(this.component, lang, payload);
        });
        this.handleSavedSuccess();
      }));
  }
  
  private handleSavedSuccess(): void {
    this.subs.add(combineLatest([this.updating$, this.error$, this.processingUpdateRequestsAmount$])
      .pipe(
        first(([updating, error, processingReqAmount]) => !!error || processingReqAmount === 0)
      )
      .subscribe(([updating, error]) => {
        if (!updating && !error) {
          this.isSaving = false;
          this.dialogRef.close();
        }
      }));
  }

  private populate(utils: ComponentOnboardingTypes): void {
    this.onBoardingForm.patchValue({
      onboardingShow: utils.onboardingShow,
      onboardingImage: utils.onboardingImage,
    });
  }

  private getTranslationFormFor(language: EventLanguageModel) {
    if (!this.translationForms.controls[language.code]) {
      const form = this.createTranslationForm(language.code);
      this.translationForms.addControl(language.code, form);
      this.subs.add(this.componentUtilsFacade.getUtilsTranslation(language.code).pipe(
        filter(x => !!x), take(1),
      ).subscribe(
        (tr: ComponentOnboardingTypes) => this.translationForms.controls[language.code].patchValue({
          onboardingName: tr.onboardingName,
          onboardingDescription: tr.onboardingDescription,
        })
      ));

      this.subs.add(this.componentUtilsFacade.getUtilsTranslation(language.code).pipe(take(1)).subscribe(
        tr => !tr && this.loadTranslationFormFor(language)
      ));
    }

    return this.translationForms.controls[language.code] as FormGroup;
  }

  private loadTranslationFormFor(language: EventLanguageModel) {
    this.componentUtilsFacade.load(this.component, language);
  }


  private createTranslationForm(language: string) {
    const translationForm: ModelForm<OnboardingTranslatableFields> = {
      onboardingName: new FormControl(''),
      onboardingDescription: new FormControl(''),
    };

    return new FormGroup({
      ...translationForm,
    });
  }

}

export type ComponentOnboardingTypes = ComponentBookingUtilsModel | ComponentBusinessMatchingUtilsModel | ComponentTreasureHuntUtilsModel;
export type ComponentOnboardingPatchTypes = ComponentBookingUtilsPatchModel | ComponentBusinessMatchingUtilsPatchModel | ComponentTreasureHuntUtilsPatchModel;
import { EventUserModel } from '@shared/models/event-user.model';
import { TagApiModel } from '@store/features/tags/tags.models';

export enum BusinessMatchingMeetingStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Declined = 'declined',
}

export interface BusinessMatchingMeetingModel {
  id: number;
  componentId: number;
  sessionId: number;
  // TODO: Use UUID instead of models, requires event users' store freature
  fromUser: EventUserModel;
  toUser: EventUserModel;
  timeStart: string;
  timeEnd: string;
  message: string;
  status: BusinessMatchingMeetingStatus;
  // TODO: Use tag ID instead of models, requires tags' store freature
  placeTag: TagApiModel;
  customPlace: string;
}

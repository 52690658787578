import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { InteractiveMapModel } from '@components/interactive-map/models/interactive-map.model';
import { InteractiveMapLocationModel } from '@components/interactive-map/models/interactive-map-location.model';

import * as InteractiveMapActions from '../actions/interactive-map.actions';

import * as fromInteractiveMap from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class InteractiveMapFacadeService {
  constructor(private store$: Store<any>) {}

  getAllInteractiveMaps(): Observable<InteractiveMapModel[]> {
    return this.store$.pipe(select(fromInteractiveMap.selectAllInteractiveMaps));
  }

  getInteractiveMapLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromInteractiveMap.selectInteractiveMapLoading));
  }

  getInteractiveMapLoaded(): Observable<boolean> {
    return this.store$.pipe(select(fromInteractiveMap.selectInteractiveMapLoaded));
  }

  getInteractiveMapError(): Observable<Error> {
    return this.store$.pipe(select(fromInteractiveMap.selectInteractiveMapError));
  }

  getInteractiveMapUpdating(): Observable<boolean> {
    return this.store$.pipe(select(fromInteractiveMap.selectInteractiveMapUpdating));
  }

  getUpdatedInteractiveMap(): Observable<InteractiveMapModel> {
    return this.store$.pipe(select(fromInteractiveMap.selectUpdatedInteractiveMap));
  }

  getInteractiveMapLocations(interactiveMap: InteractiveMapModel): Observable<InteractiveMapLocationModel[]> {
    return this.store$.pipe(select(fromInteractiveMap.selectInteractiveMapLocations(), { interactiveMap }));
  }

  loadInteractiveMaps(event: EventModel, component: ComponentModel): void {
    this.store$.dispatch(InteractiveMapActions.loadInteractiveMaps({ event, component }));
  }

  createInteractiveMap(event: EventModel, component: ComponentModel, interactiveMap: InteractiveMapModel, locations: InteractiveMapLocationModel[]): void {
    this.store$.dispatch(InteractiveMapActions.createInteractiveMap({ event, component, interactiveMap, locations }));
  }

  updateInteractiveMap(event: EventModel, component: ComponentModel, interactiveMap: InteractiveMapModel, locations: InteractiveMapLocationModel[]): void {
    this.store$.dispatch(InteractiveMapActions.updateInteractiveMap({ event, component, interactiveMap, locations }));
  }

  deleteInteractiveMap(event: EventModel, component: ComponentModel, interactiveMap: InteractiveMapModel): void {
    this.store$.dispatch(InteractiveMapActions.deleteInteractiveMap({ event, component, interactiveMap }));
  }

  reorderMaps(event: EventModel, component: ComponentModel, interactiveMaps: InteractiveMapModel[]): void {
    this.store$.dispatch(InteractiveMapActions.reorderMaps({ event, component, interactiveMaps }));
  }
}

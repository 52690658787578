import { Injectable } from '@angular/core';

import { Observable, from, of } from 'rxjs';
import { map, toArray, filter } from 'rxjs/operators';

import { ImportMetadata } from './import-loader.service';

export interface ImportMatchDescription {
  columns: string[];
}

export interface ImportMatchResult {
  valid: boolean;
  columns: string[];
  error: Error;
}

@Injectable({
  providedIn: 'root'
})
export class ImportMatcherService {
  match(desc: ImportMatchDescription, meta: ImportMetadata): Observable<ImportMatchResult> {
    if (!meta) {
      return of(null);
    }

    const validatedColumns$ = from(desc.columns).pipe(
      filter((column, index) => meta.columns[index] === column),
      toArray(),
    );

    return validatedColumns$.pipe(
      map(columns => this.buildResultFor(desc, columns))
    );
  }

  private buildResultFor(desc: ImportMetadata, columns: string[]): ImportMatchResult {
    const valid = desc.columns.length === columns.length;
    const error = valid ? null : new Error('Failed to match columns');

    return {
      valid, columns, error,
    };
  }
}

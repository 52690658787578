import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { TimezoneModel } from '../models/timezone.model';

import * as TimezoneActions from '../actions/timezone.actions';

export interface State extends EntityState<TimezoneModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<TimezoneModel>({
  selectId: timezone => timezone.identifier,
  sortComparer: false,
});

export const initialState: State = {
  ...adapter.getInitialState(),
  loading: false,
  loaded: false,
  error: null,
};

export const timezoneReducer = createReducer(
  initialState,
  on(TimezoneActions.loadTimezones, state => ({
    ...initialState,
    loading: true,
  })),
  on(TimezoneActions.loadTimezonesSuccess, (state, { timezones }) =>
    adapter.setAll(timezones, { ...state, loading: false, loaded: true })
  ),
  on(TimezoneActions.loadTimezonesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function reducer(state: State, action: Action): State {
  return timezoneReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectTimezoneIds = selectIds;

export const selectTimezoneEntities = selectEntities;

export const selectAllTimezones = selectAll;

export const selectTimezoneLoading = (state: State) => state.loading;

export const selectTimezoneLoaded = (state: State) => state.loaded;

export const selectTimezoneError = (state: State) => state.error;

export const selectSortedTimezones = createSelector(
  selectAllTimezones,
  timezones => timezones.sort(sortTimezones)
);

function sortTimezones(a: TimezoneModel, b: TimezoneModel): number {
  return a.fullName.localeCompare(b.fullName);
}

import { createAction, props } from '@ngrx/store';

import { ExhibitorModel } from '@components/exhibitor/models/exhibitor.model';
import { ExhibitorTranslationModel } from '@components/exhibitor/models/exhibitor-translation.model';

export const loadExhibitorTranslation = createAction(
  '[Exhibitor/Exhibitor Modal] Load Exhibitor Translation',
  props<{ exhibitor: ExhibitorModel, language: string }>()
);

export const loadExhibitorTranslationSuccess = createAction(
  '[Exhibitor/API] Load Exhibitor Translation Success',
  props<{ exhibitor: ExhibitorModel, translation: ExhibitorTranslationModel }>()
);

export const loadExhibitorTranslationFailure = createAction(
  '[Exhibitor/API] Load Exhibitor Translation Failure',
  props<{ exhibitor: ExhibitorModel, error: Error }>()
);

export const updateExhibitorTranslations = createAction(
  '[Exhibitor/Exhibitor Modal] Update Exhibitor Translations',
  props<{ exhibitor: ExhibitorModel, translations: ExhibitorTranslationModel[] }>()
);

export const updateExhibitorTranslationsSuccess = createAction(
  '[Exhibitor/API] Update Exhibitor Translations Success',
  props<{ exhibitor: ExhibitorModel, translations: ExhibitorTranslationModel[] }>()
);

export const updateExhibitorTranslationsFailure = createAction(
  '[Exhibitor/API] Update Exhibitor Translations Failure',
  props<{ exhibitor: ExhibitorModel, error: Error }>()
);

export const clearExhibitorTranslations = createAction(
  '[Exhibitor/Exhibitor Delete] Clear Exhibitor Translations',
  props<{ exhibitor: ExhibitorModel }>()
);

import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSpeaker from './speaker.reducer';
import * as fromSpeakerTranslation from './speaker-translation.reducer';
import * as fromSpeakerModal from './speaker-modal.reducer';

export const featureKey = 'speakerFeature';

export interface State {
  [fromSpeaker.stateKey]: fromSpeaker.State;
  [fromSpeakerTranslation.stateKey]: fromSpeakerTranslation.State;
  [fromSpeakerModal.stateKey]: fromSpeakerModal.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromSpeaker.stateKey]: fromSpeaker.reducer,
  [fromSpeakerTranslation.stateKey]: fromSpeakerTranslation.reducer,
  [fromSpeakerModal.stateKey]: fromSpeakerModal.reducer,
};

export const selectSpeakerFeature = createFeatureSelector<State>(featureKey);

export const selectSpeakerState = createSelector(
  selectSpeakerFeature,
  state => state[fromSpeaker.stateKey]
);

export const selectSpeakerEntities = createSelector(
  selectSpeakerState,
  fromSpeaker.selectSpeakerEntities
);

export const selectAllSpeakers = createSelector(
  selectSpeakerState,
  fromSpeaker.selectAllSpeakers
);

export const selectSpeakers = createSelector(
  selectSpeakerState,
  fromSpeaker.selectSpeakers
);

export const selectSpeakerLoading = createSelector(
  selectSpeakerState,
  fromSpeaker.selectSpeakerLoading
);

export const selectSpeakerLoaded = createSelector(
  selectSpeakerState,
  fromSpeaker.selectSpeakerLoaded
);

export const selectSpeakerError = createSelector(
  selectSpeakerState,
  fromSpeaker.selectSpeakerError
);

export const selectSpeakerUpdating = createSelector(
  selectSpeakerState,
  fromSpeaker.selectSpeakerUpdating
);

export const selectSpeakerReordering = createSelector(
  selectSpeakerState,
  fromSpeaker.selectSpeakerReordering
);

export const selectUpdatedSpeaker = createSelector(
  selectSpeakerState,
  fromSpeaker.selectUpdatedSpeaker
);

export const selectSpeakerTranslationState = createSelector(
  selectSpeakerFeature,
  state => state[fromSpeakerTranslation.stateKey]
);

export const selectSpeakerTranslations = createSelector(
  selectSpeakerTranslationState,
  fromSpeakerTranslation.selectSpeakerTranslations
);

export const selectSpeakerTranslation = createSelector(
  selectSpeakerTranslationState,
  fromSpeakerTranslation.selectSpeakerTranslation
);

export const selectSpeakerTranslationLoading = createSelector(
  selectSpeakerTranslationState,
  fromSpeakerTranslation.selectSpeakerTranslationLoading
);

export const selectLoadedSpeakerTranslation = createSelector(
  selectSpeakerTranslationState,
  fromSpeakerTranslation.selectLoadedSpeakerTranslation
);

export const selectSpeakerTranslationError = createSelector(
  selectSpeakerTranslationState,
  fromSpeakerTranslation.selectSpeakerTranslationError
);

export const selectSpeakerModalState = createSelector(
  selectSpeakerFeature,
  state => state[fromSpeakerModal.stateKey]
);

export const selectSpeakerModalUpdating = createSelector(
  selectSpeakerModalState,
  fromSpeakerModal.selectSpeakerModalUpdating
);

export const selectUpdatedSpeakerModalId = createSelector(
  selectSpeakerModalState,
  fromSpeakerModal.selectUpdatedSpeakerModalId
);

export const selectUpdatedSpeakerModal = createSelector(
  selectSpeakerEntities,
  selectUpdatedSpeakerModalId,
  (speakers, updatedId) => speakers[updatedId]
);
import { GetVenueIconModel } from '@components/venue/models/venue.model';
import { createAction, props } from '@ngrx/store';


export const loadVenueIcons = createAction(
  '[Venue/API] Load Icons',
);

export const loadVenueIconsSuccess = createAction(
  '[Venue/API] Load Icons Success',
  props<{ icons: GetVenueIconModel[] }>()
);

export const loadVenueIconsFailure = createAction(
  '[Venue/API] Load Icons Failure',
  props<{ error: Error }>()
);
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as fromPartner from './partner.reducer';
import * as fromPartnerTranslation from './partner-translation.reducer';
import * as fromPartnerContactPerson from './partner-contact-person.reducer';
import * as fromPartnerContactPersonTranslation from './partner-contact-person-translation.reducer';
import * as fromPartnerCategory from './partner-category.reducer';
import * as fromPartnerModal from './partner-modal.reducer';
import * as fromPartnerImport from './partner-import.reducer';

export const featureKey = 'partnerFeature';

export interface State {
  [fromPartner.stateKey]: fromPartner.State;
  [fromPartnerTranslation.stateKey]: fromPartnerTranslation.State;
  [fromPartnerContactPerson.stateKey]: fromPartnerContactPerson.State;
  [fromPartnerContactPersonTranslation.stateKey]: fromPartnerContactPersonTranslation.State;
  [fromPartnerCategory.stateKey]: fromPartnerCategory.State;
  [fromPartnerModal.stateKey]: fromPartnerModal.State;
  [fromPartnerImport.stateKey]: fromPartnerImport.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromPartner.stateKey]: fromPartner.reducer,
  [fromPartnerTranslation.stateKey]: fromPartnerTranslation.reducer,
  [fromPartnerContactPerson.stateKey]: fromPartnerContactPerson.reducer,
  [fromPartnerContactPersonTranslation.stateKey]:
    fromPartnerContactPersonTranslation.reducer,
  [fromPartnerCategory.stateKey]: fromPartnerCategory.reducer,
  [fromPartnerModal.stateKey]: fromPartnerModal.reducer,
  [fromPartnerImport.stateKey]: fromPartnerImport.reducer,
};

export const selectPartnerFeature = createFeatureSelector<State>(featureKey);

export const selectPartnerState = createSelector(
  selectPartnerFeature,
  state => state[fromPartner.stateKey]
);

export const selectAllPartners = createSelector(
  selectPartnerState,
  fromPartner.selectAllPartners
);

export const selectPartners = createSelector(
  selectPartnerState,
  fromPartner.selectPartners
);

export const selectPartnerLoading = createSelector(
  selectPartnerState,
  fromPartner.selectPartnerLoading
);

export const selectPartnerLoaded = createSelector(
  selectPartnerState,
  fromPartner.selectPartnerLoaded
);

export const selectPartnerError = createSelector(
  selectPartnerState,
  fromPartner.selectPartnerError
);

export const selectUpdatedPartner = createSelector(
  selectPartnerState,
  fromPartner.selectUpdatedPartner
);

export const selectPartnersByCategory = () =>
  createSelector(selectPartners, (partners, { categoryId }) => {
    const filtered = partners.filter(
      partner => partner.categoryId === categoryId
    );
    return filtered;
  });

export const selectPartnerContactPersonState = createSelector(
  selectPartnerFeature,
  state => state[fromPartnerContactPerson.stateKey]
);

export const selectAllPartnerContactPeople = createSelector(
  selectPartnerContactPersonState,
  fromPartnerContactPerson.selectAllPartnerContactPeople
);

export const selectPartnerContactPeople = () =>
  createSelector(
    selectPartnerContactPersonState,
    fromPartnerContactPerson.selectPartnerContactPeople()
  );

export const selectPartnerContactPersonLoading = createSelector(
  selectPartnerContactPersonState,
  fromPartnerContactPerson.selectPartnerContactPersonLoading
);

export const selectPartnerContactPersonLoaded = createSelector(
  selectPartnerContactPersonState,
  fromPartnerContactPerson.selectPartnerContactPersonLoaded
);

export const selectPartnerContactPersonError = createSelector(
  selectPartnerContactPersonState,
  fromPartnerContactPerson.selectPartnerContactPersonError
);

export const selectPartnerModalState = createSelector(
  selectPartnerFeature,
  state => state[fromPartnerModal.stateKey]
);

export const selectPartnerModalSaving = createSelector(
  selectPartnerModalState,
  fromPartnerModal.selectPartnerModalSaving
);

export const selectPartnerModalSaved = createSelector(
  selectPartnerModalState,
  fromPartnerModal.selectPartnerModalSaved
);

export const selectPartnerModalError = createSelector(
  selectPartnerModalState,
  fromPartnerModal.selectPartnerModalError
);

export const selectPartnerTranslationState = createSelector(
  selectPartnerFeature,
  state => state[fromPartnerTranslation.stateKey]
);

export const selectPartnerTranslations = createSelector(
  selectPartnerTranslationState,
  fromPartnerTranslation.selectPartnerTranslations
);

export const selectPartnerTranslation = createSelector(
  selectPartnerTranslationState,
  fromPartnerTranslation.selectPartnerTranslation
);

export const selectPartnerTranslationLoading = createSelector(
  selectPartnerTranslationState,
  fromPartnerTranslation.selectPartnerTranslationLoading
);

export const selectPartnerTranslationError = createSelector(
  selectPartnerTranslationState,
  fromPartnerTranslation.selectPartnerTranslationError
);

export const selectPartnerContactPersonTranslationState = createSelector(
  selectPartnerFeature,
  state => state[fromPartnerContactPersonTranslation.stateKey]
);

export const selectPartnerContactPersonTranslations = createSelector(
  selectPartnerContactPersonTranslationState,
  fromPartnerContactPersonTranslation.selectPartnerContactPersonTranslations
);

export const selectPartnerContactPersonTranslation = createSelector(
  selectPartnerContactPersonTranslationState,
  fromPartnerContactPersonTranslation.selectPartnerContactPersonTranslation
);

export const selectPartnerContactPersonTranslationLoading = createSelector(
  selectPartnerContactPersonTranslationState,
  fromPartnerContactPersonTranslation.selectPartnerContactPersonTranslationLoading
);

export const selectPartnerContactPersonTranslationError = createSelector(
  selectPartnerContactPersonTranslationState,
  fromPartnerContactPersonTranslation.selectPartnerContactPersonTranslationError
);

export const selectPartnerCategoryState = createSelector(
  selectPartnerFeature,
  state => state[fromPartnerCategory.stateKey]
);

export const selectAllPartnerCategories = createSelector(
  selectPartnerCategoryState,
  fromPartnerCategory.selectAllCategories
);

export const selectAllPartnerCategoriesWithPlaceholder = createSelector(
  selectPartnerCategoryState,
  fromPartnerCategory.selectAllCategoriesWithPlaceholder
);

export const selectPartnerCategoryLoading = createSelector(
  selectPartnerCategoryState,
  fromPartnerCategory.selectCategoryLoading
);

export const selectPartnerCategoryLoaded = createSelector(
  selectPartnerCategoryState,
  fromPartnerCategory.selectCategoryLoaded
);

export const selectPartnerCategoryError = createSelector(
  selectPartnerCategoryState,
  fromPartnerCategory.selectCategoryError
);

export const selectPartnerCategoryTranslations = () =>
  createSelector(
    selectPartnerCategoryState,
    fromPartnerCategory.selectTranslations()
  );

export const selectPartnerCategoryTranslation = () =>
  createSelector(
    selectPartnerCategoryState,
    fromPartnerCategory.selectTranslation()
  );

export const selectPartnerCategoryPlaceholderUuid = createSelector(
  selectPartnerCategoryState,
  fromPartnerCategory.selectPlaceholderUuid
);

export const selectPartnerCategoryPlaceholderShown = createSelector(
  selectPartnerCategoryState,
  fromPartnerCategory.selectPlaceholderShown
);

export const selectPartnerCategoryPlaceholderFocused = createSelector(
  selectPartnerCategoryState,
  fromPartnerCategory.selectPlaceholderFocused
);

export const selectPartnerImportState = createSelector(
  selectPartnerFeature,
  state => state[fromPartnerImport.stateKey]
);

export const selectPartnerImporting = createSelector(
  selectPartnerImportState,
  fromPartnerImport.selectImporting
);

export const selectPartnerImported = createSelector(
  selectPartnerImportState,
  fromPartnerImport.selectImported
);

export const selectPartnerImportResults = createSelector(
  selectPartnerImportState,
  fromPartnerImport.selectResults
);

export const selectPartnerImportError = createSelector(
  selectPartnerImportState,
  fromPartnerImport.selectError
);

<div class="chart-container">
  <div class="chart-wrapper">
    <canvas
      baseChart
      class="chart"
      [data]="data"
      [type]="type"
      [options]="options"
      [plugins]="plugins">
    </canvas>
  </div>
</div>
import { Observable } from 'rxjs';
import { FileUploaderDialogComponent } from './file-uploader-dialog.component';
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class FileUploaderDialogService {
  constructor(private dialog: MatDialog) {}

  open(): Observable<string> {
    const dialog = this.dialog.open(FileUploaderDialogComponent, {
      disableClose: true,
      panelClass: 'confirm-dialog-container'
    });

    dialog.componentInstance.onSubmit.subscribe(() => dialog.close());

    return dialog.componentInstance.onSubmit;
  }

}
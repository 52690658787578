import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from "@angular/core";
import { ConfirmModalComponent } from './confirm-modal.component';

export interface ConfirmDialogContent {
  title?: string;
  desc: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  private content: ConfirmDialogContent;
  private countdown: boolean;

  constructor(private dialog: MatDialog) {}

  open(content: ConfirmDialogContent, countdown = true): MatDialogRef<any> {
    this.content = content;
    this.countdown = countdown;
    return this.openDialog();
  }

  private openDialog(): MatDialogRef<any> {
    const dialogRef = this.dialog.open(ConfirmModalComponent, { disableClose: true, panelClass: 'confirm-dialog-container' });
    dialogRef.componentInstance.title = this.content.title;
    dialogRef.componentInstance.desc = this.content.desc;
    dialogRef.componentInstance.countdown = this.countdown;
    return dialogRef;
  }
}
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';

import { WwwResourceModel } from '@components/www-resource/models/www-resource.model';

import * as WwwResourceActions from '../actions/www-resource.actions';
import { selectEvent } from '@store/features/event/actions';

export interface State extends EntityState<WwwResourceModel> {
  error: Error;
}

export const wwwResourceAdapter = createEntityAdapter<WwwResourceModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialWwwResourceState: State = wwwResourceAdapter.getInitialState({
  error: null,
});

export const wwwResourceReducer = createReducer(
  initialWwwResourceState,
  on(
    WwwResourceActions.loadWwwResourcesRequest,
    (state, { componentId }) => wwwResourceAdapter.removeMany(wwwResource => wwwResource.componentId === componentId, state)
  ),
  on(
    WwwResourceActions.loadWwwResourcesSuccess,
    (state, { wwwResources }) => wwwResourceAdapter.addMany(wwwResources, state)
  ),
  on(
    WwwResourceActions.createWwwResourceSuccess,
    WwwResourceActions.updateWwwResourceSuccess,
    (state, { wwwResource }) => wwwResourceAdapter.upsertOne(wwwResource, state)
  ),
  on(
    WwwResourceActions.deleteWwwResourceSuccess,
    (state, { wwwResource }) => wwwResourceAdapter.removeOne(wwwResource.id, state)
  ),

  on(
    WwwResourceActions.reorderItemsInplace,
    (state, { items }) => {

      const updates: Update<WwwResourceModel>[] = items.map((item, order) => ({
        id: item.id,
        changes: { order },
      }));

      return wwwResourceAdapter.updateMany(updates, state);
    }
  ),
  on(
    WwwResourceActions.reorderItemsSuccess,
    (state, { items }) => wwwResourceAdapter.upsertMany(items, state)
  ),
  on(
    WwwResourceActions.createWwwResourceFailure,
    WwwResourceActions.reorderItemsFailure,
    WwwResourceActions.updateWwwResourceFailure,
    WwwResourceActions.deleteWwwResourceFailure,
    (state, { error }) => ({ ...state, error })
  ),

  on(
    selectEvent,
    () => ({ ...initialWwwResourceState }),
  )
);

export function reducer(state: State, action: Action): State {
  return wwwResourceReducer(state, action);
}

/**
 * WwwResource selectors
 */
const {
  selectIds,
  selectEntities,
  selectAll,
} = wwwResourceAdapter.getSelectors();

export const selectWwwResourceIds = selectIds;

export const selectWwwResourceEntities = selectEntities;

export const selectAllWwwResources = selectAll;

export const selectWwwResources = createSelector(
  selectAllWwwResources,
  (wwwResources: WwwResourceModel[], { componentId }: { componentId: number }) =>
    wwwResources.filter(wwwResource => wwwResource.componentId === componentId )
);

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TokenModel } from '@app/core/models/token.model';
import { ApiTokenModel, deflateTokenModel, inflateTokenModel } from '@app/core/models/api-token.model';

import { API_AUTH_BASE_URL, API_BASE_URL } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenApiService {
  constructor(private httpClient: HttpClient) { }

  refreshToken(token: TokenModel): Observable<TokenModel> {
    const url = `${API_AUTH_BASE_URL}/refresh_token`;
    const apiToken = deflateTokenModel(token);
    return this.httpClient.post<ApiTokenModel>(url, apiToken).pipe(map(inflateTokenModel));
  }
}

import {
  Action,
  createReducer,
  createSelector,
  on,
  combineReducers,
} from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import {
  WwwResourceTranslationModel,
  WwwResourceCategoryTranslationModel,
} from '@components/www-resource/models/www-resource.model';

import * as TranslationActions from '../actions/translation.actions';
import { selectEvent } from '@store/features/event/actions';

export interface WwwResourceTranslationState
  extends EntityState<WwwResourceTranslationModel> {
  loading: boolean;
  error: Error;
}

export interface CategoryTranslationState
  extends EntityState<WwwResourceCategoryTranslationModel> {
  loading: boolean;
  error: Error;
}

export interface State {
  wwwResources: WwwResourceTranslationState;
  categories: CategoryTranslationState;
}

export const wwwResourceAdapter =
  createEntityAdapter<WwwResourceTranslationModel>({
    sortComparer: false,
  });

export const initialWwwResourceState: WwwResourceTranslationState =
  wwwResourceAdapter.getInitialState({
    loading: false,
    error: null,
  });

export const wwwResourceTranslationReducer = createReducer(
  initialWwwResourceState,
  on(
    TranslationActions.loadWwwResourceTranslation,
    (state, { wwwResource }) => ({ ...state, loading: true })
  ),
  on(
    TranslationActions.loadWwwResourceTranslationSuccess,
    (state, { wwwResource, translation }) =>
      wwwResourceAdapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    TranslationActions.loadWwwResourceTranslationFailure,
    (state, { wwwResource, error }) => ({ ...state, loading: false, error })
  ),
  on(
    TranslationActions.updateWwwResourceTranslationsSuccess,
    (state, { wwwResource, translations }) =>
      wwwResourceAdapter.upsertMany(translations, state)
  ),
  on(
    TranslationActions.updateWwwResourceTranslationsFailure,
    (state, { wwwResource, error }) => ({ ...state, error })
  ),

  on(
    selectEvent,
    () => ({ ...initialWwwResourceState }),
  )
);

export const categoryAdapter =
  createEntityAdapter<WwwResourceCategoryTranslationModel>({
    sortComparer: false,
  });

export const initialCategoryState: CategoryTranslationState =
  categoryAdapter.getInitialState({
    loading: false,
    error: null,
  });

export const categoryTranslationReducer = createReducer(
  initialCategoryState,
  on(
    TranslationActions.loadWwwResourceCategoryTranslation,
    (state, { category }) => ({ ...state, loading: true })
  ),
  on(
    TranslationActions.loadWwwResourceCategoryTranslationSuccess,
    (state, { category, translation }) =>
      categoryAdapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    TranslationActions.loadWwwResourceCategoryTranslationFailure,
    (state, { category, error }) => ({ ...state, loading: false, error })
  ),
  on(
    TranslationActions.updateWwwResourceCategoryTranslationsSuccess,
    (state, { category, translations }) =>
      categoryAdapter.upsertMany(translations, state)
  ),
  on(
    TranslationActions.updateWwwResourceCategoryTranslationsFailure,
    (state, { category, error }) => ({ ...state, error })
  )
);

export const combinedReducers = combineReducers<State>({
  wwwResources: wwwResourceTranslationReducer,
  categories: categoryTranslationReducer,
});

export function reducer(state: State, action: Action): State {
  return combinedReducers(state, action);
}

// VENUE SELECTORS

export const selectWwwResourceTranslationState = (state: State) =>
  state.wwwResources;

const { selectAll: selectAllWwwResourceTranslations } =
  wwwResourceAdapter.getSelectors(selectWwwResourceTranslationState);

export const selectWwwResourceTranslations = createSelector(
  selectAllWwwResourceTranslations,
  (wwwResources: WwwResourceTranslationModel[], { wwwResourceId, language }) =>
    wwwResources.filter(
      wwwResource => wwwResource.wwwResourceId === wwwResourceId
    )
);

export const selectWwwResourceTranslation = createSelector(
  selectWwwResourceTranslations,
  (wwwResources: WwwResourceTranslationModel[], { language }) =>
    wwwResources.find(wwwResource => wwwResource.language === language)
);

export const selectWwwResourceTranslationLoading = createSelector(
  selectWwwResourceTranslationState,
  state => state.loading
);

export const selectWwwResourceTranslationError = createSelector(
  selectWwwResourceTranslationState,
  state => state.error
);

// CATEGORY SELECTORS

export const selectCategoryTranslationState = (state: State) =>
  state.categories;

const { selectAll: selectAllCategoryTranslations } =
  categoryAdapter.getSelectors(selectCategoryTranslationState);

export const selectCategoryTranslations = () =>
  createSelector(
    selectAllCategoryTranslations,
    (
      categories: WwwResourceCategoryTranslationModel[],
      { categoryId, language }
    ) => categories.filter(cat => cat.categoryId === categoryId)
  );

export const selectCategoryTranslation = () =>
  createSelector(
    selectCategoryTranslations(),
    (categories: WwwResourceCategoryTranslationModel[], { language }) =>
      categories.find(cat => cat.language === language)
  );

export const selectCategoryTranslationLoading = createSelector(
  selectCategoryTranslationState,
  state => state.loading
);

export const selectCategoryTranslationError = createSelector(
  selectCategoryTranslationState,
  state => state.error
);

import {
  Action,
  createReducer,
  combineReducers,
  createSelector,
  on,
} from '@ngrx/store';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';

import { PartnerCategoryModel } from '@components/partner/models/partner-category.model';
import { PartnerCategoryTranslationModel } from '@components/partner/models/partner-category-translation.model';

import * as PartnerCategoryActions from '../actions/partner-category.actions';
import * as ImportActions from '../actions/partner-import.actions';

export const stateKey = 'partnerCategory';

export interface CategoryState extends EntityState<PartnerCategoryModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export interface TranslationState
  extends EntityState<PartnerCategoryTranslationModel> {
  loading: boolean;
  error: Error;
}

export interface PlaceholderState {
  uuid: string;
  shown: boolean;
  focused: boolean;
}

export interface State {
  categories: CategoryState;
  translations: TranslationState;
  placeholder: PlaceholderState;
}

export const categoryAdapter = createEntityAdapter<PartnerCategoryModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const translationAdapter =
  createEntityAdapter<PartnerCategoryTranslationModel>({
    sortComparer: false,
  });

export const initialState: State = {
  categories: categoryAdapter.getInitialState({
    loading: false,
    loaded: false,
    error: null,
    creating: false,
    createdId: 0,
  }),
  translations: translationAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  placeholder: {
    uuid: null,
    shown: false,
    focused: false,
  },
};

export const categoryReducer = createReducer(
  initialState.categories,
  on(PartnerCategoryActions.loadCategories, state => ({
    ...initialState.categories,
    loading: true,
  })),
  on(PartnerCategoryActions.loadCategoriesSuccess, (state, { categories }) =>
    categoryAdapter.setAll(categories, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(PartnerCategoryActions.loadCategoriesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(PartnerCategoryActions.createCategorySuccess, (state, { category }) =>
    categoryAdapter.addOne(category, state)
  ),
  on(PartnerCategoryActions.updateCategorySuccess, (state, { category }) =>
    categoryAdapter.upsertOne(category, state)
  ),
  on(PartnerCategoryActions.reorderCategories, (state, { categories }) => {
    const updates: Update<PartnerCategoryModel>[] = categories.map(
      (partner, order) => ({
        id: partner.id,
        changes: { order },
      })
    );

    return categoryAdapter.updateMany(updates, state);
  }),
  on(PartnerCategoryActions.reorderCategoriesSuccess, (state, { categories }) =>
    categoryAdapter.upsertMany(categories, state)
  ),
  on(PartnerCategoryActions.deleteCategorySuccess, (state, { category }) =>
    categoryAdapter.removeOne(category.id, state)
  ),
  on(
    PartnerCategoryActions.createCategoryFailure,
    PartnerCategoryActions.updateCategoryFailure,
    PartnerCategoryActions.reorderCategoriesFailure,
    PartnerCategoryActions.deleteCategoryFailure,
    (state, { error }) => ({ ...state, error })
  )
);

export const translationReducer = createReducer(
  initialState.translations,
  on(PartnerCategoryActions.loadCategoryTranslation, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    PartnerCategoryActions.loadCategoryTranslationSuccess,
    (state, { translation }) =>
      translationAdapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    PartnerCategoryActions.loadCategoryTranslationFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    PartnerCategoryActions.updateCategoryTranslationSuccess,
    (state, { translation }) =>
      translationAdapter.upsertOne(translation, { ...state })
  ),
  on(
    PartnerCategoryActions.updateCategoryTranslationFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(ImportActions.importPartnersSuccess, state => ({
    ...initialState.translations,
  }))
);

export const placeholderReducer = createReducer(
  initialState.placeholder,
  on(PartnerCategoryActions.addCategoryPlaceholder, (state, { uuid }) => ({
    shown: true,
    focused: true,
    uuid,
  })),
  on(PartnerCategoryActions.focusCategoryPlaceholder, state => ({
    ...state,
    shown: true,
    focused: true,
  })),
  on(PartnerCategoryActions.blurCategoryPlaceholder, state => ({
    ...state,
    focused: false,
  })),
  on(
    PartnerCategoryActions.resetCategoryPlaceholder,
    PartnerCategoryActions.removeCategoryPlaceholder,
    state => ({ ...initialState.placeholder })
  )
);

export const combinedReducers = combineReducers<State, Action>({
  categories: categoryReducer,
  translations: translationReducer,
  placeholder: placeholderReducer,
});

export function reducer(state: State, action: Action): State {
  return combinedReducers(state, action);
}

export const selectCategoryState = (state: State) => state.categories;

const {
  selectIds: selectCategoryIds,
  selectEntities: selectCategoryEntities,
  selectAll: selectAllCategories,
} = categoryAdapter.getSelectors(selectCategoryState);

export { selectCategoryIds, selectCategoryEntities, selectAllCategories };

export const selectCategoryLoading = createSelector(
  selectCategoryState,
  state => state.loading
);

export const selectCategoryLoaded = createSelector(
  selectCategoryState,
  state => state.loaded
);

export const selectCategoryError = createSelector(
  selectCategoryState,
  state => state.error
);

export const selectTranslationState = (state: State) => state.translations;

const {
  selectIds: selectTranslationIds,
  selectEntities: selectTranslationEntities,
  selectAll: selectAllTranslations,
} = translationAdapter.getSelectors(selectTranslationState);

export {
  selectTranslationIds,
  selectTranslationEntities,
  selectAllTranslations,
};

export const selectTranslations = () =>
  createSelector(
    selectAllTranslations,
    (
      translations: PartnerCategoryTranslationModel[],
      { categoryId, language }
    ) => translations.filter(tr => tr.targetId === categoryId)
  );

export const selectTranslation = () =>
  createSelector(selectTranslations(), (translations, { language }) =>
    translations.find(tr => tr.language === language)
  );

export const selectPlaceholderState = (state: State) => state.placeholder;

export const selectPlaceholderUuid = createSelector(
  selectPlaceholderState,
  state => state.uuid
);

export const selectPlaceholderShown = createSelector(
  selectPlaceholderState,
  state => state.shown
);

export const selectPlaceholderFocused = createSelector(
  selectPlaceholderState,
  state => state.focused
);

export const selectAllCategoriesWithPlaceholder = createSelector(
  selectAllCategories,
  selectPlaceholderUuid,
  (categories, placeholderUuid) => {
    if (placeholderUuid) {
      const placeholderCategory: PartnerCategoryModel = {
        id: 0,
        componentId: 0,
        uuid: placeholderUuid,
        name: '',
        description: '',
        level: 1,
        order: categories.length,
      };

      return [...categories, placeholderCategory];
    }

    return categories;
  }
);

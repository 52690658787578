import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EventUserModel } from '@shared/models/event-user.model';
import { UserPickerAction } from '../actions/user-picker.actions';
import { selectEvent } from '@store/features/event/actions';

export const featureKey = 'usersPicker';

export interface State extends EntityState<EventUserModel> {
  loading: boolean;
  loaded: boolean;
  maximum: number;
  limit: number;
  searchTerm: string;
  error: Error;
}

export const adapter = createEntityAdapter<EventUserModel>({
  selectId: user => user.uuid,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  maximum: 0,
  limit: 0,
  searchTerm: '',
  error: null,
});

export const userPickerReducer = createReducer(
  initialState,
  on(
    UserPickerAction.loadEventUsers,
    UserPickerAction.searchEventUsers,
    (state) => ({ ...initialState, loading: true })
  ),
  on(UserPickerAction.searchEventUsers, (state, { searchTerm }) => ({
    ...state,
    searchTerm,
  })),
  on(UserPickerAction.loadEventUsersSuccess, (state, { users }) =>
    adapter.setAll(users, state)
  ),
  on(UserPickerAction.loadNextEventUsersSuccess, (state, { users }) =>
    adapter.addMany(users, state)
  ),
  on(
    UserPickerAction.loadEventUsersSuccess,
    UserPickerAction.loadNextEventUsersSuccess,
    (state, { limit, hasMore }) => ({ ...state, limit, loaded: !hasMore })
  ),
  on(
    UserPickerAction.loadEventUsersFailure,
    UserPickerAction.loadNextEventUsersFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    UserPickerAction.loadEventUsersSuccess,
    UserPickerAction.loadNextEventUsersSuccess,
    UserPickerAction.loadEventUsersFailure,
    UserPickerAction.loadNextEventUsersFailure,
    state => ({ ...state, loading: false })
  ),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return userPickerReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectEventUserIds = selectIds;

export const selectEventUserEntities = selectEntities;

export const selectPickerUsers = selectAll;

export const selectPickerUsersLoading = (state: State) => state.loading;

export const selectLoadedUserPicker = (state: State) => state.loaded;

export const selectEventUserLimit = (state: State) => state.limit;

export const selectUserPickerSearchTerm = (state: State) => state.searchTerm;

export const selectEventUserError = (state: State) => state.error;

export const selectLastPickerEventUser = createSelector(
  selectEventUserIds,
  selectEventUserEntities,
  (ids, entities) => entities[ids[ids.length - 1]]
);
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tip-card',
  styleUrls: ['./tip-card.component.scss'],
  template: `
    <div class="tip-card">
      <div class="tip-card__img-container" *ngIf="showTipIcon">
        <img src="/assets/icons/bulb.svg" alt="" />
      </div>
      <ng-content></ng-content>
    </div>
  `,
})
export class TipCardComponent {
  @Input() showTipIcon: boolean = true;
}

import { DeeplinkBuilderService } from '../deeplink-builder-modal/deeplink-builder.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  forwardRef
} from '@angular/core';
import { DeeplinkBuilderModalComponent } from '../deeplink-builder-modal/deeplink-builder-modal.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-deeplink-builder',
  templateUrl: './deeplink-builder.component.html',
  styleUrls: ['./deeplink-builder.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeeplinkBuilderComponent),
      multi: true
    }
  ]
})
export class DeeplinkBuilderComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private subs = new Subscription();
  @Input() disabled: boolean = false;
  @Input() value: string = null;

  private onChange: (deepLink: string) => void = () => {};
  private onTouched: () => void;

  constructor(
    public dlService: DeeplinkBuilderService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subs.add(this.dlService.deepLink$.subscribe(dl => {
      this.onChange(dl);
      this.value = dl;
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  writeValue(deepLink: string): void {
    this.dlService.setDeepLink(deepLink);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  openCreator(): void {
    this.dlService.setDeepLink(this.value);
    this.subs.add(this.dialog.open(DeeplinkBuilderModalComponent, {
      width: '400px', minHeight: '600px',
    }).afterClosed()
      .subscribe(() => this.dlService.reset()));
  }
}

<div class="consent-checkbox" [ngClass]="{'information-type': displayType === ConsentDisplayType.TEXT}">
  <mat-checkbox
    *ngIf="displayType !== ConsentDisplayType.TEXT"
    [id]="form.value.attrId"
    [checked]="form.controls.checked.value"
    (change)="handleStateChanged($event.checked)">
  </mat-checkbox>
  <div class="label" [ngClass]="{'pl-0': displayType === ConsentDisplayType.TEXT}">
     <span
       *ngIf="displayType !== ConsentDisplayType.TEXT && form.value.data.required"
       class="required-asterisk"
     >*</span>
    <span [attr.for]="form.value.attrId" [innerHTML]="form.value.text">
    </span>
    <a class="btn-link" *ngIf="form.value.expandableBtnText" (click)="changeFormExpanded($event)"><br />{{ form.value.expandableBtnText }}</a>
    <div class="expanded-content mt-2" *ngIf="form.controls.expanded.value" [innerHTML]="form.value.expandableText"></div>
    <div *ngIf="form.invalid && submitted" class="form-control-feedback mt-1">
      {{ 'consents.consent_req' | translate }}
    </div>
  </div>
</div>

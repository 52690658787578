import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ComponentModel } from "@shared/models/component.model";
import { ComponentNames } from "@utils/component-names.util";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RouterProviderService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  getRouterMainView(url: string): RouterMainView {
    const subject = getSplittedUrl(url)[ROUTER_CONSTANTS.MAIN_VIEW_INDEX];
    if (!subject) return null;
    return Object.values(RouterMainView)
      .find(val => val === subject);
  }

  getRouterAuthorizedView(url: string): RouterAuthorizedView {
    const subject = getSplittedUrl(url)[ROUTER_CONSTANTS.AUTHORIZED_VIEW_INDEX];
    if (!subject) return null;
    return Object.values(RouterAuthorizedView)
      .find(val => val === subject);
  }

  getRouterEventView(url: string): RouterEventView {
    const subject = getSplittedUrl(url)[ROUTER_CONSTANTS.EVENT_VIEW_INDEX];
    if (!subject) return null;
    return Object.values(RouterEventView)
      .find(val => val === subject);
  }

  getEventConstructorComponentRouterArray(comp: ComponentModel): string[] {
    let base = ['component', `${comp.id}`];
    if (comp.componentName === ComponentNames.FORM) {
      base.push('forms');
    }
    return base;
  }

  getEventId(url: string): number {
    const subject = getSplittedUrl(url)[ROUTER_CONSTANTS.EVENT_ID_INDEX];
    return subject ? Number(subject) : null;
  }

  getQueryParams(): Observable<Params> {
    return this.route.queryParams;
  }

  getComponentId(url: string): number {
    let urlComponentIdIndex = null;

    switch (this.getRouterEventView(url)) {
      case RouterEventView.CONSTRUCTOR:
        urlComponentIdIndex = ROUTER_CONSTANTS.EVENT_CONSTRUCTOR_COMPONENT_ID_INDEX;
        break;
      case RouterEventView.APPLICATION:
        urlComponentIdIndex = ROUTER_CONSTANTS.EVENT_APPLICATION_COMPONENT_ID_INDEX;
        break;
      default:
        urlComponentIdIndex = null;
        break;
    }

    if (urlComponentIdIndex === null) return null;

    const subject = getSplittedUrl(url)[urlComponentIdIndex];
    return subject ? Number(subject) : null;
  }

  goBack(lvl = 1): void {
    const splittedUrl = getSplittedUrl(this.router.url);
    this.router.navigate([...splittedUrl.slice(0, splittedUrl.length - lvl)])
  }

  toMainView(mainView: RouterMainView): void {
    this.router.navigate([mainView]);
  }

  toAuthorizedView(authorizedView: RouterAuthorizedView, eventId?: number): void {
    let navigationArray: string[] = [RouterMainView.BACKEND, authorizedView];
    if (authorizedView === RouterAuthorizedView.EVENT && eventId) {
      navigationArray.push(`${eventId}`);
    };
    this.router.navigate(navigationArray);
  }

  toEventView(eventId: number, eventView: RouterEventView, buildOptions?: RouterOptions): void {
    const navigationArray = [
      RouterMainView.BACKEND,
      RouterAuthorizedView.EVENT,
      eventId,
      eventView,
    ];
    if (buildOptions) {
      this.router.navigate(navigationArray, buildOptions);
    } else {
      this.router.navigate(navigationArray);
    }
  }

  toEventConstructorStep(eventId: number, step: RouterEventConstructorStep, rest?: string[]): void {
    let navigationArray: string[] = [
      RouterMainView.BACKEND,
      RouterAuthorizedView.EVENT,
      `${eventId}`,
      RouterEventView.CONSTRUCTOR,
      'step',
      `${step}`,
    ];
    if (rest && rest.length > 0) {
      rest.forEach(r => {
        r.length > 0 && navigationArray.push(r)
      });
    }
    this.router.navigate(navigationArray);
  }

  toEventConstructorComponent(eventId: number, compId: number, rest: string[] = []): void {
    let navigationArray: string[] = [
      RouterMainView.BACKEND,
      RouterAuthorizedView.EVENT,
      `${eventId}`,
      RouterEventView.CONSTRUCTOR,
      'step',
      `${RouterEventConstructorStep.CONTENT}`,
      'component',
      `${compId}`,
      ...rest,
    ];
    this.router.navigate(navigationArray);
  }

  toEventApplicationComponent(eventId: number, compId: number): Promise<boolean> {
    return this.router.navigate([
      RouterMainView.BACKEND,
      RouterAuthorizedView.EVENT,
      eventId,
      RouterEventView.APPLICATION,
      'component',
      compId,
    ]);
  }

  toEventApplicationEmpty(eventId: number): Promise<boolean> {
    return this.router.navigate([
      RouterMainView.BACKEND,
      RouterAuthorizedView.EVENT,
      eventId,
      RouterEventView.APPLICATION,
      'empty',
    ]);
  }

  toAccountView(accountView: RouterAccountView, buildOptions?: RouterOptions): void {
    const navigationArray = [RouterMainView.ACCOUNT, accountView];
    if (buildOptions) {
      this.router.navigate(navigationArray, buildOptions);
    } else {
      this.router.navigate(navigationArray);
    }
  }

  buildOptions(params: { key: RouterQueryParams, val: string }[]): { queryParams: { [key: string]: string } } {
    let queryParams = {};
    params.forEach(param => {
      queryParams = { ...queryParams, [param.key]: param.val };
    });
    return { queryParams };
  }
}

export function getSplittedUrl(url: string) {
  return url.split('/');
}

export enum RouterMainView {
  ACCOUNT = 'account',
  BACKEND = 'backend',
  LICENSES = 'licenses',
}

export enum RouterAccountView {
  LOGIN = 'login',
  REGISTER = 'register',
  EMAIL_CONFIRMATION = 'email-confirmation',
  FORGOT_PASSWORD = 'forgot-password',
  FORGOT_PASSWORD_SUBMIT = 'forgot-password-submit',
  MAGIC_LINK = 'magic-link',
}

export enum RouterAuthorizedView {
  DASHBOARD = 'dashboard',
  EVENT = 'event',
  RESET_PASSWORD = 'reset-password',
}

export enum RouterEventView {
  CONSTRUCTOR = 'constructor',
  APPLICATION = 'application',
  PERSONALIZATION = 'personalization',
}

export enum RouterEventConstructorStep {
  BASIC_INFO = 1,
  ACCESS = 2,
  COMPONENTS = 3,
  CONTENT = 4,
  SUMMARY = 5,
  PUBLISH = 6,
}

export enum RouterEventAccessViews {
  MAIN = '',
  TICKETS = 'tickets',
  DISCOUNTS = 'discounts',
  FORMS = 'forms',
  BADGES = 'badges',
  CONSENTS = 'consents',
}

export function getAccessViewFromUrl(url: string): RouterEventAccessViews {
  const splittedUrl = getSplittedUrl(url);
  const lastUrlPart = splittedUrl[splittedUrl.length - 1];
  switch (lastUrlPart) {
    case RouterEventAccessViews.FORMS:
      return RouterEventAccessViews.FORMS;
    case RouterEventAccessViews.TICKETS:
      return RouterEventAccessViews.TICKETS;
    case RouterEventAccessViews.DISCOUNTS:
      return RouterEventAccessViews.DISCOUNTS;
    case RouterEventAccessViews.BADGES:
      return RouterEventAccessViews.BADGES;
    case RouterEventAccessViews.CONSENTS:
      return RouterEventAccessViews.CONSENTS;
    default:
      return RouterEventAccessViews.MAIN;
  }
}

export const ROUTER_CONSTANTS = {
  MAIN_VIEW_INDEX: 1,
  AUTHORIZED_VIEW_INDEX: 2,
  EVENT_VIEW_INDEX: 4,

  EVENT_ID_INDEX: 3,

  EVENT_CONSTRUCTOR_COMPONENT_ID_INDEX: 8,
  EVENT_APPLICATION_COMPONENT_ID_INDEX: 6,
}

export enum RouterQueryParams {
  APP_ID = 'app_id',
  EMAIL = 'email',
  IS_INVALID_TOKEN = 'is_invalid_token',
};

export interface RouterOptions {
  queryParams: { [key: string]: string };
}

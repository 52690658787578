import { createAction, props } from "@ngrx/store";
import { ConsentSchemaCreateModel, ConsentSchemaModel, ConsentSchemaUpdateModel } from "../models/consent-schema.model";
import { ConsentSchemaTranslationCreateModel, ConsentSchemaTranslationModel, ConsentSchemaTranslationUpdateModel } from "../models/consent-schema-translation.model";
import { ConsentSchemaVersionModel } from "../models/consent-schema-version.model";
import { ConsentCreateActionParamsModel, ConsentUpdateActionParamsModel } from "../utils/consent-event.helper";

export const loadConsentEventSchemas = createAction(
  '[App/Consent Event] Load Consent Event Schemas'
);

export const loadConsentEventSchemasSuccess = createAction(
  '[App/Consent Event] Load Consent Event Schemas Success',
  props<{ entities: ConsentSchemaModel[] }>()
);

export const loadConsentEventSchemasFailure = createAction(
  '[App/Consent Event] Load Consent Event Schemas Failure',
  props<{ error: Error }>()
);

export const createConsentEventSchema = createAction(
  '[App/Consent Event] Create Consent Event Schemas',
  props<{ payload: ConsentSchemaCreateModel }>()
);

export const createConsentEventSchemaSuccess = createAction(
  '[App/Consent Event] Create Consent Event Schemas Success',
  props<{ entity: ConsentSchemaModel }>()
);

export const createConsentEventSchemaFailure = createAction(
  '[App/Consent Event] Create Consent Event Schemas Failure',
  props<{ error: Error }>()
);

export const updateConsentEventSchema = createAction(
  '[App/Consent Event] Update Consent Event Schemas',
  props<{ schema: ConsentSchemaModel, payload: ConsentSchemaUpdateModel }>()
);

export const updateConsentEventSchemaSuccess = createAction(
  '[App/Consent Event] Update Consent Event Schemas Success',
  props<{ entity: ConsentSchemaModel }>()
);

export const updateConsentEventSchemaFailure = createAction(
  '[App/Consent Event] Update Consent Event Schemas Failure',
  props<{ error: Error }>()
);

export const loadConsentSchemaTranslations = createAction(
  '[App/Consent Event] Load Consent Schema Translations',
  props<{ schema: ConsentSchemaModel, version: ConsentSchemaVersionModel }>()
);

export const loadConsentSchemaTranslationsSuccess = createAction(
  '[App/Consent Event] Load Consent Schema Translations Success',
  props<{ schema: ConsentSchemaModel, version: ConsentSchemaVersionModel, entities: ConsentSchemaTranslationModel[] }>()
);

export const loadConsentSchemaTranslationsFailure = createAction(
  '[App/Consent Event] Load Consent Schema Translations Failure',
  props<{ error: Error }>()
);

export const createConsentSchemaTranslation = createAction(
  '[App/Consent Event] Create Consent Schema Translation',
  props<{ schema: ConsentSchemaModel, version: ConsentSchemaVersionModel, payload: ConsentSchemaTranslationCreateModel }>()
);

export const createConsentSchemaTranslationSuccess = createAction(
  '[App/Consent Event] Create Consent Schema Translation Success',
  props<{ schema: ConsentSchemaModel, version: ConsentSchemaVersionModel, entity: ConsentSchemaTranslationModel }>()
);

export const createConsentSchemaTranslationFailure = createAction(
  '[App/Consent Event] Create Consent Schema Translation Failure',
  props<{ error: Error }>()
);

export const updateConsentSchemaTranslation = createAction(
  '[App/Consent Event] Update Consent Schema Translation',
  props<{ schema: ConsentSchemaModel, version: ConsentSchemaVersionModel, translation: ConsentSchemaTranslationModel, payload: ConsentSchemaTranslationUpdateModel }>()
);

export const updateConsentSchemaTranslationSuccess = createAction(
  '[App/Consent Event] Update Consent Schema Translation Success',
  props<{ schema: ConsentSchemaModel, version: ConsentSchemaVersionModel, entity: ConsentSchemaTranslationModel }>()
);

export const updateConsentSchemaTranslationFailure = createAction(
  '[App/Consent Event] Update Consent Schema Translation Failure',
  props<{ error: Error }>()
);

export const saveNewConsentSchema = createAction(
  '[App/Consent Event] Save New Consent Schema',
  props<{ data: ConsentCreateActionParamsModel }>()
);

export const publishConsentSchema = createAction(
  '[App/Consent Event] Publish Consent Schema',
  props<{ consentSchemaId: number }>()
);

export const unPublishConsentSchema = createAction(
  '[App/Consent Event] Unpublish Consent Schema',
  props<{ consentSchemaId: number }>()
);

export const publishConsentSchemaSuccess = createAction(
  '[App/Consent Event] Publish Consent Schema Success',
  props<{ consentSchemaId: number }>()
);

export const publishConsentSchemaFailure = createAction(
  '[App/Consent Event] Publish Consent Schema Failure',
  props<{ consentSchemaId: number, error: Error }>()
);

export const unPublishConsentSchemaSuccess = createAction(
  '[App/Consent Event] Unpublish Consent Schema Success',
  props<{ consentSchemaId: number }>()
);

export const unPublishConsentSchemaFailure = createAction(
  '[App/Consent Event] Unpublish Consent Schema Failure',
  props<{ consentSchemaId: number, error: Error }>()
);

export const saveNewConsentSchemaSuccess = createAction(
  '[App/Consent Event] Save New Consent Schema Success',
  props<{ schema: ConsentSchemaModel }>()
);

export const saveNewConsentSchemaFailure = createAction(
  '[App/Consent Event] Save New Consent Schema Failure',
  props<{ error: Error }>()
);

export const saveExistingConsentSchema = createAction(
  '[App/Consent Event] Save Existing Consent Schema',
  props<{ data: ConsentUpdateActionParamsModel }>()
);

export const saveExistingConsentSchemaSuccess = createAction(
  '[App/Consent Event] Save Existing Consent Schema Success',
  props<{ schema: ConsentSchemaModel }>()
);

export const saveExistingConsentSchemaFailure = createAction(
  '[App/Consent Event] Save Existing Consent Schema Failure',
  props<{ error: Error }>()
);

import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { ComponentPlanModel } from '@app/construct-steps/models/component-plan.model';

import * as ComponentPlanActions from '../actions/component-plan.actions';

export const featureKey = 'componentPlan';

export interface State extends EntityState<ComponentPlanModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<ComponentPlanModel>({
  selectId: componentPlan => componentPlan.type,
});

export const initialState: State = {
  ...adapter.getInitialState(),
  loading: false,
  loaded: false,
  error: null,
};

export const componentReducer = createReducer(
  initialState,
  on(ComponentPlanActions.loadComponentPlans, state => ({
    ...initialState,
    loading: true,
  })),
  on(ComponentPlanActions.loadComponentPlansSuccess, (state, { plans }) =>
    adapter.setAll(plans, { ...state, loading: false, loaded: true })
  ),
  on(ComponentPlanActions.loadComponentPlansFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function reducer(state: State, action: Action): State {
  return componentReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectComponentPlanIds = selectIds;

export const selectComponentPlanEntities = selectEntities;

export const selectAllComponentPlans = selectAll;

export const selectComponentPlanLoading = (state: State) => state.loading;

export const selectComponentPlanLoaded = (state: State) => state.loaded;

export const selectComponentPlanError = (state: State) => state.error;

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-item-checkbox',
  templateUrl: './dropdown-item-checkbox.component.html',
  styleUrls: ['./dropdown-item-checkbox.component.scss']
})
export class DropdownItemCheckboxComponent {

  @Input() checked: boolean = false;
  @Input() value: any;
  @Output() checkedChange: EventEmitter<{ state: boolean, value: any }>;

  constructor() {
    this.checkedChange = new EventEmitter<{ state: boolean, value: any }>();
  }

  onCheckChange(event?: Event): void {
    this.checked = !this.checked;
    this.checkedChange.emit({ state: this.checked, value: this.value });
    if (event) {
      event.stopPropagation();
    }
  }

}

import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ConsentDisplayType } from "./consent-display-type.enum";
import { ConsentType, EventConsentType } from "./consent-type.enum";
import { ModelForm } from "@utils/model-form.util";
import { ConsentSchemaCreateModel, ConsentSchemaModel, ConsentSchemaUpdateModel } from "../models/consent-schema.model";
import { ConsentSchemaTranslationCreateModel, ConsentSchemaTranslationModel } from "../models/consent-schema-translation.model";
import { ConsentSchemaVersionModel } from "../models/consent-schema-version.model";
import { ConsentDisplayExpandableCreateModel, ConsentDisplayPlaceholderCreateModel, ConsentDisplayPlaceholderUpdateModel } from "../models/consent-display.model";

export type ConsentSchemaFormType = FormGroup<ModelForm<ConsentSchemaFormModel>>;
export type ConsentTranslationFormType = FormGroup<ModelForm<ConsentTranslationFormModel>>;

// form models
export interface ConsentSchemaFormModel {
  id: number;
  consentType: ConsentType;
  eventConsentType: string;
  title: string;
  required: boolean;
  displayType: ConsentDisplayType;
  published: boolean;
  translations: FormArray<ConsentTranslationFormType>;
  translationsLoadedForVersionId: number | null;
  manuallyCreated: boolean;
}

export interface ConsentTranslationFormModel {
  id: number;
  locale: string;
  file: File | null;
  fileName: string | null;
  fileExt: string | null;

  checkboxText: string;

  expandableEnabled: boolean;
  expandableButtonText: string | null;
  expandableText: string | null;
}

// value models
export interface ConsentSchemaFormValueModel {
  id: number;
  consentType: ConsentType;
  eventConsentType: string;
  title: string;
  required: boolean;
  displayType: ConsentDisplayType;
  published: boolean;
  translations: ConsentTranslationFormValueModel[];
}

export interface ConsentTranslationFormValueModel {
  id: number;
  locale: string;
  file: File | null;

  checkboxText: string;

  expandableEnabled: boolean;
  expandableButtonText: string | null;
  expandableText: string | null;
}

// other models
export interface ConsentCreateActionParamsModel {
  createConsentPayload: ConsentSchemaCreateModel;
  createTranslationPayloads: ConsentSchemaTranslationCreateModel[];
}

export interface ConsentUpdateActionParamsModel {
  schema: ConsentSchemaModel;
  version: ConsentSchemaVersionModel;
  translations: ConsentSchemaTranslationModel[];

  updateConsentPayload: ConsentSchemaUpdateModel;
  createTranslationPayloads: ConsentSchemaTranslationCreateModel[];
}

// validators
export const CONSENT_SCHEMA_TITLE_MIN_LENGTH = 3;
export const CONSENT_SCHEMA_TITLE_MAX_LENGTH = 256;
export const CONSENT_CHECKBOX_TEXT_MIN_LENGTH = 3;
export const CONSENT_CHECKBOX_TEXT_MAX_LENGTH = 1024;

// methods
const createConsentSchemaFormGroup = (consent: ConsentSchemaModel | null, manuallyCreated: boolean, consentType?: ConsentType, eventConsentType?: string): ConsentSchemaFormType => {
  const formEventConsentType = consent?.eventConsentType || eventConsentType || null;
  const formDisplayType = formEventConsentType === EventConsentType.EVENT_PRIVACY_POLICY ? ConsentDisplayType.TEXT : consent?.displayType || ConsentDisplayType.CHECKBOX;
  return new FormGroup<ModelForm<ConsentSchemaFormModel>>({
    id: new FormControl(consent?.id || `${Date.now() * -1 - 2}`),
    consentType: new FormControl(consent?.consentType || consentType || ConsentType.EVENT_SPECIFIC),
    eventConsentType: new FormControl(formEventConsentType),
    title: new FormControl(consent?.title || null, [Validators.required, Validators.minLength(CONSENT_SCHEMA_TITLE_MIN_LENGTH), Validators.maxLength(CONSENT_SCHEMA_TITLE_MAX_LENGTH)]),
    required: new FormControl(consent?.required || false),
    displayType: new FormControl(formDisplayType),
    published: new FormControl(true),
    translations: new FormArray([]),
    translationsLoadedForVersionId: new FormControl(null),
    manuallyCreated: new FormControl(manuallyCreated),
  });
}

const createConsentTranslationForm = (index: number, locale: string, translation?: ConsentSchemaTranslationModel): ConsentTranslationFormType => {
  const txExpandable = translation?.consentCheckboxConfig.expandable || null;
  const mainPhLabel = translation?.consentCheckboxConfig?.placeholders[0]?.label || null;
  const checkboxText = translation?.consentCheckboxConfig.text
    ? (mainPhLabel
      ? replaceTextInBraces(translation.consentCheckboxConfig.text, translation.consentCheckboxConfig.placeholders[0].label)
      : translation.consentCheckboxConfig.text)
    : null;
  const form = new FormGroup<ModelForm<ConsentTranslationFormModel>>({
    id: new FormControl(translation?.id || createId(index)),
    locale: new FormControl(locale),
    file: new FormControl(null),
    fileName: new FormControl(translation?.fileName || null),
    fileExt: new FormControl(translation?.fileExtension || null),
    checkboxText: new FormControl(checkboxText),
    expandableEnabled: new FormControl((txExpandable && txExpandable.button && txExpandable.button) ? true : false),
    expandableButtonText: new FormControl(txExpandable?.button || null),
    expandableText: new FormControl(txExpandable?.text || null),
  });

  return form;
}

function createPredefinedConsent(type: EventConsentType, index: number): ConsentSchemaModel {
  let schema = createConsentSchema(index);
  return {
    ...schema,
    eventConsentType: type,
    displayType: type === EventConsentType.EVENT_PRIVACY_POLICY ? ConsentDisplayType.TEXT : ConsentDisplayType.CHECKBOX,
    required: true,
    title: type === EventConsentType.EVENT_PRIVACY_POLICY ? 'Event Privacy Policy' : 'Event Attendee Terms',
  }
}

function createConsentSchema(index: number): ConsentSchemaModel {
  return {
    id: createId(index),
    consentType: ConsentType.EVENT_SPECIFIC,
    eventConsentType: 'custom',
    title: '',
    required: false,
    displayType: ConsentDisplayType.CHECKBOX,
    published: true,
    consentVersions: [],
  }
}

function getNewestVersion(consent: ConsentSchemaModel): ConsentSchemaVersionModel | undefined {
  return consent.consentVersions.reduce((latest, current) => {
    return current.versionNumber > (latest?.versionNumber ?? -Infinity) ? current : latest;
  }, undefined);
}

function createId(index: number): number {
  return (Date.now() * -1) - index;
}

export function fileRequiredValidator(control: AbstractControl): ValidationErrors | null {
  const file = control.get('file')?.value;
  const fileName = control.get('fileName')?.value;
  const fileExt = control.get('fileExt')?.value;

  if (!file && (!fileName || !fileExt)) {
    return { fileRequired: true };
  }

  return null;
}

export interface GetFormUpdateModelParams {
  formValue: ConsentSchemaFormValueModel;
  schema: ConsentSchemaModel;
  translations: ConsentSchemaTranslationModel[];
}

function mapFormToUpdateModel(params: GetFormUpdateModelParams): ConsentUpdateActionParamsModel | null {
  const { formValue, schema, translations } = params;
  const newestVersion = getNewestVersion(schema);
  if (!newestVersion) {
    return null;
  }


  const updateConsentPayload: ConsentSchemaUpdateModel = {
    consentType: formValue.consentType,
    eventConsentType: formValue.eventConsentType,
    title: formValue.title,
    required: formValue.required,
    displayType: formValue.displayType,
    version: {
      changelog: 'not defined',
    }
  };

  const createTranslationPayloads: ConsentSchemaTranslationCreateModel[] = [];
  formValue.translations.forEach(tx => {
    const isFileNotAdded = !tx.file;
    const copyFileFromPrevVersion = isFileNotAdded && schema.consentVersions.length > 0;
    const ph = getPlaceholderFromTxForCreate(tx);
    const consentText = replaceTextInBraces(tx.checkboxText, '0');
    const expandable: ConsentDisplayExpandableCreateModel = tx.expandableEnabled ? ({
      button: tx.expandableButtonText,
      text: tx.expandableText,
      placeholders: [],
    }) : null;
    const payload: ConsentSchemaTranslationCreateModel = {
      locale: tx.locale,
      file: tx.file,
      copyFileFromPreviousVersion: copyFileFromPrevVersion,
      consentCheckboxConfig: {
        text: consentText,
        placeholders: ph ? [ph] : [],
        expandable,
      }
    }
    createTranslationPayloads.push(payload);
  });

  return {
    schema,
    translations,
    version: newestVersion,
    updateConsentPayload,
    createTranslationPayloads,
  }
}

function mapFormToCreateModel(formValue: ConsentSchemaFormValueModel): ConsentCreateActionParamsModel {
  const createConsentPayload: ConsentSchemaCreateModel = {
    consentType: formValue.consentType,
    eventConsentType: formValue.eventConsentType,
    title: formValue.title,
    required: formValue.required,
    displayType: formValue.displayType,
    version: {
      changelog: 'not defined',
    }
  };

  const createTranslationPayloads: ConsentSchemaTranslationCreateModel[] = [];
  formValue.translations.forEach(tx => {
    const ph = getPlaceholderFromTxForCreate(tx);
    const consentText = replaceTextInBraces(tx.checkboxText, '0');
    const expandable: ConsentDisplayExpandableCreateModel = tx.expandableEnabled ? ({
      button: tx.expandableButtonText,
      text: tx.expandableText,
      placeholders: [],
    }) : null;
    const payload: ConsentSchemaTranslationCreateModel = {
      locale: tx.locale,
      file: tx.file,
      copyFileFromPreviousVersion: false,
      consentCheckboxConfig: {
        text: consentText,
        placeholders: ph ? [ph] : [],
        expandable,
      }
    }
    createTranslationPayloads.push(payload);
  });

  return {
    createConsentPayload,
    createTranslationPayloads,
  }
}

function getPlaceholderFromTxForCreate(
  translation: ConsentTranslationFormValueModel
): ConsentDisplayPlaceholderCreateModel {
  return getPhFromText(translation.checkboxText);
}

function getPlaceholderFromTxForUpdate(
  translation: ConsentTranslationFormValueModel
): ConsentDisplayPlaceholderUpdateModel {
  return getPhFromText(translation.checkboxText);
}

function getPhFromText(text: string): { label: string, value: string } | null {
  const regex = /\{(.*?)\}/g;
  const values: string[] = [];

  let match: RegExpExecArray | null;
  while ((match = regex.exec(text)) !== null) {
    values.push(match[1]);
  }

  return !!values[0] ? {
    label: values[0],
    value: '{file_url}',
  } : null;
}

function replaceTextInBraces(input: string, to: string): string {
  return input.replace(/{[^}]*}/g, `{${to}}`);
}

export const ConsentEventHelper = {
  createForm: createConsentSchemaFormGroup,
  createTranslationForm: createConsentTranslationForm,
  createPredefinedConsent: createPredefinedConsent,
  createConsentSchema: createConsentSchema,
  getNewestVersion: getNewestVersion,
  getCreateModel: mapFormToCreateModel,
  getUpdateModel: mapFormToUpdateModel,
}

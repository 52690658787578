import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { ExhibitorContactPersonTranslationModel } from '@components/exhibitor/models/exhibitor-contact-person-translation.model';

import * as ExhibitorTranslationActions from '../actions/exhibitor-contact-person-translation.actions';

export const stateKey = 'exhibitorContactPersonTranslation';

export interface State
  extends EntityState<ExhibitorContactPersonTranslationModel> {
  loading: boolean;
  error: Error;
}

export const adapter =
  createEntityAdapter<ExhibitorContactPersonTranslationModel>({
    sortComparer: false,
  });

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const exhibitorContactPersonTranslationReducer = createReducer(
  initialState,
  on(
    ExhibitorTranslationActions.loadExhibitorContactPersonTranslation,
    (state, { contact }) => ({ ...initialState, loading: true })
  ),
  on(
    ExhibitorTranslationActions.loadExhibitorContactPersonTranslationSuccess,
    (state, { contact, translation }) =>
      adapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    ExhibitorTranslationActions.loadExhibitorContactPersonTranslationFailure,
    (state, { contact, error }) => ({ ...state, loading: false, error })
  ),
  on(
    ExhibitorTranslationActions.updateExhibitorContactPersonTranslationsSuccess,
    (state, { contact, translations }) =>
      adapter.upsertMany(translations, state)
  ),
  on(
    ExhibitorTranslationActions.updateExhibitorContactPersonTranslationsFailure,
    (state, { contact, error }) => ({ ...state, error })
  )
);

export function reducer(state: State, action: Action): State {
  return exhibitorContactPersonTranslationReducer(state, action);
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectExhibitorContactPersonTranslationEntities = selectEntities;

export const selectAllExhibitorContactPersonTranslations = selectAll;

export const selectExhibitorContactPersonTranslations = createSelector(
  selectAllExhibitorContactPersonTranslations,
  (trs: ExhibitorContactPersonTranslationModel[], { contactId, language }) =>
    trs.filter(tr => tr.exhibitorContactPersonId === contactId)
);

export const selectExhibitorContactPersonTranslation = createSelector(
  selectExhibitorContactPersonTranslations,
  (trs: ExhibitorContactPersonTranslationModel[], { language }) =>
    trs.find(tr => tr.language === language)
);

export const selectExhibitorContactPersonTranslationLoading = (state: State) =>
  state.loading;

export const selectExhibitorContactPersonTranslationError = (state: State) =>
  state.error;

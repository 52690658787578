import { Action, createReducer, createSelector, on } from '@ngrx/store';

import { InstanceState, createInstanceAdapter, createInstanceSelector } from '@store/adapters/instance.adapter';
import { LoaderState, createLoaderAdapter } from '@store/adapters/loader.adapter';

import * as VenueActions from '../actions/venue.actions';

export interface ComponentState extends LoaderState {
  error: Error;
}

export interface State extends InstanceState<ComponentState> {
}

export const loaderAdapter = createLoaderAdapter<ComponentState>();

export const initialComponentState: ComponentState = loaderAdapter.getInitialState({
  error: null,
});

export const componentReducer = createReducer(
  initialComponentState,
  on(
    VenueActions.loadVenuesRequest,
    state => loaderAdapter.load(state)
  ),
  on(
    VenueActions.loadVenuesSuccess,
    state => loaderAdapter.loaded(state)
  ),
  on(
    VenueActions.loadVenuesFailure,
    (state, { error }) => loaderAdapter.reset({ ...state, error })
  ),
);

export const instanceAdapter = createInstanceAdapter({
  reducer: componentReducer,
});

export const initialInstanceState: State = instanceAdapter.getInitialState();

export const instanceReducer = createReducer(
  initialInstanceState,
  on(
    VenueActions.loadVenuesRequest,
    VenueActions.loadVenuesSuccess,
    VenueActions.loadVenuesFailure,
    (state, action) => instanceAdapter.update(action.componentId, state, action)
  ),
);

export function reducer(state: State, action: Action): State {
  return instanceReducer(state, action);
}

export const selectComponentState = createInstanceSelector(
  (state: State, { componentId }: { componentId: number }) => componentId
);

const {
  selectLoading,
  selectLoaded,
} = loaderAdapter.getSelectors();

export const selectComponentLoading = createSelector(
  selectComponentState,
  selectLoading
);

export const selectComponentLoaded = createSelector(
  selectComponentState,
  selectLoaded
);

export const selectComponentError = createSelector(
  selectComponentState,
  state => state.error
);

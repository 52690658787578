import { Component, HostListener, OnInit } from '@angular/core';
import { WindowSizeService } from '@shared/providers/window-size.service';
import { LangService } from '@shared/providers/lang.service';
import '@angular/common/locales/global/pl';
import { closeAllClearStateRequests } from '@store/utils/clear-state.util';
import { deleteAllLocalStorageErorrs } from '@shared/errors/error-local-storage';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit  {

  constructor(
    private windowSizeService: WindowSizeService,
    private langService: LangService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onWindowResized(event: any) {
    document.documentElement.style.setProperty(
      '--vh',
      `calc(${window.innerHeight * 0.01}px)`
    );
    this.windowSizeService.resized({
      width: event.target.innerWidth,
      height: event.target.innerHeight,
    });
  }

  ngOnInit(): void {
    closeAllClearStateRequests();
    deleteAllLocalStorageErorrs();
    this.langService.langInit();
  }
}
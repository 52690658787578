<div class="d-flex justify-content-between align-items-center">
  <h2 mat-dialog-title>{{ title }}</h2>
</div>

<mat-dialog-content>
  <div class="form-row">
    <div class="col-8 form-group">
      <label>{{ 'import_modal.choose_csv_file' | translate }}</label>
      <input type="file" class="form-control mb-1" (click)="$event.target.value = null" (change)="onFileInputChange($event)">
    </div>

    <div class="col-4 form-group">
      <label>{{ 'import_modal.language' | translate }}</label>
      <select class="form-control arrow-input" [formControl]="languageControl" [compareWith]="compareWithLanguage">
        <option *ngFor="let lang of languages$ | async" [ngValue]="lang.code">{{ lang.name }}</option>
      </select>
    </div>
  </div>

  <p class="mb-3">
    {{ 'import_modal.importer_accepts_only' | translate }} <strong>.CSV</strong> {{ 'import_modal.files_encoded_in' | translate }} <strong>UTF-8</strong> {{ 'import_modal.with_first_row' | translate }}
    {{ 'import_modal.required_cols_names' | translate }}:
  </p>

  <div class="column-list mb-3">
    <span *ngFor="let column of getColumnsToDisplay() | async; last as last" class="column text-nowrap" [ngClass]="{ 'valid': column.status === true, 'invalid': column.status === false }">
      {{ column.column }}<span *ngIf="!last">,</span>
    </span>
    <a class="column column-link text-nowrap" title="Download Template" [href]="templateUrl" [download]="templateFilename">
      <app-icon icon="/assets/icons/export.svg"></app-icon>
      {{ 'import_modal.template' | translate }} XLSX
    </a>
    <a class="column column-link text-nowrap" title="Download Template" [href]="templateCSVUrl" [download]="templateCSVFilename">
      <app-icon icon="/assets/icons/export.svg"></app-icon>
      {{ 'import_modal.template' | translate }} CSV
    </a>
  </div>

  <ng-container *ngIf="(isValid$ | async); else hasInvalidConfiguration">
    <p *ngIf="!(importError$ | async); else hasImportError">{{ 'import_modal.everything_ok' | translate }}!</p>
  </ng-container>

  <ng-template #hasInvalidConfiguration>
    <p class="text-danger">{{ error$ | async | extractError }}</p>
  </ng-template>

  <ng-template #hasImportError>
    <p class="text-danger">{{ importError$ | async | extractError }}</p>
  </ng-template>

  <div *ngIf="hasColumn('day', 'start_date', 'end_date')">
    <p class="mb-1">
      {{ 'import_modal.please_use_following_date' | translate }}:
    </p>

    <ul>
      <li *ngIf="hasColumn('day')">day: YYYY-MM-DD</li>
      <li *ngIf="hasColumn('start_date')">start_date: YYYY-MM-DD 00:00:00</li>
      <li *ngIf="hasColumn('end_date')">end_date: YYYY-MM-DD 00:00:00</li>
    </ul>
  </div>

</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button class="btn" type="button" mat-dialog-close>{{ 'buttons.cancel' | translate }}</button>
  <button class="btn btn-primary ml-2" [disabled]="!(isValid$ | async)" (click)="onImportClick($event)">{{ 'import_modal.import' | translate }}</button>
</mat-dialog-actions>

<app-spinner-overlay *ngIf="isImporting$ | async"></app-spinner-overlay>

<!-- Import Results Modal -->
<ng-template #resultModalTemplate let-results let-dialogRef="dialogRef">
  <h2 mat-dialog-title>{{ 'import_modal.import_results' | translate }}</h2>
  <mat-dialog-content>
    <ng-container *ngIf="results.length; else noResults">
      <p>{{ 'import_modal.check_import_summary' | translate }}</p>
      <ul class="pl-4 ml-0">
        <li *ngFor="let result of results" [class]="getStatusCssClass(result.status)">{{ result.results }}</li>
      </ul>
    </ng-container>

    <ng-template #noResults>
      <p>{{ 'import_modal.everything_went_fine' | translate }}</p>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex flex-row justify-content-end">
    <button class="btn" type="button" (click)="dialogRef.close()">{{ 'buttons.close' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
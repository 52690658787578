import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { TokenModel } from '@app/core/models/token.model';

import * as tokenActions from '@app/core/store/token.actions';
import * as tokenSelectors from '@app/core/store/token.selectors';
import { first } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(
    private store$: Store<any>,
    private actions$: Actions,
  ) {}

  getIsAuthenticated(): Observable<boolean> {
    return this.store$.pipe(select(tokenSelectors.selectIsAuthenticated));
  }

  getIsRetrieving(): Observable<boolean> {
    return this.store$.pipe(select(tokenSelectors.selectIsRetrieving));
  }

  getIsRefreshing(): Observable<boolean> {
    return this.store$.pipe(select(tokenSelectors.selectIsRefreshing));
  }

  getToken(): Observable<TokenModel> {
    return this.store$.pipe(select(tokenSelectors.selectToken));
  }

  getError(): Observable<Error> {
    return this.store$.pipe(select(tokenSelectors.selectError));
  }

  refreshToken(token: TokenModel) {
    this.store$.dispatch(tokenActions.tokenRefresh({ token }));
  }

  refreshWithCurrentToken() {
    this.getToken()
      .pipe(first())
      .subscribe(token => this.refreshToken(token));
  }

  clearToken() {
    this.store$.dispatch(tokenActions.tokenClear())
  }

  // actions
  onTokenRefreshSuccess(): Observable<void> {
    return this.actions$.pipe(
      ofType(tokenActions.tokenRefreshSuccess)
    );
  }

  onTokenRefreshFailure(): Observable<void> {
    return this.actions$.pipe(
      ofType(tokenActions.tokenRefreshFailure)
    );
  }
}

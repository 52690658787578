import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';

import { AuthGuard } from '@shared/guards/auth.guard';
import { RouterAccountView, RouterMainView } from '@shared/providers/router-provider.service';
import { ComponentReuseStrategy } from '@utils/component-reuse.strategy';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: `/${RouterMainView.ACCOUNT}/${RouterAccountView.LOGIN}`,
  },
  // Back compatible routing
  {
    path: RouterAccountView.FORGOT_PASSWORD,
    pathMatch: 'full',
    redirectTo: `/${RouterMainView.ACCOUNT}/${RouterAccountView.FORGOT_PASSWORD}`,
  },
  // Lazy loading modules
  {
    path: RouterMainView.ACCOUNT,
    loadChildren: () => import('@app/account/account.module').then(m => m.AccountModule),
  },
  {
    path: RouterMainView.BACKEND,
    loadChildren: () => import('@app/backend/backend.module').then(m => m.BackendModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: RouterMainView.LICENSES,
    loadChildren: () => import('@app/licenses/licenses.module').then(m => m.LicensesModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: ComponentReuseStrategy },
  ]
})
export class AppRoutingModule {}
import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { ComponentTranslationModel } from '@components/component/models/component-translation.model';

export const loadComponentTranslation = createAction(
  '[Component/Component Modal] Load Component Translation',
  props<{ event: EventModel, component: ComponentModel, language: string }>()
);

export const loadComponentTranslationSuccess = createAction(
  '[Component/API] Load Component Translation Success',
  props<{ translation: ComponentTranslationModel }>()
);

export const loadComponentTranslationFailure = createAction(
  '[Component/API] Load Component Translation Failure',
  props<{ error: Error }>()
);

import { Component } from '@angular/core';
import { ProfileModel } from '@app/backend/models/profile.model';
import { ProfileService } from '@app/backend/services/profile.service';
import { EventFacadeService } from '@store/features/event/event-facade.service';
import { EventModel } from '@store/features/event/models/event.model';
import { EventUrlBuilder } from '@utils/event-url-builder.util';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-live-preview-tape',
  templateUrl: './live-preview-tape.component.html',
  styleUrls: ['./live-preview-tape.component.scss'],
})
export class LivePreviewTapeComponent {
  event$: Observable<EventModel>;
  profile$: Observable<ProfileModel>;

  constructor(private eventFacade: EventFacadeService, private profileFacade: ProfileService) {
    this.event$ = this.eventFacade.getActiveEvent();
    this.profile$ = this.profileFacade.getProfile();
  }

  get landingPageUrl(): Observable<string | null> {
    return combineLatest([this.event$, this.profile$]).pipe(
      map(([event, profile]) => {
        if (!event) return null;

        return EventUrlBuilder.getEventLandingUrl(event, {
          email: profile?.email,
        });
      })
    );
  }

  get qrcode(): Observable<string | null> {
    return this.event$.pipe(map(event => (event ? event.qrcode : null)));
  }
}

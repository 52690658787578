import { AttachmentModel } from '@shared/models/attachment.model';
import { EventUserApiModel } from '@shared/models/event-user.api-model';
import { UserApiModel } from '@shared/models/user.api-model';
import { TagApiModel } from '@store/features/tags/tags.models';

export interface ExhibitorUpdateModel {
  id: 25,
  exhibitor_id: 1,
  exhibitor_moderator_user: ExhibitorModeratorUser,
  for_logged_contact: boolean,
  for_logged_sm: boolean,
  name: string,
  description: string,
  scope_description: string,
  phone_country_code: string,
  phone: string,
  email: string,
  www: string,
  facebook_profile: string,
  twitter_profile: string,
  linked_profile: string,
  youtube_profile: string,
  instagram_profile: string,
  video_call_url: string,
  promotion_film_url: string,
  translations: ExhibitorUpdateTranslationModel[],
  exhibitor_contact_people: ExhibitorUpdateContactPeople[],
  tags_exhibitors: TagApiModel[],
  exhibitor_contact_users: EventUserApiModel[],
  exhibitor_attachments: AttachmentModel[],
  picture: AttachmentModel,
  vip_picture: AttachmentModel,
  extra_note: string,
  status: ExhibitorUpdatesStatus
}

export interface ExhibitorUpdateContactPeople {
  id: number;
  order: number,
  first_name: string,
  last_name: string,
  phone_country_code: string,
  phone: string,
  email: string,
  facebook_profile: string,
  twitter_profile: string,
  linked_profile: string,
  description: string,
  translations: ExhibitorUpdateTranslationModel[]
  picture: AttachmentModel;
  contact_person_id: number | null;
}

export interface ExhibitorUpdateTranslationModel {
  description: string;
  scope_description: string;
  name: string;
  locale: string; // only in ngrx store
}

// getting updates (payload)
export interface ExhibitorUpdateQueries {
  status: ExhibitorUpdatesStatus,
  limit: number,
  page: number
}

// response meta
export interface ExhibitorUpdatesMeta {
  total: number | null,
  limit: number,
  total_pages: number | null,
  page: number
}

// getting updates (response)
export interface ExhibitorUpdatesResponse {
  exhibitors_updates: ExhibitorUpdateModel[],
  exhibitor_updates: ExhibitorUpdateModel[], // hot fix for rejected status
  meta: ExhibitorUpdatesMeta
}

// update exhibitor change status (payload - body)
export interface ExhibitorUpdatePatch {
  status: ExhibitorUpdatesStatus,
  extra_note: string
}

export interface ExhibitorModeratorUser {
  user_identity_token: string,
  first_name: string,
  last_name: string,
  position: string | null,
  company: string | null,
  picture: AttachmentModel | null,
  qrcode: string,
  user_tags: UserApiModel,
  user_external_uuid: string | null;
}


export enum ExhibitorUpdatesStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PENDING = 'pending'
}
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollectionBaseService } from '@shared/providers/collection-base.service';

import { BusinessMatchingMeetingApiModel } from '@components/business-matching/models/business-matching-meeting.api-model';

@Injectable({
  providedIn: 'root'
})
export class BusinessMatchingMeetingStoreService extends CollectionBaseService<BusinessMatchingMeetingApiModel> {
  constructor() {
    super([]);
  }

  getSortedBusinessMatchingMeetings(): Observable<BusinessMatchingMeetingApiModel[]> {
    const sortMeetingsByDate = (meetings: BusinessMatchingMeetingApiModel[]) => {
      return meetings.sort((a, b) => (Date.parse(a.time_start) - Date.parse(b.time_start)));
    };

    return this.getBusinessMatchingMeetings().pipe(
      map(meetings => sortMeetingsByDate(meetings))
    );
  }

  getBusinessMatchingMeetings(): Observable<BusinessMatchingMeetingApiModel[]> {
    return this.getEntities();
  }

  updateEntity(entity: BusinessMatchingMeetingApiModel): void {
    super.updateEntity(entity, (a, b) => (a.id === b.id));
  }

  removeEntity(entity: BusinessMatchingMeetingApiModel): void {
    super.removeEntity(entity, (a, b) => (a.id === b.id));
  }
}

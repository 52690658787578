import { EventLanguageModel } from "@store/features/event/models/event-language.model";
import { SpeakerCategoryModel, SpeakerCategoryTranslationModel } from "../models/speaker-category.model";
import { mapSpeakerCategoryToTranslationModel } from "../models/speaker-category.mapper";
import { Action, createReducer, on } from "@ngrx/store";
import { loadSpeakerCategories, loadSpeakerCategoriesFailure, loadSpeakerCategoriesSuccess, setSpeakerCategoriesError, updateSpeakerCategories, updateSpeakerCategoriesFailure, updateSpeakerCategoriesSuccess } from "../actions/speaker-category.actions";
import { selectEvent } from "@store/features/event/actions";

export const stateKey = 'speakerCategories';

export interface State {
  baseLoading: boolean;
  baseUpdating: boolean;
  translationsLoading: boolean;
  translationsUpdating: boolean;
  error: Error | null;
  entities: SpeakerCategoryModel[];
  translations: { [key: string]: SpeakerCategoryTranslationModel[] };
}

export const initialState: State = {
  baseLoading: false,
  baseUpdating: false,
  translationsLoading: false,
  translationsUpdating: false,
  error: null,
  entities: [],
  translations: {},
}

export const speakerCategoryReducer = createReducer(
  initialState,
  on(
    loadSpeakerCategories,
    (state, { lang }) => ({
      ...state,
      baseLoading: lang.default ? true : state.baseLoading,
      translationsLoading: lang.default ? state.translationsLoading : true,
    })
  ),
  on(
    loadSpeakerCategoriesSuccess,
    (state, { lang, entities }) => ({
      ...state,
      ..._loadSpeakerCategoriesSuccessStateModifier(state, lang, entities),
    })
  ),
  on(
    loadSpeakerCategoriesFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),

  on(
    updateSpeakerCategories,
    (state, { lang }) => ({
      ...state,
      baseUpdating: lang.default ? true : state.baseLoading,
      translationsUpdating: lang.default ? state.translationsLoading : true,
    })
  ),
  on(
    updateSpeakerCategoriesSuccess,
    (state, { lang, entities }) => ({
      ...state,
      ..._loadSpeakerCategoriesSuccessStateModifier(state, lang, entities),
      translationsUpdating: lang.default ? state.translationsUpdating : false,
      baseUpdating: lang.default ? false : state.baseUpdating,
    })
  ),
  on(
    updateSpeakerCategoriesFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),

  on(
    setSpeakerCategoriesError,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

function _loadSpeakerCategoriesSuccessStateModifier(state: State, lang: EventLanguageModel, entities: SpeakerCategoryModel[]): State {
  const orderedEntities = [...entities].sort((a, b) => a.order - b.order);
  return {
    ...state,
    baseLoading: !state.baseLoading ? false : !lang.default,
    translationsLoading: false,
    error: null,
    entities: lang.default ? orderedEntities : [...state.entities],
    translations: {
      ...state.translations,
      [lang.code]: orderedEntities.map(entity => mapSpeakerCategoryToTranslationModel(entity)),
    }
  }
}

export function reducer(state: State, action: Action): State {
  return speakerCategoryReducer(state, action);
}

export const selectBaseLoading = (state: State) => state.baseLoading;
export const selectBaseUpdating = (state: State) => state.baseUpdating;
export const selectTranslationsLoading = (state: State) => state.translationsLoading;
export const selectTranslationsUpdating = (state: State) => state.translationsUpdating;
export const selectError = (state: State) => state.error;
export const selectEntities = (state: State) => state.entities;
export const selectTranslations = (state: State) => state.translations;
import { Component, Input, ContentChild } from '@angular/core';

import { ActionMenuComponent } from '@shared/components/action-menu/action-menu.component';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent {
  @Input() icon: string;
  @Input() disabled: boolean;

  @ContentChild(ActionMenuComponent) actionMenuComponent: ActionMenuComponent;

  get dataToggleAttr(): string {
    return this.actionMenuComponent ? 'dropdown' : (void 0);
  }
}

import { createAction, props } from "@ngrx/store";
import { ConsentModel } from "../models/consent.model";
import { ConsentUserAcceptModel } from "../models/consent-user-accept.model";

export const loadUserAppConsents = createAction(
  '[App/Consents] Load User App Consents',
  props<{ langKey?: string }>()
);

export const loadUserAppConsentsSuccess = createAction(
  '[App/Consents] Load User App Consents Success',
  props<{ entities: ConsentModel[] }>()
);

export const loadUserAppConsentsFailure = createAction(
  '[App/Consents] Load User App Consents Failure',
  props<{ error: Error }>()
);

export const acceptUserConsents = createAction(
  '[App/Consents] Accept User Consents',
  props<{ payload: ConsentUserAcceptModel[] }>()
);

export const acceptUserConsentsSuccess = createAction(
  '[App/Consents] Accept User Consents Success',
  props<{ acceptedIds: number[] }>()
);

export const acceptUserConsentsFailure = createAction(
  '[App/Consents] Accept User Consents Failure',
  props<{ error: Error }>()
);

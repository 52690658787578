import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { ComponentTranslationModel } from '@components/component/models/component-translation.model';

import * as ComponentTranslationActions from './actions/component-translation.actions';
import * as fromComponent from './reducers';

@Injectable()
export class ComponentTranslationFacadeService {
  constructor(private store$: Store<any>) {}

  getComponentTranslationLoading(): Observable<boolean> {
    return this.store$.pipe(
      select(fromComponent.selectComponentTranslationLoading)
    );
  }

  getComponentTranslationError(): Observable<Error> {
    return this.store$.pipe(
      select(fromComponent.selectComponentTranslationError)
    );
  }

  getComponentTranslations(
    component: ComponentModel
  ): Observable<ComponentTranslationModel[]> {
    const componentId = component.id;
    return this.store$.pipe(
      select(fromComponent.selectComponentTranslations(), {
        componentId,
        language: null,
      })
    );
  }

  getComponentTranslation(
    component: ComponentModel,
    language: string
  ): Observable<ComponentTranslationModel> {
    const componentId = component.id;
    return this.store$.pipe(
      select(fromComponent.selectComponentTranslation(), {
        componentId,
        language,
      })
    );
  }

  loadComponentTranslation(
    event: EventModel,
    component: ComponentModel,
    language: string
  ) {
    this.store$.dispatch(
      ComponentTranslationActions.loadComponentTranslation({
        event,
        component,
        language,
      })
    );
  }
}

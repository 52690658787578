import { ComponentModel } from './../../../shared/models/component.model';
import { EventModel } from '@store/features/event/models/event.model';
import { BookingAdapterService } from '@components/booking/store/providers/booking-adapter.service';
import { BookingReservationAPI } from '@components/booking/store/models/booking-reservation.model';
import { BookingReservation, BookingReservationStatus, BookingReservationPost, BookingReservationSlot, BookingReservationsGet, BookingReservationMeta } from './models/booking-reservation.model';
import { bookingReservationActions } from './actions/booking-reservation.actions';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { bookingReservationSelector } from './reducers/index';
import * as fromBookingReservation from './reducers/booking-reservation.reducer';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class BookingReservationFacadeService {
  constructor(
    private store$: Store<any>,
    private updates$: Actions,
    private bookingAdapter: BookingAdapterService
  ) {}

  // api
  create(bookingReservation: BookingReservationPost): void {
    this.store$.dispatch(bookingReservationActions.createBookingReservation(bookingReservation));
  }

  loadAll(preset: BookingReservationsGet): void {
    this.store$.dispatch(bookingReservationActions.loadBookingReservations(preset));
  }

  loadBookingReservationsForDlBuilder(event: EventModel, component: ComponentModel): void {
    this.store$.dispatch(bookingReservationActions.loadBookingReservationsForDlBuilder(event, component));
  }

  accept(bookingReservation: BookingReservation): void {
    this.store$.dispatch(bookingReservationActions.acceptBookingReservation(bookingReservation));
  }

  decline(bookingReservation: BookingReservation): void {
    this.store$.dispatch(bookingReservationActions.declineBookingReservation(bookingReservation));
  }

  loadSlots(): void {
    this.store$.dispatch(bookingReservationActions.loadBookingReservationSlots());
  }

  exportReservations(): void {
    this.store$.dispatch(bookingReservationActions.exportBookingReservations());
  }

  // add only to state if user view is collapsing to handled pusher reservation event
  handleNewBookingReservation(bookingReservation: BookingReservationAPI): void {
    this.store$.dispatch(bookingReservationActions.addFromSocket(this.bookingAdapter.adaptBookingReservation(bookingReservation)));
  }

  // remove only from state if user view is not collapsing to handled pusher reservation event
  handleDeleteBookingReservation(bookingReservation: BookingReservationAPI): void {
    this.store$.dispatch(bookingReservationActions.deleteFromSocket(bookingReservation.id));
  }

  // store
  getStore(): Observable<fromBookingReservation.State> {
    return this.store$.pipe(select(bookingReservationSelector.getState));
  }

  getMeta(): Observable<BookingReservationMeta> {
    return this.store$.pipe(select(bookingReservationSelector.getMeta));
  }

  getAll(): Observable<BookingReservation[]> {
    return this.store$.pipe(select(bookingReservationSelector.getAll));
  }

  getById(id: number): Observable<BookingReservation> {
    return this.store$.pipe(select(bookingReservationSelector.getById(id)));
  }

  getActiveStatus(): Observable<BookingReservationStatus> {
    return this.store$.pipe(select(bookingReservationSelector.getActiveStatus));
  }

  getIsExporting(): Observable<boolean> {
    return this.store$.pipe(select(bookingReservationSelector.getIsExporting));
  }

  setActiveStatus(status: BookingReservationStatus): void {
    this.store$.dispatch(bookingReservationActions.setBookingReservationActiveStatus(status));
  }

  isLoading(): Observable<boolean> {
    return this.store$.pipe(select(bookingReservationSelector.isLoading));
  }

  isSaving(): Observable<boolean> {
    return this.store$.pipe(select(bookingReservationSelector.isSaving));
  }

  getError(): Observable<Error> {
    return this.store$.pipe(select(bookingReservationSelector.getError));
  }

  setError(error: Error | null): void {
    this.store$.dispatch(bookingReservationActions.setBookingReservationError(error));
  }

  getCreatorMode(): Observable<boolean> {
    return this.store$.pipe(select(bookingReservationSelector.getCreatorMode));
  }

  setCreatorMode(state: boolean): void {
    this.store$.dispatch(bookingReservationActions.setBookingReservationCreatorMode(state));
  }

  getSlots(): Observable<BookingReservationSlot[]> {
    return this.store$.pipe(select(bookingReservationSelector.getSlots));
  }

  getIsCreatedSuccessfully(): Observable<boolean> {
    return this.updates$.pipe(
      ofType(bookingReservationActions.createBookingReservationSuccess),
    );
  }

}

import { Component, Input, Output, ViewChild, ContentChild, HostListener, TemplateRef, ElementRef, EventEmitter } from '@angular/core';

import { VirtualScrollItemDirective } from '@shared/directives/virtual-scroll-item.directive';
import { VirtualScrollPlaceholderDirective } from '@shared/directives/virtual-scroll-placeholder.directive';

@Component({
  selector: 'app-virtual-scroll',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.scss']
})
export class VirtualScrollComponent {
  @Input() items: any[];
  @Input() maxItems: number;

  @Output() appLoadNext: EventEmitter<() => void>;

  @ViewChild('placeholder', { static: true }) placeholderRef: ElementRef<any>;

  @ContentChild(VirtualScrollItemDirective, { static: true, read: TemplateRef }) itemTemplateRef: TemplateRef<any>;
  @ContentChild(VirtualScrollPlaceholderDirective, { static: true, read: TemplateRef }) placeholderTemplateRef: TemplateRef<any>;

  get remainingItems(): number[] {
    const remaining = this.maxItems - this.items.length;
    const items = [...Array(remaining)].map((value, index) => index);

    return items;
  }

  private updateLocked: boolean;

  constructor(private elementRef: ElementRef) {
    this.appLoadNext = new EventEmitter<() => void>();
  }

  @HostListener('scroll', ['event$'])
  onHostScroll(ev: Event) {
    this.updateBoundaries();
  }

  private updateBoundaries() {
    if (this.updateLocked || (this.items.length >= this.maxItems)) {
      return;
    }

    if (this.hasScrollEnded()) {
      this.updateLocked = true;

      this.appLoadNext.next(() => {
        this.updateLocked = false;
        this.deferUpdateBoundaries();
      });
    }
  }

  private deferUpdateBoundaries() {
    setTimeout(() => this.updateBoundaries(), 0);
  }

  private hasScrollEnded(): boolean {
    const scrollElement: HTMLElement = this.elementRef.nativeElement;
    const placeholderElement: HTMLElement = this.placeholderRef.nativeElement;
    const scrollBottom = scrollElement.scrollTop + scrollElement.offsetHeight;

    return (scrollBottom > placeholderElement.offsetTop);
  }
}

import { createSelector } from '@ngrx/store';

import { v4 as uuid } from 'uuid';

export interface PlaceholderState {
  uuid: string;
  shown: boolean;
  focused: boolean;
}

export interface PlaceholderStateAdapter<T extends PlaceholderState> {
  focus(state: T): T;
  blur(state: T): T;
  hide(state: T): T;
}

export interface PlaceholderSelectors<T extends PlaceholderState> {
  selectUuid(state: T): string;
  selectShown(state: T): boolean;
  selectFocused(state: T): boolean;
}

export interface PlaceholderAdapter<T extends PlaceholderState>
  extends PlaceholderStateAdapter<T> {
  getInitialState(): T;
  getInitialState<S>(state: S): T;
  getSelectors(): PlaceholderSelectors<T>;
  getSelectors<S>(
    selectState: (state: S) => PlaceholderState
  ): PlaceholderSelectors<T>;
}

export function createPlaceholderAdapter<
  T extends PlaceholderState = PlaceholderState
>(): PlaceholderAdapter<T> {
  const initialStateFactory = createInitialStateFactory<T>();
  const selectorsFactory = createSelectorsFactory<T>();
  const stateUpdaters = createStateAdapterFactor<T>();

  return {
    ...initialStateFactory,
    ...selectorsFactory,
    ...stateUpdaters,
  };
}

function createStateAdapterFactor<T extends PlaceholderState>() {
  const reducerFactory = (update: Partial<PlaceholderState>) => (state: T) => {
    // Generate new UUID every placeholder show
    if (!state?.shown && update?.shown) {
      update.uuid = uuid();
    }

    return { ...state, ...update };
  };

  return {
    focus: reducerFactory({ shown: true, focused: true }),
    blur: reducerFactory({ focused: false }),
    hide: reducerFactory({ shown: false, focused: false }),
  };
}

function createInitialStateFactory<T extends PlaceholderState>() {
  const initialState: PlaceholderState = {
    uuid: null,
    shown: false,
    focused: false,
  };

  function getInitialState(): T;
  function getInitialState<S>(additionalState: S): T & S;
  function getInitialState(additionalState: any = {}): T {
    return Object.assign({}, initialState, additionalState);
  }

  return {
    getInitialState,
  };
}

function createSelectorsFactory<T extends PlaceholderState>() {
  function getSelectors(): PlaceholderSelectors<T>;
  function getSelectors<S>(
    selectState: (state: S) => PlaceholderState
  ): PlaceholderSelectors<T>;
  function getSelectors(
    selectState?: (state: any) => PlaceholderState
  ): PlaceholderSelectors<T> {
    const selectUuid = (state: T) => state?.uuid;
    const selectShown = (state: T) => state?.shown;
    const selectFocused = (state: T) => state?.focused;

    if (selectState) {
      return {
        selectUuid: createSelector(selectState, selectUuid),
        selectShown: createSelector(selectState, selectShown),
        selectFocused: createSelector(selectState, selectFocused),
      };
    } else {
      return {
        selectUuid,
        selectShown,
        selectFocused,
      };
    }
  }

  return {
    getSelectors,
  };
}

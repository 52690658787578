import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { applicationTags, applicationUsers, customEmails, ticketStats } from "@app/application/components/application/application.component";
import { couponsComponent } from "@app/construct-steps/steps/content/content.component";
import { USER_ROLE_PERMISSIONS, isUserRoleAllowed, isUserRoleComponentHidden } from "@shared/constants/user-role-permissions";
import { UserRoleEnum } from "@shared/enums/user-role.enum";
import { RouterEventConstructorStep, RouterEventView, RouterProviderService } from "@shared/providers/router-provider.service";
import { EventFacadeService } from "@store/features/event/event-facade.service";
import { EventModel } from "@store/features/event/models/event.model";
import { ComponentNames } from "@utils/component-names.util";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private eventFacade: EventFacadeService,
    private routerProvider: RouterProviderService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const allowedRoles: UserRoleEnum[] = route.data.allowedRoles;

    const eventId = this.routerProvider.getEventId(state.url);

    // for creating
    if (Number(eventId) === 0) {
      return true;
    }

    return this.eventFacade.getActiveEvent()
      .pipe(
        first(event => !!event),
        map(event => {
          if (!event.userRole) {
            return true;
          }

          // sections
          const sectionAllowed = this.sectionAllowed(state.url, event);
          if (sectionAllowed !== null) {
            return sectionAllowed;
          }

          // construct => components
          const contentComponentsAllowed = this.contentComponentsAllowed(state.url, event);
          if (contentComponentsAllowed !== null) {
            return contentComponentsAllowed;
          }

          // application => components
          const applicationComponentsAllowed = this.applicationComponentsAllowed(state.url, event);
          if (applicationComponentsAllowed !== null) {
            return applicationComponentsAllowed;
          }

          // allowed route checking by given data direcly in router
          if (allowedRoles) {
            const allowed = allowedRoles.some(role => role === event.userRole);          
            if (!allowed) {
              this.routerProvider.toEventConstructorStep(event.id, RouterEventConstructorStep.CONTENT);
            }
  
            return allowed;
          }

          return true;
        }),
      );
  }

  private sectionAllowed(url: string, event: EventModel): boolean | null {
    // landing page section
    if (this.routerProvider.getRouterEventView(url) === RouterEventView.PERSONALIZATION) {
      const isAllowed = isUserRoleAllowed(event, USER_ROLE_PERMISSIONS.SECTION_LANDING_PAGE);
      if (!isAllowed) {
        this.routerProvider.toEventConstructorStep(event.id, RouterEventConstructorStep.CONTENT);
      }
      return isAllowed;
    }
    return null;
  }

  private contentComponentsAllowed(url: string, event: EventModel): boolean | null {
    // coupons component view
    if (this.routerProvider.getRouterEventView(url) === RouterEventView.CONSTRUCTOR
      && this.routerProvider.getComponentId(url) === couponsComponent.id
    ) {
      const isAllowed = !isUserRoleComponentHidden(event, ComponentNames.COUPONS);
      if (!isAllowed) {
        this.routerProvider.toEventConstructorStep(event.id, RouterEventConstructorStep.CONTENT);
      }
      return isAllowed;
    }
    return null;
  }

  private applicationComponentsAllowed(url: string, event: EventModel): boolean | null {
    if (this.routerProvider.getRouterEventView(url) === RouterEventView.APPLICATION) {
      const compId = this.routerProvider.getComponentId(url);
      if (!compId) {
        return null;
      }
      const predefinedComponents = [applicationUsers, ticketStats, applicationTags, customEmails];
      const activePredefinedComponent = predefinedComponents.find(component => component.id === compId);

      if (activePredefinedComponent !== undefined) {
        const isAllowed = !isUserRoleComponentHidden(event, activePredefinedComponent.componentName);
        if (!isAllowed) {
          this.routerProvider.toEventApplicationEmpty(event.id);
        }
        return isAllowed;
      }
      return null;
    }
    return null;
  }
}
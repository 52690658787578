import { Action, createReducer, on } from "@ngrx/store";
import { AudioVisualModel } from "../models/audio-visual.models";
import { AudioVisualAction } from "../actions/audio-visual.actions";


export interface State {
  loading: boolean;
  updating: boolean;
  activeId: number | null;
  audioVisuals: AudioVisualModel[];
  error: Error | null;
  expandedId: number;

  filesDeleteInProgress: boolean;
  filesUpdateInProgress: boolean;
  filesCreateInProgress: boolean;

  createMode: boolean;
}

export const initialState: State = {
  loading: false,
  updating: false,
  activeId: null,
  audioVisuals: [],
  error: null,
  expandedId: 0,

  filesDeleteInProgress: false,
  filesUpdateInProgress: false,
  filesCreateInProgress: false,

  createMode: false,
}

export const audioVisualReducer = createReducer(
  initialState,
  on(
    AudioVisualAction.loadAudioVisuals,
    AudioVisualAction.loadAudioVisualsForDeepLinks,
    (state) => ({ ...state, loading: true })
  ),

  on(
    AudioVisualAction.loadAudioVisualsSuccess,
    (state, { audioVisuals }) => ({ ...state, loading: false, error: null, audioVisuals: sortItems(audioVisuals) })
  ),
  on(
    AudioVisualAction.loadAudioVisualsFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    AudioVisualAction.createAudioVisual,
    AudioVisualAction.patchAudioVisual,
    AudioVisualAction.deleteAudioVisual,
    (state) => ({ ...state, updating: true })
  ),
  on(
    AudioVisualAction.createAudioVisualSuccess,
    (state, { audioVisual }) => ({
      ...state,
      updating: false,
      erorr: null,
      audioVisuals: [...state.audioVisuals, audioVisual],
      expandedId: audioVisual.id
    })
  ),
  on(
    AudioVisualAction.createAudioVisualFailure,
    AudioVisualAction.patchAudioVisualFailure,
    AudioVisualAction.deleteAudioVisualFailure,
    AudioVisualAction.createAudioVisualFileFailure,
    AudioVisualAction.deleteAudioVisualFileFailure,
    (state, { error }) => ({ ...state, updating: false, error })
  ),
  on(
    AudioVisualAction.patchAudioVisualSuccess,
    (state, { audioVisual }) => ({
      ...state,
      updating: false,
      error: null,
      audioVisuals: state.audioVisuals.map(aV => aV.id === audioVisual.id ? audioVisual : aV),
    }),
  ),
  on(
    AudioVisualAction.deleteAudioVisualSuccess,
    (state, { audioVisual }) => ({
      ...state,
      updating: false,
      error: null,
      audioVisuals: [...state.audioVisuals.filter(aV => aV.id !== audioVisual.id)],
    }),
  ),
  on(
    AudioVisualAction.reorderAudioVisuals,
    (state, { ordered }) => ({
      ...state,
      error: null,
      audioVisuals: [...ordered],
    }),
  ),
  on(
    AudioVisualAction.reorderAudioVisualsSuccess,
    (state) => ({
      ...state,
      error: null,
    }),
  ),
  on(
    AudioVisualAction.reorderAudioVisualsFailure,
    (state, { error }) => ({
      ...state,
      error: error,
    }),
  ),

  // files

  on(
    AudioVisualAction.createAudioVisualFile,
    AudioVisualAction.patchAudioVisualFile,
    AudioVisualAction.deleteAudioVisualFile,
    (state) => ({ ...state, updating: true })
  ),
  on(
    AudioVisualAction.createAudioVisualFileSuccess,
    (state, { audioVisual, audioVisualFile }) => ({
      ...state,
      updating: false,
      audioVisuals: [
        ...state.audioVisuals.map(aV => aV.id !== audioVisual.id
          ? aV
        : ({ ...audioVisual, audioVisualFiles: [...audioVisual.audioVisualFiles, audioVisualFile] }))
        ],
    }),
  ),
  on(
    AudioVisualAction.patchAudioVisualFileSuccess,
    (state, { audioVisual, audioVisualFile }) => ({
      ...state,
      updating: false,
      audioVisuals: [
        ...state.audioVisuals.map(aV => aV.id !== audioVisual.id
          ? aV
        : ({ ...audioVisual, audioVisualFiles: [...audioVisual.audioVisualFiles.map(f => f.id === audioVisualFile.id ? audioVisualFile : f)] }))
        ],
    }),
  ),
  on(
    AudioVisualAction.deleteAudioVisualFileSuccess,
    (state, { audioVisual, audioVisualFile }) => ({
      ...state,
      updating: false,
      audioVisuals: [
        ...state.audioVisuals.map(aV => aV.id !== audioVisual.id
          ? aV
        : ({ ...audioVisual, audioVisualFiles: [...audioVisual.audioVisualFiles.filter(f => f.id !== audioVisualFile.id)] }))
        ],
    }),
  ),
  on(
    AudioVisualAction.reorderAudioVisualFiles,
    (state, { audioVisual, ordered }) => ({
      ...state,
      error: null,
      audioVisuals: [...state.audioVisuals.map(aV => aV.id === audioVisual.id ? ({ ...audioVisual, audioVisualFiles: ordered }) : aV)],
    }),
  ),
  on(
    AudioVisualAction.reorderAudioVisualFilesSuccess,
    (state) => ({
      ...state,
      error: null,
    }),
  ),
  on(
    AudioVisualAction.reorderAudioVisualFilesFailure,
    (state, { error }) => ({
      ...state,
      error,
    }),
  ),
  on(
    AudioVisualAction.patchAudioVisualFiles,
    (state) => ({
      ...state,
      filesUpdateInProgress: true,
    }),
  ),
  on(
    AudioVisualAction.patchAudioVisualFilesSuccess,
    (state) => ({
      ...state,
      filesUpdateInProgress: false,
      error: null,
    }),
  ),
  on(
    AudioVisualAction.patchAudioVisualFilesFailure,
    (state, { error }) => ({
      ...state,
      error,
      filesUpdateInProgress: false,
    }),
  ),
  on(
    AudioVisualAction.createAudioVisualFiles,
    (state) => ({
      ...state,
      filesCreateInProgress: true,
    }),
  ),
  on(
    AudioVisualAction.createAudioVisualFilesSuccess,
    (state, { audioVisual, files }) => ({
      ...state,
      filesCreateInProgress: false,
      audioVisuals: [...state.audioVisuals.map(aV => aV.id === audioVisual.id ? ({ ...audioVisual, audioVisualFiles: [...files] }) : aV)]
    }),
  ),
  on(
    AudioVisualAction.createAudioVisualFilesFailure,
    (state, { error }) => ({
      ...state,
      error,
      filesCreateInProgress: false,
    }),
  ),
  on(
    AudioVisualAction.deleteAudioVisualFiles,
    (state) => ({
      ...state,
      filesDeleteInProgress: true,
    }),
  ),
  on(
    AudioVisualAction.deleteAudioVisualFilesSuccess,
    (state, { audioVisual, ids }) => ({
      ...state,
      filesDeleteInProgress: false,
      audioVisuals: [...state.audioVisuals.map(aV => aV.id === audioVisual.id ? ({ ...audioVisual, audioVisualFiles: audioVisual.audioVisualFiles.filter(f => !ids.includes(f.id)) }) : aV)]
    }),
  ),
  on(
    AudioVisualAction.deleteAudioVisualFilesFailure,
    (state, { error }) => ({
      ...state,
      error,
      filesDeleteInProgress: false,
    }),
  ),


  // other
  on(
    AudioVisualAction.setCreateMode,
    (state, { status }) => ({
      ...state,
      createMode: status,
      audioVisuals: handleAudioVisualCreateMode(status, state.audioVisuals),
    }),
  ),
  on(
    AudioVisualAction.setExpandedId,
    (state, { id }) => ({
      ...state,
      expandedId: id,
      createMode: id === -1 ? true : false
    }),
  ),
  on(
    AudioVisualAction.setActiveAudioVisualId,
    (state, { id }) => ({
      ...state,
      activeId: id,
    }),
  ),
);

export function reducer(state: State, action: Action): State {
  return audioVisualReducer(state, action);
}

function handleAudioVisualCreateMode(status: boolean, array: AudioVisualModel[]): AudioVisualModel[] {
  if (status) {
    if (array.find(a => a.id === -1)) {
      return array;
    } else {
      return [...array, getMockedAudioVisual()];
    }
  } else {
    return array.filter(a => a.id !== -1);
  }
}

function getMockedAudioVisual(): AudioVisualModel {
  return {
    id: -1,
    name: null,
    audioVisualFiles: null,
    order: 0,
  }
}

function sortItems(audioVisuals: AudioVisualModel[]): AudioVisualModel[] {
  return audioVisuals
    .map(catalog => ({ ...catalog, audioVisualFiles: [...catalog.audioVisualFiles].sort((a, b) => a.order - b.order) }))
    .sort((a, b) => a.order - b.order);
}

export const selectLoading = (state: State) => state.loading;
export const selectUpdating = (state: State) => state.updating;
export const selectExpandedId = (state: State) => state.expandedId;
export const selectError = (state: State) => state.error;
export const selectActiveId = (state: State) => state.activeId;
export const selectAudioVisuals = (state: State) => state.audioVisuals;
export const selectCreateMode = (state: State) => state.createMode;
export const selectActiveAudioVisual = (state: State) => state.audioVisuals.find(aV => aV.id === state.activeId);

export const selectFilesUpdateInProgress = (state: State) => state.filesUpdateInProgress;
export const selectFilesDeleteInProgress = (state: State) => state.filesDeleteInProgress;
export const selectFilesCreateInProgress = (state: State) => state.filesCreateInProgress;
import { Selector, createSelector } from '@ngrx/store';

export interface LoaderState {
  loading: boolean;
  loaded: boolean;
}

export interface LoaderStateAdapter<T extends LoaderState> {
  load(state: T): T;
  loaded(state: T): T;
  reset(state: T): T;
}

export interface LoaderSelectors<T extends LoaderState> {
  selectLoading(state: T): boolean;
  selectLoaded(state: T): boolean;
}

export interface LoaderAdapter<T extends LoaderState> extends LoaderStateAdapter<T> {
  getInitialState(): LoaderState;
  getInitialState<S>(state: S): LoaderState & S;
  getSelectors(): LoaderSelectors<T>;
  getSelectors<S>(selectState: Selector<S, T>): LoaderSelectors<T>;
}

export function createLoaderAdapter<T extends LoaderState>(): LoaderAdapter<T> {
  const initialStateFactory = createInitialStateFactory<T>();
  const selectorsFactory = createSelectorsFactory<T>();
  const stateUpdaters = createStateAdapterFactor<T>();

  return {
    ...initialStateFactory,
    ...selectorsFactory,
    ...stateUpdaters,
  };
}

function createInitialStateFactory<T extends LoaderState>(): Pick<LoaderAdapter<T>, 'getInitialState'> {
  const initialState: LoaderState = {
    loading: false, loaded: false,
  };

  function getInitialState(): LoaderState;
  function getInitialState<S>(additionalState: S): LoaderState & S;
  function getInitialState(additionalState: any = {}): LoaderState {
    return Object.assign({}, initialState, additionalState);
  }

  return {
    getInitialState,
  };
}

function createSelectorsFactory<T extends LoaderState>(): Pick<LoaderAdapter<T>, 'getSelectors'> {
  function getSelectors(): LoaderSelectors<T>;
  function getSelectors<S>(selectState: Selector<S, T>): LoaderSelectors<T>;
  function getSelectors(selectState?: Selector<any, T>): LoaderSelectors<T> {
    const selectLoading = (state: T) => state?.loading;
    const selectLoaded = (state: T) => state?.loaded;

    if (selectState) {
      return {
        selectLoading: createSelector(selectState, selectLoading),
        selectLoaded: createSelector(selectState, selectLoaded),
      };
    } else {
      return {
        selectLoading,
        selectLoaded,
      };
    }
  }

  return {
    getSelectors,
  };
}

function createStateAdapterFactor<T extends LoaderState>(): LoaderStateAdapter<T> {
  const reducerFactory = (update: Partial<LoaderState>) => (state: T): T => {
    return { ...state, ...update };
  };

  return {
    load: reducerFactory({ loading: true, loaded: false }),
    loaded: reducerFactory({ loading: false, loaded: true }),
    reset: reducerFactory({ loading: false, loaded: false }),
  };
}

import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { DropdownItemCheckboxComponent } from '../dropdown-item-checkbox/dropdown-item-checkbox.component';
import { DropdownItemComponent } from '../dropdown-item/dropdown-item.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements AfterContentInit {

  @ContentChildren(DropdownItemCheckboxComponent) dropdownItemsCheckbox!: QueryList<DropdownItemCheckboxComponent>;
  @ContentChildren(DropdownItemComponent) dropdownItems!: QueryList<DropdownItemComponent>;

  @Input() label: string | null = null;
  @Input() staticLabel: boolean = false;
  @Input() disabled: boolean = false;
  @Input() multi: boolean = false;
  @Input() nullable: boolean = false; // show null option

  @Input() checked: any[] = []; // for multi choice
  @Input() value: any = null; // for single choice

  initializated = false;

  selectedLabel: string; // display selected item label (if staticLabel is setted to false)

  @Output() onSelectMultiChanged: EventEmitter<any[]>; // for multi choice
  @Output() onSelectChanged: EventEmitter<any>; // for single choice


  constructor(
    public i18n: TranslateService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.onSelectMultiChanged = new EventEmitter<any[]>();
    this.onSelectChanged = new EventEmitter<any>();
  }

  ngAfterContentInit(): void {
    if (this.initializated === false) {
      this.multi ? this.initMulti() : this.initSingle();
    }

    this.initializated = true;

    this.cdRef.detectChanges();
  }

  public select(value: any, selectedLabel?: string): void {
    this.multi ? this.selectMultiChanged() : this.selectSingleChanged(value, selectedLabel);
  }

  private selectMultiChanged(): void {
    const checkedArray = this.dropdownItemsCheckbox
      .filter(i => i.checked)
      .map(el => el.value);

    if (checkedArray.length === this.checked.length) {
      return;
    }

    this.checked = [...checkedArray];
    this.onSelectMultiChanged.emit(this.checked);
  }

  private selectSingleChanged(value: any, selectedLabel?: string): void {
    this.value = value;
    this.selectedLabel = selectedLabel;
    this.dropdownItems.forEach(item => {
      item.isSelected = item.value === value;
    });
    this.onSelectChanged.emit(this.value);
  }

  private initMulti(): void {
    this.dropdownItemsCheckbox.forEach(item => {
      if (this.checked.includes(item.value)) {
        item.checked = true;
      }
    });
  }

  private initSingle(): void {
    this.dropdownItems.forEach(i => i.isSelected = false);
    const subject = this.dropdownItems.find(i => i.value === this.value);
    if (subject !== undefined) {
      subject.isSelected = true;
    }
  }

}
import { ComponentUtilsType } from "../models/component-utils.model";
import { Action, createReducer, on } from "@ngrx/store";
import { clearComponentUtilsState, loadComponentUtils, loadComponentUtilsFailure, loadComponentUtilsSuccess, patchComponentUtils, patchComponentUtilsFailure, patchComponentUtilsSuccess } from "../actions/component-utils.actions";
import { ComponentModel } from "@shared/models/component.model";
import { EventLanguageModel } from "@store/features/event/models/event-language.model";

export const featureKey = 'componentUtils';

export interface State {
  loading: boolean;
  loadedComponentId: number;
  updating: boolean;
  processingUpdateRequestsAmount: number;
  error: Error;
  utils: ComponentUtilsType;
  utilsTranslations: { [key: string]: ComponentUtilsType };
}

export const initialState: State = {
  loading: false,
  loadedComponentId: null,
  updating: false,
  processingUpdateRequestsAmount: 0,
  error: null,
  utils: null,
  utilsTranslations: {},
}

export const componentUtilsReducer = createReducer(
  initialState,
  on(
    loadComponentUtils,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    loadComponentUtilsSuccess,
    (state, { component, lang, utils }) => ({
      ..._loadUtilsSuccessModifyState(state, component, lang, utils),
    }),
  ),
  on(
    loadComponentUtilsFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
  ),

  on(
    patchComponentUtils,
    (state) => ({
      ...state,
      updating: true,
      processingUpdateRequestsAmount: state.processingUpdateRequestsAmount +1,
    }),
  ),
  on(
    patchComponentUtilsSuccess,
    (state, { lang, utils }) => ({
      ...state,
      updating: false,
      processingUpdateRequestsAmount: state.processingUpdateRequestsAmount -1,
      utilsTranslations: {
        ...state.utilsTranslations,
        [lang.code]: utils,
      }
    }),
  ),
  on(
    patchComponentUtilsFailure,
    (state, { error }) => ({
      ...state,
      updating: false,
      processingUpdateRequestsAmount: state.processingUpdateRequestsAmount -1,
      error,
    }),
  ),

  on(clearComponentUtilsState, (_) => ({ ...initialState })),
);

function _loadUtilsSuccessModifyState(state: State, component: ComponentModel, lang: EventLanguageModel, utils: ComponentUtilsType): State {
  let modifiedState = {
    ...state,
    loading: false,
    loadedComponentId: component.id,
    utilsTranslations: {
      ...state.utilsTranslations,
      [lang.code]: utils,
    }
  };
  if (lang.default) {
    return {
      ...modifiedState,
      utils,
    }
  }
  return modifiedState;
}

export function reducer(state: State, action: Action): State {
  return componentUtilsReducer(state, action);
}

export const selectLoading = (state: State) => state.loading;
export const selectProcessingUpdateRequestsAmount = (state: State) => state.processingUpdateRequestsAmount;
export const selectUpdating = (state: State) => state.updating;
export const selectError = (state: State) => state.error;
export const selectUtils = (state: State) => state.utils;
export const selectUtilsTranslations = (state: State) => state.utilsTranslations;
export const selectLoadedComponentId = (state: State) => state.loadedComponentId;
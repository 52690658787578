import { ConsentSchemaApiModel, ConsentSchemaCreateApiModel, ConsentSchemaUpdateApiModel } from "../api/consent-schema.api-model";
import { ConsentSchemaCreateModel, ConsentSchemaModel, ConsentSchemaUpdateModel } from "../consent-schema.model";
import { mapConsentSchemaVersionCreateModel, mapConsentSchemaVersionModel, mapConsentSchemaVersionUpdateModel } from "./consent-schema-version.mapper";

export const mapConsentSchemaModel = (model: ConsentSchemaApiModel): ConsentSchemaModel => {
  return {
    id: model.id,
    consentType: model.consent_type,
    eventConsentType: model.event_consent_type,
    title: model.title,
    required: model.required,
    displayType: model.display_type,
    published: model.published,
    consentVersions: model.consent_versions.map(mapConsentSchemaVersionModel),
  }
}

export const mapConsentSchemaUpdateModel = (model: ConsentSchemaUpdateModel): ConsentSchemaUpdateApiModel => {
  return {
    consent_type: model.consentType,
    event_consent_type: model.eventConsentType,
    title: model.title,
    required: model.required,
    display_type: model.displayType,
    version: mapConsentSchemaVersionUpdateModel(model.version),
  }
}

export const mapConsentSchemaCreateModel = (model: ConsentSchemaCreateModel): ConsentSchemaCreateApiModel => {
  return {
    consent_type: model.consentType,
    event_consent_type: model.eventConsentType,
    title: model.title,
    required: model.required,
    display_type: model.displayType,
    version: mapConsentSchemaVersionCreateModel(model.version),
  }
}

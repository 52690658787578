import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ProductTicketHelper } from "./product-ticket.helpers";

export const validateProductDescription: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const currVal = control.value;

  if (!!currVal && currVal.includes('<li>') && currVal.includes('</li>')) {
    const pointsArray = ProductTicketHelper.splitDescToPoints(currVal);
    const invalid = pointsArray.some(val => !val || val.length < 3 || val.length > 128);
    if (invalid) {
      return { pointedDescError: true };
    }
    return null;
  }
}
<div class="tag-input-container">
  <div class="tag"
       *ngFor="let tag of tags"
       [class.highlighted]="isTagHighlighted(tag)"
       role="listitem">
    {{ tag }}
    <span class="remove-tag"
          (click)="removeTag(tag)"
          role="button">✖</span>
  </div>

  <input type="text"
         [(ngModel)]="newTag"
         [placeholder]="placeholder"
         (keydown)="handleKeydown($event)"
         (input)="handleInput()"
         (blur)="handleBlur()"
         [class.invalid]="isInputInvalid"
         role="textbox" />
</div>
<div class="error-message" *ngIf="errorMessage" role="alert">
  {{ errorMessage }}
</div>

import { AbstractControl, FormArray, FormGroup, ValidatorFn } from "@angular/forms";
import { ProductBundleFormModel, TicketFormAddonsType, TicketFormPricesType, TicketFormSessionsType } from "./product-ticket.helpers";
import { ModelForm } from "@utils/model-form.util";

export enum PriceFormArrayErrorKeys {
  DUPLICATE_CURRENCY_CODE = 'duplicateCurrencyCode',
  NO_PRICES = 'noPrices',
}

export interface PriceFormArrayErrors {
  [PriceFormArrayErrorKeys.NO_PRICES]?: boolean;
  [PriceFormArrayErrorKeys.DUPLICATE_CURRENCY_CODE]?: boolean;
}

export function pricesValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!(control instanceof FormArray)) {
      return null; // Nie jest FormArray
    }

    const pricesArray = control as TicketFormPricesType;
    
    // 1. Sprawdź, czy jest przynajmniej jeden element
    if (pricesArray.length === 0) {
      return { noPrices: true };
    }

    // 2. Sprawdź unikalność currencyCode
    const allCurrencyCodes = pricesArray.controls.map((group) => group.controls.currencyCode.value);
    const filteredCurrencyCodes = allCurrencyCodes.filter(c => !!c);
    const hasDuplicates = filteredCurrencyCodes
      .some((code, index) => filteredCurrencyCodes.indexOf(code) !== index);

    if (hasDuplicates) {
      return { duplicateCurrencyCode: true };
    }

    return null; // Wszystko jest w porządku
  };
}

export function childrenPricesValidator(): ValidatorFn {
  return (form: FormGroup<ModelForm<ProductBundleFormModel>>): { [key: string]: any } | null => {
    const bundlePrices = form.controls.prices as TicketFormPricesType;
    const sessionTickets = form.controls.sessionTickets as TicketFormSessionsType;
    const addons = form.controls.addons as TicketFormAddonsType;

    const bundleCurrencyCodes: string[] = bundlePrices.controls.map(form => form.controls.currencyCode.value);

    sessionTickets.controls.forEach(sessionForm => {
      const prices = sessionForm.controls.prices as TicketFormPricesType;
      const sessionCurrencyCodes: string[] = prices.controls.map(priceForm => priceForm.controls.currencyCode.value);
      const missingCurrencyCodes = bundleCurrencyCodes.filter(bundleCurrency => 
        !sessionCurrencyCodes.includes(bundleCurrency)
      );
      if (missingCurrencyCodes.length > 0) {
        sessionForm.setErrors({
          missingCurrencyCodes: true,
        });
      } else {
        sessionForm.setErrors(null);
      }
    });

    addons.controls.forEach(addonForm => {
      const prices = addonForm.controls.prices as TicketFormPricesType;
      const sessionCurrencyCodes: string[] = prices.controls.map(priceForm => priceForm.controls.currencyCode.value);
      const missingCurrencyCodes = bundleCurrencyCodes.filter(bundleCurrency => 
        !sessionCurrencyCodes.includes(bundleCurrency)
      );
      if (missingCurrencyCodes.length > 0) {
        addonForm.setErrors({
          missingCurrencyCodes: true,
        });
      } else {
        addonForm.setErrors(null);
      }
    });

    return null;
  };
}
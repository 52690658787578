import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qrcode'
})
export class QrcodePipe implements PipeTransform {
  transform(eventUrl: string): any {
    return `https://qr.meetingapplication.com/?qr_url=${eventUrl}`;
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar-stack',
  templateUrl: './avatar-stack.component.html',
  styleUrls: ['./avatar-stack.component.scss']
})
export class AvatarStackComponent {
  // TODO: remove as this field is unused
  @Input() people: any;
}

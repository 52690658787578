import { Component, Input, Inject } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() safeMessage: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = this.data.title;
    this.message = this.data.message;

    if (this.data.safeMessage) {
      this.safeMessage = this.sanitizer.bypassSecurityTrustHtml(this.data.safeMessage);
    }
  }
}

<app-color-pallete data-toggle="dropdown" [colors]="[currentColor]">
  <ng-content></ng-content>
</app-color-pallete>

<div class="dropdown-menu">
  <p>{{ 'color_picker.type_hex_number' | translate }}</p>
  <div class="form-group mb-2">
    <input type="text" class="form-control" placeholder="#ffffff" [formControl]="colorControl"/>
  </div>
  <div class="predefined-colors">
    <span *ngFor="let color of colors" [style.backgroundColor]="color" (click)="onColorSelect($event, color)"></span>
  </div>
</div>

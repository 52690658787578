import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';
import { AgendaTranslationModel } from '@components/agenda/models/agenda-translation.model';
import { AgendaTicketModel } from '@components/agenda/models/agenda-ticket.model';
import { TagApiModel } from '@store/features/tags/tags.models';

export const loadAgendaSessions = createAction(
  '[Agenda/Agenda Component] Load Agenda Sessions',
  props<{ event: EventModel, component: ComponentModel, loadGroups: boolean }>()
);

export const loadAgendaSessionsSuccess = createAction(
  '[Agenda/API] Load Agenda Sessions Success',
  props<{ sessions: AgendaSessionModel[] }>()
);

export const loadAgendaSessionsFailure = createAction(
  '[Agenda/API] Load Agenda Sessions Failure',
  props<{ error: Error }>()
);

export const createAgendaSession = createAction(
  '[Agenda/Agenda Component] Create Agenda Session',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel, translations: AgendaTranslationModel[], tickets: AgendaTicketModel[], userGroupTags: TagApiModel[] }>()
);

export const createAgendaSessionSuccess = createAction(
  '[Agenda/API] Create Agenda Session Success',
  props<{ session: AgendaSessionModel, translations: AgendaTranslationModel[], tickets: AgendaTicketModel[] }>()
);

export const createAgendaSessionFailure = createAction(
  '[Agenda/API] Create Agenda Session Failure',
  props<{ error: Error }>()
);

export const updateAgendaSession = createAction(
  '[Agenda/Agenda Component] Update Agenda Session',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel, translations: AgendaTranslationModel[], tickets: AgendaTicketModel[], userGroupTags: TagApiModel[] }>()
);

export const updateAgendaSessionSuccess = createAction(
  '[Agenda/API] Update Agenda Session Success',
  props<{ session: AgendaSessionModel, translations: AgendaTranslationModel[], tickets: AgendaTicketModel[] }>()
);

export const updateAgendaSessionFailure = createAction(
  '[Agenda/API] Update Agenda Session Failure',
  props<{ error: Error }>()
);

export const changeAgendaSessionDiscussion = createAction(
  '[Agenda/Agenda Component] Change Agenda Session Discussion',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel, discussionOn: boolean }>()
);

export const changeAgendaSessionDiscussionSuccess = createAction(
  '[Agenda/API] Change Agenda Session Discussion Success',
  props<{ session: AgendaSessionModel }>()
);

export const changeAgendaSessionDiscussionFailure = createAction(
  '[Agenda/API] Change Agenda Session Discussion Failure',
  props<{ error: Error }>()
);

export const changeAgendaSessionFeedback = createAction(
  '[Agenda/Agenda Component] Change Agenda Session Feedback',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel, enabled: boolean }>()
);

export const changeAgendaSessionFeedbackSuccess = createAction(
  '[Agenda/API] Change Agenda Session Feedback Success',
  props<{ session: AgendaSessionModel }>()
);

export const changeAgendaSessionFeedbackFailure = createAction(
  '[Agenda/API] Change Agenda Session Feedback Failure',
  props<{ error: Error }>()
);

export const rescheduleAgendaSession = createAction(
  '[Agenda/Agenda Component] Reschedule Agenda Session',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel, timeStart: string, timeEnd: string, orderedSessions: AgendaSessionModel[] }>()
);

export const rescheduleAgendaSessionSuccess = createAction(
  '[Agenda/API] Reschedule Agenda Session Success',
  props<{ session: AgendaSessionModel }>()
);

export const rescheduleAgendaSessionFailure = createAction(
  '[Agenda/API] Reschedule Agenda Session Failure',
  props<{ error: Error }>()
);

export const rescheduleAgendaChildSession = createAction(
  '[Agenda/Agenda Component] Reschedule Agenda Child Session',
  props<{ event: EventModel, component: ComponentModel, parentSession: AgendaSessionModel, session: AgendaSessionModel, timeStart: string, timeEnd: string, orderedSessions: AgendaSessionModel[] }>()
);

export const rescheduleAgendaChildSessionSuccess = createAction(
  '[Agenda/API] Reschedule Agenda Child Session Success',
  props<{ session: AgendaSessionModel }>()
);

export const rescheduleAgendaChildSessionFailure = createAction(
  '[Agenda/API] Reschedule Agenda Child Session Failure',
  props<{ error: Error }>()
);

export const deleteAgendaSession = createAction(
  '[Agenda/Agenda Component] Delete Agenda Session',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel }>()
);

export const deleteAgendaSessionSuccess = createAction(
  '[Agenda/API] Delete Agenda Session Success',
  props<{ session: AgendaSessionModel }>()
);

export const deleteAgendaSessionFailure = createAction(
  '[Agenda/API] Delete Agenda Session Failure',
  props<{ error: Error }>()
);

export const selectAgendaSession = createAction(
  '[Agenda/Session] Select Agenda Session',
  props<{ session: AgendaSessionModel }>()
);

export const changeAgendaSessionsDiscussion = createAction(
  '[Agenda/Component] Change Agenda Sessions Discussion',
  props<{ event: EventModel, component: ComponentModel, enabled: boolean }>()
);

export const changeAgendaSessionsDiscussionSuccess = createAction(
  '[Agenda/API] Change Agenda Sessions Discussion Success',
  props<{ enabled: boolean }>()
);

export const changeAgendaSessionsDiscussionFailure = createAction(
  '[Agenda/API] Change Agenda Sessions Discussion Failure',
  props<{ error: Error }>()
);

export const changeAgendaSessionsFeedback = createAction(
  '[Agenda/Component] Change Agenda Sessions Feedback',
  props<{ event: EventModel, component: ComponentModel, enabled: boolean }>()
);

export const changeAgendaSessionsFeedbackSuccess = createAction(
  '[Agenda/API] Change Agenda Sessions Feedback Success',
  props<{ enabled: boolean }>()
);

export const changeAgendaSessionsFeedbackFailure = createAction(
  '[Agenda/API] Change Agenda Sessions Feedback Failure',
  props<{ error: Error }>()
);

export const changeAgendaSessionsDefault = createAction(
  '[Agenda/API] Change Agenda Sessions Default',
  props<{ agendaComponents: ComponentModel[], target: ComponentModel }>()
);

export const changeAgendaSessionsDefaultSuccess = createAction(
  '[Agenda/API] Change Agenda Sessions Default Success',
  props<{ component: ComponentModel }>()
);

export const changeAgendaSessionsDefaultFailure = createAction(
  '[Agenda/API] Change Agenda Sessions Default Failure',
  props<{ error: Error }>()
);

export const changeAgendaSessionsOrder = createAction(
  '[Agenda/API] Change Agenda Sessions Order',
  props<{ sessions: AgendaSessionModel[] }>()
)

export const changeAgendaSessionsOrderSuccess = createAction(
  '[Agenda/API] Change Agenda Sessions Order Success',
  props<{ sessions: AgendaSessionModel[] }>()
)

export const changeAgendaSessionsOrderFailure = createAction(
  '[Agenda/API] Change Agenda Sessions Order Failure',
  props<{ error: Error }>()
);

export const changeAgendaChildSessionsOrder = createAction(
  '[Agenda/API] Change Agenda Child Sessions Order',
  props<{ parentSession: AgendaSessionModel, sessions: AgendaSessionModel[] }>()
)

export const changeAgendaChildSessionsOrderSuccess = createAction(
  '[Agenda/API] Change Agenda Child Sessions Order Success',
  props<{ parentSession: AgendaSessionModel, sessions: AgendaSessionModel[] }>()
)

export const changeAgendaChildSessionsOrderFailure = createAction(
  '[Agenda/API] Change Agenda Child Sessions Order Failure',
  props<{ error: Error }>()
);

export const updateSessionParentIdStatus = createAction(
  '[Agenda/API] Update Session Parent Id Status',
  props<{ session: AgendaSessionModel, status: number | null }>()
);
import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[appFileDrag]'
})
export class FileDragDirective {

    @Input()
    private allowed_extensions: Array<string> = [];

    @Output()
    private fileChangeEmitter: EventEmitter<File> = new EventEmitter();

    @Output()
    private fileInvalidEmitter: EventEmitter<File> = new EventEmitter();

    @HostBinding('style.background')
    private background = '#eee';

    constructor() {
    }

    @HostListener('dragover', ['$event'])
    public onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#999999';
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#eee';
    }

    @HostListener('drop', ['$event'])
    public onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#eee';

        const files = evt.dataTransfer.files;



        if (files.length > 0) {
            const file: File = files[0];
            const ext = file.name.split('.')[file.name.split('.').length - 1].toUpperCase();

            // png -> PNG
            this.allowed_extensions = this.allowed_extensions.map(a => a.toUpperCase());

            if (this.allowed_extensions.lastIndexOf(ext) !== -1) {
                this.fileChangeEmitter.emit(file);
            } else {
                this.fileInvalidEmitter.emit(file);
            }
        }
    }

    @HostListener('change', ['$event'])
    public onChange(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#eee';

        const files = evt.target.files;

        if (files.length > 0) {
            const file: File = files[0];
            const ext = file.name.split('.')[file.name.split('.').length - 1];


            if (this.allowed_extensions.lastIndexOf(ext) !== -1) {
                this.fileChangeEmitter.emit(file);
            } else {
                this.fileInvalidEmitter.emit(file);
            }
        }
    }

}

<div class="input-group">
  <div class="input-group-prepend">
    <div class="input-group-text">
      <img src="/assets/icons/loupe.svg" alt="Search">
    </div>
  </div>
  <input appAutofocus [formControl]="searchControl" class="form-control" type="text">
</div>

<div class="user-dropdown-content">
  <div cdkScrollable class="user-select">
    <ng-container *ngIf="isLoading$ | async; else loadFinished">
      <div class="progress-container">
        <app-spinner-progress [color]="primary"></app-spinner-progress>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loadFinished>
  <ng-container *ngIf="hasEventUsers$ | async; else noEventUsers">
    <div *ngFor="let user of eventUsers$ | async" class="user-select-option" (click)="selectUser.emit(user)">
      <app-avatar [picture]="user.picture" [firstName]="user.firstName" [lastName]="user.lastName"></app-avatar>
      <p>{{ user.firstName }} {{ user.lastName }}</p>
    </div>
  </ng-container>

  <ng-template #noEventUsers>
    <div class="no-results">
      <p>{{ 'user_select.no_users' | translate }}</p>
    </div>
  </ng-template>
</ng-template>

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollectionBaseService } from '@shared/providers/collection-base.service';

import { SurveyQuizModel } from '@components/survey-quiz/models/survey-quiz.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyQuizStoreService extends CollectionBaseService<SurveyQuizModel> {
  constructor() {
    super([]);
  }

  getOrderedSurveyQuizzes(): Observable<SurveyQuizModel[]> {
    const sortByOrder = (surveyQuizzes: SurveyQuizModel[]) => {
      return surveyQuizzes.sort((a, b) => a.order - b.order);
    };

    return this.getSurveyQuizzes().pipe(
      map(surveyQuizzes => sortByOrder(surveyQuizzes))
    );
  }

  getSurveyQuizzes(): Observable<SurveyQuizModel[]> {
    return this.getEntities();
  }

  updateEntity(entity: SurveyQuizModel) {
    super.updateEntity(entity, (a, b) => (a.id === b.id));
  }

  removeEntity(entity: SurveyQuizModel) {
    super.removeEntity(entity, (a, b) => (a.id === b.id));
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormApiModel, FormPatchApiModel, FormPostApiModel } from "../models/form.api-models";
import { API_BASE_URL } from "@env/environment";
import { Observable } from "rxjs";
import { FormActionDeleteModel, FormActionGetModel, FormActionUpdateModel, FormActionReorderModel } from "../models/form.action-models";

@Injectable({
  providedIn: 'root'
})
export class FormApiService {
  constructor(private http: HttpClient) {}

  getAll(eventId: number, componentId: number): Observable<FormApiModel[]> {
    return this.http.get<FormApiModel[]>(`${buildBaseUrl(eventId, componentId)}/forms/admin`);
  }

  get(args: FormActionGetModel, eventId: number, componentId: number): Observable<FormApiModel> {
    const { lang, id } = args;
    const headers = { 'CONTENT_LANGUAGE': lang.code, 'Accept-Language': lang.code };
    return this.http.get<FormApiModel>(`${buildBaseUrl(eventId, componentId)}/form/${id}/admin`, { headers });
  }

  create(payload: FormPostApiModel, eventId: number, componentId: number): Observable<FormApiModel> {
    return this.http.post<FormApiModel>(`${buildBaseUrl(eventId, componentId)}/form`, payload);
  }

  update(args: FormActionUpdateModel, payload: FormPatchApiModel, eventId: number, componentId: number): Observable<FormApiModel> {
    const { lang, id } = args;
    const headers = { 'CONTENT_LANGUAGE': lang.code, 'Accept-Language': lang.code };
    return this.http.patch<FormApiModel>(`${buildBaseUrl(eventId, componentId)}/form/${id}`, payload, { headers });
  }

  reorder(args: FormActionReorderModel, eventId: number, componentId: number): Observable<FormApiModel[]> {
    const { entities, } = args;
    return this.http.patch<FormApiModel[]>(`${buildBaseUrl(eventId, componentId)}/forms/order`, { orders: entities.map(e => e.id) });
  }

  delete(args: FormActionDeleteModel, eventId: number, componentId: number): Observable<void> {
    const { id } = args;
    return this.http.delete<void>(`${buildBaseUrl(eventId, componentId)}/form/${id}`);
  }
}

function buildBaseUrl(eventId: number, componentId: number): string {
  return `${API_BASE_URL}/events/${eventId}/components/${componentId}`;
}
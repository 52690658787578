import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';
import { AgendaTranslationModel } from '@components/agenda/models/agenda-translation.model';

export const resetAgendaTranslations = createAction(
  '[Agenda/Agenda Modal] Reset Agenda Translations'
);

export const loadAgendaTranslation = createAction(
  '[Agenda/Agenda Modal] Load Agenda Translation',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel, language: string }>()
);

export const loadAgendaTranslationSuccess = createAction(
  '[Agenda/API] Load Agenda Translation Success',
  props<{ translation: AgendaTranslationModel }>()
);

export const loadAgendaTranslationFailure = createAction(
  '[Agenda/API] Load Agenda Translation Failure',
  props<{ error: Error }>()
);

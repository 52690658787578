import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { EventTranslationModel } from '../models/event-translation.model';

import * as EventActions from '../actions/event.actions';
import * as EventTranslationActions from '../actions/event-translation.actions';
import { clearEventState } from '../actions/event.actions';

export const featureKey = 'eventTranslation';

export interface State extends EntityState<EventTranslationModel> {
  loading: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<EventTranslationModel>({
  selectId: translation => translation.language,
  sortComparer: false,
});

export const initialState: State = {
  ...adapter.getInitialState(),
  loading: false,
  error: null,
};

export const eventTranslationReducer = createReducer<State>(
  initialState,
  on(
    EventActions.selectEvent,
    state => ({ ...initialState })
  ),
  on(
    EventTranslationActions.loadEventTranslation,
    state => ({ ...state, loading: true, error: null })
  ),
  on(
    EventTranslationActions.loadEventTranslationSuccess,
    (state, { translation }) => adapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    EventTranslationActions.loadEventTranslationFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    EventActions.updateEventSuccess,
    (state, { translations }) => adapter.upsertMany(translations, state)
  ),

  on(clearEventState, (state) => ({ ...initialState })),
);

export function reducer(state: State, action: Action): State {
  return eventTranslationReducer(state, action);
}

const {
  selectIds,
  selectEntities,
  selectAll,
} = adapter.getSelectors();

export const selectEventTranslationIds = selectIds;

export const selectEventTranslationEntities = selectEntities;

export const selectAllEventTranslations = selectAll;

export const selectEventTranslationLoading = (state: State) => state.loading;

export const selectEventTranslationError = (state: State) => state.error;

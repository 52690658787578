import {Action, createReducer, on} from "@ngrx/store";
import {ConsentSchemaModel} from "../models/consent-schema.model";
import {
  createConsentEventSchema,
  createConsentEventSchemaFailure,
  createConsentEventSchemaSuccess,
  createConsentSchemaTranslation,
  createConsentSchemaTranslationFailure,
  createConsentSchemaTranslationSuccess,
  loadConsentEventSchemas,
  loadConsentEventSchemasFailure,
  loadConsentEventSchemasSuccess,
  loadConsentSchemaTranslations,
  loadConsentSchemaTranslationsFailure,
  loadConsentSchemaTranslationsSuccess, publishConsentSchema, publishConsentSchemaFailure, publishConsentSchemaSuccess,
  saveExistingConsentSchema,
  saveExistingConsentSchemaFailure,
  saveExistingConsentSchemaSuccess,
  saveNewConsentSchema,
  saveNewConsentSchemaFailure,
  saveNewConsentSchemaSuccess, unPublishConsentSchema,
  unPublishConsentSchemaFailure, unPublishConsentSchemaSuccess,
  updateConsentEventSchema,
  updateConsentEventSchemaFailure,
  updateConsentEventSchemaSuccess,
  updateConsentSchemaTranslation,
  updateConsentSchemaTranslationFailure,
  updateConsentSchemaTranslationSuccess
} from "../actions/consent-event.actions";
import {clearEventState, selectEvent} from "@store/features/event/actions";
import {ConsentSchemaTranslationModel} from "../models/consent-schema-translation.model";

export const childKey = 'consentsEvent';

// key = consent schema id
export type ConsentEventTranslationState = { [key: number]: ConsentSchemaTranslationModel[] };

export interface State {
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  creating: boolean;
  updating: boolean;
  translationsLoading: boolean;
  translationsCreating: boolean;
  translationsUpdating: boolean;
  error: Error | null;
  entities: ConsentSchemaModel[];
  translations: ConsentEventTranslationState;
  publishing: { [key: number]: boolean }; // Track publishing status per schema
  publishingErrors: { [key: number]: Error | null }; // Track publishing errors per schema
  unpublishing: { [key: number]: boolean }; // Track unpublishing status per schema
  unpublishingErrors: { [key: number]: Error | null }; // Track unpublishing errors per schema

}

export const initialState: State = {
  loading: false,
  loaded: false,
  saving: false,
  creating: false,
  updating: false,
  translationsLoading: false,
  translationsCreating: false,
  translationsUpdating: false,
  error: null,
  entities: [],
  translations: {},
  publishing: {},
  publishingErrors: {},
  unpublishing: {},
  unpublishingErrors: {},
}

export const consentEventReducer = createReducer(
  initialState,
  on(
    loadConsentEventSchemas,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    loadConsentEventSchemasSuccess,
    (state, {entities}) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      entities,
    })
  ),
  on(
    loadConsentEventSchemasFailure,
    (state, {error}) => ({
      ...state,
      loading: false,
      loaded: true,
      error,
    })
  ),

  on(
    createConsentEventSchema,
    (state) => ({
      ...state,
      creating: true,
    })
  ),

  on(
    createConsentEventSchemaSuccess,
    (state, {entity}) => ({
      ...state,
      creating: false,
      entities: [...state.entities, entity]
    })
  ),

  on(
    createConsentEventSchemaFailure,
    (state, {error}) => ({
      ...state,
      creating: false,
      error,
    })
  ),

  on(
    updateConsentEventSchema,
    (state) => ({
      ...state,
      updating: true,
    })
  ),

  on(
    updateConsentEventSchemaSuccess,
    (state, {entity}) => ({
      ...state,
      updating: false,
      entities: [...state.entities.map(e => e.id === entity.id ? entity : e)]
    })
  ),

  on(
    updateConsentEventSchemaFailure,
    (state, {error}) => ({
      ...state,
      updating: false,
      error,
    })
  ),

  on(
    loadConsentSchemaTranslations,
    (state) => ({
      ...state,
      translationsLoading: true,
    })
  ),

  on(
    loadConsentSchemaTranslationsSuccess,
    (state, {schema, entities}) => ({
      ...state,
      translationsLoading: false,
      error: null,
      translations: {
        ...state.translations,
        [schema.id]: entities,
      }
    })
  ),

  on(
    loadConsentSchemaTranslationsFailure,
    (state, {error}) => ({
      ...state,
      translationsLoading: false,
      error,
    })
  ),

  on(
    createConsentSchemaTranslation,
    (state) => ({
      ...state,
      translationsCreating: true,
    })
  ),

  on(
    createConsentSchemaTranslationSuccess,
    (state, {schema, entity}) => ({
      ...state,
      translationsCreating: false,
      error: null,
      translations: {
        ...state.translations,
        [schema.id]: [
          ...(state.translations[schema.id] || []),
          entity,
        ]
      }
    })
  ),

  on(
    createConsentSchemaTranslationFailure,
    (state, {error}) => ({
      ...state,
      translationsCreating: false,
      error,
    })
  ),

  on(
    updateConsentSchemaTranslation,
    (state) => ({
      ...state,
      translationsUpdating: true,
    })
  ),

  on(
    updateConsentSchemaTranslationSuccess,
    (state, {schema, entity}) => ({
      ...state,
      translationsUpdating: false,
      error: null,
      translations: {
        ...state.translations,
        [schema.id]: [
          ...state.translations[schema.id]?.map(t => t.id === entity.id ? ({...entity}) : t),
        ]
      }
    })
  ),

  on(
    updateConsentSchemaTranslationFailure,
    (state, {error}) => ({
      ...state,
      translationsUpdating: false,
      error,
    })
  ),

  on(
    saveNewConsentSchema,
    saveExistingConsentSchema,
    (state) => ({
      ...state,
      saving: true,
    })
  ),
  on(
    saveNewConsentSchemaSuccess,
    saveExistingConsentSchemaSuccess,
    (state) => ({
      ...state,
      saving: false,
      error: null,
    })
  ),
  on(
    saveNewConsentSchemaFailure,
    saveExistingConsentSchemaFailure,
    (state, {error}) => ({
      ...state,
      saving: false,
      error,
    })
  ),

  on(
    publishConsentSchema,
    (state, {consentSchemaId}) => ({
      ...state,
      publishing: {
        ...state.publishing,
        [consentSchemaId]: true
      },
      publishingErrors: {
        ...state.publishingErrors,
        [consentSchemaId]: null
      }
    })
  ),

  on(
    publishConsentSchemaSuccess,
    (state, {consentSchemaId}) => ({
      ...state,
      publishing: {
        ...state.publishing,
        [consentSchemaId]: false
      },
      entities: state.entities.map(entity =>
        entity.id === consentSchemaId
          ? {...entity, published: true}
          : entity
      )
    })
  ),

  on(
    publishConsentSchemaFailure,
    (state, {consentSchemaId, error}) => ({
      ...state,
      publishing: {
        ...state.publishing,
        [consentSchemaId]: false
      },
      publishingErrors: {
        ...state.publishingErrors,
        [consentSchemaId]: error
      }
    })
  ),

  on(
    unPublishConsentSchema,
    (state, {consentSchemaId}) => ({
      ...state,
      unpublishing: {
        ...state.unpublishing,
        [consentSchemaId]: true
      },
      unpublishingErrors: {
        ...state.unpublishingErrors,
        [consentSchemaId]: null
      }
    })
  ),

  on(
    unPublishConsentSchemaSuccess,
    (state, {consentSchemaId}) => ({
      ...state,
      unpublishing: {
        ...state.unpublishing,
        [consentSchemaId]: false
      },
      entities: state.entities.map(entity =>
        entity.id === consentSchemaId
          ? {...entity, published: false}
          : entity
      )
    })
  ),

  on(
    unPublishConsentSchemaFailure,
    (state, {consentSchemaId, error}) => ({
      ...state,
      unpublishing: {
        ...state.unpublishing,
        [consentSchemaId]: false
      },
      unpublishingErrors: {
        ...state.unpublishingErrors,
        [consentSchemaId]: error
      }
    })
  ),

  // reset state
  on(
    selectEvent,
    clearEventState,
    () => initialState,
  ),
);

export function reducer(state: State, action: Action): State {
  return consentEventReducer(state, action);
}

export const selectLoading = (state: State) => state.loading;
export const selectLoaded = (state: State) => state.loaded;
export const selectSaving = (state: State) => state.saving;
export const selectCreating = (state: State) => state.creating;
export const selectUpdating = (state: State) => state.updating;
export const selectTranslationsLoading = (state: State) => state.translationsLoading;
export const selectTranslationsCreating = (state: State) => state.translationsCreating;
export const selectTranslationsUpdating = (state: State) => state.translationsUpdating;
export const selectError = (state: State) => state.error;
export const selectEntities = (state: State) => state.entities;
export const selectTranslations = (state: State) => state.translations;
export const selectSchemaTranslations = (state: State, schemaId: number) => state.translations[schemaId] || null;
export const selectPublishing = (state: State, schemaId: number) =>
  state.publishing[schemaId] || false;
export const selectPublishingError = (state: State, schemaId: number) =>
  state.publishingErrors[schemaId] || null;
export const selectUnpublishing = (state: State, schemaId: number) =>
  state.unpublishing[schemaId] || false;
export const selectUnpublishingError = (state: State, schemaId: number) =>
  state.unpublishingErrors[schemaId] || null;

import { createAction, props } from "@ngrx/store";
import { AudioVisualModel } from "../models/audio-visual.models";
import { AudioVisualFileTranslationModel, AudioVisualTranslationModel } from "../models/audio-visual-translation.models";
import { EventLanguageModel } from "@store/features/event/models/event-language.model";

const loadAudioVisualsTranslation = createAction(
  '[AudioVisual/Translation] Load Audio Visual Translation',
  props<{ language: string }>()
);

const loadAudioVisualsTranslationSuccess = createAction(
  '[AudioVisual/Translation] Load Audio Visual Translation Success',
  props<{ translations: AudioVisualTranslationModel[], language: string }>()
);

const loadAudioVisualsTranslationFailure = createAction(
  '[AudioVisual/Translation] Load Audio Visual Translation Failure',
  props<{ error: Error }>()
);

const updateAudioVisualTranslation = createAction(
  '[AudioVisual/Translation] Update Audio Visual Translations',
  props<{ audioVisual: AudioVisualModel, language: string }>()
);

const updateAudioVisualTranslationSuccess = createAction(
  '[AudioVisual/Translation] Update Audio Visual Translations Success',
  props<{ translation: AudioVisualTranslationModel, language: string }>()
);

const updateAudioVisualTranslationFailure = createAction(
  '[AudioVisual/Translation] Update Audio Visual Translations',
  props<{ error: Error }>()
);

const loadAudioVisualFilesTranslation = createAction(
  '[AudioVisual/Translation] Load Audio Visual Files Translation',
  props<{ audioVisual: AudioVisualModel, language: string }>()
);

const loadAudioVisualFilesTranslationSuccess = createAction(
  '[AudioVisual/Translation] Load Audio Visual Files Translation Success',
  props<{ audioVisual: AudioVisualModel, translations: AudioVisualFileTranslationModel[], language: string }>()
);

const loadAudioVisualFilesTranslationFailure = createAction(
  '[AudioVisual/Translation] Load Audio Visual Files Translation Failure',
  props<{ error: Error }>()
);

const setActiveLanguage = createAction(
  '[AudioVisual/Translation] Set Active Language',
  props<{ language: EventLanguageModel }>()
);

const resetFilesTranslations = createAction(
  '[Audio Visuals] Reset File Translations',
);

export const AudioVisualTranslationAction = {
  loadAudioVisualsTranslation,
  loadAudioVisualsTranslationSuccess,
  loadAudioVisualsTranslationFailure,

  updateAudioVisualTranslation,
  updateAudioVisualTranslationSuccess,
  updateAudioVisualTranslationFailure,

  loadAudioVisualFilesTranslation,
  loadAudioVisualFilesTranslationSuccess,
  loadAudioVisualFilesTranslationFailure,

  setActiveLanguage,
  resetFilesTranslations,
}
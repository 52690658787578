import { Component, Input, forwardRef } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormArray} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'domain-chips-input',
  templateUrl: './domain-chips-input.component.html',
  styleUrls: ['./domain-chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DomainChipsInputComponent),
      multi: true,
    },
  ],
})
export class DomainChipsInputComponent implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() highlighted: string[] = [];

  tags: string[] = [];
  newTag: string = '';
  errorMessage: string = '';
  isInputInvalid: boolean = false;

  onChange = (tags: string[]) => {};
  onTouched = () => {};

  private readonly domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  constructor(private i18n: TranslateService) {}

  validateDomain(domain: string): boolean {
    if (!domain) return false;

    const trimmedDomain = domain.trim().toLowerCase();
    if (trimmedDomain.length < 4) {
      this.errorMessage = this.i18n.instant('access_configuration.whitelist_min_length');
      return false;
    }

    if (!trimmedDomain.includes('.')) {
      this.errorMessage = this.i18n.instant('access_configuration.whitelist_dot_required');
      return false;
    }

    if (!this.domainRegex.test(trimmedDomain)) {
      this.errorMessage = this.i18n.instant('access_configuration.whitelist_invalid_format');
      return false;
    }

    this.errorMessage = '';
    return true;
  }

  addTag(): void {
    const processedTag = this.newTag.trim().toLowerCase();

    if (processedTag && !this.tags.includes(processedTag) && this.validateDomain(processedTag)) {
      this.tags = [...this.tags, processedTag];
      this.newTag = '';
      this.isInputInvalid = false;
      this.onChange(this.tags);
    } else if (processedTag && this.tags.includes(processedTag)) {
      this.errorMessage = this.i18n.instant('access_configuration.whitelist_already_exists');
      this.isInputInvalid = true;
    } else {
      this.isInputInvalid = true;
    }
  }

  removeTag(tag: string): void {
    this.tags = this.tags.filter(t => t !== tag);
    this.onChange(this.tags);
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.addTag();
      event.preventDefault();
    }
  }

  handleInput(): void {
    // Reset error on input change
    this.isInputInvalid = false;
    this.errorMessage = '';
  }

  handleBlur(): void {
    this.onTouched();
    if (this.newTag.trim()) {
      this.addTag();
    }
  }

  isTagHighlighted(tag: string): boolean {
    return this.highlighted.includes(tag);
  }

  writeValue(tags: string[]): void {
    if (tags) {
      this.tags = tags;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

import { ChangePasswordModel } from './../../account/models/change-password.model';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { ProfileModel } from '@app/backend/models/profile.model';

import * as profileSelectors from '@app/backend/store/profile.selectors';
import * as profileActions from '@app/backend/store/profile.actions';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private store$: Store<any>) {}

  getLoading(): Observable<boolean> {
    return this.store$.pipe(select(profileSelectors.selectLoading));
  }

  getError(): Observable<Error> {
    return this.store$.pipe(select(profileSelectors.selectError));
  }

  getIsUpdating(): Observable<boolean> {
    return this.store$.pipe(select(profileSelectors.selectPasswordUpdating));
  }

  getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(profileSelectors.selectLoaded));
  }

  getProfile(): Observable<ProfileModel> {
    return this.store$.pipe(select(profileSelectors.selectProfile));
  }

  loadProfile() {
    this.store$.dispatch(profileActions.loadProfile());
  }

  changeProfilePicture(picture: File) {
    this.store$.dispatch(profileActions.changeProfilePicture({ picture }));
  }

  changePassword(payload: ChangePasswordModel) {
    this.store$.dispatch(profileActions.changePassword({ payload }));
  }

  signOut() {
    this.store$.dispatch(profileActions.signOut());
  }
}

import { ConsentSchemaTranslationApiModel, ConsentSchemaTranslationCreateApiModel, ConsentSchemaTranslationUpdateApiModel } from "../api/consent-schema-translation.api-model"
import { ConsentSchemaTranslationCreateModel, ConsentSchemaTranslationModel, ConsentSchemaTranslationUpdateModel } from "../consent-schema-translation.model"
import { mapConsentDisplayConfigCreateModel, mapConsentDisplayConfigModel, mapConsentDisplayConfigUpdateModel } from "./consent-display.mapper"

export const mapConsentSchemaTranslationModel = (model: ConsentSchemaTranslationApiModel): ConsentSchemaTranslationModel => {
  return {
    id: model.id,
    locale: model.locale,
    consentCheckboxConfig: mapConsentDisplayConfigModel(model.consent_checkbox_config),
    fileUrl: model.file_url,
    fileExtension: model.file_extension,
    fileName: model.file_name,
  }
}

export const mapConsentSchemaTranslationCreateModel = (model: ConsentSchemaTranslationCreateModel): ConsentSchemaTranslationCreateApiModel => {
  return {
    locale: model.locale,
    consent_checkbox_config: mapConsentDisplayConfigCreateModel(model.consentCheckboxConfig),
    copy_file_from_previous_version: model.copyFileFromPreviousVersion,
    file: model.file,
  }
}

export const mapConsentSchemaTranslationUpdateModel = (model: ConsentSchemaTranslationUpdateModel): ConsentSchemaTranslationUpdateApiModel => {
  return {
    id: model.id,
    locale: model.locale,
    consent_checkbox_config: mapConsentDisplayConfigUpdateModel(model.consentCheckboxConfig),
    file: model.file,
  }
}

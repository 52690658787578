import { createAction, props } from '@ngrx/store';

import { ComponentPlanModel } from '@app/construct-steps/models/component-plan.model';
import { ComponentDefinitionModel } from '@app/construct-steps/models/component-definition.model';

export const loadComponentPlans = createAction(
  '[Component/Constructor] Load Component Plans'
);

export const loadComponentPlansSuccess = createAction(
  '[Component/API] Load Component Plans Success',
  props<{ plans: ComponentPlanModel[], definitions: ComponentDefinitionModel[] }>()
);

export const loadComponentPlansFailure = createAction(
  '[Component/API] Load Component Plans Failure',
  props<{ error: Error }>()
);

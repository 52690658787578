<div class="graphic-item position-relative" appFileDrag (fileChangeEmitter)="onFilesChange($event)"
  (fileInvalidEmitter)="onFileInvalids($event)" [allowed_extensions]="allowedFileTypes">

  <img *ngIf="state == statePending" class="upload-icon" src="./assets/img/icons/upload.svg" alt="">
  <span *ngIf="state == statePending" class="dnd">{{ 'file_uploader.drag_and_drop' | translate }} <br></span>
  <a *ngIf="state == statePending" style="color: #00aeef" (click)="fileInp.click()" class="btn btn-link">
    {{ 'file_uploader.browse_for_files' | translate }}
  </a>

  <div *ngIf="state == stateUploading" style="padding: 10px; height: 100%;background: #fafbfb">
    <img width="60" src="./assets/img/file-types/jpeg.svg" style="height: 65%">

    <div>
      <span class="file-title">{{fileName}}</span>
      <span class="size-progress">{{progress}}% {{ 'file_uploader.uploading_caps' | translate }}</span>
      <div style="display: flex">
        <mat-progress-bar class="mt-2" mode="determinate" [value]="progress"></mat-progress-bar>
        <img src="./assets/img/icons/delete-cross.svg" width="12"
          style="cursor: pointer;margin-left: 5px;margin-right: 2px;" (click)="cancel()" />
      </div>
    </div>

  </div>

  <img *ngIf="state == stateDone" id="img_container" [src]="imageSrc" [ngClass]="{'rounded-pic' : roundedPic, 'uploaded' : !roundedPic}" alt="">
  <div *ngIf="state == stateDone && svgContent" id="svg_container" [innerHTML]="svgContent"></div>
  <button *ngIf="state == stateDone" class="btn btn-light" (click)="fileInp.click()">{{ 'file_uploader.change' | translate }}</button>

  <input type="file" style="display: none;" #fileInp appFileDrag (fileChangeEmitter)="onFilesChange($event)"
    (fileInvalidEmitter)="onFileInvalids($event)" [allowed_extensions]="allowedFileTypes" />

  <div class="custom-button delete-button" *ngIf="state == stateDone" (click)="remove()">
    <app-icon icon="/assets/icons/delete.svg"></app-icon>
  </div>
  
  <div class="custom-button download-button" *ngIf="state == stateDone" (click)="openFullResolution()">
    <app-icon icon="/assets/icons/zoom-in.svg"></app-icon>
  </div>

  <div class="custom-button crop-button" *ngIf="(state == stateDone) && cropper && !svg" (click)="crop()">
    <app-icon icon="/assets/icons/crop.svg"></app-icon>
  </div>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ValidationMessageType } from '../validation-message/validation-message.component';
import { Observable, Subscription } from 'rxjs';
import { ImportConfigModel, ImportConfigPatchModel } from '@store/features/import/import.models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImportProviderService } from '@store/features/import/import-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, first } from 'rxjs/operators';
import { EventModel } from '@store/features/event/models/event.model';

@Component({
  selector: 'app-sync-import-dialog',
  templateUrl: './sync-import-dialog.component.html',
  styleUrls: ['./sync-import-dialog.component.scss']
})
export class SyncImportDialogComponent implements OnInit, OnDestroy {

  public ValidationMessageType = ValidationMessageType;

  event: EventModel;

  loading$: Observable<boolean>;
  updating$: Observable<boolean>;
  importing$: Observable<boolean>;
  config$: Observable<ImportConfigModel>;
  error$: Observable<Error | null>;
  syncError$: Observable<Error | null>;

  submitted = false;
  isSaveEnabled = false;
  success = false;

  
  form: FormGroup = new FormGroup({
    spreadsheetId: new FormControl(null, Validators.required),
    enabled: new FormControl(false),
    endDate: new FormControl(null),
  });

  // to compare changes
  initialConfigForm: FormGroup = new FormGroup({
    spreadsheetId: new FormControl(null, Validators.required),
    enabled: new FormControl(false),
    endDate: new FormControl(null),
  });

  get enabled(): boolean {
    return this.form.controls['enabled'].value;
  }

  get isDisabled(): boolean {
    return !this.form.controls['enabled'].value;
  }

  get instruction(): string[] {
    let points: string[] = [];
    points.push(this.i18n.instant('import_sync_dialog.instruction_p1'));
    points.push(this.i18n.instant('import_sync_dialog.instruction_p2'));
    points.push(this.i18n.instant('import_sync_dialog.instruction_p3'));
    points.push(this.i18n.instant('import_sync_dialog.instruction_p4'));
    points.push(this.i18n.instant('import_sync_dialog.instruction_p5'));
    points.push(this.i18n.instant('import_sync_dialog.instruction_p6'));
    points.push(this.i18n.instant('import_sync_dialog.instruction_p7'));
    return points;
  }

  private subs = new Subscription();

  constructor(
    private importProvider: ImportProviderService,
    private i18n: TranslateService,
  ) {
    this.loading$ = this.importProvider.getLoading();
    this.updating$ = this.importProvider.getUpdating();
    this.importing$ = this.importProvider.getImporting();
    this.config$ = this.importProvider.getConfig();
    this.error$ = this.importProvider.getError();
    this.syncError$ = this.importProvider.getImportingError();
    this.importProvider.setError(null);
  }

  ngOnInit(): void {
    this.subs.add(this.config$.pipe(first()).subscribe(
      config => !config && this.importProvider.load()
    ));

    this.form.controls['endDate'].setValue(this.event.endDate);

    
    this.subs.add(this.syncError$
      .subscribe(error => {
        if (error) this.success = false;
      }));

    this.subs.add(this.config$
      .pipe(filter(conf => !!conf))
      .subscribe(conf => this.populateConf(conf)));

    this.subs.add(this.importProvider.onSyncSuccess.subscribe(
      () => this.success = true,
    ));

    this.subs.add(this.form.valueChanges.subscribe(() => {
      const curr = this.getConfigPatchModelFrom(this.form);
      const init = this.getConfigPatchModelFrom(this.initialConfigForm);
      this.isSaveEnabled = curr.enabled !== init.enabled || curr.endDate !== init.endDate || curr.spreadsheetId !== init.spreadsheetId;
    }));

    this.subs.add(this.form.controls['enabled'].valueChanges.subscribe(
      val => !!val ? this.form.controls['spreadsheetId'].enable() : this.form.controls['spreadsheetId'].disable()
    ));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onEnabledChange(checked: boolean): void {
    this.form.controls['enabled'].setValue(checked);
  }

  createGoogleSheetTemplate(): void {
    window.open('https://docs.google.com/spreadsheets/d/1rg6kwSPD9GOAonlJf9iJ50ViTUUHNJH_bRR6H4-tEiY/copy', '_blank');
  }

  sync(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.importProvider.sync();
  }

  updateConfig(): void {
    const payload: ImportConfigPatchModel = this.getConfigPatchModelFrom(this.form);
    this.importProvider.update(payload);
  }

  private getConfigPatchModelFrom(form: FormGroup): ImportConfigPatchModel {
    return {
      spreadsheetId: form.controls['spreadsheetId'].value,
      endDate: !!form.controls['endDate'].value ? (new Date(form.controls['endDate'].value) as Date).toDateString() : null,
      enabled: form.controls['enabled'].value,
    }
  }

  private populateConf(conf: ImportConfigModel): void {
    const payload: ImportConfigPatchModel = {
      spreadsheetId: conf.spreadsheetId,
      endDate: conf.endDate || this.event.endDate,
      enabled: conf.enabled,
    }
    this.initialConfigForm.patchValue(payload);
    this.form.patchValue(payload);
  }

}

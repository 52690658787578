import { NgModule } from '@angular/core';
import { CoreStoreModule } from './core-store.module';

import { QRCODE_BASE_URL } from '@shared/providers/qrcode.service';

const qrCodeBaseUrlProvider = {
  provide: QRCODE_BASE_URL,
  useValue: 'https://qr.meetingapplication.com/?qr_url=https://meetingapplication.com',
};

@NgModule({
  imports: [CoreStoreModule],
  providers: [qrCodeBaseUrlProvider],
})
export class CoreModule {}

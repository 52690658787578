import { Directive, ElementRef, Input, OnDestroy } from "@angular/core";
import { UserRoleEnum } from "@shared/enums/user-role.enum";
import { EventFacadeService } from "@store/features/event/event-facade.service";
import { EventModel } from "@store/features/event/models/event.model";
import { Observable, Subscription } from "rxjs";
import { first } from "rxjs/operators";

@Directive({
  selector: '[showForRole]'
})
export class ShowForRoleDirective implements OnDestroy {

  activeEvent$: Observable<EventModel>;

  private subs = new Subscription();
  private backedUpDisplay?: string;

  constructor(
    private domEl: ElementRef,
    private eventFacade: EventFacadeService,
  ) {
    this.activeEvent$ = this.eventFacade.getActiveEvent();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @Input() set showForRole(roles: UserRoleEnum[]) {
    this.activeEvent$
      .pipe(first(event => !!event))
      .subscribe(event => {
        const show = roles.some(role => role === event.userRole);
        this.modifyTemplate(show);
      });
  }

  private modifyTemplate(show: boolean): void {
    if (show) {
      this.domEl.nativeElement.style.display = this.backedUpDisplay;
    } else {
      if (this.domEl.nativeElement.style.display !== 'none') {
        this.backedUpDisplay = this.domEl.nativeElement.style.display;
        this.domEl.nativeElement.style.display = 'none';
      }
    }
  }
}
import { Action, createReducer, on } from "@ngrx/store";
import { ImportConfigModel } from "./import.models";
import { loadImportConfig, loadImportConfigFailure, loadImportConfigSuccess, setError, syncImport, syncImportFailure, syncImportSuccess, updateImportConfig, updateImportConfigFailure, updateImportConfigSuccess } from "./import.actions";
import { selectEvent } from "../event/actions";

export interface State {
  loading: boolean;
  updating: boolean;
  config: ImportConfigModel | null;
  importing: boolean;
  importingError: Error;
  error: Error;
}

export const initialState: State = {
  loading: false,
  updating: false,
  config: null,
  importing: false,
  importingError: null,
  error: null,
};

export const importReducer = createReducer(
  initialState,
  on(
    loadImportConfig,
    state => ({ ...state, loading: true })
  ),
  on(
    loadImportConfigSuccess,
    (state, { config }) => ({ ...state, loading: false, config, error: null })
  ),
  on(
    loadImportConfigFailure,
    (state, { error }) => ({ ...state, loading: false })
  ),

  on(
    updateImportConfig,
    state => ({ ...state, updating: true })
  ),
  on(
    updateImportConfigSuccess,
    (state, { config }) => ({ ...state, updating: false, error: null, config })
  ),
  on(
    updateImportConfigFailure,
    (state, { error }) => ({ ...state, updating: false, error, config: { ...state.config } })
  ),

  on(
    syncImport,
    (state) => ({ ...state, importing: true })
  ),
  on(
    syncImportSuccess,
    (state) => ({ ...state, importing: false, error: null })
  ),
  on(
    syncImportFailure,
    (state, { error }) => ({ ...state, importing: false, importingError: error })
  ),
  on(
    setError,
    (state, { error }) => ({ ...state, error, importingError: null })
  ),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return importReducer(state, action);
}

export const selectConfig = (state: State) => state.config;
export const selectLoading = (state: State) => state.loading;
export const selectUpdating = (state: State) => state.updating;
export const selectError = (state: State) => state.error;

export const selectImporting = (state: State) => state.importing;
export const selectImportingError = (state: State) => state.importingError;
import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { PriceTagModel } from '@app/construct-steps/models/price-tag.model';
import { SummaryModel } from '@app/construct-steps/models/summary.model';

export const loadSummary = createAction(
  '[Constructor/Event Load] Load Summary',
  props<{ event: EventModel }>()
);

export const refreshSummary = createAction(
  '[Constructor/Component Refresh] Refresh Summary',
  props<{ event: EventModel }>()
);

export const loadSummarySuccess = createAction(
  '[API/Event] Load Summary Success',
  props<{ summary: SummaryModel, priceTags: PriceTagModel[] }>()
);

export const loadSummaryFailure = createAction(
  '[API/Event] Load Summary Failure',
  props<{ error: Error }>()
);

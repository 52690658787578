import { ConsentSchemaVersionApiModel, ConsentSchemaVersionCreateApiModel, ConsentSchemaVersionUpdateApiModel } from "../api/consent-schema-version.api-model";
import { ConsentSchemaVersionCreateModel, ConsentSchemaVersionModel, ConsentSchemaVersionUpdateModel } from "../consent-schema-version.model";

export const mapConsentSchemaVersionModel = (model: ConsentSchemaVersionApiModel): ConsentSchemaVersionModel => {
  return {
    id: model.id,
    versionNumber: model.version_number,
    changelog: model.changelog,
  }
}

export const mapConsentSchemaVersionUpdateModel = (model: ConsentSchemaVersionUpdateModel): ConsentSchemaVersionUpdateApiModel => {
  return {
    changelog: model.changelog,
  }
}

export const mapConsentSchemaVersionCreateModel = (model: ConsentSchemaVersionCreateModel): ConsentSchemaVersionCreateApiModel => {
  return {
    changelog: model.changelog,
  }
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SpeakerModel } from '@components/speaker/models/speaker.model';

import * as SpeakerActions from './actions/speaker.actions';
import * as fromSpeaker from './reducers';

@Injectable({
  providedIn: 'root'
})
export class SpeakerFacadeService {
  constructor(private store$: Store<any>) {}

  getAllSpeakers(): Observable<SpeakerModel[]> {
    return this.store$.pipe(select(fromSpeaker.selectAllSpeakers));
  }

  getSpeakers(component: ComponentModel): Observable<SpeakerModel[]> {
    const { id: componentId } = component;
    return this.store$.pipe(select(fromSpeaker.selectSpeakers, { componentId }));
  }

  getSpeakerLoading(component: ComponentModel): Observable<boolean> {
    const { id: componentId } = component;
    return this.store$.pipe(select(fromSpeaker.selectSpeakerLoading, { componentId }));
  }

  getSpeakerLoaded(component: ComponentModel): Observable<boolean> {
    const { id: componentId } = component;
    return this.store$.pipe(select(fromSpeaker.selectSpeakerLoaded, { componentId }));
  }

  getSpeakerError(component: ComponentModel): Observable<Error> {
    const { id: componentId } = component;
    return this.store$.pipe(select(fromSpeaker.selectSpeakerError, { componentId }));
  }

  getSpeakerUpdating(component: ComponentModel): Observable<boolean> {
    const { id: componentId } = component;
    return this.store$.pipe(select(fromSpeaker.selectSpeakerUpdating, { componentId }));
  }

  getSpeakerReordering(component: ComponentModel): Observable<boolean> {
    const { id: componentId } = component;
    return this.store$.pipe(select(fromSpeaker.selectSpeakerReordering, { componentId }));
  }

  getUpdatedSpeaker(component: ComponentModel): Observable<SpeakerModel> {
    const { id: componentId } = component;
    return this.store$.pipe(select(fromSpeaker.selectUpdatedSpeaker, { componentId }));
  }

  loadSpeakers(event: EventModel, component: ComponentModel) {
    return this.store$.dispatch(SpeakerActions.loadSpeakers({ event, component }));
  }

  createSpeaker(event: EventModel, component: ComponentModel, speaker: SpeakerModel) {
    return this.store$.dispatch(SpeakerActions.createSpeaker({ event, component, speaker }));
  }

  updateSpeaker(event: EventModel, component: ComponentModel, speaker: SpeakerModel) {
    return this.store$.dispatch(SpeakerActions.updateSpeaker({ event, component, speaker }));
  }

  reorderSpeakers(event: EventModel, component: ComponentModel, speakers: SpeakerModel[]) {
    return this.store$.dispatch(SpeakerActions.reorderSpeakers({ event, component, speakers }));
  }

  deleteSpeaker(event: EventModel, component: ComponentModel, speaker: SpeakerModel) {
    return this.store$.dispatch(SpeakerActions.deleteSpeaker({ event, component, speaker }));
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { take } from 'rxjs/operators';

import { EventTimezoneService } from '@shared/providers/event-timezone.service';

import { DateTime } from 'luxon';

@Pipe({
  name: 'dateTimeFormat',
})
export class DateTimeFormatPipe implements PipeTransform {
  timezone: string;

  constructor(private eventTimezone: EventTimezoneService) {
    this.eventTimezone.getTimezone().pipe(take(1)).subscribe(
      timezone => this.timezone = timezone
    );
  }

  transform(value: any, ...args: any[]): any {
    const dateTime = DateTime.fromISO(value, { zone: this.timezone });
    return dateTime.toFormat('HH:mm dd LLLL yyyy');
  }
}

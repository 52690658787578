import { Action, createReducer, on } from "@ngrx/store";
import { AudioVisualTranslationAction } from "../actions/audio-visual-translation.actions";
import { AudioVisualFileTranslationModel, AudioVisualTranslationModel } from "../models/audio-visual-translation.models";
import { EventLanguageModel } from "@store/features/event/models/event-language.model";

export interface State {
  loading: boolean;
  updating: boolean;
  error: Error | null;
  translations: { [key: string]: AudioVisualTranslationModel[] };
  fileTranslations: { [key: string]: AudioVisualFileTranslationModel[] };
  activeLanguage: EventLanguageModel;
}

export const initialState: State = {
  loading: false,
  updating: false,
  error: null,
  translations: {},
  fileTranslations: {},
  activeLanguage: null,
}

export const audioVisualTranslationsReducer = createReducer(
  initialState,
  on(
    AudioVisualTranslationAction.loadAudioVisualsTranslation,
    (state) => ({ ...state, loading: true })
  ),
  on(
    AudioVisualTranslationAction.loadAudioVisualsTranslationSuccess,
    (state, { translations, language }) => ({ ...state, loading: false, translations: { ...state.translations, [language]: translations } })
  ),
  on(
    AudioVisualTranslationAction.loadAudioVisualsTranslationFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),

  on(
    AudioVisualTranslationAction.updateAudioVisualTranslation,
    (state) => ({ ...state, updating: true })
  ),
  on(
    AudioVisualTranslationAction.updateAudioVisualTranslationSuccess,
    (state, { translation, language }) => ({ ...state, updating: false, translations: {
      ...state.translations, [language]: [...state.translations[language].map(t => t.id === translation.id ? translation : t)] }
    })
  ),
  on(
    AudioVisualTranslationAction.updateAudioVisualTranslationFailure,
    (state, { error }) => ({ ...state, updating: false, error })
  ),
  on(
    AudioVisualTranslationAction.setActiveLanguage,
    (state, { language }) => ({ ...state, activeLanguage: language })
  ),

  // files

  on(
    AudioVisualTranslationAction.loadAudioVisualFilesTranslation,
    (state) => ({ ...state, loading: true })
  ),
  on(
    AudioVisualTranslationAction.loadAudioVisualFilesTranslationSuccess,
    (state, { translations, language }) => ({ ...state, loading: false, fileTranslations: { ...state.fileTranslations, [language]: translations } })
  ),
  on(
    AudioVisualTranslationAction.loadAudioVisualsTranslationFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    AudioVisualTranslationAction.resetFilesTranslations,
    (state,) => ({ ...state, loading: false, fileTranslations: {} })
  ),
);

export function reducer(state: State, action: Action): State {
  return audioVisualTranslationsReducer(state, action);
}

export const selectAllTranslations = (state: State) => state.translations;
export const selectActiveLanguage = (state: State) => state.activeLanguage;
export const selectError = (state: State) => state.error;
export const selectLoading = (state: State) => state.loading;
export const selectUpdating = (state: State) => state.updating;
export const selectForActiveLanguage = (state: State) => state.translations[state.activeLanguage.code];
export const selectAllFilesTranslations = (state: State) => state.fileTranslations;
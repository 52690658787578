import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'svg-icon',
  template: `<span
      class="svg-icon"
      [style.height]="height"
      [style.width]="width"
      [style.fill]="fillHex"
      [innerHTML]="svgIcon">
    </span>`,
  styleUrls: ['./svg-preview.component.scss'],
})
export class SvgPreviewComponent implements OnInit, OnChanges {

  @Input()
  public svgIconUrl: string;

  @Input()
  height: string;

  @Input()
  width: string;

  @Input()
  fillHex: string;

  public svgIcon: any;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
  ) {
  }

  public ngOnInit(): void {
    this.loadSvgIcon()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.svgIconUrl && !changes.svgIconUrl.firstChange) {
      this.loadSvgIcon()
    }
  }

  private loadSvgIcon(): void {
    this.httpClient
      .get(this.svgIconUrl, { responseType: 'text' })
      .subscribe(value => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
      });
  }
}

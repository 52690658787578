import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FullApiService } from '@shared/providers/full-api.service';

import {
  AttachmentModel,
  AttachmentPostModel,
  AttachmentPatchModel
} from '@shared/models/attachment.model';

@Injectable({
  providedIn: 'root'
})
export class AttachmentProviderService {
  constructor(private apiService: FullApiService) {}

  createAttachment(file: File): Observable<AttachmentModel> {
    const attachmentData = mapAttachmentPost(file);
    return this.apiService.createAttachment(attachmentData);
  }

  updateAttachment(attachment: AttachmentModel, file: File): Observable<AttachmentModel> {
    const attachmentData = mapAttachmentPatch(file);
    return this.apiService.updateAttachment2(attachment.id, attachmentData);
  }

  deleteAttachment(attachment: AttachmentModel): Observable<boolean> {
    return this.apiService.deleteAttachment(attachment.id).pipe(
      map(({ success }) => success)
    );
  }

  createAttachmentEvent(file: File): Observable<HttpEvent<AttachmentModel>> {
    const attachmentData = mapAttachmentPost(file);
    return this.apiService.createAttachmentEvent(attachmentData);
  }

  updateAttachmentEvent(attachment: AttachmentModel, file: File): Observable<HttpEvent<AttachmentModel>> {
    const attachmentData = mapAttachmentPatch(file);
    return this.apiService.updateAttachmentEvent(attachment.id, attachmentData);
  }
}

function mapAttachmentPost(file: File): AttachmentPostModel {
  return {
    filename: file.name, data: file
  };
}

function mapAttachmentPatch(file: File): AttachmentPatchModel {
  return {
    filename: file.name, data: file
  };
}

<div class="dialog">
  <h2 mat-dialog-title>{{ 'file_uploader_dialog.title' | translate }}</h2>

  <mat-dialog-content>
    <app-file-uploader
      (started)="onFileUploadStarted()"
      [formControl]="image"
    ></app-file-uploader>
  
    <div class="img__link form-group mt-4">
      <label>{{ 'file_uploader_dialog.image_external_link' | translate }}</label>
      <input [formControl]="imgLink" type="text" class="form-control" placeholder="https://panel.meetingapplication.com/">
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" class="btn mr-2 btn-primary" [mat-dialog-close]="false">{{ 'buttons.cancel' | translate }}</button>
    <button type="button" class="btn btn-secondary" [disabled]="!image.value && !imgLink.value" (click)="handleSubmit()">{{ 'buttons.submit' | translate }}</button>
  </mat-dialog-actions>
</div>
import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { ComponentDefinitionModel } from '@app/construct-steps/models/component-definition.model';
import { ComponentTranslationModel } from '@components/component/models/component-translation.model';
import { TagApiModel } from '@store/features/tags/tags.models';

export const loadComponents = createAction(
  '[Component/Event] Load Components',
  props<{ event: EventModel, authorizedUserGroups: TagApiModel[] }>()
);

export const loadComponentsSuccess = createAction(
  '[Component/API] Load Components Success',
  props<{ components: ComponentModel[] }>()
);

export const loadComponentsFailure = createAction(
  '[Component/API] Load Components Failure',
  props<{ error: Error }>()
);

export const setAllowedComponents = createAction(
  '[Component/API] Set Allowed components',
  props<{ ids: number[] }>()
);

export const setAllowedComponentsFailure = createAction(
  '[Component/API] Set Allowed components Failure',
);

export const createComponents = createAction(
  '[Component/Constructor] Create Components',
  props<{ event: EventModel, definitions: ComponentDefinitionModel[] }>()
);

export const createComponentsSuccess = createAction(
  '[Component/API] Create Components Success',
  props<{ components: ComponentModel[] }>()
);

export const createComponentsFailure = createAction(
  '[Component/API] Create Components Failure',
  props<{ error: Error }>()
);

export const insertComponents = createAction(
  '[Component/Component List] Insert Components',
  props<{ event: EventModel, definitions: ComponentDefinitionModel[], at: number }>()
);

export const insertComponentsSuccess = createAction(
  '[Component/API] Insert Components Success',
  props<{ components: ComponentModel[] }>()
);

export const insertComponentsFailure = createAction(
  '[Component/API] Insert Components Failure',
  props<{ error: Error }>()
);

export const updateComponent = createAction(
  '[Component/Constructor] Update Component',
  props<{ event: EventModel, component: ComponentModel, translations: ComponentTranslationModel[] }>()
);

export const updateComponentSuccess = createAction(
  '[Component/API] Update Component Success',
  props<{ component: ComponentModel, translations: ComponentTranslationModel[] }>()
);

export const updateComponentFailure = createAction(
  '[Component/API] Update Component Failure',
  props<{ error: Error }>()
);

export const updateOnboarding = createAction(
  '[Component/Constructor] Update Onboarding',
  props<{ event: EventModel, component: ComponentModel, translations: ComponentTranslationModel[] }>()
);

export const updateOnboardingSuccess = createAction(
  '[Component/Constructor] Update Onboarding Success',
  props<{ component: ComponentModel, translations: ComponentTranslationModel[] }>()
);

export const updateOnboardingFailure = createAction(
  '[Component/Constructor] Update Onboarding Failure',
  props<{ error: Error }>()
);

export const updateComponentAdditionalInfo = createAction(
  '[Component/Constructor] Update Component Additional Info',
  props<{ event: EventModel, component: ComponentModel, additionalInfo: any }>()
);

export const updateComponentAdditionalInfoSuccess = createAction(
  '[Component/API] Update Component Additional Info Success',
  props<{ component: ComponentModel }>()
);

export const updateComponentAdditionalInfoFailure = createAction(
  '[Component/API] Update Component Additional Info Failure',
  props<{ error: Error }>()
);

export const reorderComponents = createAction(
  '[Component/Constructor] Reorder Components',
  props<{ event: EventModel, components: ComponentModel[] }>()
);

export const reorderComponentsSuccess = createAction(
  '[Component/API] Reorder Components Success',
  props<{ components: ComponentModel[] }>()
);

export const reorderComponentsFailure = createAction(
  '[Component/API] Reorder Components Failure',
  props<{ error: Error }>()
);

export const changeComponentVisibility = createAction(
  '[Component/Constructor] Change Component Visibility',
  props<{ event: EventModel, component: ComponentModel, visible: boolean }>()
);

export const changeComponentVisibilitySuccess = createAction(
  '[Component/API] Change Component Visibility Success',
  props<{ component: ComponentModel }>()
);

export const changeComponentVisibilityFailure = createAction(
  '[Component/API] Change Component Visibility Failure',
  props<{ error: Error }>()
);

export const setComponentFirstToOpen = createAction(
  '[Component/Constructor] Set Component First To Open',
  props<{ event: EventModel, component: ComponentModel }>()
);

export const setComponentFirstToOpenSuccess = createAction(
  '[Component/API] Set Component First To Open Success',
  props<{ component: ComponentModel }>()
);

export const setComponentFirstToOpenFailure = createAction(
  '[Component/API] Set Component First To Open Failure',
  props<{ error: Error }>()
);

export const deleteComponent = createAction(
  '[Component/Constructor] Delete Component',
  props<{ event: EventModel, component: ComponentModel }>()
);

export const deleteComponentSuccess = createAction(
  '[Component/API] Delete Component Success',
  props<{ components: ComponentModel[], deleted: ComponentModel }>()
);

export const deleteComponentFailure = createAction(
  '[Component/API] Delete Component Failure',
  props<{ error: Error }>()
);

export const selectComponent = createAction(
  '[Component/Constructor] Select Component',
  props<{ componentId: number }>()
);

export const updateAgendaDefaultViewType = createAction(
  '[Component/Constructor] Update Agenda Default View Type',
  props<{ eventId: number, componentId: number, viewType: 0 | 1 }>()
);

export const updateAgendaDefaultViewTypeSuccess = createAction(
  '[Component/Constructor] Update Agenda Default View Type Success',
  props<{ componentId: number, viewType: 0 | 1 }>()
);


export const updateAgendaDefaultViewTypeFailure = createAction(
  '[Component/Constructor] Update Agenda Default View Type Failure',
  props<{ error: Error }>()
);
import { createAction, props } from '@ngrx/store';

import { IconModel } from '@shared/models/icon.model';

export const loadIcons = createAction(
  '[Icon/Backend] Load Icons'
);

export const loadIconsSuccess = createAction(
  '[Icon/API] Load Icons Success',
  props<{ icons: IconModel[] }>()
);

export const loadIconsFailure = createAction(
  '[Icon/API] Load Icons Failure',
  props<{ error: Error }>()
);

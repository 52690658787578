import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';

export interface Pagination {
  total: number;
  limit: number;
  page: number;
  options?: number[];
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})

export class PaginatorComponent implements OnInit, OnDestroy {

  private SHOW_ALL = 1000000;

  @Input() mobile: boolean = false;
  @Input() total: number;
  @Input() page: number;
  @Input() limit: number;
  @Input() options: number[];
  @Input() refresh?: Observable<Pagination>
  @Input() showAllEnabled = false;

  @Output() onValueChanged?: EventEmitter<Pagination>;
  
  totalPages: number;

  private sub: Subscription;

  constructor(private i18n: TranslateService) {
    this.onValueChanged = new EventEmitter<Pagination>();
  }

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.total / this.limit);
    if(this.refresh) {
      this.sub = this.refresh
        .pipe(filter(pagination => !!pagination))
        .subscribe(pagination => {
          const totalPages = Math.ceil(pagination.total / pagination.limit);
          if(this.page > totalPages) {
            this.page = totalPages;
          }
        });
    }
    if(this.showAllEnabled) {
      this.options.push(this.SHOW_ALL);
    }
  }

  ngOnChanges(_: SimpleChanges): void {
    this.totalPages = Math.ceil(this.total / this.limit);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  previous(): void {
    this.page--;
    this.changed();
  }

  next(): void {
    this.page++;
    this.changed();
  }

  option(option: any): void {
    if(typeof Number(option) === 'number') {
      if(option < 100) {
        this.limit = option;
      } else {
        this.limit = this.SHOW_ALL; // for show all option
      }
    } else {
      this.limit = this.SHOW_ALL; // for show all option
    }
    this.page = 1;
    this.changed();
  }

  changed(): void {
    this.onValueChanged.emit({
      total: this.total,
      limit: this.limit,
      page: this.page,
      options: this.options
    });
  }

  onPageSelect(page: number) {
    this.page = page;
    this.getPagesToDisplay();
    this.changed();
  }

  getPagesToDisplay(): number[] {
    let pages = [];
    for (let i = 2; i <= this.totalPages; i++) {
      pages.push(i);
    }
    let slicedArr = pages.slice(0, 3);
    if(this.page === slicedArr[slicedArr.length - 1]) {
      slicedArr = pages.slice(this.page - 3, this.page);
    } else if(this.page > slicedArr[slicedArr.length - 1]) {
      slicedArr = pages.slice(this.page - 3, this.page);
    }
    if(this.page > pages[pages.length - 4]) {
      slicedArr = pages.slice(pages.length - 4, pages.length);
    }
    if(this.totalPages === slicedArr[slicedArr.length - 1]) {
      slicedArr.pop();
    }
    return slicedArr;
  }

  showOption(option: number): number | string {
    if(option === this.SHOW_ALL) {
      return this.i18n.instant('paginator.all');
    } else {
      return option;
    }
  }

}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import { catchError, concatMap, filter, map, mergeMap, switchMap, take, tap, toArray, withLatestFrom } from "rxjs/operators";

import * as fromEvent from '@store/features/event/reducers';
import { createConsentEventSchema, createConsentEventSchemaFailure, createConsentEventSchemaSuccess, createConsentSchemaTranslation, createConsentSchemaTranslationFailure, createConsentSchemaTranslationSuccess, saveExistingConsentSchema, saveExistingConsentSchemaFailure, saveExistingConsentSchemaSuccess, saveNewConsentSchema, saveNewConsentSchemaFailure, saveNewConsentSchemaSuccess, updateConsentEventSchema, updateConsentEventSchemaFailure, updateConsentEventSchemaSuccess, updateConsentSchemaTranslation, updateConsentSchemaTranslationFailure, updateConsentSchemaTranslationSuccess } from "../actions/consent-event.actions";
import { ConsentSchemaTranslationCreateModel, ConsentSchemaTranslationModel, ConsentSchemaTranslationUpdateModel } from "../models/consent-schema-translation.model";
import {Observable, from, of, defer} from "rxjs";
import { ConsentSchemaModel } from "../models/consent-schema.model";
import { ConsentCreateActionParamsModel, ConsentEventHelper, ConsentUpdateActionParamsModel } from "../utils/consent-event.helper";
import { ConsentEventAdapterService } from "../adapters/consent-event-adapter.service";

@Injectable()
export class ConsentEventSaveEffects {

  onSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(saveNewConsentSchemaSuccess, saveExistingConsentSchemaSuccess),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    concatMap(([action, event]) =>
      this.adapter.publishSchemaVersion(event, action.schema, ConsentEventHelper.getNewestVersion(action.schema)).pipe(
        concatMap(() => this.adapter.publishConsentSchema(event, action.schema.id))
      )
    )
  ), { dispatch: false });

  saveNewConsentSchema$ = createEffect(() => this.actions$.pipe(
    ofType(saveNewConsentSchema),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    switchMap(([action, event]) => {
      const data: ConsentCreateActionParamsModel = action.data;

      return this.createConsentSchema$(data).pipe(
        switchMap(entity => {
          return this.createTranslations$(entity, data.createTranslationPayloads).pipe(
            map((action) => {
              return action;
            }),
            catchError(error => of(saveNewConsentSchemaFailure({ error }))),
          );
        }),
        catchError(error => of(saveNewConsentSchemaFailure({ error }))),
      );
    })
  ));

  saveExistingConsentSchema$ = createEffect(() => this.actions$.pipe(
    ofType(saveExistingConsentSchema),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    switchMap(([action, event]) => {
      const data: ConsentUpdateActionParamsModel = action.data;

      return this.updateConsentSchema$(data).pipe(
        switchMap(entity => {
          return this.createTranslations$(entity, data.createTranslationPayloads).pipe(
            map((action) => {
              return action;
            }),
            catchError(error => of(saveExistingConsentSchemaFailure({ error }))),
          );
        }),
        catchError(error => of(saveExistingConsentSchemaFailure({ error }))),
      );
    })
  ));

  private createConsentSchema$(data: ConsentCreateActionParamsModel) {
    return this.store$.pipe(
      concatMap(() => {
        this.store$.dispatch(createConsentEventSchema({ payload: data.createConsentPayload }));
        return this.actions$.pipe(ofType(createConsentEventSchemaSuccess, createConsentEventSchemaFailure));
      }),
      map((action) => {
        if (action.type === createConsentEventSchemaSuccess.type) {
          return action.entity;
        } else {
          throw new Error('Error at creating consent schema');
        }
      }),
    );
  }

  private updateConsentSchema$(data: ConsentUpdateActionParamsModel) {
    return this.store$.pipe(
      concatMap(() => {
        this.store$.dispatch(updateConsentEventSchema({ schema: data.schema, payload: data.updateConsentPayload }));
        return this.actions$.pipe(ofType(updateConsentEventSchemaSuccess, updateConsentEventSchemaFailure));
      }),
      map((action) => {
        if (action.type === updateConsentEventSchemaSuccess.type) {
          return action.entity;
        } else {
          throw new Error('Error at updating consent schema');
        }
      }),
    );
  }

  // private createTranslations$(schema: ConsentSchemaModel, payloads: ConsentSchemaTranslationCreateModel[]): Observable<Action> {
  //   const createRequests: Observable<Action>[] = [];
  //   const version = ConsentEventHelper.getNewestVersion(schema);
  //   if (!version) {
  //     throw new Error('Error at saving translations');
  //   }
  //
  //   payloads.forEach(payload => {
  //     const action = createConsentSchemaTranslation({ schema, version, payload });
  //     this.store$.dispatch(action);
  //     const request$ = this.actions$.pipe(
  //       ofType(createConsentSchemaTranslationSuccess, createConsentSchemaTranslationFailure),
  //       take(1),
  //     );
  //     createRequests.push(request$);
  //   });
  //
  //   return from(createRequests).pipe(
  //     mergeMap(request$ => request$),
  //     toArray(),
  //     map(results => {
  //       const hasFailure = results.some(result => result.type === createConsentSchemaTranslationFailure.type);
  //       if (hasFailure) {
  //         throw new Error('Error at saving translations');
  //       }
  //       return saveNewConsentSchemaSuccess({ schema });
  //     }),
  //     catchError(error => {
  //       return of(saveNewConsentSchemaFailure({ error }));
  //     })
  //   );
  // }

  private createTranslations$(schema: ConsentSchemaModel, payloads: ConsentSchemaTranslationCreateModel[]): Observable<Action> {
    const version = ConsentEventHelper.getNewestVersion(schema);
    if (!version) {
      throw new Error('Error at saving translations');
    }

    return from(payloads).pipe(
      // Sekwencyjne przetwarzanie każdego payloadu
      concatMap(payload => {
        const action = createConsentSchemaTranslation({ schema, version, payload });

        return defer(() => {
          // Dispatch akcji dopiero w momencie subskrypcji
          this.store$.dispatch(action);

          return this.actions$.pipe(
            ofType(createConsentSchemaTranslationSuccess, createConsentSchemaTranslationFailure),
            take(1)
          );
        });
      }),
      // Zbierz wszystkie wyniki
      toArray(),
      map(results => {
        const hasFailure = results.some(result => result.type === createConsentSchemaTranslationFailure.type);
        if (hasFailure) {
          throw new Error('Error at saving translations');
        }
        return saveNewConsentSchemaSuccess({ schema });
      }),
      catchError(error => {
        return of(saveNewConsentSchemaFailure({ error }));
      })
    );
  }

  private updateTranslations$(schema: ConsentSchemaModel, translations: ConsentSchemaTranslationModel[], payloads: ConsentSchemaTranslationUpdateModel[]): Observable<Action> {
    const updateRequests: Observable<Action>[] = [];
    const version = ConsentEventHelper.getNewestVersion(schema);
    if (!version) {
      throw new Error('Error at saving translations');
    }

    payloads.forEach(payload => {
      const translation = translations.find(t => t.locale === payload.locale);
      if (!translation) {
        throw new Error('Error at saving translations');
      }
      const action = updateConsentSchemaTranslation({ schema, version, translation, payload });
      this.store$.dispatch(action);
      const request$ = this.actions$.pipe(
        ofType(updateConsentSchemaTranslationSuccess, updateConsentSchemaTranslationFailure),
        take(1),
      );
      updateRequests.push(request$);
    });

    return from(updateRequests).pipe(
      mergeMap(request$ => request$),
      toArray(),
      map(results => {
        const hasFailure = results.some(result => result.type === updateConsentSchemaTranslationFailure.type);
        if (hasFailure) {
          throw new Error('Error at saving translations');
        }
        return saveExistingConsentSchemaSuccess({ schema });
      }),
      catchError(error => {
        return of(saveExistingConsentSchemaFailure({ error }));
      })
    );
  }

  constructor(
    private store$: Store<any>,
    private actions$: Actions,
    private adapter: ConsentEventAdapterService,
  ) { }
}

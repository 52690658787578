import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { AgendaRating2Model } from '@components/agenda/models/agenda-rating-2.model';

import * as RatingActions from '../actions/rating.actions';
import { selectEvent } from '@store/features/event/actions';

export const stateKey = 'ratings';

export interface State extends EntityState<AgendaRating2Model> {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

const adapter = createEntityAdapter<AgendaRating2Model>({
  sortComparer: (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt),
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const ratingReducer = createReducer(
  initialState,
  on(RatingActions.loadRatings, state => ({ ...initialState, loading: true })),
  on(RatingActions.loadRatingsSuccess, (state, { ratings }) =>
    adapter.setAll(ratings, { ...state, loading: false, loaded: true })
  ),
  on(RatingActions.loadRatingsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(RatingActions.deleteRatingSuccess, (state, { rating }) =>
    adapter.removeOne(rating.id, state)
  ),
  on(RatingActions.deleteRatingFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return ratingReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectRatingIds = selectIds;

export const selectRatingEntities = selectEntities;

export const selectAllRatings = selectAll;

export const selectRatingLoading = (state: State) => state.loading;

export const selectRatingLoaded = (state: State) => state.loaded;

export const selectRatingError = (state: State) => state.error;

import { Component, Input, Output, HostBinding, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input() color: string | number;
  @Input() removable: boolean;

  @Output() appRemove: EventEmitter<Event>;

  @HostBinding('style.backgroundColor') get _backgroundColor(): string {
    if (typeof this.color === 'number') {
      const colorHex = this.color.toString(16);
      return `#${colorHex}`;
    }

    return this.color;
  }

  constructor() {
    this.appRemove = new EventEmitter<Event>();
  }

  onRemoveClick(ev: Event) {
    this.appRemove.emit(ev);
  }
}

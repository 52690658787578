import { createAction, props } from '@ngrx/store';

import { PartnerModel } from '@components/partner/models/partner.model';
import { PartnerTranslationModel } from '@components/partner/models/partner-translation.model';
import { PartnerContactPersonModel } from '@components/partner/models/partner-contact-person.model';
import { PartnerContactPersonTranslationModel } from '@components/partner/models/partner-contact-person-translation.model';

export const modalCreatePartner = createAction(
  '[Partner/Partner Modal] Modal Create Partner',
  props<{ partner: PartnerModel, translations: PartnerTranslationModel[], contacts: PartnerContactPersonModel[], translations2: PartnerContactPersonTranslationModel[][] }>()
);

export const modalCreatePartnerSuccess = createAction(
  '[Partner/API] Modal Create Partner Success',
  props<{ partner: PartnerModel, contacts: PartnerContactPersonModel[] }>()
);

export const modalCreatePartnerFailure = createAction(
  '[Partner/API] Modal Create Partner Failure',
  props<{ error: Error }>()
);

export const modalUpdatePartner = createAction(
  '[Partner/Partner Modal] Modal Update Partner',
  props<{ partner: PartnerModel, translations: PartnerTranslationModel[], contacts: PartnerContactPersonModel[], translations2: PartnerContactPersonTranslationModel[][] }>()
);

export const modalUpdatePartnerSuccess = createAction(
  '[Partner/API] Modal Update Partner Success',
  props<{ partner: PartnerModel, contacts: PartnerContactPersonModel[] }>()
);

export const modalUpdatePartnerFailure = createAction(
  '[Partner/API] Modal Update Partner Failure',
  props<{ error: Error }>()
);

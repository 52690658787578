import { Injectable } from '@angular/core';

import { Observable, defer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AgendaQuestionProviderService } from './agenda-question-provider.service';
import { AgendaQuestionStoreService } from './agenda-question-store.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';
import { AgendaQuestionModel } from '@components/agenda/models/agenda-question.model';

@Injectable({
  providedIn: 'root'
})
export class AgendaQuestionService {
  protected currentEvent: EventModel;
  protected currentComponent: ComponentModel;
  protected currentSession: AgendaSessionModel;

  constructor(private agendaQuestionProvider: AgendaQuestionProviderService,
    private agendaQuestionStore: AgendaQuestionStoreService) {}

  getOrderedAgendaQuestions(): Observable<AgendaQuestionModel[]> {
    return this.agendaQuestionStore.getOrderedAgendaQuestions();
  }

  getAgendaQuestions(): Observable<AgendaQuestionModel[]> {
    return this.agendaQuestionStore.getAgendaQuestions();
  }

  loadAgendaSession(event: EventModel, component: ComponentModel,
    session: AgendaSessionModel): Observable<AgendaQuestionModel[]> {

    return defer(() => this.loadAgendaQuestionsFor(event, component, session));
  }

  refreshAgendaSession(): Observable<AgendaQuestionModel[]> {
    return this.agendaQuestionProvider.loadAgendaQuestions(this.currentEvent, this.currentComponent, this.currentSession).pipe(
      tap(questions => this.agendaQuestionStore.setup(questions))
    );
  }

  createAgendaQuestion(question: AgendaQuestionModel, anonymous?: boolean): Observable<AgendaQuestionModel> {
    return this.agendaQuestionProvider.createAgendaQuestion(
      this.currentEvent, this.currentComponent, this.currentSession, question, anonymous
    ).pipe(
      tap(createdQuestion => this.agendaQuestionStore.pushEntity(createdQuestion))
    );
  }

  deleteAgendaQuestion(question: AgendaQuestionModel): Observable<boolean> {
    return this.agendaQuestionProvider.deleteAgendaQuestion(this.currentEvent, this.currentComponent, this.currentSession, question).pipe(
      tap(success => success && this.agendaQuestionStore.removeEntity(question))
    );
  }

  private loadAgendaQuestionsFor(event: EventModel, component: ComponentModel,
    session: AgendaSessionModel): Observable<AgendaQuestionModel[]> {

    this.reset();

    return this.agendaQuestionProvider.loadAgendaQuestions(event, component, session).pipe(
      tap(questions => this.setup(event, component, session, questions))
    );
  }

  private setup(event: EventModel, component: ComponentModel, session: AgendaSessionModel, questions: AgendaQuestionModel[]) {
    this.currentEvent = event;
    this.currentComponent = component;
    this.currentSession = session;
    this.agendaQuestionStore.setup(questions);
  }

  private reset() {
    this.currentEvent = null;
    this.currentComponent = null;
    this.currentSession = null;
    this.agendaQuestionStore.reset();
  }
}

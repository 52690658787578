import { Action, createReducer, on } from '@ngrx/store';

import { ImportResultModel } from '@shared/models/import-result.model';

import * as ImportActions from '../actions/partner-import.actions';

export const stateKey = 'import';

export interface State {
  importing: boolean;
  imported: boolean;
  results: ImportResultModel[];
  error: Error;
}

export const initialState: State = {
  importing: false,
  imported: false,
  results: [],
  error: null,
};

export const importReducer = createReducer(
  initialState,
  on(
    ImportActions.importPartners,
    state => ({ ...state, importing: true, imported: false })
  ),
  on(
    ImportActions.importPartnersSuccess,
    (state, { results }) => ({ ...state, importing: false, imported: true, results })
  ),
  on(
    ImportActions.importPartnersFailure,
    (state, { error }) => ({ ...state, importing: false, error })
  ),
);

export function reducer(state: State, action: Action): State {
  return importReducer(state, action);
}

export const selectImporting = (state: State) => state.importing;

export const selectImported = (state: State) => state.imported;

export const selectResults = (state: State) => state.results;

export const selectError = (state: State) => state.error;

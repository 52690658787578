import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { InteractiveMapLocationModel } from '@components/interactive-map/models/interactive-map-location.model';

import * as InteractiveMapActions from '../actions/interactive-map.actions';

export const stateKey = 'locations';

export interface State extends EntityState<InteractiveMapLocationModel> {}

export const adapter = createEntityAdapter<InteractiveMapLocationModel>({
  sortComparer: (a, b) => a.id - b.id,
});

export const initialState: State = adapter.getInitialState({});

export const locationReducer = createReducer(
  initialState,
  on(InteractiveMapActions.loadInteractiveMapsSuccess, (state, { locations }) =>
    adapter.setAll(locations, initialState)
  ),
  on(
    InteractiveMapActions.createInteractiveMapSuccess,
    (state, { locations }) => adapter.upsertMany(locations, state)
  ),
  on(
    InteractiveMapActions.updateInteractiveMapSuccess,
    (state, { interactiveMap, locations }) => {
      const clean = adapter.removeMany(
        l => l.interactiveMapId === interactiveMap.id,
        state
      );
      return adapter.upsertMany(locations, clean);
    }
  ),
  on(
    InteractiveMapActions.deleteInteractiveMapSuccess,
    (state, { interactiveMap }) =>
      adapter.removeMany(
        location => location.interactiveMapId === interactiveMap.id,
        state
      )
  )
);

export function reducer(state: State, action: Action): State {
  return locationReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectAllLocations = selectAll;

export const selectInteractiveMapLocations = () =>
  createSelector(
    selectAllLocations,
    (locations, { interactiveMap }) =>
      locations.filter(
        location => location.interactiveMapId === interactiveMap.id
      ) as InteractiveMapLocationModel[]
  );

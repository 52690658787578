import { Injectable } from '@angular/core';

import { Observable, defer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SocialMediumProviderService } from '@components/social-medium/providers/social-medium-provider.service';
import { SocialMediumStoreService } from '@components/social-medium/providers/social-medium-store.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SocialMediumModel } from '@components/social-medium/models/social-medium.model';

@Injectable({
  providedIn: 'root'
})
export class SocialMediumService {
  protected currentEvent: EventModel;
  protected currentComponent: ComponentModel;

  constructor(private socialMediumProvider: SocialMediumProviderService, private socialMediumStore: SocialMediumStoreService) {}

  getSortedSocialMedia(): Observable<SocialMediumModel[]> {
    return this.socialMediumStore.getSortedSocialMedia();
  }

  getOrderedSocialMedia(): Observable<SocialMediumModel[]> {
    return this.socialMediumStore.getOrderedSocialMedia();
  }

  getSocialMedia(): Observable<SocialMediumModel[]> {
    return this.socialMediumStore.getSocialMedia();
  }

  loadComponent(event: EventModel, component: ComponentModel): Observable<SocialMediumModel[]> {
    return defer(() => this.loadSocialMediaFor(event, component));
  }

  createSocialMedium(socialMedium: SocialMediumModel): Observable<SocialMediumModel> {
    return this.socialMediumProvider.createSocialMedium(this.currentEvent, this.currentComponent, socialMedium).pipe(
      tap(createdSocialMedium => this.socialMediumStore.pushEntity(createdSocialMedium))
    );
  }

  updateSocialMedium(socialMedium: SocialMediumModel): Observable<SocialMediumModel> {
    return this.socialMediumProvider.updateSocialMedium(this.currentEvent, this.currentComponent, socialMedium).pipe(
      tap(updatedSocialMedium => this.socialMediumStore.updateEntity(updatedSocialMedium))
    );
  }

  deleteSocialMedium(socialMedium: SocialMediumModel): Observable<boolean> {
    return this.socialMediumProvider.deleteSocialMedium(this.currentEvent, this.currentComponent, socialMedium).pipe(
      tap(success => success && this.socialMediumStore.removeEntity(socialMedium))
    );
  }

  saveSocialMedium(socialMedium: SocialMediumModel): Observable<SocialMediumModel> {
    if (socialMedium.id) {
      return this.updateSocialMedium(socialMedium);
    } else {
      return this.createSocialMedium(socialMedium);
    }
  }

  private loadSocialMediaFor(event: EventModel, component: ComponentModel): Observable<SocialMediumModel[]> {
    this.reset();

    return this.socialMediumProvider.loadSocialMedia(event, component).pipe(
      tap(socialMedia => this.setup(event, component, socialMedia))
    );
  }

  private setup(event: EventModel, component: ComponentModel, socialMedia: SocialMediumModel[]) {
    this.currentEvent = event;
    this.currentComponent = component;
    this.socialMediumStore.setup(socialMedia);
  }

  private reset() {
    this.currentEvent = null;
    this.currentComponent = null;
    this.socialMediumStore.reset();
  }
}

import { TagApiModel, TagGroup, UserGroupTag } from './../tags.models';
import { Action, createReducer, on } from '@ngrx/store';
import { TagActions } from '../actions/tags.actions';
import { TagStateKey } from '../tags.const';
import { selectEvent } from '@store/features/event/actions';

export const featureKey = 'applicationTags';

export interface TagsStateModel {
  userGroupTags: TagApiModel[];
  userTags: TagApiModel[];
  agendaPathTags: TagApiModel[];
  agendaPlaceTags: TagApiModel[];
  exhibitorTags: TagApiModel[];
}

export interface State {
  loading: boolean;
  loadingAuthorizedUser: boolean;
  error: Error;
  tags: TagsStateModel;
  userGroups: UserGroupTag; // always type_tag === TagType.User
  reordering: boolean;
}

export const initialState: State = {
  loading: false,
  loadingAuthorizedUser: false,
  reordering: false,
  error: null,
  tags: {
    userGroupTags: [],
    userTags: [],
    agendaPathTags: [],
    agendaPlaceTags: [],
    exhibitorTags: [],
  },
  userGroups: {},
}

export const tagsReducer = createReducer(
  initialState,
  on(
    TagActions.loadTags,
    (state) => ({ ...state, loading: true })
  ),
  on(
    TagActions.loadTagsSuccess,
    (state, { tags, tagGroup }) =>
      ({ ...state, loading: false, tags: {...state.tags, [tagKey(tagGroup)]: tags} })
  ),
  on(
    TagActions.loadTagsFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    TagActions.createTag,
    (state) => ({ ...state, loading: true })
  ),
  on(
    TagActions.createTagSuccess,
    (state, { tag, tagGroup }) =>
      ({ ...state, loading: false, tags: {...state.tags, [tagKey(tagGroup)]: [...state.tags[tagKey(tagGroup)], tag] } })
  ),
  on(
    TagActions.createTagFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    TagActions.updateTag,
    (state) => ({ ...state, loading: true })
  ),
  on(
    TagActions.updateTagSuccess,
    (state, { tag, tagGroup }) => {
      const tags = state.tags[tagKey(tagGroup)].map((t: TagApiModel) => t.id === tag.id ? tag : t);
      return { ...state, loading: false, tags: { ...state.tags, [tagKey(tagGroup)]: tags } };
    }
  ),
  on(
    TagActions.updateTagFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    TagActions.deleteTag,
    (state) => ({ ...state, loading: true })
  ),
  on(
    TagActions.deleteTagSuccess,
    (state, { tagId, tagGroup }) => {
      const tags = state.tags[tagKey(tagGroup)].filter((t: TagApiModel) => t.id !== tagId);
      return { ...state, loading: false, tags: { ...state.tags, [tagKey(tagGroup)]: tags } };
    }
  ),
  on(
    TagActions.deleteTagFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),

  on(
    TagActions.reorderTags,
    (state) => ({...state, reordering: true })
  ),
  on(
    TagActions.reorderTagsSuccess,
    (state, { tags, tagGroup }) => ({ ...state, reordering: false, tags: {...state.tags, [tagKey(tagGroup)]: tags } })
  ),
  on(
    TagActions.reorderTagsFailure,
    (state, { error }) => ({...state, error, reordering: false })
  ),

  on(
    TagActions.loadUserGroupTags,
    (state) => ({ ...state, loading: true })
  ),
  on(
    TagActions.loadUserGroupTagsSuccess,
    (state, { tags }) => ({ ...state, loading: false, userGroups: tags })
  ),
  on(
    TagActions.loadUserGroupTagsFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    TagActions.addUserGroupTag,
    (state) => ({ ...state, loading: true })
  ),
  on(
    TagActions.addUserGroupTagSuccess,
    (state, { tags, uuid }) => {
      const userGroups = {
        ...state.userGroups,
        [uuid]: tags
      }
      return { ...state, loading: false, userGroups };
    }
  ),
  on(
    TagActions.addUserGroupTagFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    TagActions.addMultiUserGroupTags,
    (state) => ({ ...state, loading: true })
  ),
  on(
    TagActions.addMultiUserGroupTagsSuccess,
    (state, { tags, uuids }) => ({ ...state, loading: false, userGroups: mapMultiUserGroupsToState(tags, uuids, state.userGroups)})
  ),
  on(
    TagActions.addMultiUserGroupTagsFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    TagActions.deleteUserGroupTag,
    (state) => ({ ...state, loading: true })
  ),
  on(
    TagActions.deleteUserGroupTagSuccess,
    (state, { tags, uuid }) => {
      const userGroups = {
        ...state.userGroups,
        [uuid]: tags
      }
      return { ...state, loading: false, userGroups };
    }
  ),
  on(
    TagActions.deleteUserGroupTagFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    TagActions.modifyStateTags,
    (state, { tags, tagGroup }) => {
      return { ...state, loading: false, tags: {...state.tags, [tagKey(tagGroup)]: tags} }
    }
  ),
  on(
    TagActions.deleteEmptyTags,
    (state, { tagGroup }) => {
      return { ...state, loading: false, tags: {...state.tags, [tagKey(tagGroup)]: [...state.tags[tagKey(tagGroup)].filter((t: TagApiModel) => t.value && t.value.length !== 0)]} }
    }
  ),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

function tagKey(tagGroup: TagGroup): string {
  if(tagGroup === TagGroup.User) {
    return TagStateKey.User;
  }
  if(tagGroup === TagGroup.AgendaPath) {
    return TagStateKey.AgendaPath;
  }
  if(tagGroup === TagGroup.AgendaPlace) {
    return TagStateKey.AgendaPlace;
  }
  if(tagGroup === TagGroup.ExhibitorTag) {
    return TagStateKey.ExhibitorTag;
  }
  if(tagGroup === TagGroup.UserGroup) {
    return TagStateKey.UserGroup;
  }
  if(tagGroup === TagGroup.TagEvents) {
    return TagStateKey.EventTags;
  }
}

export function mapMultiUserGroupsToState(tags: TagApiModel[], uuids: string[], stateGroups: UserGroupTag): UserGroupTag {
  const userGroups = { ...stateGroups };
  uuids.forEach(uuid => {
    userGroups[uuid] = tags;
  });
  return userGroups;
}

export function reducer(state: State, action: Action): State {
  return tagsReducer(state, action);
}

export const selectTags = (state: State) => state.tags;
export const selectUserTags = (state: State) => state.tags.userTags;
export const selectAgendaPathTags = (state: State) => state.tags.agendaPathTags;
export const selectAgendaPlaceTags = (state: State) => state.tags.agendaPlaceTags;
export const selectExhibitorTags = (state: State) => state.tags.exhibitorTags;
export const selectUserGroupTags = (state: State) => state.tags.userGroupTags;

export const selectUserGroups = (state: State) => state.userGroups;
export const selectUserGroupsByUUID = (uuid: string) => (state: State) => state.userGroups[uuid];

export const selectLoading = (state: State) => state.loading;
export const selectReordering = (state: State) => state.reordering;
export const selectError = (state: State) => state.error;

export const selectLoadingAuthorizedUser = (state: State) => state.loading;
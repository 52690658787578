import { createAction, props } from '@ngrx/store';

import { WwwResourceCategoryModel } from '@components/www-resource/models/www-resource.model';

// Categories are loaded with www-resource items
export const loadCategoriesSuccess = createAction(
  '[WwwResource/API] Load Categories Success',
  props<{ categories: WwwResourceCategoryModel[] }>()
);

export const createCategory = createAction(
  '[WwwResource/Component] Create Category',
  props<{ category: WwwResourceCategoryModel }>()
);

export const createCategorySuccess = createAction(
  '[WwwResource/API] Create Category Success',
  props<{ category: WwwResourceCategoryModel }>()
);

export const createCategoryFailure = createAction(
  '[WwwResource/API] Create Category Failure',
  props<{ error: Error }>()
);

export const updateCategory = createAction(
  '[WwwResource/Component] Update Category',
  props<{ category: WwwResourceCategoryModel }>()
);

export const updateCategorySuccess = createAction(
  '[WwwResource/API] Update Category Success',
  props<{ category: WwwResourceCategoryModel }>()
);

export const updateCategoryFailure = createAction(
  '[WwwResource/API] Update Category Failure',
  props<{ error: Error }>()
);

export const reorderCategories = createAction(
  '[WwwResource/Component] Reorder Categories',
  props<{ from: number, to: number }>()
);

export const reorderCategoriesInplace = createAction(
  '[WwwResource/Effect] Reorder Categories Inplace',
  props<{ categories: WwwResourceCategoryModel[] }>()
);

export const reorderCategoriesSuccess = createAction(
  '[WwwResource/API] Reorder Categories Success',
  props<{ categories: WwwResourceCategoryModel[] }>()
);

export const reorderCategoriesFailure = createAction(
  '[WwwResource/API] Reorder Categories Failure',
  props<{ error: Error }>()
);

export const deleteCategory = createAction(
  '[WwwResource/Component] Delete Category',
  props<{ category: WwwResourceCategoryModel }>()
);

export const deleteCategorySuccess = createAction(
  '[WwwResource/API] Delete Category Success',
  props<{ category: WwwResourceCategoryModel }>()
);

export const deleteCategoryFailure = createAction(
  '[WwwResource/API] Delete Category Failure',
  props<{ error: Error }>()
);

export const selectCategory = createAction(
  '[WwwResource/Modal] Select Category',
  props<{ category: WwwResourceCategoryModel }>()
);

/**
 * Category Placeholder
 */
export const focusPlaceholder = createAction(
  '[WwwResource/Component] Focus Placeholder',
  props<{ componentId: number }>()
);

export const blurPlaceholder = createAction(
  '[WwwResource/Component] Blur Placeholder',
  props<{ componentId: number }>()
);

export const hidePlaceholder = createAction(
  '[WwwResource/Effect] Hide Placeholder',
  props<{ componentId: number }>()
);

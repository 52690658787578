import {FileUploaderDialogService} from './../file-uploader-dialog/file-uploader-dialog.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import {AttachmentProviderService} from '@shared/providers/attachment-provider.service';

import tinymce from 'tinymce/tinymce.min.js';

import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/plugins/code/plugin.min.js';
import 'tinymce/plugins/link/plugin.min.js';
import 'tinymce/plugins/image/plugin.min.js';
import 'tinymce/plugins/lists/plugin.min.js';
import 'tinymce/plugins/autoresize/plugin.min.js';
import 'tinymce/plugins/colorpicker/plugin.min.js';
import 'tinymce/plugins/textcolor/plugin.min.js';
import 'tinymce/plugins/visualblocks/plugin.min.js';
import 'tinymce/plugins/nonbreaking/plugin.min.js';
import {getEditorSnippets} from './snippets.const';
import {CustomEmail} from '@components/custom-emails/store/custom-emails.models';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WysiwygEditorComponent),
      multi: true,
    },
  ],
})
export class WysiwygEditorComponent implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {
  // TODO: add more configurable options
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() codeEnabled = true;
  @Input() addButtonsEnabled = false; // if true -> required to implement addButton in parent component
  @Input() snippets = false;

  @Input() minEditorHeight = 200;
  @Input() maxEditorHeight = 350;

  @Input() initValue: string = '';
  @Input() emailType: CustomEmail = CustomEmail.WELCOME_REGISTERED;

  @Output() addButton: EventEmitter<void>;

  @HostBinding('class.readonly') get _readOnly(): boolean {
    return !!this.readonly;
  }

  get value(): string {
    return this.editorInstance.getContent();
  }

  @ViewChild('nativeInput', {static: true}) nativeInput: ElementRef;

  private editorInstance: any;

  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor(
    private attachmentProvider: AttachmentProviderService,
    private fileUploaderDialogService: FileUploaderDialogService,
    private translateService: TranslateService,
  ) {
    this.addButton = new EventEmitter<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.editorInstance) {
      this.createEditorInstance();
    }

    if ('readonly' in changes) {
      this.editorInstance.setMode(this.readonly ? 'readonly' : 'design');
    }

    if (changes.hasOwnProperty('initValue')) {
      // fix for default template placeholder values in template elements styles
      // these hardcoded values are also present in custom-emails-button-creator::setInitValues
      this.initValue = changes.initValue.currentValue
        .replace(/{{main_color}}/g, '#00aeef')
        .replace(/{{main_text_color}}/g, '#ffffff');
      this.editorInstance.setContent(this.initValue);
    }

    if (changes.hasOwnProperty('emailType') && this.editorInstance) {
      this.updateSnippetsMenu();
    }
  }

  ngAfterViewInit() {
    this.editorInstance.id = this.nativeInput.nativeElement;
    this.editorInstance.render();
  }

  ngOnDestroy() {
    this.destroyEditorInstance();
  }

  private updateSnippetsMenu() {
    this.editorInstance.ui.registry.addMenuButton('customsnippets', {
      icon: 'code-sample',
      placeholder: 'Snippets',
      fetch: (callback: any) => {
        const items = getEditorSnippets(this.emailType).map(item => ({
          type: item.type,
          text: this.translateService.instant(item.translationKey),
          onAction: () => {
            this.editorInstance.insertContent(item.content);
          },
        }));
        callback(items);
      },
    });
  }

  onEditorChange(ev: any) {
    if (this.onChange) {
      this.onChange(this.editorInstance.getContent());
    }
  }

  onEditorBlur(ev: any) {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: any): void {
    this.editorInstance.setContent(value || '');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.editorInstance.mode.set(isDisabled ? 'readonly' : 'design');
  }

  handleButtonClick(): void {
    this.addButton.emit();
  }

  private createEditorInstance() {
    const setup = editor => {
      editor.on('init', ev => {
        ev.target.setContent(this.initValue);
        ev.target.getDoc().body.style.fontFamily = 'Open Sans';
      });

      // editor.on('change', ev => this.onEditorChange(ev));
      editor.on('blur', (ev: any) => {
        this.onChange(this.editorInstance.getContent());
        this.onEditorBlur(ev);
      });
      // editor.on('input', ev => {
      //   this.onChange(this.editorInstance.getContent())
      // });

      editor.ui.registry.addButton('addcustombutton', {
        icon: 'plus',
        placeholder: 'Add custom buttons',
        onAction: () => this.handleButtonClick(),
      });

      editor.ui.registry.addButton('divwrapper', {
        icon: 'non-breaking',
        placeholder: 'Wrap selected content into div block',
        onAction: () => {
          editor.dom.setOuterHTML(
            editor.selection.getNode(),
            '<div class="custom-div">' + editor.dom.getOuterHTML(editor.selection.getNode()) + '</div>'
          );
        },
      });

      editor.ui.registry.addButton('removeselectedarea', {
        icon: 'remove',
        placeholder: 'Remove selected blocks',
        onAction: () => {
          editor.dom.setOuterHTML(editor.selection?.getNode(), '');
        },
      });

      editor.ui.registry.addMenuButton('customsnippets', {
        icon: 'code-sample',
        placeholder: 'Snippets',
        fetch: (callback: any) => {
          const items = getEditorSnippets(this.emailType).map(item => ({
            type: item.type,
            text: this.translateService.instant(item.translationKey),
            onAction: () => {
              editor.insertContent(item.content);
            },
          }));
          callback(items);
        },
      });

      editor.ui.registry.addButton('customimageuploader', {
        icon: 'image',
        placeholder: 'Image',
        onAction: () => {
          this.fileUploaderDialogService.open().subscribe(html => editor.insertContent(html));
        },
      });
    };

    const images_upload_handler = (blobInfo: any, success, failure) => {
      const file = blobInfo.blob();

      this.attachmentProvider.createAttachment(file).subscribe({
        next: attachment => {
          const urlWithoutQueryParams = attachment.file_url.split('?')[0];
          return success(urlWithoutQueryParams);
        },
        error: err => failure(err),
      });
    };

    this.editorInstance = tinymce.createEditor(null, {
      selector: 'textarea',
      skin_url: '/assets/editor/tinymce/skins/ui/oxide',
      icons_url: '/assets/editor/tinymce/icons/default/icons.min.js',
      content_css: '/assets/editor/tinymce/skins/content/default/content.min.css',
      content_style:
        "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600&display=swap'); p { font-family: Open Sans; }",
      paste_data_images: true,
      min_height: this.minEditorHeight,
      max_height: this.maxEditorHeight,
      plugins: 'code,link,image,lists,autoresize,textcolor,colorpicker,visualblocks,nonbreaking',
      toolbar: `${
        this.codeEnabled ? 'code |' : null
      } formatselect bold italic underline removeformat fontsizeselect | forecolor backcolor | alignjustify alignleft aligncenter alignright | numlist bullist | customimageuploader link | ${
        this.addButtonsEnabled ? 'addcustombutton' : null
      } visualblocks ${this.snippets ? 'customsnippets' : null}`,
      fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
      menubar: false,
      contextmenu: false,
      elementpath: false,
      statusbar: false,
      cleanup: true,
      remove_linebreaks: false,
      forced_root_block: '',
      convert_newlines_to_brs: false,
      format_empty_lines: false,
      force_p_newlines: false,
      force_br_newlines: false,
      inline_styles: true,
      object_resizing: 'img',
      entity_encoding: 'raw',
      entities: '160,nbsp,38,amp,60,lt,62,gt',
      block_unsupported_drop: false,
      setup,
      images_upload_handler,
    });
  }

  private destroyEditorInstance() {
    this.editorInstance.destroy();
  }
}

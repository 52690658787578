import { UserApiModel } from '@shared/models/user.api-model';
import { BookingDay, BookingDayAPI, BookingFilter } from './booking-session.model';
import { BookingRoom } from '@components/booking/store/models/booking-session.model';

export interface BookingReservationMeta {
  total: number;
  limit: number;
  page: number;
  reservation_status: BookingReservationStatus;
}

export interface BookingReservationPlace {
  id: number;
  name: string;
  room: BookingRoom
}

export interface BookingReservationsResponseAPI {
  booking_orders: BookingReservationAPI[];
  meta: BookingReservationMeta;
}

export interface BookingReservationsResponse {
  data: BookingReservation[];
  meta: BookingReservationMeta;
}

export interface BookingReservation {
  id?: number;
  timeStart: string;
  timeEnd: string;
  place: BookingReservationPlace;
  filter: BookingFilter; // filterModel
  status: BookingReservationStatus;
  user: UserApiModel;
  sessionId: number;
}

export interface BookingReservationPost {
  timeStart: string;
  timeEnd: string;
  roomId: number;
  filterId: string;
  userForId: number;
  dayId: number;
  sessionId: number;
}

export interface BookingReservationPostApi {
  time_start: string;
  time_end: string;
  room_id: number;
  filter_id: string;
  user_for_id: number;
  day_id: number;
  session_id: number;
}

export interface BookingReservationAPI {
  id?: number;
  time_start: string;
  time_end: string;
  place: BookingReservationPlace;
  filter: BookingFilter; // filterModel
  status: BookingReservationStatus;
  user: UserApiModel;
  session_id: number;
}

export enum BookingReservationStatus {
  Accepted = 'accepted',
  Declined = 'declined', // by admin
  Pending = 'pending',
  Canceled = 'canceled' // by user
}

export interface BookingReservationSlotAPI {
  session_id: number;
  day: BookingDayAPI;
  time_start: string;
  time_end: string;
  place: BookingReservationPlace;
  filters: BookingFilter[];
}

export interface BookingReservationSlot {
  sessionId: number;
  day: BookingDay;
  timeStart: string;
  timeEnd: string;
  place: BookingReservationPlace;
  filters: BookingFilter[];
}

export interface BookingReservationsGet {
  reservation_status: BookingReservationStatus;
  page: number;
  size: number;
}
import { BookingBuilding, BookingPlace } from '@components/booking/store/models/booking-session.model';
import { createAction } from "@ngrx/store";

const loadBookingBuildings = createAction('[Booking Building] Load Booking Building');
const loadBookingBuildingsSuccess = createAction('[Booking Building] Load Booking Building Success', (bookingBuildings: BookingPlace[]) => ({ bookingBuildings }));
const loadBookingBuildingsFailure = createAction('[Booking Building] Load Booking Building Failure', (error: any) => ({ error }));

const setupBuildingRoom = createAction('[Booking Building/Rooms] Setup Building Rooms', (place: BookingPlace) => ({ place }));
const setBookingBuildingUpdatedId = createAction('[Booking Building] Set Booking Building Updated Id', (id: number) => ({ id }));

const addBookingBuilding = createAction('[Booking Building] Add Booking Building', (bookingBuilding: BookingBuilding) => ({ bookingBuilding }));
const addBookingBuildingSuccess = createAction('[Booking Building] Add Booking Building Success', (bookingBuilding: BookingBuilding) => ({ bookingBuilding }));
const addBookingBuildingFailure = createAction('[Booking Building] Add Booking Building Failure', (error: any) => ({ error }));

const updateBookingBuilding = createAction('[Booking Building] Update Booking Building', (bookingBuilding: BookingBuilding) => ({ bookingBuilding }));
const updateBookingBuildingSuccess = createAction('[Booking Building] Update Booking Building Success', (bookingBuilding: BookingBuilding) => ({ bookingBuilding }));
const updateBookingBuildingFailure = createAction('[Booking Building] Update Booking Building Failure', (error: any) => ({ error }));

const deleteBookingBuilding = createAction('[Booking Building] Delete Booking Building', (bookingBuilding: BookingBuilding) => ({ bookingBuilding }));
const deleteBookingBuildingSuccess = createAction('[Booking Building] Delete Booking Building Success', (bookingBuilding: BookingBuilding) => ({ bookingBuilding }));
const deleteBookingBuildingFailure = createAction('[Booking Building] Delete Booking Building Failure', (error: any) => ({ error }));


const setBookingBuildingError = createAction('[Booking Building] Set Booking Building Error', (error: any) => ({ error }));

export const bookingBuildingActions = {
    loadBookingBuildings,
    loadBookingBuildingsSuccess,
    loadBookingBuildingsFailure,
    addBookingBuilding,
    addBookingBuildingSuccess,
    addBookingBuildingFailure,
    updateBookingBuilding,
    updateBookingBuildingSuccess,
    updateBookingBuildingFailure,
    deleteBookingBuilding,
    deleteBookingBuildingSuccess,
    deleteBookingBuildingFailure,
    setBookingBuildingError,
    setupBuildingRoom,
    setBookingBuildingUpdatedId
};

import { Injectable, Inject, ValueProvider, InjectionToken, Type } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { EventFacadeService } from '@store/features/event/event-facade.service';
import { ComponentFacadeService } from '@store/features/component/component-facade.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';

export interface ComponentType {
  name: string;
  component: Type<any>;
}

export interface ComponentRegistryProvider extends ValueProvider {
  useValue: ComponentType;
}

export const COMPONENT_REGISTRY = new InjectionToken<ComponentType[]>('Component Registry');

@Injectable({
  providedIn: 'root'
})
export class ComponentOutletService {
  private component$: BehaviorSubject<ComponentModel>;
  private componentType$: Observable<Type<any>>;

  constructor(private eventFacade: EventFacadeService, private componentFacade: ComponentFacadeService,
    @Inject(COMPONENT_REGISTRY) private componentRegistry: ComponentType[]) {

    this.component$ = new BehaviorSubject(null);

    this.componentType$ = this.getComponent().pipe(
      map(component => this.getComponentTypeFor(component))
    );
  }

  getComponents(): InjectionToken<ComponentType[]> {
    return COMPONENT_REGISTRY;
  }

  getEvent(): Observable<EventModel> {
    return this.eventFacade.getActiveEvent();
  }

  getComponent(): Observable<ComponentModel> {
    return this.component$.asObservable();
  }

  getComponentTuple(): Observable<[ComponentModel, EventModel]> {
    return this.getComponent().pipe(
      withLatestFrom(this.eventFacade.getActiveEvent())
    );
  }

  getComponentType(): Observable<Type<any>> {
    return this.componentType$;
  }

  setComponent(component: ComponentModel) {
    this.component$.next(component);
  }

  private getComponentTypeFor(component: ComponentModel): Type<any> {
    const componentName = component.componentName;
    const registeredComponent = this.componentRegistry.find(
      componentType => (componentType.name === componentName)
    );

    if (registeredComponent) {
      return registeredComponent.component;
    }

    return null;
  }
}

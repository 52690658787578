import {CustomEmail} from "@components/custom-emails/store/custom-emails.models";

export const editorSnippets = {
  fullName: '{{user_full_name}}',
  firstName: '{{user_first_name}}',
  lastName: '{{user_last_name}}',
  eventEmail: '{{event_email}}',
  eventLandingPage: '{{event_lp}}',
  userPasswordRecoveryLink: '{{user_password_recovery_link}}',
  getAndroidApp: '{{android_app_download_url}}',
  getIosApp: '{{ios_app_download_url}}',
  eventName: '{{event_title}}',
  mainColor: '{{main_color}}',
  mainTextColor: '{{main_text_color}}',
  invoiceNumber: '{{invoice_number}}',
  ticketName: '{{ticket_name}}',
  itemsPackageHtml: '{{items_package_html}}'
}

const createMenuItem = (translationKey: string, snippetKey: keyof typeof editorSnippets) => ({
  type: 'menuitem' as const,
  translationKey,
  content: `<span contenteditable="false">${editorSnippets[snippetKey]}</span>`
});

export const baseSnippets = [
  createMenuItem('snippets.full_name', 'fullName'),
  createMenuItem('snippets.first_name', 'firstName'),
  createMenuItem('snippets.last_name', 'lastName'),
  createMenuItem('snippets.event_email', 'eventEmail'),
  createMenuItem('snippets.event_name', 'eventName'),
  createMenuItem('snippets.event_landing_page', 'eventLandingPage'),
];

export const appSnippets = [
  createMenuItem('snippets.android_app', 'getAndroidApp'),
  createMenuItem('snippets.ios_app', 'getIosApp'),
];

export const activationSnippets = [
  createMenuItem('snippets.user_activation', 'userPasswordRecoveryLink'),
];

export const invoiceSnippets = [
  createMenuItem('snippets.invoice_number', 'invoiceNumber'),
];

export const ticketSnippets = [
  createMenuItem('snippets.ticket_name', 'ticketName'),
];

export const itemsPackageSnippets = [
  createMenuItem('snippets.items_package_list', 'itemsPackageHtml'),
];

export function getEditorSnippets(emailType: CustomEmail) {
  switch (emailType) {
    case CustomEmail.WELCOME:
      return [...baseSnippets, ...appSnippets, ...activationSnippets];

    case CustomEmail.WELCOME_REGISTERED:
      return [...baseSnippets, ...appSnippets];

    case CustomEmail.INVOICE_EMAIL:
    case CustomEmail.INVOICE_PROFORMA:
    case CustomEmail.INVOICE_PROFORMA_AUTOPAY:
      return [...baseSnippets, ...invoiceSnippets];

    case CustomEmail.TICKET:
    case CustomEmail.AGENDA_SESSION_TICKET:
      return [...baseSnippets, ...ticketSnippets];

    case CustomEmail.ASSIGN_ITEMS_PACKAGE:
      return [...baseSnippets, ...itemsPackageSnippets];

    case CustomEmail.EXHIBITOR_MODERATOR:
      return [...baseSnippets, ...appSnippets];

    case CustomEmail.MAGIC_LINK_EMAIL:
    case CustomEmail.MARKETING:
    default:
      return baseSnippets;
  }
}

import { AttachmentModel } from '@shared/models/attachment.model';
import { SafeHtml } from '@angular/platform-browser';

export enum CustomEmail {
  WELCOME = 'welcome_not_registered',
  WELCOME_REGISTERED = 'welcome_registered',
  INVOICE_EMAIL = 'invoice',
  TICKET = 'event_ticket',
  MARKETING = 'marketing',
  EXHIBITOR_MODERATOR = 'exhibitor_moderator',
  MAGIC_LINK_EMAIL = 'magic_link',
  AGENDA_SESSION_TICKET = 'agenda_session_ticket',
  INVOICE_PROFORMA = 'invoice_proforma',
  INVOICE_PROFORMA_AUTOPAY = 'invoice_proforma_autopay',
  ASSIGN_ITEMS_PACKAGE = 'assign_items_package',

  // NOT IMPLEMENTED ON BACKEND SIDE
  // TICKET_RESERVATION = 'ticket_reservation',
  // RSVP = 'rsvp',
  // FINISH_REGISTRATION = 'finish_registration', // ???
  // WHITELIST_ACCEPTED = 'whitelist_accepted',
  // WHITELIST_DECLINED = 'whitelist_declined',
}

export interface CustomEmailModel {
  subject: string;
  content: SafeHtml;
  branded: boolean;
  enabled: boolean;
  email_type: CustomEmail;
  banner: AttachmentModel;
}

export interface CustomEmailPatchModel {
  subject: string;
  content: SafeHtml;
  branded: boolean;
  enabled: boolean;
  email_type: CustomEmail;
  banner_attachment_id: number | null; // id
}

import { createAction, props } from "@ngrx/store";
import { AudioVisualFileModel, AudioVisualFilePatchModel, AudioVisualFilePostModel, AudioVisualModel, AudioVisualPatchModel, AudioVisualPostModel } from "../models/audio-visual.models";
import { AudioVisualFilePatchTranslationModel } from "../models/audio-visual-translation.models";
import { EventModel } from "@store/features/event/models/event.model";
import { ComponentModel } from "@shared/models/component.model";
import { TagApiModel } from "@store/features/tags/tags.models";

const loadAudioVisuals = createAction(
  '[AudioVisuals] Load Audio Visuals',
);

const loadAudioVisualsSuccess = createAction(
  '[AudioVisuals] Load Audio Visuals Success',
  props<{ audioVisuals: AudioVisualModel[] }>()
);

const loadAudioVisualsFailure = createAction(
  '[AudioVisuals] Load Audio Visuals Failure',
  props<{ error: Error }>()
);

const loadAudioVisualsForDeepLinks = createAction(
  '[AudioVisuals] Load Audio Visuals For Deep Links',
  props<{ event: EventModel, component: ComponentModel }>()
);

const createAudioVisual = createAction(
  '[AudioVisuals] Create Audio Visual',
  props<{ payload: AudioVisualPostModel, tags: TagApiModel[] }>()
);

const createAudioVisualSuccess = createAction(
  '[AudioVisuals] Create Audio Visual Success',
  props<{ audioVisual: AudioVisualModel }>()
);

const createAudioVisualFailure = createAction(
  '[AudioVisuals] Create Audio Visual Failure',
  props<{ error: Error }>()
);

const patchAudioVisual = createAction(
  '[AudioVisuals] Patch Audio Visual',
  props<{ audioVisual: AudioVisualModel, payload: AudioVisualPatchModel }>()
);

const patchAudioVisualSuccess = createAction(
  '[AudioVisuals] Patch Audio Visual Success',
  props<{ audioVisual: AudioVisualModel }>()
);

const patchAudioVisualFailure = createAction(
  '[AudioVisuals] Patch Audio Visual Failure',
  props<{ error: Error }>()
);

const deleteAudioVisual = createAction(
  '[AudioVisuals] Delete Audio Visual',
  props<{ audioVisual: AudioVisualModel }>()
);

const deleteAudioVisualSuccess = createAction(
  '[AudioVisuals] Delete Audio Visual Success',
  props<{ audioVisual: AudioVisualModel }>()
);

const deleteAudioVisualFailure = createAction(
  '[AudioVisuals] Delete Audio Visual Failure',
  props<{ error: Error }>()
);

const reorderAudioVisuals = createAction(
  '[AudioVisuals] Reorder Audio Visuals',
  props<{ ordered: AudioVisualModel[] }>()
);

const reorderAudioVisualsSuccess = createAction(
  '[AudioVisuals] Reorder Audio Visuals Success',
  props<{ audioVisuals: AudioVisualModel[] }>()
);

const reorderAudioVisualsFailure = createAction(
  '[AudioVisuals] Reorder Audio Visuals Failure',
  props<{ error: Error }>()
);


// files
const createAudioVisualFile = createAction(
  '[AudioVisuals] Create Audio Visual File',
  props<{ audioVisual: AudioVisualModel, payload: AudioVisualFilePostModel }>()
);

const createAudioVisualFileSuccess = createAction(
  '[AudioVisuals] Create Audio Visual File Success',
  props<{ audioVisual: AudioVisualModel, audioVisualFile: AudioVisualFileModel }>()
);

const createAudioVisualFileFailure = createAction(
  '[AudioVisuals] Create Audio Visual File Failure',
  props<{ error: Error }>()
);

const patchAudioVisualFile = createAction(
  '[AudioVisuals] Patch Audio Visual File',
  props<{ audioVisual: AudioVisualModel, file: AudioVisualFileModel, payload: AudioVisualFilePatchModel }>()
);

const patchAudioVisualFileSuccess = createAction(
  '[AudioVisuals] Patch Audio Visual File Success',
  props<{ audioVisual: AudioVisualModel, audioVisualFile: AudioVisualFileModel }>()
);

const patchAudioVisualFileFailure = createAction(
  '[AudioVisuals] Patch Audio Visual File Failure',
  props<{ error: Error }>()
);

const deleteAudioVisualFile = createAction(
  '[AudioVisuals] Delete Audio Visual File',
  props<{ audioVisual: AudioVisualModel, file: AudioVisualFileModel }>()
);

const deleteAudioVisualFileSuccess = createAction(
  '[AudioVisuals] Delete Audio Visual File Success',
  props<{ audioVisual: AudioVisualModel, audioVisualFile: AudioVisualFileModel }>()
);

const deleteAudioVisualFileFailure = createAction(
  '[AudioVisuals] Delete Audio Visual File Failure',
  props<{ error: Error }>()
);

const reorderAudioVisualFiles = createAction(
  '[AudioVisuals] Reorder Audio Visual Files',
  props<{ audioVisual: AudioVisualModel, ordered: AudioVisualFileModel[] }>()
);

const reorderAudioVisualFilesSuccess = createAction(
  '[AudioVisuals] Reorder Audio Visual Files Success',
  props<{ audioVisual: AudioVisualModel, files: AudioVisualFileModel[] }>()
);

const reorderAudioVisualFilesFailure = createAction(
  '[AudioVisuals] Reorder Audio Visual Files Failure',
  props<{ error: Error }>()
);

const patchAudioVisualFiles = createAction(
  '[AudioVisuals] Patch Audio Visual Files',
  props<{
    audioVisual: AudioVisualModel,
    files: AudioVisualFilePatchTranslationModel[],
    language: string,
  }>()
);

const patchAudioVisualFilesSuccess = createAction(
  '[AudioVisuals] Patch Audio Visual Files Success',
  props<{ files: AudioVisualFileModel[] }>()
);

const patchAudioVisualFilesFailure = createAction(
  '[AudioVisuals] Patch Audio Visual Files Failure',
  props<{ error: Error }>()
);

const deleteAudioVisualFiles = createAction(
  '[AudioVisuals] Delete Audio Visual Files',
  props<{ audioVisual: AudioVisualModel, ids: number[] }>()
);

const deleteAudioVisualFilesSuccess = createAction(
  '[AudioVisuals] Delete Audio Visual Files Success',
  props<{ audioVisual: AudioVisualModel, ids: number[] }>()
);

const deleteAudioVisualFilesFailure = createAction(
  '[AudioVisuals] Delete Audio Visual Files Failure',
  props<{ error: Error }>()
);

const createAudioVisualFiles = createAction(
  '[AudioVisuals] Create Audio Visual Files',
  props<{ audioVisual: AudioVisualModel, audioVisualFiles: AudioVisualFilePostModel[] }>()
);

const createAudioVisualFilesSuccess = createAction(
  '[AudioVisuals] Create Audio Visual Files Success',
  props<{ audioVisual: AudioVisualModel, files: AudioVisualFileModel[] }>()
);

const createAudioVisualFilesFailure = createAction(
  '[AudioVisuals] Create Audio Visual Files Failure',
  props<{ error: Error }>()
);

// others
const setExpandedId = createAction(
  '[AudioVisuals] Set Collapsed Id',
  props<{ id: number }>()
);

const setError = createAction(
  '[AudioVisuals] Set Error',
  props<{ error: Error | null }>()
);

const setActiveAudioVisualId = createAction(
  '[AudioVisuals] Set Active Audio Visual Id',
  props<{ id: number }>()
);

const setCreateMode = createAction(
  '[AudioVisuals] Set Create Mode',
  props<{ status: boolean }>()
);

export const AudioVisualAction = {
  loadAudioVisuals,
  loadAudioVisualsSuccess,
  loadAudioVisualsFailure,

  loadAudioVisualsForDeepLinks,
  
  createAudioVisual,
  createAudioVisualSuccess,
  createAudioVisualFailure,

  patchAudioVisual,
  patchAudioVisualSuccess,
  patchAudioVisualFailure,

  deleteAudioVisual,
  deleteAudioVisualSuccess,
  deleteAudioVisualFailure,

  reorderAudioVisuals,
  reorderAudioVisualsSuccess,
  reorderAudioVisualsFailure,

  // files

  createAudioVisualFile,
  createAudioVisualFileSuccess,
  createAudioVisualFileFailure,

  patchAudioVisualFile,
  patchAudioVisualFileSuccess,
  patchAudioVisualFileFailure,

  deleteAudioVisualFile,
  deleteAudioVisualFileSuccess,
  deleteAudioVisualFileFailure,

  reorderAudioVisualFiles,
  reorderAudioVisualFilesSuccess,
  reorderAudioVisualFilesFailure,

  patchAudioVisualFiles,
  patchAudioVisualFilesSuccess,
  patchAudioVisualFilesFailure,

  deleteAudioVisualFiles,
  deleteAudioVisualFilesSuccess,
  deleteAudioVisualFilesFailure,

  createAudioVisualFiles,
  createAudioVisualFilesSuccess,
  createAudioVisualFilesFailure,

  // others
  setExpandedId,
  setError,
  setActiveAudioVisualId,
  setCreateMode,
}
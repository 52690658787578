import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAudioVisualReducer from './reducers/audio-visual.reducer';
import * as fromAudioVisualTranslationsReducer from './reducers/audio-visual-translations.reducer';

export const featureKey = 'audioVisualFeature';

export interface State {
  audioVisual: fromAudioVisualReducer.State;
  translations: fromAudioVisualTranslationsReducer.State,
}

export const combinedReducers = combineReducers({
  audioVisual: fromAudioVisualReducer.reducer,
  translations: fromAudioVisualTranslationsReducer.reducer,
});

// Wrap combined reducers by function for AOT build compatibility
export function reducer(state: State, action: Action): State {
  return combinedReducers(state, action);
}

export const selectAudioVisualFeature = createFeatureSelector<State>(featureKey);

export const selectAudioVisualState = createSelector(
  selectAudioVisualFeature,
  state => state.audioVisual
);

export const selectAudioVisualTranslationsState = createSelector(
  selectAudioVisualFeature,
  state => state.translations
);


const selectLoading = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectLoading
);

const selectUpdating = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectUpdating
);

const selectError = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectError
);

const selectActiveId = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectActiveId
);

const selectExpandedId = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectExpandedId
);

const selectCreateMode = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectCreateMode
);

const selectAudioVisuals = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectAudioVisuals
);

const selectFilesUpdateInProgress = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectFilesUpdateInProgress
);

const selectFilesDeleteInProgress = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectFilesDeleteInProgress
);

const selectFilesCreateInProgress = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectFilesCreateInProgress
);

const selectActiveAudioVisual = createSelector(
  selectAudioVisualState,
  fromAudioVisualReducer.selectActiveAudioVisual
);

const selectAudioVisualTranslations = createSelector(
  selectAudioVisualTranslationsState,
  fromAudioVisualTranslationsReducer.selectAllTranslations
);

const selectAudioVisualTranslationsActiveLanguage = createSelector(
  selectAudioVisualTranslationsState,
  fromAudioVisualTranslationsReducer.selectActiveLanguage
);

const selectAudioVisualTranslationsErorr = createSelector(
  selectAudioVisualTranslationsState,
  fromAudioVisualTranslationsReducer.selectError
);

const selectAudioVisualTranslationsLoading = createSelector(
  selectAudioVisualTranslationsState,
  fromAudioVisualTranslationsReducer.selectLoading
);

const selectAudioVisualTranslationsUpdating = createSelector(
  selectAudioVisualTranslationsState,
  fromAudioVisualTranslationsReducer.selectUpdating
);

const selectAllFilesTranslations = createSelector(
  selectAudioVisualTranslationsState,
  fromAudioVisualTranslationsReducer.selectAllFilesTranslations
);

const selectForActiveLanguage = createSelector(
  selectAudioVisualTranslationsState,
  fromAudioVisualTranslationsReducer.selectForActiveLanguage
);

export const AudioVisualSelectors = {
  getLoading: selectLoading,
  getUpdating: selectUpdating,
  getError: selectError,
  getActiveId: selectActiveId,
  getAudioVisuals: selectAudioVisuals,
  getActiveAudioVisual: selectActiveAudioVisual,
  getExpandedId: selectExpandedId,
  getCreateModeActive: selectCreateMode,
  getFilesUpdateInProgress: selectFilesUpdateInProgress,
  getFilesDeleteInProgress: selectFilesDeleteInProgress,
  getFilesCreateInProgress: selectFilesCreateInProgress,
}

export const AudioVisualTranslationsSelectors = {
  getAll: selectAudioVisualTranslations,
  getActiveLanguage: selectAudioVisualTranslationsActiveLanguage,
  getUpdating: selectAudioVisualTranslationsUpdating,
  getLoading: selectAudioVisualTranslationsLoading,
  getError: selectAudioVisualTranslationsErorr,
  getForActiveLanguage: selectForActiveLanguage,
  getFilesTranslations: selectAllFilesTranslations,
}
import { Directive, OnDestroy, AfterContentInit, Input, ContentChildren, QueryList, ElementRef } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';

import { FormValidateService } from '@shared/providers/form-validate.service';

@Directive({
  selector: '[appFormValidate]',
  providers: [
    FormValidateService,
  ]
})
export class FormValidateDirective implements OnDestroy, AfterContentInit {
  @Input('appFormValidate') initialControls: NgControl[];

  @ContentChildren(NgControl) controls: QueryList<NgControl>;

  get errors(): ValidationErrors {
    return this.formValidate.errors;
  }

  get element(): Element {
    return this.elementRef.nativeElement;
  }

  private unbindControls$: Subject<void>;
  private teardown$: Subject<void>;

  constructor(private elementRef: ElementRef, private formValidate: FormValidateService) {
    this.unbindControls$ = new Subject();
    this.teardown$ = new Subject();
  }

  ngOnDestroy() {
    this.unbindControls$.next();
    this.unbindControls$.complete();
    this.teardown$.next();
    this.teardown$.complete();
  }

  ngAfterContentInit() {
    this.controls.changes.pipe(takeUntil(this.teardown$), startWith(this.controls)).subscribe(
      controls => this.handleControlListChanges(controls)
    );

    // Add initial controls
    if (this.initialControls) {
      this.initialControls.forEach(control =>
        this.bindStatusChangesFor(control)
      );
    }

    // Initially populate all errors
    this.pupulateControlErrors();
  }

  private handleControlListChanges(controls: QueryList<NgControl>) {
    // Teardown old subscriptions to prevent overlaping subscriptions
    this.unbindControls$.next();

    // Rebind controls' status changes listener
    controls.forEach(control => this.bindStatusChangesFor(control));
  }

  private bindStatusChangesFor(control: NgControl) {
    control.statusChanges.pipe(takeUntil(this.unbindControls$)).subscribe(
      status => this.pupulateControlErrors()
    );
  }

  private pupulateControlErrors() {
    const collectedErrors = Object.create(null);

    this.controls.forEach(control =>
      Object.assign(collectedErrors, control.errors)
    );

    if (this.initialControls) {
      this.initialControls.forEach(control =>
        Object.assign(collectedErrors, control.errors)
      );
    }

    this.formValidate.setErrors(collectedErrors);
  }
}

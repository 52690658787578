import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromImportReducer from './import.reducer';

export const featureKey = 'importFeature';

export function reducer(state: fromImportReducer.State, action: Action): fromImportReducer.State {
  return fromImportReducer.reducer(state, action);
}

export const selectFeatureKey = createFeatureSelector<fromImportReducer.State>(featureKey);

const selectConfig = createSelector(
  selectFeatureKey,
  fromImportReducer.selectConfig
);

const selectLoading = createSelector(
  selectFeatureKey,
  fromImportReducer.selectLoading
);

const selectUpdating = createSelector(
  selectFeatureKey,
  fromImportReducer.selectUpdating
);

const selectError = createSelector(
  selectFeatureKey,
  fromImportReducer.selectError
);

const selectImporting = createSelector(
  selectFeatureKey,
  fromImportReducer.selectImporting
);

const selectImportingError = createSelector(
  selectFeatureKey,
  fromImportReducer.selectImportingError
);

export const ImportSelector = {
  selectConfig,
  selectLoading,
  selectUpdating,
  selectError,
  selectImporting,
  selectImportingError,
}
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ConsentSchemaCreateModel, ConsentSchemaModel } from "../models/consent-schema.model";
import { EventModel } from "@store/features/event/models/event.model";
import { mapConsentSchemaCreateModel, mapConsentSchemaModel } from "../models/mappers/consent-schema.mapper";
import { ConsentEventApiService } from "../apis/consent-event-api.service";
import { ConsentSchemaVersionModel } from "../models/consent-schema-version.model";
import { mapConsentSchemaTranslationCreateModel, mapConsentSchemaTranslationModel, mapConsentSchemaTranslationUpdateModel } from "../models/mappers/consent-schema-translation.mapper";
import { ConsentSchemaTranslationCreateModel, ConsentSchemaTranslationModel, ConsentSchemaTranslationUpdateModel } from "../models/consent-schema-translation.model";

@Injectable()
export class ConsentEventAdapterService {
  constructor(private api: ConsentEventApiService) {}

  getEventConsentSchemas(event: EventModel): Observable<ConsentSchemaModel[]> {
    return this.api.getEventConsentSchemas(event.id).pipe(
      map(entities => entities.map(mapConsentSchemaModel))
    );
  }

  createEventConsentSchema(event: EventModel, payload: ConsentSchemaCreateModel): Observable<ConsentSchemaModel> {
    const adaptedPayload = mapConsentSchemaCreateModel(payload);
    return this.api.createEventConsentSchema(event.id, adaptedPayload).pipe(
      map(entity => mapConsentSchemaModel(entity))
    );
  }

  updateEventConsentSchema(event: EventModel, schema: ConsentSchemaModel, payload: ConsentSchemaCreateModel): Observable<ConsentSchemaModel> {
    const adaptedPayload = mapConsentSchemaCreateModel(payload);
    return this.api.updateEventConsentSchema(event.id, schema.id, adaptedPayload).pipe(
      map(entity => mapConsentSchemaModel(entity))
    );
  }

  loadConsentSchemaTranslations(event: EventModel, schema: ConsentSchemaModel, version: ConsentSchemaVersionModel): Observable<ConsentSchemaTranslationModel[]> {
    return this.api.loadConsentSchemaTranslations(event.id, schema.id, version.id).pipe(
      map(entities => entities.map(mapConsentSchemaTranslationModel))
    );
  }

  createConsentSchemaTranslations(event: EventModel, schema: ConsentSchemaModel, version: ConsentSchemaVersionModel, payload: ConsentSchemaTranslationCreateModel): Observable<ConsentSchemaTranslationModel> {
    const adaptedPayload = mapConsentSchemaTranslationCreateModel(payload);
    return this.api.createConsentSchemaTranslation(event.id, schema.id, version.id, adaptedPayload).pipe(
      map(mapConsentSchemaTranslationModel)
    );
  }

  updateConsentSchemaTranslations(event: EventModel, schema: ConsentSchemaModel, version: ConsentSchemaVersionModel, translation: ConsentSchemaTranslationModel, payload: ConsentSchemaTranslationUpdateModel): Observable<ConsentSchemaTranslationModel> {
    const adaptedPayload = mapConsentSchemaTranslationUpdateModel(payload);
    return this.api.updateConsentSchemaTranslation(event.id, schema.id, version.id, translation.id, adaptedPayload).pipe(
      map(mapConsentSchemaTranslationModel)
    );
  }

  publishConsentSchema(event: EventModel, consentSchemaId: number): Observable<void> {
    return this.api.publishConsentSchema(event.id, consentSchemaId).pipe();
  }

  unpublishConsentSchema(event: EventModel, consentSchemaId: number): Observable<void> {
    return this.api.unpublishConsentSchema(event.id, consentSchemaId).pipe();
  }

  publishSchemaVersion(event: EventModel, schema: ConsentSchemaModel, version: ConsentSchemaVersionModel): Observable<void> {
    return this.api.publishSchemaVersion(event.id, schema.id, version.id).pipe();
  }

}

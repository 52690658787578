import { createAction, props } from '@ngrx/store';

import { AuthLoginModel } from '@app/account/models/auth-login.model';
import { AuthRegisterModel } from '@app/account/models/auth-register.model';
import { PasswordRemindModel } from '@app/account/models/password-remind.model';
import { PasswordResetModel } from '@app/account/models/password-reset.model';
import { TokenModel } from '@app/core/models/token.model';
import { AuthConfirmationEmailModel } from '@app/account/models/auth-confirmation-email.model';
import { SocialSingInData } from './auth.models';
import { SendMagicLinkPayload, SignInMagicLinkResponse } from '../models/magic-link.model';
import {ApiTokenModel} from "@app/core/models/api-token.model";

export const SIGN_IN_SUCCESS = '[Account/Auth] Sign In Success';

export const signIn = createAction('[Account/Auth] Sign In', props<{ credentials: AuthLoginModel }>());
export const signInSuccess = createAction(SIGN_IN_SUCCESS, props<{ token: TokenModel }>());
export const signInFailed = createAction('[Account/Auth] Sign In Failed', props<{ error: Error }>());

export const signInByFacebook = createAction('[Account/Auth] Sign In By Facebook', props<{ data: SocialSingInData }>());
export const signInByFacebookSuccess = createAction('[Account/Auth] Sign In By Facebook Success', props<{ token: TokenModel }>());
export const signInByFacebookFailure = createAction('[Account/Auth] Sign In By Facebook Failure', props<{ error: Error }>());

export const signInByApple = createAction('[Account/Auth] Sign In By Apple', props<{ data: SocialSingInData }>());
export const signInByAppleSuccess = createAction('[Account/Auth] Sign In By Apple Success', props<{ token: TokenModel }>());
export const signInByAppleFailure = createAction('[Account/Auth] Sign In By Apple Failure', props<{ error: Error }>());

export const signUp = createAction('[Account/Auth] Sign Up', props<{ details: AuthRegisterModel }>());
export const signUpSuccess = createAction('[Account/Auth] Sign Up Success', props<{ token: TokenModel }>());
export const signUpFailure = createAction('[Account/Auth] Sign Up Failed', props<{ error: Error }>());

export const resendConfirmationEmail = createAction('[Account/Auth] Resend Confirmation Email', props<{ payload: AuthConfirmationEmailModel }>());
export const resendConfirmationEmailSuccess = createAction('[Account/Auth] Resend Confirmation Email Success');
export const resendConfirmationEmailFailure = createAction('[Account/Auth] Resend Confirmation Email Failure', props<{ error: Error }>());

export const passwordRemind = createAction('[Account/Auth] Password Remind', props<{ payload: PasswordRemindModel }>());
export const passwordRemindSucceed = createAction('[Account/Auth] Password Remind Succeed');
export const passwordRemindFailure = createAction('[Account/Auth] Password Remind Failure', props<{ error: Error }>());

export const passwordReset = createAction('[Account/Auth] Password Reset', props<{ payload: PasswordResetModel }>());
export const passwordResetSucceed = createAction('[Account/Auth] Password Reset Succeed');
export const passwordResetFailure = createAction('[Account/Auth] Password Reset Failure', props<{ error: Error }>());

export const sendMagicLink = createAction('[Account/Auth] Send Magic Link', props<{ payload: SendMagicLinkPayload }>());
export const sendMagicLinkSuccess = createAction('[Account/Auth] Send Magic Link Success');
export const sendMagicLinkFailure = createAction('[Account/Auth] Send Magic Link Failure', props<{ error: Error }>());
export const setMagicLinkError = createAction('[Account/Auth] Set Magic Link Error', props<{ error: null }>());

export const signInByMagicLink = createAction('[Account/Auth] Sign In By Magic Link', props<{ magicLinkToken: string }>());
export const signInByMagicLinkSuccess = createAction('[Account/Auth] Sign In By Magic Link Success', props<{ token: ApiTokenModel }>());
export const signInByMagicLinkFailure = createAction('[Account/Auth] Sign In By Magic Link Failure', props<{ error: Error }>());

import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { AgendaRatingQuestionModel } from '@components/agenda/models/agenda-rating-question.model';

export const loadRatingQuestions = createAction(
  '[Agenda/Component] Load Rating Questions',
  props<{ event: EventModel, component: ComponentModel }>()
);

export const loadRatingQuestionsSuccess = createAction(
  '[Agenda/API] Load Rating Questions Success',
  props<{ questions: AgendaRatingQuestionModel[] }>()
);

export const loadRatingQuestionsFailure = createAction(
  '[Agenda/API] Load Rating Questions Failure',
  props<{ error: Error }>()
);

export const createRatingQuestion = createAction(
  '[Agenda/Component] Create Rating Question',
  props<{ event: EventModel, component: ComponentModel, question: AgendaRatingQuestionModel, answers: string[] }>()
);

export const createRatingQuestionSuccess = createAction(
  '[Agenda/API] Create Rating Question Success',
  props<{ question: AgendaRatingQuestionModel }>()
);

export const createRatingQuestionFailure = createAction(
  '[Agenda/API] Create Rating Question Failure',
  props<{ error: Error }>()
);

export const updateRatingQuestion = createAction(
  '[Agenda/Component] Update Rating Question',
  props<{ event: EventModel, component: ComponentModel, question: AgendaRatingQuestionModel, answers: string[] }>()
);

export const updateRatingQuestionSuccess = createAction(
  '[Agenda/API] Update Rating Question Success',
  props<{ question: AgendaRatingQuestionModel }>()
);

export const updateRatingQuestionFailure = createAction(
  '[Agenda/API] Update Rating Question Failure',
  props<{ error: Error }>()
);

export const reorderRatingQuestions = createAction(
  '[Agenda/Component] Reorder Rating Questions',
  props<{ event: EventModel, component: ComponentModel, questions: AgendaRatingQuestionModel[] }>()
);

export const reorderRatingQuestionSuccess = createAction(
  '[Agenda/API] Reorder Rating Questions Success',
  props<{ questions: AgendaRatingQuestionModel[] }>()
);

export const reorderRatingQuestionFailure = createAction(
  '[Agenda/API] Reorder Rating Questions Failure',
  props<{ error: Error }>()
);

export const deleteRatingQuestion = createAction(
  '[Agenda/Component] Delete Rating Question',
  props<{ event: EventModel, component: ComponentModel, question: AgendaRatingQuestionModel }>()
);

export const deleteRatingQuestionSuccess = createAction(
  '[Agenda/API] Delete Rating Question Success',
  props<{ question: AgendaRatingQuestionModel }>()
);

export const deleteRatingQuestionFailure = createAction(
  '[Agenda/API] Delete Rating Question Failure',
  props<{ error: Error }>()
);

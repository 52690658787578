import { Component, OnChanges, Input, SimpleChanges, ElementRef } from '@angular/core';

import { Observable, from } from 'rxjs';
import { switchMap, shareReplay } from 'rxjs/operators';

export interface IconCache {
  [key: string]: Observable<string>;
}

export const cachedIcons: IconCache = Object.create(null);

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnChanges {
  @Input() icon: string;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('icon' in changes) {
      this.loadIconFrom(this.icon);
    }
  }

  loadIcon(assetUrl: string): Observable<string> {
    // omit a fetch for already cached icons
    if (cachedIcons[assetUrl]) {
      return cachedIcons[assetUrl];
    }

    cachedIcons[assetUrl] = from(fetch(assetUrl)).pipe(
      switchMap(response => from(response.text())),
      shareReplay(1)
    );

    return cachedIcons[assetUrl];
  }

  private loadIconFrom(assetUrl: string) {
    this.loadIcon(assetUrl).subscribe(
      svgData => this.setHtmlContent(svgData)
    );
  }

  private setHtmlContent(htmlContent: string) {
    const hostElement: HTMLElement = this.elementRef.nativeElement;
    hostElement.innerHTML = htmlContent;
  }
}

import { Injectable, Optional, SkipSelf } from '@angular/core';

import { Subject, Observable } from 'rxjs';

import { CheckboxGroupDirective } from '@shared/directives/checkbox-group.directive';
import { CheckboxControlDirective } from '@shared/directives/checkbox-control.directive';

type CheckboxDirectiveUnion = CheckboxGroupDirective | CheckboxControlDirective;

@Injectable()
export class CheckboxGroupService {
  private controls: CheckboxDirectiveUnion[];
  private update$: Subject<void>;
  private controlsUpdated$: Subject<CheckboxDirectiveUnion>;

  constructor(@Optional() @SkipSelf() public parent: CheckboxGroupService) {
    this.controls = [];
    this.update$ = new Subject<void>();
    this.controlsUpdated$ = new Subject<CheckboxDirectiveUnion>();
  }

  isAllChecked(): boolean {
    if (!this.controls.length) {
      return false;
    }

    return this.controls.every(control => control.checked && !control.indeterminate);
  }

  isAnyChecked(): boolean {
    if (!this.controls.length) {
      return false;
    }

    return this.controls.some(control => control.checked);
  }

  getUpdate(): Observable<void> {
    return this.update$.asObservable();
  }

  getControls(): CheckboxDirectiveUnion[] {
    return this.controls;
  }

  addControl(control: CheckboxDirectiveUnion) {
    this.controls.push(control);
    this.update();
    this.controlsUpdated$.next(control);
  }

  removeControl(control: CheckboxDirectiveUnion) {
    const removeIndex = this.controls.indexOf(control);

    if (removeIndex !== -1) {
      this.controls.splice(removeIndex, 1);
    }

    this.update();
    this.controlsUpdated$.next(control);
  }

  addGroup(control: CheckboxGroupDirective) {
    if (this.parent) {
      this.parent.addControl(control);
    }

    this.update();
  }

  getControlsUpdated(): Observable<CheckboxDirectiveUnion> {
    return this.controlsUpdated$?.asObservable();
  }

  setControlChecked(control: CheckboxGroupDirective, checked: boolean) {
    if(checked) {
      control.check();
    } else {
      control.uncheck();
    }
    this.update();
  }

  removeGroup(control: CheckboxGroupDirective) {
    if (this.parent) {
      this.parent.removeControl(control);
    }
    this.update();
  }

  checkAll() {
    this.controls.forEach(control => control.check());
    this.update();
  }

  uncheckAll() {
    this.controls.forEach(control => control.uncheck());
    this.update();
  }

  update() {
    this.update$.next();

    if (this.parent) {
      this.parent.update();
    }
  }

  getCheckedData(fromRoot?: boolean): any[] {
    if (fromRoot && this.parent) {
      return this.parent.getCheckedData(true);
    }

    return this.controls.filter(control => control.checked).map(control => control.data);
  }
}

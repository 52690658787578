import { Component, AfterViewInit, ViewChild, HostListener, NgZone, ElementRef } from '@angular/core';

@Component({
  selector: 'app-batch-action-group',
  templateUrl: './batch-action-group.component.html',
  styleUrls: ['./batch-action-group.component.scss']
})
export class BatchActionGroupComponent implements AfterViewInit {
  @ViewChild('scrollable', { static: true }) scrollable: ElementRef;
  @ViewChild('content', { static: true }) content: ElementRef;

  leftArrowVisible: boolean;
  rightArrowVisible: boolean;

  private scrollDirection: number;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit() {
    // Delegate arrows visibility update
    setTimeout(() => this.updateArrowsVisibility());
  }

  startScrolling(direction: number) {
    this.scrollDirection = direction;

    // Disable Angular's internal changes detection
    // by running frame update outside the Angular's zone
    this.ngZone.runOutsideAngular(() => {
      this.scrollUpdateFrame();
    });
  }

  onMouseWheel(ev: WheelEvent) {
    this.ngZone.runOutsideAngular(() => {
      this.scrollContentBy(-ev.deltaY);
    });

    // Stop browser content scrolling
    if (this.leftArrowVisible || this.rightArrowVisible) {
      ev.preventDefault();
    }
  }

  @HostListener('window:mouseup')
  onWindowMouseUp() {
    this.scrollDirection = null;
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.updateArrowsVisibility();
  }

  private scrollUpdateFrame() {
    // Animate scrolling only on mousedown
    if (this.scrollDirection) {
      this.scrollContentBy(this.scrollDirection);
      requestAnimationFrame(() => this.scrollUpdateFrame());
    }
  }

  private scrollContentBy(scrollBy: number) {
    const scrollable = this.scrollable.nativeElement as HTMLElement;
    const content = this.content.nativeElement as HTMLElement;

    const leftPos = content.offsetLeft;
    const maxLeftPos = -(content.clientWidth - scrollable.clientWidth);
    const nextLeftPos = Math.min(0, Math.max(maxLeftPos, leftPos + scrollBy));

    content.style.left = `${nextLeftPos}px`;

    // Update arrows visibility but in Angular's zone
    this.ngZone.run(() => this.updateArrowsVisibility());
  }

  private updateArrowsVisibility() {
    const scrollable = this.scrollable.nativeElement as HTMLElement;
    const content = this.content.nativeElement as HTMLElement;

    if (content.offsetLeft < 0) {
      this.leftArrowVisible = true;
    } else {
      this.leftArrowVisible = false;
    }

    const rightOffset = content.clientWidth + content.offsetLeft;
    if (rightOffset > scrollable.clientWidth) {
      this.rightArrowVisible = true;
    } else {
      this.rightArrowVisible = false;
    }
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { AgendaTicketModel } from '@components/agenda/models/agenda-ticket.model';

import * as AgendaTicketActions from '../actions/agenda-ticket.actions';
import { selectEvent } from '@store/features/event/actions';

export const featureKey = 'agendaTicket';

export interface State extends EntityState<AgendaTicketModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<AgendaTicketModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: State = {
  ...adapter.getInitialState(),
  loading: false,
  loaded: false,
  error: null,
};

export const agendaTicketReducer = createReducer(
  initialState,
  on(AgendaTicketActions.loadAgendaTickets, state => ({
    ...initialState,
    loading: true,
  })),
  on(AgendaTicketActions.loadAgendaTicketsSuccess, (state, { tickets }) =>
    adapter.setAll(tickets, { ...state, loading: false, loaded: true })
  ),
  on(AgendaTicketActions.loadAgendaTicketsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return agendaTicketReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectAgendaTicketIds = selectIds;

export const selectAgendaTicketEntiites = selectEntities;

export const selectAllAgendaTickets = selectAll;

export const selectAgendaTicketLoading = (state: State) => state.loading;

export const selectAgendaTicketLoaded = (state: State) => state.loaded;

export const selectAgendaTicketError = (state: State) => state.error;

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-add',
  templateUrl: './button-add.component.html',
  styleUrls: ['./button-add.component.scss']
})
export class ButtonAddComponent {
  @Input() alt: string;
  @Input() disabled: boolean;

  @Output() add: EventEmitter<Event>;

  constructor() {
    this.add = new EventEmitter<Event>();
  }
}

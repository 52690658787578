import { Action, createReducer, on } from '@ngrx/store';

import * as ApplicationActions from '../actions/application.actions';

export interface State {
  menuExpanded: boolean;
}

export const initialState: State = {
  menuExpanded: true,
};

export const applicationReducer = createReducer(
  initialState,
  on(
    ApplicationActions.toggleMenuExpand,
    state => ({ menuExpanded: !state.menuExpanded })
  ),
);

export function reducer(state: State, action: Action): State {
  return applicationReducer(state, action);
}

export const selectMenuExpanded = (state: State) => state.menuExpanded;

import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { ComponentDefinitionModel } from '@app/construct-steps/models/component-definition.model';

import * as ComponentPlanActions from '../actions/component-plan.actions';

export const featureKey = 'componentDefinition';

export interface State extends EntityState<ComponentDefinitionModel> {}

export const adapter = createEntityAdapter<ComponentDefinitionModel>({
  selectId: componentDefinition => componentDefinition.name,
  sortComparer: (a, b) => a.friendlyName?.localeCompare(b.friendlyName),
});

export const initialState: State = {
  ...adapter.getInitialState(),
};

export const componentReducer = createReducer(
  initialState,
  on(ComponentPlanActions.loadComponentPlans, state => ({ ...initialState })),
  on(ComponentPlanActions.loadComponentPlansSuccess, (state, { definitions }) =>
    adapter.setAll(definitions, state)
  )
);

export function reducer(state: State, action: Action): State {
  return componentReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectComponentDefinitionIds = selectIds;

export const selectComponentDefinitionEntities = selectEntities;

export const selectAllComponentDefinitions = selectAll;

import {DeepLinkConstants} from '@utils/deep-link/deep-link-const';

declare global {
  interface String {
    getSegmentAtPosition(position: number): string;
  }
}

String.prototype.getSegmentAtPosition = function (position: number) {
  const segments = this.split("/");
  return position < segments.length ? segments[position] : null;
};

export function getBaseDeepLink(): string {
  return `${DeepLinkConstants.DEEP_LINK_SCHEME}://${DeepLinkConstants.DEEP_LINK_HOST}/${DeepLinkConstants.APP_SEGMENT_NAME}`;
}

export function isValidDeepLink(deepLink: string): boolean {
  return deepLink?.startsWith(getBaseDeepLink().toString())
}

export function isGlobalDeepLink(deepLink: string): boolean {
  return isValidDeepLink(deepLink) && !Number.isInteger(deepLink.getSegmentAtPosition(DeepLinkConstants.EVENT_ID_SEGMENT_POSITION));
}

export function hasComponentSegment(deepLink: string): boolean {
  return deepLink.split('/').length > DeepLinkConstants.COMPONENT_SEGMENT_POSITION
}

export function hasComponentTypeSegment(deepLink: string): boolean {
  return deepLink.split('/').length > DeepLinkConstants.COMPONENT_TYPE_SEGMENT_POSITION;
}

export function trimDeepLinkToComponentSegment(deepLink: string): string {
  if(isValidDeepLink(deepLink) && hasComponentSegment(deepLink)) {
    return deepLink
      .split('/')
      .reduce((acc, segment, currentIndex) => {
        return currentIndex >= DeepLinkConstants.COMPONENT_SEGMENT_POSITION ? `${acc}/${segment}` : acc;
      }, "")
  }
  return "";
}

export function getEventIdFromDeepLink(deepLink: string): number {
  if(isValidDeepLink(deepLink) && deepLink.getSegmentAtPosition(DeepLinkConstants.EVENT_ID_SEGMENT_POSITION) != null) {
    return Number.parseInt(deepLink.getSegmentAtPosition(DeepLinkConstants.EVENT_ID_SEGMENT_POSITION));
  } else {
    return null;
  }
}

export function getComponentIdFromDeepLink(deepLink: string) : number {
  if(isValidDeepLink(deepLink) && deepLink.getSegmentAtPosition(DeepLinkConstants.COMPONENT_ID_SEGMENT_POSITION) != null) {
    return Number.parseInt(deepLink.getSegmentAtPosition(DeepLinkConstants.COMPONENT_ID_SEGMENT_POSITION));
  } else {
    return null;
  }
}

export function getComponentTypeFromDeepLink(deepLink: string) : string {
  if(isValidDeepLink(deepLink)) {
    return deepLink.getSegmentAtPosition(DeepLinkConstants.COMPONENT_TYPE_SEGMENT_POSITION);
  } else {
    return null;
  }
}

//
// DEEPLINK POPULATE FUNCTIONS
//
export function getComponentChildIds(deepLink: string) {
    return {
      firstLevelChildId: getFirstLevelChildId(deepLink),
      secondLevelChildId: getSecondLevelChildId(deepLink)
    }
}

export function getFirstLevelChildId(deepLink: string): number {
  if(isValidDeepLink(deepLink) && hasComponentTypeSegment(deepLink)) {
    let componentType = getComponentTypeFromDeepLink(deepLink);
    if(componentType) {
      let childIdPosition = getFirstLevelChildIdPosition(componentType);
      if(childIdPosition) {
        return Number.parseInt(deepLink.getSegmentAtPosition(childIdPosition));
      }
    }
  }
  return null;
}

export function getSecondLevelChildId(deepLink: string): number {
  if(isValidDeepLink(deepLink) && hasComponentTypeSegment(deepLink)) {
    let componentType = getComponentTypeFromDeepLink(deepLink);
    if(componentType) {
      let childIdPosition = getSecondLevelChildIdPosition(componentType);
      if(childIdPosition) {
        return Number.parseInt(deepLink.getSegmentAtPosition(childIdPosition));
      }
    }
  }
  return null;
}

function getFirstLevelChildIdPosition(componentType: string) {
  switch(componentType) {
    case DeepLinkConstants.BUSINESS_MATCHING_COMPONENT_SEGMENT_NAME:
    case DeepLinkConstants.BOOKING_COMPONENT_SEGMENT_NAME:
    case DeepLinkConstants.TAXI_COMPONENT_SEGMENT_NAME: {
      return 11;
    }
    default: {
      return 10;
    }
  }
}

function getSecondLevelChildIdPosition(componentType: string) {
  switch(componentType) {
    case DeepLinkConstants.AGENDA_COMPONENT_SEGMENT_NAME:
    case DeepLinkConstants.FEEDWALL_COMPONENT_SEGMENT_NAME:
    case DeepLinkConstants.VENUE_COMPONENT_SEGMENT_NAME:
    case DeepLinkConstants.RESOURCE_COMPONENT_SEGMENT_NAME:
    case DeepLinkConstants.INTERACTIVE_MAP_COMPONENT_SEGMENT_NAME:
    case DeepLinkConstants.AUDIO_VISUAL_COMPONENT_SEGMENT_NAME: {
      return 12;
    }
    default: {
      return null;
    }
  }
}

export interface ComponentDeepLinkSegments {
  eventId: string;
  componentId: string;
  frLvl: string;
  secLvl?: string;
}

import { TagApiModel, TagType } from "@store/features/tags/tags.models";

export function sortByTagType(a: TagApiModel, b: TagApiModel) {
  if (a.type_tag === TagType.System && b.type_tag !== TagType.System) {
      return -1;
  } else if (a.type_tag !== TagType.System && b.type_tag === TagType.System) {
      return 1;
  } else {
      return 0;
  }
}
import { LEGACY_LANDING_PAGE_BASE_URL, APP_DOMAIN } from '@env/environment';
import { EventModel } from '@store/features/event/models/event.model';

export class EventUrlBuilder {
  private static buildBaseUrl(subdomain: string): string {
    return `https://${subdomain}.${APP_DOMAIN}`;
  }

  public static getEventLandingUrl(event: EventModel, options?: { email?: string }): string {
    const baseUrl = this.buildBaseUrl(event.lpSubdomain);
    const eventPath = `/event/${event.lpUrl}`;

    if (options?.email) {
      const encodedEmail = encodeURIComponent(options.email);
      return `${baseUrl}${eventPath}?sign_in_with_email=${encodedEmail}`;
    }

    return `${baseUrl}${eventPath}`;
  }

  public static getLegacyEventLandingUrl(event: EventModel): string {
    return `${LEGACY_LANDING_PAGE_BASE_URL}/${event.lpUrl}`;
  }
}

import { Component, OnInit, HostBinding, forwardRef } from '@angular/core';
import { ControlValueAccessor, Validator, ValidationErrors, AbstractControl, FormGroup, FormControl, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor, Validator {
  @HostBinding('class.form-row') get _formRow() {
    return true;
  }

  pickerForm: FormGroup;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor() {
    this.createForm();
  }

  ngOnInit() {
    this.pickerForm.valueChanges.subscribe(
      value => this.propagateChanges(value)
    );
  }

  writeValue(date: string): void {
    this.pickerForm.patchValue({
      date: date ? new Date(date) : null,
      time: date ? new Date(date) : null
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.pickerForm.disable();
    } else {
      this.pickerForm.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.pickerForm.valid ? null : { dateTimePickerError: { valid: false } };
  }

  private createForm() {
    this.pickerForm = new FormGroup({
      date: new FormControl(null, [Validators.required]),
      time: new FormControl(null, [Validators.required]),
    });
  }

  private propagateChanges(value: any) {
    const date: Date = value.date;
    const time: Date = value.time;

    if (this.onChange) {
      if (!date || !time) {
        this.onChange(null);
        return;
      }

      // fix timezone to GMT+0
      date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
      date.setHours(time.getHours(), time.getMinutes());

      this.onChange(date.toISOString());
    }
  }
}

import { createAction, props } from "@ngrx/store";
import { EventUserResponseWithMetaApiModel, EventUserSetup, UserBatchActionStatus, UsersTableColumn } from "../models/user.models";
import { Pagination } from "@shared/components/paginator/paginator.component";
import { EventUserTagApiModel } from "@shared/models/event-user-tag.api-model";
import { UserApiModel } from "@shared/models/user.api-model";
import { EventUserApiModel } from "@shared/models/event-user.api-model";

const setTableColumns = createAction(
  '[APP/USER] Set Table Columns',
  props<{ columns: UsersTableColumn[] }>(),
);

const setCheckedViewActive = createAction(
  '[APP/USER] Set Checked View Active',
  props<{ active: boolean }>(),
);

const setEntitiesSetup = createAction(
  '[APP/USER] Set Entities Setup',
  props<{ setup: EventUserSetup }>(),
);

const paginatorRefresh = createAction(
  '[APP/USER] Paginator Refresh',
  props<{ pagination: Pagination }>(),
);

const setSearchTerm = createAction(
  '[APP/USER] Set Search Term',
  props<{ term: string }>(),
);

const setSearchBy = createAction(
  '[APP/USER] Set Search By',
  props<{ searchBy: string[] }>(),
);

const setError = createAction(
  '[APP/USER] Set Error',
  props<{ error: Error | null }>(),
);

const setFiltersOpen = createAction(
  '[APP/USER] Set Filters Open',
  props<{ open: boolean }>(),
);

const setMobileFiltersActive = createAction(
  '[APP/USER] Set Mobile Filters Active',
  props<{ active: boolean }>(),
);

const loadUsersBySetup = createAction(
  '[APP/USER] Load Users By Setup',
  props<{ setup: EventUserSetup }>(),
);

const loadUsersBySetupSuccess = createAction(
  '[APP/USER] Load Users By Setup Success',
  props<{ data: EventUserResponseWithMetaApiModel }>(),
);

const loadUsersBySetupFailure = createAction(
  '[APP/USER] Load Users By Setup Failure',
  props<{ error: Error }>(),
);

const resendUsersEventTicketEmail = createAction(
  '[APP/USER] Resend Users Event Ticket Email',
  props<{ uuids: string[] }>(),
);

const resendUsersEventTicketEmailSuccess = createAction(
  '[APP/USER] Resend Users Event Ticket Email Success',
  props<{ results: UserBatchActionStatus[] }>(),
);

const resendUsersEventTicketEmailFailure = createAction(
  '[APP/USER] Resend Users Event Ticket Email Failure',
  props<{ error: Error }>(),
);

const sendUsersInvitations = createAction(
  '[APP/USER] Send Users Invitations',
  props<{ uuids: string[] }>(),
);

const sendUsersInvitationsSuccess = createAction(
  '[APP/USER] Send Users Invitations Success',
);

const sendUsersInvitationsFailure = createAction(
  '[APP/USER] Send Users Invitations Failure',
  props<{ error: Error }>(),
);

const sendUsersMarketingEmail = createAction(
  '[APP/USER] Send Users Marketing Email',
  props<{ uuids: string[] | null }>(),
);

const sendUsersMarketingEmailSuccess = createAction(
  '[APP/USER] Send Users Marketing Email Success',
  props<{ results: UserBatchActionStatus[] }>(),
);

const sendUsersMarketingEmailFailure = createAction(
  '[APP/USER] Send Users Marketing Email Failure',
  props<{ error: Error }>(),
);

const checkInUser = createAction(
  '[APP/USER] Check In User',
  props<{ uuid: string, userHash: string }>(),
);

const checkInUserSuccess = createAction(
  '[APP/USER] Check In User Success',
);

const checkInUserFailure = createAction(
  '[APP/USER] Check In User Failure',
  props<{ error: Error }>(),
);

const checkInUsers = createAction(
  '[APP/USER] Check In Users',
  props<{ uuids: string[] }>(),
);

const checkInUsersSuccess = createAction(
  '[APP/USER] Check In Users Success',
  props<{ results: UserBatchActionStatus[] }>(),
);

const checkInUsersFailure = createAction(
  '[APP/USER] Check In Users Failure',
  props<{ error: Error }>(),
);

const cancelCheckInUser = createAction(
  '[APP/USER] Cancel Check In User',
  props<{ uuid: string, userHash: string }>(),
);

const cancelCheckInUserSuccess = createAction(
  '[APP/USER] Cancel Check In User Success',
);

const cancelCheckInUserFailure = createAction(
  '[APP/USER] Cancel Check In User Failure',
  props<{ error: Error }>(),
);

const cancelCheckInUsers = createAction(
  '[APP/USER] Cancel Check In Users',
  props<{ uuids: string[] }>(),
);

const cancelCheckInUsersSuccess = createAction(
  '[APP/USER] Cancel Check In Users Success',
  props<{ results: UserBatchActionStatus[] }>(),
);

const cancelCheckInUsersFailure = createAction(
  '[APP/USER] Cancel Check In Users Failure',
  props<{ error: Error }>(),
);

const acceptUserRequest = createAction(
  '[APP/USER] Accept User Request',
  props<{ uuid: string }>(),
);

const acceptUserRequestSuccess = createAction(
  '[APP/USER] Accept User Request Success',
  props<{ user: EventUserApiModel }>(),
);

const acceptUserRequestFailure = createAction(
  '[APP/USER] Accept User Request Failure',
  props<{ error: Error }>(),
);

const downloadUserQrCodes = createAction(
  '[APP/USER] Download User Qr Codes',
  props<{ uuids: string[] }>(),
);

const downloadUserQrCodesSuccess = createAction(
  '[APP/USER] Download User Qr Codes Success',
);

const downloadUserQrCodesFailure = createAction(
  '[APP/USER] Download User Qr Codes Failure',
  props<{ error: Error }>(),
);

const removeUser = createAction(
  '[APP/USER] Remove User',
  props<{ uuid: string }>(),
);

const removeUserSuccess = createAction(
  '[APP/USER] Remove User Success',
  props<{ uuid: string }>(),
);

const removeUserFailure = createAction(
  '[APP/USER] Remove User Failure',
  props<{ error: Error }>(),
);

const loadUserTags = createAction(
  '[APP/USER] Load User Tags',
  props<{ uuids: string[] }>(),
);

const loadUserTagsSuccess = createAction(
  '[APP/USER] Load User Tags Success',
  props<{ data: EventUserTagApiModel }>(),
);

const loadUserTagsFailure = createAction(
  '[APP/USER] Load User Tags Failure',
  props<{ error: Error }>(),
);

const loadSingleUser = createAction(
  '[APP/USER] Load Single User',
  props<{ uuid: string }>(),
);

const loadSingleUserSuccess = createAction(
  '[APP/USER] Load Single User Success',
  props<{ user: UserApiModel }>(),
);

const loadSingleUserFailure = createAction(
  '[APP/USER] Load Single User Failure',
  props<{ error: Error }>(),
);

const loadUserAdmin = createAction(
  '[APP/USER] Load User Admin',
  props<{ uuid: string, addToUsersTable: boolean }>(),
);

const loadUserAdminSuccess = createAction(
  '[APP/USER] Load User Admin Success',
  props<{ user: EventUserApiModel, addToUsersTable: boolean }>(),
);

const loadUserAdminFailure = createAction(
  '[APP/USER] Load User Admin Failure',
  props<{ error: Error }>(),
);

const setCheckedUsers = createAction(
  '[APP/USER] Set Checked Users',
  props<{ users: EventUserApiModel[] }>(),
);

const addCheckedUsers = createAction(
  '[APP/USER] Add Checked Users',
  props<{ users: EventUserApiModel[] }>(),
);

const deleteCheckedUsers = createAction(
  '[APP/USER] Delete Checked Users',
  props<{ users: EventUserApiModel[] }>(),
);

const resetUsersActionResults = createAction(
  '[APP/USER] Reset Users Action Results',
);

const resetUserActionError = createAction(
  '[APP/USER] Reset User Action Error',
);

const reset = createAction(
  '[APP/USER] Reset',
);

const setAreFiltered = createAction(
  '[APP/USER] Set Are Filtered',
  props<{ areFiltered: boolean }>(),
);

const setUserActionSuccess = createAction(
  '[APP/USER] Set User Action Success',
  props<{ translationKey: string }>(),
);

export const UserAction = {
  setTableColumns,
  setCheckedViewActive,
  setEntitiesSetup,
  paginatorRefresh,
  setSearchTerm,
  setSearchBy,
  setError,
  setFiltersOpen,
  setMobileFiltersActive,

  loadUsersBySetup,
  loadUsersBySetupSuccess,
  loadUsersBySetupFailure,

  resendUsersEventTicketEmail,
  resendUsersEventTicketEmailSuccess,
  resendUsersEventTicketEmailFailure,

  sendUsersInvitations,
  sendUsersInvitationsSuccess,
  sendUsersInvitationsFailure,

  sendUsersMarketingEmail,
  sendUsersMarketingEmailSuccess,
  sendUsersMarketingEmailFailure,

  checkInUser,
  checkInUserSuccess,
  checkInUserFailure,

  checkInUsers,
  checkInUsersSuccess,
  checkInUsersFailure,

  cancelCheckInUser,
  cancelCheckInUserSuccess,
  cancelCheckInUserFailure,

  cancelCheckInUsers,
  cancelCheckInUsersSuccess,
  cancelCheckInUsersFailure,

  acceptUserRequest,
  acceptUserRequestSuccess,
  acceptUserRequestFailure,

  downloadUserQrCodes,
  downloadUserQrCodesSuccess,
  downloadUserQrCodesFailure,

  removeUser,
  removeUserSuccess,
  removeUserFailure,

  loadUserTags,
  loadUserTagsSuccess,
  loadUserTagsFailure,

  loadSingleUser,
  loadSingleUserSuccess,
  loadSingleUserFailure,

  loadUserAdmin,
  loadUserAdminSuccess,
  loadUserAdminFailure,

  setCheckedUsers,
  addCheckedUsers,
  deleteCheckedUsers,

  resetUsersActionResults,
  resetUserActionError,
  reset,
  setAreFiltered,

  setUserActionSuccess,
}

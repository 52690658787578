import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-color-pallete',
  templateUrl: './color-pallete.component.html',
  styleUrls: ['./color-pallete.component.scss']
})
export class ColorPalleteComponent {
  @Input() colors: string[];
}

import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { AttachmentModel } from '@shared/models/attachment.model';

export interface MobilePreviewContext {
  banner: AttachmentModel;
  logo: AttachmentModel;
  title: string;
  startDate: string;
  endDate: string;
  description: string;
  placeAddress: string;
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: 'root'
})
export class MobilePreviewService {
  private context$: BehaviorSubject<MobilePreviewContext>;

  constructor() {
    this.context$ = new BehaviorSubject<MobilePreviewContext>({
      banner: null, logo: null, title: '', startDate: '', endDate: '', description: '', placeAddress: '', latitude: 0, longitude: 0
    });
  }

  getContext(): Observable<MobilePreviewContext> {
    return this.context$.asObservable();
  }

  update(newContext: Partial<MobilePreviewContext>) {
    this.context$.next({ ...this.context$.value, ...newContext });
  }
}

<div class="input-group-prepend">
  <div class="input-group-text">
    <img class="input-icons-left" src="/assets/img/icons/search.svg" alt="Search">
  </div>
</div>

<input
  #nativeInput
  class="form-control"
  type="text"
  [placeholder]="placeholder"
  (change)="onValueChange($event)"
  (input)="onValueChange($event)"
  (blur)="onBlur($event)"
  (keydown)="onKeydown($event)">

import { Action, createReducer, on } from '@ngrx/store';

import { EventModel } from '../models/event.model';

import * as EventActions from '../actions/event.actions';
import { clearEventState } from '../actions/event.actions';

export const featureKey = 'event';

export interface State {
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  error: Error;
  event: EventModel | null,
  activeId: number | null,
  publishing: boolean;
  unpublishing: boolean;
}

export const initialState: State = {
  event: null,
  activeId: null,
  loading: false,
  loaded: false,
  saving: false,
  error: null,
  publishing: false,
  unpublishing: false,
};

export const eventReducer = createReducer(
  initialState,
  on(EventActions.loadEvent, state => ({ ...state, loading: true })),
  on(EventActions.loadEventSuccess, (state, { event }) => ({ ...state, loading: false, event, loaded: true })),
  on(
    EventActions.loadEventFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),

  on(EventActions.createEvent,
    EventActions.updateEvent,
    EventActions.updateMainEventProperties,
    EventActions.updateOnlyBaseLanguageLayer,
    EventActions.updateEventHighlighted,
      state => ({
      ...state,
      saving: true,
      })),
  on(
    EventActions.createEventSuccess,
    EventActions.updateEventSuccess,
    EventActions.updateMainEventPropertiesSuccess,
    EventActions.updateOnlyBaseLanguageLayerSuccess,
    EventActions.updateEventHighlightedSuccess,
    (state, { event }) => ({ ...state, saving: false, loaded: true, activeId: event.id, event })),
  on(
    EventActions.createEventFailure,
    EventActions.updateEventFailure,
    EventActions.updateMainEventPropertiesFailure,
    EventActions.updateOnlyBaseLanguageLayerFailure,
    EventActions.updateEventHighlightedFailure,
    (state, { error }) => ({ ...state, saving: false, error })
  ),
  on(EventActions.publishEvent, state => ({ ...state, publishing: true })),
  on(EventActions.publishEventSuccess, (state, { event }) => ({ ...state, publishing: false, event })),
  on(EventActions.publishEventFailure, (state, { error }) => ({
    ...state,
    publishing: false,
    error,
  })),
  on(EventActions.unpublishEvent, state => ({ ...state, unpublishing: true })),
  on(EventActions.unpublishEventSuccess, (state, { event }) => ({ ...state, unpublishing: false, event })),
  on(EventActions.unpublishEventFailure, (state, { error }) => ({
    ...state,
    unpublishing: false,
    error,
  })),
  on(EventActions.selectEvent, (state, { eventId }) => ({
    ...state,
    activeId: eventId,
  })),
  
  on(clearEventState, (state) => ({ ...initialState })),
);

export function reducer(state: State, action: Action): State {
  return eventReducer(state, action);
}

export const selectEventLoading = (state: State) => state.loading;

export const selectEventLoaded = (state: State) => state.loaded;

export const selectActiveEventId = (state: State) => state.activeId;

export const selectEventSaving = (state: State) => state.saving;

export const selectEventError = (state: State) => state.error;

export const selectActiveEvent = (state: State) => state ? state.event : null;

export const selectEventPublishing = (state: State) => state.publishing;

export const selectEventUnpublishing = (state: State) => state.unpublishing;
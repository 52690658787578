import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements AfterViewInit {
  @Input() disabled: boolean = false;
  @Input() itemId?: number;
  @Input() openAtInit: number | null = null;
  @Input() loading: boolean = false;
  @Input() noHeaderPadding: boolean = false;
  @Input() hideToggle: boolean = false;

  @Output() expandedChanged: EventEmitter<boolean>;

  @ViewChild(MatExpansionPanel) expansionPanel!: MatExpansionPanel;

  constructor () {
    this.expandedChanged = new EventEmitter<boolean>();
  }

  ngAfterViewInit(): void {
    if (this.openAtInit === this.itemId) {
      setTimeout(() => this.open(), 500);
    }
  }

  open(): void {
    this.expansionPanel?.open();
  }

  close(): void {
    this.expansionPanel?.close();
  }

  handleExpandedChange(state: boolean): void {
    this.expandedChanged.emit(state);
  }

  // handleExpandedChange(_: boolean): void {
  //   if (this.outControlledState && !this.manualOpen) {
  //     this.close();
  //   }
  //   this.manualOpen = false;
  // }
}
import { InjectionToken } from '@angular/core';
import { Router } from '@angular/router';

export const EXTERNAL_REDIRECT_HANDLER = new InjectionToken('External Redirect Handler');

export function externalRedirectHandlerFactory(router: Router) {
  return () => {
    const url = window.location.href;

    magicLinkRedirect(router, url);
    changePasswordRedirect(router, url);
    emailConfirmationRedirect(router, url)

    return Promise.resolve();
  };
}

const magicLinkRedirect = (router: Router, url: string) => {
  const magicLinkParam = decodeURIComponent(new URL(url).searchParams.get('magic_link') || "");

  if (magicLinkParam) {
    router.navigate(['/account/login'], { queryParams: { magic_link: magicLinkParam } });
  }
};

const changePasswordRedirect = (router: Router, url: string) => {
  const resetPasswordUrlPart = url.includes('/reset-password');
  const tokenParam = decodeURIComponent(new URL(url).searchParams.get('token') || "");
  const emailParam = decodeURIComponent(new URL(url).searchParams.get('email') || "");
  const firstNameParam = decodeURIComponent(new URL(url).searchParams.get('first_name') || "");
  const lastNameParam = decodeURIComponent(new URL(url).searchParams.get('last_name') || "");
  const termsParam = decodeURIComponent(new URL(url).searchParams.get('termsParam') || "true");
  if (resetPasswordUrlPart && tokenParam && emailParam) {
    router.navigate(['/account', 'forgot-password-submit'], {
      queryParams: {
        'token': tokenParam,
        'email': emailParam,
        'first_name': firstNameParam, // optional
        'last_name': lastNameParam, // optional
        'terms': termsParam
      },
    });
  }
}

const emailConfirmationRedirect = (router: Router, url: string) => {
  const emailConfirmationSuccessUrlPart = url.includes('/registration/completed');
  const emailConfirmationErrorUrlPart = url.includes('/registration/invalid-token');
  if (emailConfirmationSuccessUrlPart) {
    router.navigate(['/account/login'], {
      queryParams: {
        'email_confirmation_success': true,
      },
    });
  }  else if(emailConfirmationErrorUrlPart) {
    router.navigate(['/account/login'], {
      queryParams: {
        'email_confirmation_error_code': true,
      },
    });
  }
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EventFacadeService } from '@store/features/event/event-facade.service';

import { EventModel } from '@store/features/event/models/event.model';

@Injectable({
  providedIn: 'root'
})
export class ConstructorService {
  constructor(private eventFacade: EventFacadeService) {}

  getEvent(): Observable<EventModel> {
    return this.eventFacade.getActiveEvent() as any;
  }

  getLoading(): Observable<boolean> {
    return this.eventFacade.getEventLoading();
  }
}

export enum FirstToOpenMode {
  None = 'none',
  LoginRequired = 'login_required',
  Disabled = 'disabled',
}

export interface ComponentModel {
  id: number;
  eventId: number;
  iconId: number;
  uuid: string;
  label: string;
  order: number;
  componentName: string;
  defaultComponent: boolean;
  visibleInApp: boolean;
  additionalInfo: any;
  firstToOpenMode: FirstToOpenMode;
}

import { Inject, Injectable, InjectionToken } from '@angular/core';

export const QRCODE_BASE_URL = new InjectionToken<string>('QrCode Base URL');

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  constructor(@Inject(QRCODE_BASE_URL) private qrCodeBaseUrl: string) {}

  getQrCodeUrl(appId: number, eventId: number, componentId: number, uuid: string): string {
    return `${this.qrCodeBaseUrl}/app/${appId}/event/${eventId}/component/${componentId}/qr_code_component/${uuid}`;
  }

  getTreasureHuntUrl(appId: number, eventId: number, componentId: number, uuid: string): string {
    return `${this.qrCodeBaseUrl}/app/${appId}/event/${eventId}/treasure_hunt_component/${componentId}/${uuid}`;
  }
}

import { Injectable } from '@angular/core';
import { BusinessMatchingMeetingProviderService } from '@components/business-matching/providers/business-matching-meeting-provider.service';
import { BusinessMatchingMeetingStoreService } from '@components/business-matching/providers/business-matching-meeting-store.service';
import { Observable, defer } from 'rxjs';
import { BusinessMatchingMeetingApiModel } from '@components/business-matching/models/business-matching-meeting.api-model';
import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusinessMatchingMeetingService {
  private currentEvent: EventModel;
  private currentComponent: ComponentModel;

  constructor(private businessMatchingMeetingProvider: BusinessMatchingMeetingProviderService,
    private businessMatchingMeetingStore: BusinessMatchingMeetingStoreService) {}

  getSortedBusinessMatchingMeetings(): Observable<BusinessMatchingMeetingApiModel[]> {
    return this.businessMatchingMeetingStore.getSortedBusinessMatchingMeetings();
  }

  getBusinessMatchingMeetings(): Observable<BusinessMatchingMeetingApiModel[]> {
    return this.businessMatchingMeetingStore.getBusinessMatchingMeetings();
  }

  loadComponent(event: EventModel, component: ComponentModel): Observable<BusinessMatchingMeetingApiModel[]> {
    return defer(() => this.loadBusinessMatchingMeetingsFor(event, component));
  }

  private loadBusinessMatchingMeetingsFor(event: EventModel, component: ComponentModel): Observable<BusinessMatchingMeetingApiModel[]> {
    this.reset();

    return this.businessMatchingMeetingProvider.getBusinessMatchingMeetings(event, component).pipe(
      tap(meetings => this.setup(event, component, meetings))
    );
  }

  private setup(event: EventModel, component: ComponentModel, meetings: BusinessMatchingMeetingApiModel[]) {
    this.currentEvent = event;
    this.currentComponent = component;
    this.businessMatchingMeetingStore.setup(meetings);
  }

  private reset() {
    this.currentEvent = null;
    this.currentComponent = null;
    this.businessMatchingMeetingStore.reset();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sign-in-strategy',
  templateUrl: './sign-in-strategy.component.html',
  styleUrls: ['./sign-in-strategy.component.scss']
})
export class SignInStrategyComponent {

  public SignInStrategyType = SignInStrategyType;

  @Input() type: SignInStrategyType;
  @Input() disabled = false;
  @Output() appClick: EventEmitter<SignInStrategyType>;

  constructor() {
    this.appClick = new EventEmitter<SignInStrategyType>();
  }

  passwordType: StrategyType = {
    icon: '/assets/icons/lock-filled.svg',
    class: 'password-type',
  }

  facebookType: StrategyType = {
    icon: '/assets/icons/social/social-facebook-icon.svg',
    class: 'facebook-type',
  };

  appleType: StrategyType = {
    icon: '/assets/icons/social/icon_apple.svg',
    class: 'apple-type',
  };

  magicLinkType: StrategyType = {
    icon: '/assets/icons/http.svg',
    class: 'magic-link-type',
  }

  get activeType(): StrategyType {
    switch (this.type) {
      case SignInStrategyType.Password:
        return this.passwordType;
      case SignInStrategyType.Facebook:
        return this.facebookType;
      case SignInStrategyType.Apple:
        return this.appleType;
      case SignInStrategyType.MagicLink:
        return this.magicLinkType;
    }
  }

}

interface StrategyType {
  icon: string;
  class: string;
}

export enum SignInStrategyType {
  Password,
  Facebook,
  Apple,
  MagicLink,
}
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortByOrder"
})
export class SoryByOrderPipe implements PipeTransform {
  transform(value: any[], sortFunction?: any): any[] {
    return value ? [...value].sort((a, b) => a.order - b.order) : [];
  }
}

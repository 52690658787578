import { Component, OnDestroy, AfterViewInit, Input, Output, ViewChild, EventEmitter, Renderer2, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-input-adaptive',
  templateUrl: './input-adaptive.component.html',
  styleUrls: ['./input-adaptive.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAdaptiveComponent),
      multi: true
    }
  ]
})
export class InputAdaptiveComponent implements OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;

  @Output() appFocus: EventEmitter<Event>;
  @Output() appBlur: EventEmitter<Event>;

  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
  @ViewChild('spanElement', { static: true }) spanElement: ElementRef;

  get value(): string {
    const input: HTMLInputElement = this.inputElement.nativeElement;
    return input.value;
  }

  get helperWidth(): number {
    const span: HTMLSpanElement = this.spanElement.nativeElement;
    const offsetWidth = span.offsetWidth;

    return offsetWidth;
  }

  private value$: BehaviorSubject<string>;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor(private renderer: Renderer2) {
    this.placeholder = '';

    this.appFocus = new EventEmitter<Event>();
    this.appBlur = new EventEmitter<Event>();

    this.value$ = new BehaviorSubject<string>('');
  }

  ngOnDestroy() {
    this.value$.complete();
  }

  ngAfterViewInit() {
    this.value$.pipe(
      tap(value => this.updateSizeFor(value))
    ).subscribe(
      value => this.renderer.setProperty(this.inputElement.nativeElement, 'value', value)
    );
  }

  focus(options?: FocusOptions) {
    const input: HTMLInputElement = this.inputElement.nativeElement;
    input.focus(options);
  }

  onInputChange(ev: Event) {
    const value = this.value;
    this.updateSizeFor(value);

    if (this.onChange) {
      this.onChange(value);
    }
  }

  onInputFocus(ev: Event) {
    this.appFocus.emit(ev);
  }

  onInputBlur(ev: Event) {
    this.appBlur.emit(ev);
  }

  writeValue(value: string): void {
    this.value$.next(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.inputElement.nativeElement, 'disabled', isDisabled);
  }

  private updateSizeFor(value: string) {
    this.renderer.setProperty(this.spanElement.nativeElement, 'textContent', value);

    const width = this.helperWidth;
    this.renderer.setStyle(this.inputElement.nativeElement, 'width', `${width}px`);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SvgService {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  getSvg(url: string): Observable<SafeHtml> {
    return this.http.get(url, { responseType: 'text' }).pipe(
      map(svg => this.sanitizer.bypassSecurityTrustHtml(svg.replace('fill="black"', 'fill="#FF4081"')))
    );
  }
}
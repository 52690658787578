import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';
import { AgendaRating2Model } from '@components/agenda/models/agenda-rating-2.model';

export const loadRatings = createAction(
  '[Agenda/Application] Load Ratings',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel }>()
);

export const loadRatingsSuccess = createAction(
  '[Agenda/API] Load Ratings Success',
  props<{ ratings: AgendaRating2Model[] }>()
);

export const loadRatingsFailure = createAction(
  '[Agenda/API] Load Ratings Failure',
  props<{ error: Error }>()
);

export const deleteRating = createAction(
  '[Agenda/Application] Delete Rating',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel, rating: AgendaRating2Model }>()
);

export const deleteRatingSuccess = createAction(
  '[Agenda/API] Delete Rating Success',
  props<{ rating: AgendaRating2Model }>()
);

export const deleteRatingFailure = createAction(
  '[Agenda/API] Delete Rating Failure',
  props<{ error: Error }>()
);

<app-tag-control
  [formControl]="selectControl"
  [staticLabel]="true"
  [readonly]="readonly"
  [tags]="tags"
  [placeholder]="placeholder">
</app-tag-control>

<app-tag-group *ngIf="hasSelectedTags">
  <app-tag *ngFor="let tag of selectedTags" [tag]="getSelectedTagFromTags(tag)" [readOnly]="true" [hideRemove]="hideRemove" (appRemove)="removeTag(tag)"></app-tag>
</app-tag-group>

<p *ngIf="!hasSelectedTags">
  {{ 'others.no_tags_selected' | translate }}
</p>
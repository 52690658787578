export enum ImportResultStatus {
  Success = 'success',
  Warning = 'warning',
  Failed = 'failed',
}

export interface ImportResultModel {
  status: ImportResultStatus;
  user_uuid?: string;
  results: string;
}

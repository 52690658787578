import { animation } from '@angular/animations';
import { style, animate } from '@angular/animations';

export const fadeSlideOut = animation([
  style({
    transform: 'translateY(0px)',
    opacity: 1.0
  }),
  animate('150ms ease', style({
    transform: 'translateY(-8px)',
    opacity: 0.0
  }))
]);

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConsentCheckboxFormModel } from '@store/features/consents/utils/consent.helper';
import { ModelForm } from '@utils/model-form.util';
import {ConsentDisplayType} from "@store/features/consents/utils/consent-display-type.enum";

@Component({
  selector: 'app-consent-checkbox',
  templateUrl: './consent-checkbox.component.html',
  styleUrls: ['./consent-checkbox.component.scss']
})
export class ConsentCheckboxComponent implements OnInit {
  @Input() form: FormGroup<ModelForm<ConsentCheckboxFormModel>>;
  @Input() submitted: boolean;

  displayType: ConsentDisplayType;
  readonly ConsentDisplayType = ConsentDisplayType;

  ngOnInit() {
    this.displayType = this.form.value.data.displayType;

    if (this.displayType === ConsentDisplayType.TEXT) {
      this.form.controls.checked.setValue(true);
      this.form.controls.checked.disable();
    }
  }

  handleStateChanged(state: boolean): void {
    if (this.displayType !== ConsentDisplayType.TEXT) {
      this.form.controls.checked.setValue(state);
    }
  }

  changeFormExpanded(event: MouseEvent): void {
    event.preventDefault();
    this.form.controls.expanded.setValue(!this.form.controls.expanded.value);
  }
}

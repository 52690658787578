<h2 mat-dialog-title>{{ 'lang_modal.comp_title' | translate }}</h2>

<mat-dialog-content>
  <div class="form-group">
    <div class="row">
      <div class="col-10">
        <label>{{ 'lang_modal.lang' | translate }}</label>
      </div>
      <!-- <div class="col-4">
        <label>{{ 'lang_modal.default' | translate }}</label>
      </div> -->
    </div>
    <div *ngFor="let language of languages.controls; index as i" class="language-list">
      <div class="row language-row" [formGroup]="language">
        <div class="col-10 d-flex align-items-center">
          <img [src]="getUrl(language.value.code)" />
          <p>{{ language.value.name }}</p>
        </div>
        <div class="col-2 d-flex align-items-center">
          <!-- <div class="flex-grow-1">
            <input name="default" type="checkbox" class="switch-checkbox" [checked]="language.value.default">
            <label (click)="onLanguageDefault(language)"></label>
          </div> -->

          <app-action-button icon="/assets/icons/delete.svg" (click)="onLanguageRemove($event, i)"
            [disabled]="language.value.default"></app-action-button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button class="btn" type="button" mat-dialog-close>{{ 'buttons.cancel' | translate }}</button>

  <button mat-button type="button" class="btn btn-primary ml-2" (click)="onSettingSave($event)">
    <ng-container *ngIf="isSaving$ | async; else noEvLgSaving">
      <app-spinner-progress></app-spinner-progress>
    </ng-container>

    <ng-template #noEvLgSaving>
      {{ eventUpdating ? ('lang_modal.save' | translate) : ('lang_modal.done' | translate) }}
    </ng-template>
  </button>
</mat-dialog-actions>

import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent {
  
  public ValidationMessageType = ValidationMessageType;

  @Input() type: ValidationMessageType;
  submitted$: Observable<boolean>;
}

export enum ValidationMessageType {
  Validation,
  Error,
  Info,
}
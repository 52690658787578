import { Action, createReducer, on } from '@ngrx/store';

import * as PartnerModalActions from '../actions/partner-modal.actions';

export const stateKey = 'partnerModal';

export interface State {
  saving: boolean;
  saved: boolean;
  error: Error;
}

export const initialState: State = {
  saving: false,
  saved: false,
  error: null,
};

export const partnerModalReducer = createReducer(
  initialState,
  on(
    PartnerModalActions.modalCreatePartner,
    PartnerModalActions.modalUpdatePartner,
    state => ({ ...initialState, saving: true })
  ),
  on(
    PartnerModalActions.modalCreatePartnerSuccess,
    PartnerModalActions.modalUpdatePartnerSuccess,
    state => ({ ...initialState, saved: true })
  ),
  on(
    PartnerModalActions.modalCreatePartnerFailure,
    PartnerModalActions.modalUpdatePartnerFailure,
    (state, { error }) => ({ ...initialState, error })
  ),
);

export function reducer(state: State, action: Action): State {
  return partnerModalReducer(state, action);
}

export const selectPartnerModalSaving = (state: State) => state.saving;

export const selectPartnerModalSaved = (state: State) => state.saved;

export const selectPartnerModalError = (state: State) => state.error;

import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { AgendaDayModel } from '@components/agenda/models/agenda-day.model';

export const loadAgendaDays = createAction(
  '[Agenda/Agenda Component] Load Agenda Days',
  props<{ event: EventModel }>()
);

export const loadAgendaDaysSuccess = createAction(
  '[Agenda/API] Load Agenda Days Success',
  props<{ days: AgendaDayModel[] }>()
);

export const loadAgendaDaysFailure = createAction(
  '[Agenda/API] Load Agenda Days Failure',
  props<{ error: Error }>()
);

export const createAgendaDay = createAction(
  '[Agenda/Agenda Component] Create Agenda Day',
  props<{ event: EventModel, day: AgendaDayModel }>()
);

export const createAgendaDaySuccess = createAction(
  '[Agenda/API] Create Agenda Day Success',
  props<{ day: AgendaDayModel }>()
);

export const createAgendaDayFailure = createAction(
  '[Agenda/API] Create Agenda Day Failure',
  props<{ error: Error }>()
);

export const deleteAgendaDay = createAction(
  '[Agenda/Agenda Component] Delete Agenda Day',
  props<{ event: EventModel, day: AgendaDayModel }>()
);

export const deleteAgendaDaySuccess = createAction(
  '[Agenda/API] Delete Agenda Day Success',
  props<{ day: AgendaDayModel }>()
);

export const deleteAgendaDayFailure = createAction(
  '[Agenda/API] Delete Agenda Day Failure',
  props<{ error: Error }>()
);

export const selectAgendaDay = createAction(
  '[Agenda/Agenda Component] Select Agenda Day',
  props<{ dayId: number }>()
);

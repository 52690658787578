import { TagApiModel } from '@store/features/tags/tags.models';
import { ComponentModel } from '@shared/models/component.model';
import { createAction } from "@ngrx/store";
import { EventModel } from "@store/features/event/models/event.model";
import { AccessGroupResponse } from "@store/features/tags/access-group-api.service";
import { AccessGroupDeleteModel, AccessGroupItemDeleteModel, AccessGroupItemPostModel, AccessGroupPostModel } from "@store/features/tags/access-group.models";

const loadComponents = createAction(
  '[Access Group Tags] Load Components Access Groups',
  (event: EventModel, componentIds: number[]) => ({ event, componentIds })
);

const loadComponentsSuccess = createAction(
  '[Access Group Tags] Load Components Access Groups Success',
  (groups: AccessGroupResponse) => ({ groups })
);

const loadComponentsFailure = createAction(
  '[Access Group Tags] Load Components Access Groups Failure',
  (error: any) => ({ error })
);

const addComponentAccessGroup = createAction(
  '[Access Group Tags] Add Component Access Group',
  (event: EventModel, payload: AccessGroupPostModel) => ({ event, payload })
);

const addComponentAccessGroupSuccess = createAction(
  '[Access Group Tags] Add Component Access Group Success',
  (data: TagApiModel, id: number) => ({ data, id })
);

const addComponentAccessGroupFailure = createAction(
  '[Access Group Tags] Add Component Access Group Failure',
  (error: any) => ({ error })
);

const removeComponentAccessGroup = createAction(
  '[Access Group Tags] Remove Component Access Group',
  (event: EventModel, payload: AccessGroupDeleteModel) => ({ event, payload })
);

const removeComponentAccessGroupSuccess = createAction(
  '[Access Group Tags] Remove Component Access Group Success',
  (data: AccessGroupDeleteModel) => ({ data })
);

const removeComponentAccessGroupFailure = createAction(
  '[Access Group Tags] Remove Component Access Group Failure',
  (error: any) => ({ error })
);

const refreshComponentAccessGroups = createAction(
  '[Access Group Tags] Refresh Component Access Groups',
  (component: ComponentModel, tags: TagApiModel[]) => ({ component, tags })
);

// ITEMS
const loadItems = createAction(
  '[Access Group Tags - ITEMS] Load Items Access Groups',
  (event: EventModel, componentId: number, itemIds: number[]) => ({ event, componentId, itemIds })
);

const loadItemsSuccess = createAction(
  '[Access Group Tags - ITEMS] Load Items Access Groups Success',
  (groups: AccessGroupResponse) => ({ groups })
);

const loadItemsFailure = createAction(
  '[Access Group Tags - ITEMS] Load Items Access Groups Failure',
  (error: any) => ({ error })
);

const loadItem = createAction(
  '[Access Group Tags - ITEMS] Load Item Access Groups',
  (event: EventModel, componentId: number, itemIds: number[]) => ({ event, componentId, itemIds })
);

const loadItemSuccess = createAction(
  '[Access Group Tags - ITEMS] Load Item Access Groups Success',
  (groups: AccessGroupResponse) => ({ groups })
);

const loadItemFailure = createAction(
  '[Access Group Tags - ITEMS] Load Item Access Groups Failure',
  (error: any) => ({ error })
);

const addItemAccessGroup = createAction(
  '[Access Group Tags - ITEMS] Add Item Access Group',
  (event: EventModel, payload: AccessGroupItemPostModel) => ({ event, payload })
);

const addItemAccessGroupSuccess = createAction(
  '[Access Group Tags - ITEMS] Add Item Access Group Success',
  (data: TagApiModel, id: number) => ({ data, id })
);

const addItemAccessGroupFailure = createAction(
  '[Access Group Tags - ITEMS] Add Item Access Group Failure',
  (error: any) => ({ error })
);

const removeItemAccessGroup = createAction(
  '[Access Group Tags - ITEMS] Remove Item Access Group',
  (event: EventModel, payload: AccessGroupItemDeleteModel) => ({ event, payload })
);

const removeItemAccessGroupSuccess = createAction(
  '[Access Group Tags - ITEMS] Remove Item Access Group Success',
  (data: AccessGroupItemDeleteModel) => ({ data })
);

const removeItemAccessGroupFailure = createAction(
  '[Access Group Tags - ITEMS] Remove Item Access Group Failure',
  (error: any) => ({ error })
);

const setItemsError = createAction(
  '[Access Group Tags - ITEMS] Set Error',
  (error: Error) => ({ error })
);

const loadAuthorizedUserAccessGroups = createAction(
  '[Tags] Load Authorized User Access Groups',
  (event: EventModel, uuid: string) => ({ event, uuid })
);

const loadAuthorizedUserAccessGroupsSuccess = createAction(
  '[Tags] Load Authorized User Access Groups Success',
  (tags: TagApiModel[]) => ({ tags })
);

const loadAuthorizedUserAccessGroupsFailure = createAction(
  '[Tags] Load Authorized User Access Groups Failure',
  (error: Error) => ({ error })
);

export const AccessGroupActions = {
  loadComponents,
  loadComponentsSuccess,
  loadComponentsFailure,

  addComponentAccessGroup,
  addComponentAccessGroupSuccess,
  addComponentAccessGroupFailure,
  
  removeComponentAccessGroup,
  removeComponentAccessGroupSuccess,
  removeComponentAccessGroupFailure,

  refreshComponentAccessGroups,

  loadItems,
  loadItemsSuccess,
  loadItemsFailure,

  loadItem,
  loadItemSuccess,
  loadItemFailure,

  addItemAccessGroup,
  addItemAccessGroupSuccess,
  addItemAccessGroupFailure,

  removeItemAccessGroup,
  removeItemAccessGroupSuccess,
  removeItemAccessGroupFailure,

  setItemsError,

  loadAuthorizedUserAccessGroups,
  loadAuthorizedUserAccessGroupsSuccess,
  loadAuthorizedUserAccessGroupsFailure,
}
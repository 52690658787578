import { Action, createReducer, on } from "@ngrx/store";
import { ConsentModel } from "../models/consent.model";
import { acceptUserConsents, acceptUserConsentsFailure, acceptUserConsentsSuccess, loadUserAppConsents, loadUserAppConsentsFailure, loadUserAppConsentsSuccess } from "../actions/consent.actions";
import { signInSuccess } from "@app/account/store/auth.actions";
import { signOut } from "@app/backend/store/profile.actions";

export const childKey = 'consents';

export interface State {
  loading: boolean;
  loaded: boolean;
  accepting: boolean;
  entities: ConsentModel[];
  error: Error | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  accepting: false,
  entities: [],
  error: null,
}

export const consentReducer = createReducer(
  initialState,
  on(
    loadUserAppConsents,
    (state) => ({ ...state, loading: true })
  ),
  on(
    loadUserAppConsentsSuccess,
    (state, { entities }) => ({ ...state, loading: false, loaded: true, error: null, entities })
  ),
  on(
    loadUserAppConsentsFailure,
    (state, { error }) => ({ ...state, loading: false, loaded: true, error })
  ),

  on(
    acceptUserConsents,
    (state) => ({ ...state, accepting: true })
  ),
  on(
    acceptUserConsentsSuccess,
    (state, { acceptedIds }) => ({
      ...state,
      accepting: false,
      error: null,
      entities: [...state.entities.filter(e => !acceptedIds.includes(e.id))],
    })
  ),
  on(
    acceptUserConsentsFailure,
    (state, { error }) => ({ ...state, accepting: false, error })
  ),
  on(
    signInSuccess,
    signOut,
    (_) => ({ ...initialState })
  ),
);

export function reducer(state: State, action: Action): State {
  return consentReducer(state, action);
}

export const selectLoading = (state: State) => state.loading;
export const selectLoaded = (state: State) => state.loaded;
export const selectAccepting = (state: State) => state.accepting;
export const selectError = (state: State) => state.error;
export const selectEntities = (state: State) => state.entities;
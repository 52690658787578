import { createAction, props } from '@ngrx/store';

import {
  VenueModel,
  VenueTranslationModel,
  VenueCategoryModel,
  VenueCategoryTranslationModel
} from '@components/venue/models/venue.model';

export const loadVenueTranslation = createAction(
  '[Venue/Venue Modal] Load Venue Translation',
  props<{ venue: VenueModel, language: string }>()
);

export const loadVenueTranslationSuccess = createAction(
  '[Venue/API] Load Venue Translation Success',
  props<{ venue: VenueModel, translation: VenueTranslationModel }>()
);

export const loadVenueTranslationFailure = createAction(
  '[Venue/API] Load Venue Translation Failure',
  props<{ venue: VenueModel, error: Error }>()
);

export const updateVenueTranslations = createAction(
  '[Venue/Venue Modal] Update Venue Translations',
  props<{ venue: VenueModel, translations: VenueTranslationModel[] }>()
);

export const updateVenueTranslationsSuccess = createAction(
  '[Venue/API] Update Venue Translations Success',
  props<{ venue: VenueModel, translations: VenueTranslationModel[] }>()
);

export const updateVenueTranslationsFailure = createAction(
  '[Venue/API] Update Venue Translations Failure',
  props<{ venue: VenueModel, error: Error }>()
);

export const clearVenueTranslations = createAction(
  '[Venue/Venue Delete] Clear Venue Translations',
  props<{ venue: VenueModel }>()
);

export const loadVenueCategoryTranslation = createAction(
  '[Venue/Component] Load Venue Category Translation',
  props<{ category: VenueCategoryModel, language: string }>()
);

export const loadVenueCategoryTranslationSuccess = createAction(
  '[Venue/API] Load Venue Category Translation Success',
  props<{ category: VenueCategoryModel, translation: VenueCategoryTranslationModel, }>()
);

export const loadVenueCategoryTranslationFailure = createAction(
  '[Venue/API] Load Venue Category Translation Failure',
  props<{ category: VenueCategoryModel, error: Error }>()
);

export const updateVenueCategoryTranslations = createAction(
  '[Venue/Component] Update Venue Category Translations',
  props<{ category: VenueCategoryModel, translations: VenueCategoryTranslationModel[] }>()
);

export const updateVenueCategoryTranslationsSuccess = createAction(
  '[Venue/API] Update Venue Category Translations Success',
  props<{ category: VenueCategoryModel, translations: VenueCategoryTranslationModel[] }>()
);

export const updateVenueCategoryTranslationsFailure = createAction(
  '[Venue/API] Update Venue Category Translations Failure',
  props<{ category: VenueCategoryModel, error: Error }>()
);

export const clearVenueCategoryTranslations = createAction(
  '[Venue/Venue Delete] Clear Venue Category Translations',
  props<{ category: VenueCategoryModel }>()
);

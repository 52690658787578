import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { ComponentPlanType } from '@app/construct-steps/models/component-plan.model';

import * as fromComponent from './component.reducer';
import * as fromComponentPlan from './component-plan.reducer';
import * as fromComponentDefinition from './component-definition.reducer';
import * as fromComponentTranslations from './component-translation.reducer';
import * as fromComponentUtils from './component-utils.reducer';

export const featureKey = 'componentFeature';

export interface State {
  [fromComponent.featureKey]: fromComponent.State;
  [fromComponentPlan.featureKey]: fromComponentPlan.State;
  [fromComponentDefinition.featureKey]: fromComponentDefinition.State;
  [fromComponentTranslations.featureKey]: fromComponentTranslations.State;
  [fromComponentUtils.featureKey]: fromComponentUtils.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromComponent.featureKey]: fromComponent.reducer,
  [fromComponentPlan.featureKey]: fromComponentPlan.reducer,
  [fromComponentDefinition.featureKey]: fromComponentDefinition.reducer,
  [fromComponentTranslations.featureKey]: fromComponentTranslations.reducer,
  [fromComponentUtils.featureKey]: fromComponentUtils.reducer,
};

export const selectComponentFeature = createFeatureSelector<State>(featureKey);

export const selectComponentState = createSelector(
  selectComponentFeature,
  state => state[fromComponent.featureKey]
);

export const selectAllComponents = createSelector(
  selectComponentState,
  fromComponent.selectAllComponents
);

export const selectAllowedComponentIds = createSelector(
  selectComponentState,
  fromComponent.selectAllowedComponentIds
);

export const selectAllowedComponentsLoadError = createSelector(
  selectComponentState,
  fromComponent.selectAllowedComponentsLoadError
);


export const selectComponentLoading = createSelector(
  selectComponentState,
  fromComponent.selectComponentLoading
);

export const selectComponentLoaded = createSelector(
  selectComponentState,
  fromComponent.selectComponentLoaded
);

export const selectComponentError = createSelector(
  selectComponentState,
  fromComponent.selectComponentError
);

export const selectActiveComponentId = createSelector(
  selectComponentState,
  fromComponent.selectActiveComponentId
);

export const selectActiveComponent = createSelector(
  selectComponentState,
  fromComponent.selectActiveComponent
);

export const selectComponentUpdating = createSelector(
  selectComponentState,
  fromComponent.selectComponentUpdating
);

export const selectUpdatedComponent = createSelector(
  selectComponentState,
  fromComponent.selectUpdatedComponent
);

export const selectComponentsByName = () => createSelector(
  selectComponentState,
  fromComponent.selectComponentsByName()
);

export const selectComponentPlanState = createSelector(
  selectComponentFeature,
  state => state[fromComponentPlan.featureKey]
);

export const selectAllComponentPlan = createSelector(
  selectComponentPlanState,
  fromComponentPlan.selectAllComponentPlans
);

export const selectComponentPlanLoading = createSelector(
  selectComponentPlanState,
  fromComponentPlan.selectComponentPlanLoading
);

export const selectComponentPlanLoaded = createSelector(
  selectComponentPlanState,
  fromComponentPlan.selectComponentPlanLoaded
);

export const selectComponentPlanError = createSelector(
  selectComponentPlanState,
  fromComponentPlan.selectComponentPlanError
);

export const selectComponentDefinitionState = createSelector(
  selectComponentFeature,
  state => state[fromComponentDefinition.featureKey]
);

export const selectAllComponentDefinitions = createSelector(
  selectComponentDefinitionState,
  fromComponentDefinition.selectAllComponentDefinitions
);

export const selectComponentPlanDefinitions = (planType: ComponentPlanType) => {
  return createSelector(
    selectAllComponentDefinitions,
    componentDefinitions => componentDefinitions.filter(def => def.planType === planType)
  );
};

export const selectComponentTranslationState = createSelector(
  selectComponentFeature,
  state => state[fromComponentTranslations.featureKey]
);

export const selectComponentTranslationLoading = createSelector(
  selectComponentTranslationState,
  fromComponentTranslations.selectComponentTranslationLoading
);

export const selectComponentTranslationError = createSelector(
  selectComponentTranslationState,
  fromComponentTranslations.selectComponentTranslationError
);

export const selectComponentTranslations = () => createSelector(
  selectComponentTranslationState,
  fromComponentTranslations.selectComponentTranslations()
);

export const selectComponentTranslation = () => createSelector(
  selectComponentTranslationState,
  fromComponentTranslations.selectComponentTranslation()
);

// component utils
export const selectComponentUtilsState = createSelector(
  selectComponentFeature,
  state => state[fromComponentUtils.featureKey]
);

export const selectComponentUtilsLoading = createSelector(
  selectComponentUtilsState,
  fromComponentUtils.selectLoading
);

export const selectComponentUtilsUpdating = createSelector(
  selectComponentUtilsState,
  fromComponentUtils.selectUpdating
);

export const selectComponentUtilsError = createSelector(
  selectComponentUtilsState,
  fromComponentUtils.selectError
);

export const selectComponentUtils = createSelector(
  selectComponentUtilsState,
  fromComponentUtils.selectUtils
);

export const selectComponentUtilsTranslations = createSelector(
  selectComponentUtilsState,
  fromComponentUtils.selectUtilsTranslations
);

export const selectProcessingUpdateRequestsAmount = createSelector(
  selectComponentUtilsState,
  fromComponentUtils.selectProcessingUpdateRequestsAmount
);

export const selectComponentUtilsLoadedComponentId = createSelector(
  selectComponentUtilsState,
  fromComponentUtils.selectLoadedComponentId
);
import { createAction, props } from '@ngrx/store';

import { VenueModel, VenueTranslationModel } from '@components/venue/models/venue.model';

export const modalCreateVenue = createAction(
  '[Venue/Modal] Modal Create Venues',
  props<{ venue: VenueModel, translations: VenueTranslationModel[] }>()
);

export const modalCreateVenueSuccess = createAction(
  '[Venue/API] Modal Create Venues Success',
  props<{ venue: VenueModel }>()
);

export const modalCreateVenueFailure = createAction(
  '[Venue/API] Modal Create Venues Failure',
  props<{ error: Error }>()
);

export const modalUpdateVenue = createAction(
  '[Venue/Modal] Modal Update Venues',
  props<{ venue: VenueModel, translations: VenueTranslationModel[] }>()
);

export const modalUpdateVenueSuccess = createAction(
  '[Venue/API] Modal Update Venues Success',
  props<{ venue: VenueModel }>()
);

export const modalUpdateVenueFailure = createAction(
  '[Venue/API] Modal Update Venues Failure',
  props<{ error: Error }>()
);

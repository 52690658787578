import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollectionBaseService } from '@shared/providers/collection-base.service';

import { SurveyQuizAnswerModel } from '@components/survey-quiz/models/survey-quiz-answer.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyQuizAnswerStoreService extends CollectionBaseService<SurveyQuizAnswerModel> {
  constructor() {
    super([]);
  }

  getOrderedSurveyQuizAnswers(): Observable<SurveyQuizAnswerModel[]> {
    const sortByOrder = (surveyQuizAnswers: SurveyQuizAnswerModel[]) => {
      return surveyQuizAnswers.sort((a, b) => a.order - b.order);
    };

    return this.getSurveyQuizAnswers().pipe(
      map(surveyQuizAnswers => sortByOrder(surveyQuizAnswers))
    );
  }

  getSurveyQuizAnswers(): Observable<SurveyQuizAnswerModel[]> {
    return this.getEntities();
  }

  updateEntity(entity: SurveyQuizAnswerModel) {
    super.updateEntity(entity, (a, b) => (a.id === b.id));
  }

  removeEntity(entity: SurveyQuizAnswerModel) {
    super.removeEntity(entity, (a, b) => (a.id === b.id));
  }
}

import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ImportMetadata {
  columns: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ImportLoaderService {
  loadMetadata(file: File): Observable<ImportMetadata> {
    if (!file) {
      return of(null);
    }

    return from(this.readCsvHeaderColumns(file)).pipe(
      map(columns => ({ columns }))
    );
  }

  private async readCsvHeaderColumns(file: File): Promise<string[]> {
    const readenLine = await readLine(file);
    return readenLine.split(',').map(col => col.trim()).filter(l => l);
  }
}

async function readLine(file: File): Promise<string> {
  let remainingSize = file.size, currentSeek = 0, resultBuffer = '';

  while (remainingSize > 0) {
    const max = Math.max(1000, remainingSize);
    const chunk = await readBuffer(file, currentSeek, max);

    resultBuffer += chunk;
    const lineEnd = resultBuffer.indexOf('\n');

    if (lineEnd !== -1) {
      const readenLine = resultBuffer.substr(0, lineEnd);
      return readenLine;
    }

    currentSeek += max;
    remainingSize -= max;
  }

  return resultBuffer;
}

async function readBuffer(file: File, start: number, size: number): Promise<string> {
  const fileChopped = file.slice(start, start + size);
  const fileReader = new FileReader();

  fileReader.readAsText(fileChopped, 'UTF-8');

  return new Promise((resolve, reject) => {
    fileReader.onload = ev => resolve(String(fileReader.result));
    fileReader.onerror = ev => reject(new Error('Failed to read file chunk'));
  });
}

import { Component, HostBinding } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';

import { fadeSlideIn } from '@app/shared/animations/fade-slide-in.animation';
import { fadeSlideOut } from '@app/shared/animations/fade-slide-out.animation';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition('void => init', useAnimation(fadeSlideIn)),
      transition('* => void', useAnimation(fadeSlideOut))
    ])
  ]
})
export class CategoryComponent {
  @HostBinding('@slideInOut') _slideInOut = 'init';
}

import { AttachmentModel } from "@shared/models/attachment.model";


export interface AudioVisualApiModel {
  id: number;
  name: string;
  audio_visual_files: AudioVisualFileApiModel[],
  order: number;
};

export interface AudioVisualModel {
  id: number;
  name: string;
  audioVisualFiles: AudioVisualFileModel[];
  order: number;
}

export interface AudioVisualPostModel {
  name: string;
  order?: number;
}

export interface AudioVisualPatchModel {
  name: string;
}

// FILES

export interface AudioVisualFilesModifyResponse {
  audio_visual_files: AudioVisualFileApiModel[];
}

export interface AudioVisualFileApiModel {
  id: number;
  audio_visual_type: AudioVisualFileType;
  title: string;
  link_url: string;
  attachment: AttachmentModel;
  order: number;
}

export interface AudioVisualFileModel {
  id: number;
  audioVisualType: AudioVisualFileType;
  title: string;
  linkUrl: string;
  attachment: AttachmentModel;
  order: number;
}

export interface AudioVisualFileDeleteModel {
  audioVisualId: number; // as param
  fileId: number; // as param
}

export interface AudioVisualFilePatchModel {
  title: string;
  linkUrl: string;
}

export interface AudioVisualFilePatchApiModel {
  title: string;
  link_url: string;
}

export interface AudioVisualFilePostApiModel {
  audio_visual_type: AudioVisualFileType;
  title: string | null;
  link_url: string | null;
  attachment: number | null; // id
  order: number;
}

export interface AudioVisualFilePostModel {
  audioVisualType: AudioVisualFileType;
  title: string | null;
  linkUrl: string | null;
  attachment: number | null; // id
  order: number;
}


// ADAPTERS

export function audioVisualAdapt(audioVisual: AudioVisualApiModel): AudioVisualModel {
  return {
    id: audioVisual.id,
    name: audioVisual.name,
    audioVisualFiles: audioVisual.audio_visual_files.map(file => audioVisualFileAdapt(file)),
    order: audioVisual.order,
  }
}

export function audioVisualToApiAdapt(audioVisual: AudioVisualModel): AudioVisualApiModel {
  return {
    id: audioVisual.id,
    name: audioVisual.name,
    audio_visual_files: audioVisual.audioVisualFiles.map(file => audioVisualFileToApiAdapt(file)),
    order: audioVisual.order,
  }
}

export function audioVisualFilePatchToApi(payload: AudioVisualFilePatchModel): AudioVisualFilePatchApiModel {
  return {
    title: payload.title,
    link_url: payload.linkUrl,
  }
}

export function audioVisualFilePostToApi(payload: AudioVisualFilePostModel): AudioVisualFilePostApiModel {
  return {
    audio_visual_type: payload.audioVisualType,
    title: payload.title,
    link_url: payload.linkUrl,
    attachment: payload.attachment,
    order: payload.order,
  }
}

export function audioVisualFileAdapt(audioVisualFile: AudioVisualFileApiModel): AudioVisualFileModel {
  return {
    id: audioVisualFile.id,
    linkUrl: audioVisualFile.link_url,
    title: audioVisualFile.title,
    audioVisualType: audioVisualFile.audio_visual_type,
    attachment: audioVisualFile.attachment,
    order: audioVisualFile.order,
  }
}

export function audioVisualFileToApiAdapt(audioVisualFile: AudioVisualFileModel): AudioVisualFileApiModel {
  return {
    id: audioVisualFile.id,
    link_url: audioVisualFile.linkUrl,
    title: audioVisualFile.title,
    audio_visual_type: audioVisualFile.audioVisualType,
    attachment: audioVisualFile.attachment,
    order: audioVisualFile.order,
  }
}

export enum AudioVisualFileType {
  URL = 'url',
  FILE = 'file',
}
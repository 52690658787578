import { createAction, props } from '@ngrx/store';

import { EventModel } from '../models/event.model';
import { EventLanguageModel } from '../models/event-language.model';
import { EventTranslationModel } from '../models/event-translation.model';
import { PaymentModel } from '@app/construct-steps/models/payment.model';

export const loadEvent = createAction(
  '[Event/Event] Load Event',
  props<{ eventId: number }>()
);

export const loadEventSuccess = createAction(
  '[Event/API] Load Event Success',
  props<{ event: EventModel }>()
);

export const loadEventFailure = createAction(
  '[Event/API] Load Event Failure',
  props<{ error: Error }>()
);

export const createEvent = createAction(
  '[Event/Basic Info] Create Event',
  props<{ event: EventModel, languages: EventLanguageModel[], translations: EventTranslationModel[], appId: number }>()
);

export const createEventSuccess = createAction(
  '[Event/API] Create Event Success',
  props<{ event: EventModel, languages: EventLanguageModel[], translations: EventTranslationModel[] }>()
);

export const createEventFailure = createAction(
  '[Event/API] Create Event Failure',
  props<{ error: Error }>()
);

export const updateEvent = createAction(
  '[Event/Basic Info] Update Event',
  props<{ event: EventModel, languages: EventLanguageModel[], translations: EventTranslationModel[] }>()
);

export const updateEventSuccess = createAction(
  '[Event/API] Update Event Success',
  props<{ event: EventModel, languages: EventLanguageModel[], translations: EventTranslationModel[] }>()
);

export const updateEventFailure = createAction(
  '[Event/API] Update Event Failure',
  props<{ error: Error }>()
);

export const updateEventHighlighted = createAction(
  '[Event/Basic Info] Update Event Highlighted',
  props<{ event: EventModel, highlighted: boolean }>()
);

export const updateEventHighlightedSuccess = createAction(
  '[Event/Basic Info] Update Event Highlighted Success',
  props<{ event: EventModel }>()
);

export const updateEventHighlightedFailure = createAction(
  '[Event/Basic Info] Update Event Highlighted Failure',
  props<{ error: Error }>()
);

export const updateMainEventProperties = createAction(
  '[Event/Basic Info] Update Main Event Properties',
  props<{ event: EventModel }>()
);

export const updateMainEventPropertiesSuccess = createAction(
  '[Event/Basic Info] Update Main Event Properties',
  props<{ event: EventModel }>()
);

export const updateMainEventPropertiesFailure = createAction(
  '[Event/Basic Info] Update Main Event Properties',
  props<{ error: Error }>()
);

export const updateOnlyBaseLanguageLayer = createAction(
  '[Event/Basic Info] Update Only Base Language Layer',
  props<{ event: EventModel }>()
);

export const updateOnlyBaseLanguageLayerSuccess = createAction(
  '[Event/API] Update Only Base Language Layer Success',
  props<{ event: EventModel }>()
);

export const updateOnlyBaseLanguageLayerFailure = createAction(
  '[Event/API] Update Only Base Language Layer Failure',
  props<{ error: Error }>()
);

export const publishEvent = createAction(
  '[Event/Publish] Publish Event',
  props<{ event: EventModel }>()
);

export const publishEventSuccess = createAction(
  '[Event/API] Publish Event Success',
  props<{ event: EventModel }>()
);

export const publishEventFailure = createAction(
  '[Event/API] Publish Event Failure',
  props<{ error: Error }>()
);

export const unpublishEvent = createAction(
  '[Event/Publish] Unpublish Event',
  props<{ event: EventModel }>()
);

export const unpublishEventSuccess = createAction(
  '[Event/API] Unpublish Event Success',
  props<{ event: EventModel }>()
);

export const unpublishEventFailure = createAction(
  '[Event/API] Unpublish Event Failure',
  props<{ error: Error }>()
);

export const selectEvent = createAction(
  '[Event/Constructor] Select Event',
  props<{ eventId: number }>()
);

export const payEvent = createAction(
  '[Event/Publish] Pay Event',
  props<{ event: EventModel }>()
);

export const payEventSuccess = createAction(
  '[Event/API] Pay Event Success',
  props<{ currency: string, total: number }>()
);

export const payEventFailure = createAction(
  '[Event/API] Pay Event Failure',
  props<{ error: Error }>()
);

export const initPayment = createAction(
  '[Event/Publish] Initialize Payment',
  props<{ event: EventModel, payment: PaymentModel, supplement: any }>()
);

export const initPaymentSuccess = createAction(
  '[Event/API] Initialize Payment Success',
  props<{ currency: string, total: number, totalWithTax: number, clientSecret: string }>()
);

export const initPaymentFailure = createAction(
  '[Event/API] Initialize Payment Failure',
  props<{ error: Error }>()
);

export const openPaymentModal = createAction(
  '[Event/Effect] Open Payment Modal',
  props<{ event: EventModel, payment: PaymentModel, supplement: any }>()
);

export const closePaymentModal = createAction(
  '[Event/Effect] Close Payment Modal',
);

export const proceedPayment = createAction(
  '[Event/Publish] Proceed Payment',
  props<{ event: EventModel, payment: PaymentModel, supplement: any }>()
);

export const proceedPaymentSuccess = createAction(
  '[Event/API] Proceed Payment Success',
);

export const proceedPaymentFailure = createAction(
  '[Event/API] Proceed Payment Failure',
  props<{ error: Error }>()
);

export const clearEventState = createAction(
  '[Event/API] Clear Event State',
);

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollectionBaseService } from '@shared/providers/collection-base.service';

import { FeedWallThreadModel } from './models/feed-wall-thread.model';

@Injectable({
  providedIn: 'root'
})
export class FeedWallThreadStoreService extends CollectionBaseService<FeedWallThreadModel> {
  constructor() {
    super([]);
  }

  getWelcomeThread(): Observable<FeedWallThreadModel> {
    const findWelcomeThread = (threads: FeedWallThreadModel[]) => {
      return threads.find(thread => thread.is_sticky);
    };

    return this.getFeedWallThreads().pipe(
      map(threads => findWelcomeThread(threads))
    );
  }

  getFeedWallThreadById(threadId: number): Observable<FeedWallThreadModel> {
    const findById = (threads: FeedWallThreadModel[], searchId: number) => {
      return threads.find(thread => thread.id === searchId);
    };

    return this.getFeedWallThreads().pipe(
      map(threads => findById(threads, threadId))
    );
  }

  getOrderedFeedWallThreads(): Observable<FeedWallThreadModel[]> {
    const sortByDate = (threads: FeedWallThreadModel[]) => {
      return threads.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at));
    };

    return this.getFeedWallThreads().pipe(
      map(threads => sortByDate(threads))
    );
  }

  getFeedWallThreads(): Observable<FeedWallThreadModel[]> {
    return this.getEntities();
  }

  updateEntity(entity: FeedWallThreadModel): void {
    super.updateEntity(entity, (a, b) => (a.id === b.id));
  }

  removeEntity(entity: FeedWallThreadModel): void {
    super.removeEntity(entity, (a, b) => (a.id === b.id));
  }
}

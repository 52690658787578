import { Injectable } from '@angular/core';

import { Observable, defer } from 'rxjs';
import { tap, take, switchMap } from 'rxjs/operators';

import { SurveyQuizQuestionProviderService } from '@components/survey-quiz/providers/survey-quiz-question-provider.service';
import { SurveyQuizQuestionStoreService } from '@components/survey-quiz/providers/survey-quiz-question-store.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SurveyQuizModel } from '@components/survey-quiz/models/survey-quiz.model';
import { SurveyQuizQuestionModel } from '@components/survey-quiz/models/survey-quiz-question.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyQuizQuestionService {
  protected currentEvent: EventModel;
  protected currentComponent: ComponentModel;
  protected currentSurveyQuiz: SurveyQuizModel;

  constructor(private surveyQuizQuestionProvider: SurveyQuizQuestionProviderService,
    private surveyQuizQuestionStore: SurveyQuizQuestionStoreService) {}

  getOrderedSurveyQuizQuestions(): Observable<SurveyQuizQuestionModel[]> {
    return this.surveyQuizQuestionStore.getOrderedSurveyQuizQuestions();
  }

  getSurveyQuizQuestions(): Observable<SurveyQuizQuestionModel[]> {
    return this.surveyQuizQuestionStore.getSurveyQuizQuestions();
  }

  loadSurveyQuiz(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel): Observable<SurveyQuizQuestionModel[]> {

    return defer(() => this.loadSurveyQuizQuestionsFor(event, component, surveyQuiz));
  }

  createSurveyQuizQuestion(surveyQuizQuestion: SurveyQuizQuestionModel): Observable<SurveyQuizQuestionModel> {
    return this.surveyQuizQuestionProvider.createSurveyQuizQuestion(
      this.currentEvent, this.currentComponent, this.currentSurveyQuiz, surveyQuizQuestion
    ).pipe(
      tap(createdSurveyQuizQuestion => this.surveyQuizQuestionStore.pushEntity(createdSurveyQuizQuestion))
    );
  }

  updateSurveyQuizQuestion(surveyQuizQuestion: SurveyQuizQuestionModel): Observable<SurveyQuizQuestionModel> {
    return this.surveyQuizQuestionProvider.updateSurveyQuizQuestion(
      this.currentEvent, this.currentComponent, this.currentSurveyQuiz, surveyQuizQuestion
    ).pipe(
      tap(updatedSurveyQuizQuestion => this.surveyQuizQuestionStore.updateEntity(updatedSurveyQuizQuestion))
    );
  }

  deleteSurveyQuizQuestion(surveyQuizQuestion: SurveyQuizQuestionModel): Observable<boolean> {
    return this.surveyQuizQuestionProvider.deleteSurveyQuizQuestion(
      this.currentEvent, this.currentComponent, this.currentSurveyQuiz, surveyQuizQuestion
    ).pipe(
      tap(success => success && this.surveyQuizQuestionStore.removeEntity(surveyQuizQuestion))
    );
  }

  diffSurveyQuizQuestions(diffWith: SurveyQuizQuestionModel[]): Observable<SurveyQuizQuestionModel[]> {
    const providerDiffObservable = (originalSurveyQuizQuestions: SurveyQuizQuestionModel[]) => {
      return this.surveyQuizQuestionProvider.diffSurveyQuizQuestions(
        this.currentEvent, this.currentComponent, this.currentSurveyQuiz,
        originalSurveyQuizQuestions, diffWith
      );
    };

    return this.getSurveyQuizQuestions().pipe(take(1)).pipe(
      switchMap(surveyQuizQuestions => providerDiffObservable(surveyQuizQuestions)),
      tap(surveyQuizQuestions => this.surveyQuizQuestionStore.setup(surveyQuizQuestions))
    );
  }

  private loadSurveyQuizQuestionsFor(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel): Observable<SurveyQuizQuestionModel[]> {

    this.reset();

    return this.surveyQuizQuestionProvider.loadSurveyQuizQuestions(event, component, surveyQuiz).pipe(
      tap(surveyQuizQuestions => this.setup(event, component, surveyQuiz, surveyQuizQuestions))
    );
  }

  private setup(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestions: SurveyQuizQuestionModel[]) {

    this.currentEvent = event;
    this.currentComponent = component;
    this.currentSurveyQuiz = surveyQuiz;
    this.surveyQuizQuestionStore.setup(surveyQuizQuestions);
  }

  private reset() {
    this.currentEvent = null;
    this.currentComponent = null;
    this.currentSurveyQuiz = null;
    this.surveyQuizQuestionStore.reset();
  }
}

import { createAction, props } from "@ngrx/store";
import { EventLanguageModel } from "@store/features/event/models/event-language.model";
import { SpeakerCategoryModel, SpeakerCategoryUpdateModel } from "../models/speaker-category.model";

export const loadSpeakerCategories = createAction(
  '[Agenda/Speaker Category] Load Speaker Categories',
  props<{ lang: EventLanguageModel }>()
);

export const loadSpeakerCategoriesSuccess = createAction(
  '[Agenda/Speaker Category] Load Speaker Categories Success',
  props<{ lang: EventLanguageModel, entities: SpeakerCategoryModel[] }>()
);

export const loadSpeakerCategoriesFailure = createAction(
  '[Agenda/Speaker Category] Load Speaker Categories Failure',
  props<{ error: Error }>()
);

export const updateSpeakerCategories = createAction(
  '[Agenda/Speaker Category] Update Speaker Categories',
  props<{ lang: EventLanguageModel, categories: SpeakerCategoryUpdateModel[] }>()
);

export const updateSpeakerCategoriesSuccess = createAction(
  '[Agenda/Speaker Category] Update Speaker Categories Success',
  props<{ lang: EventLanguageModel, entities: SpeakerCategoryModel[] }>()
);

export const updateSpeakerCategoriesFailure = createAction(
  '[Agenda/Speaker Category] Update Speaker Categories Failure',
  props<{ error: Error }>()
);

export const setSpeakerCategoriesError = createAction(
  '[Agenda/Speaker Category] Set Speaker Categories Error',
  props<{ error: null | Error }>()
);
<div class="block-container modal-container" *ngIf="isModal">
  <div mat-dialog-title>
    <ng-template [ngTemplateOutlet]="header"></ng-template>
  </div>

  <mat-dialog-content>
    <ng-template [ngTemplateOutlet]="content">
      <p>{{ 'tag_database.comp_desc' | translate }}</p>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions align="start">
    <ng-template [ngTemplateOutlet]="footer"></ng-template>
  </mat-dialog-actions>
</div>

<div class="block-container" *ngIf="!isModal">
  <ng-template [ngTemplateOutlet]="header"></ng-template>
  <app-panel>
    <div [ngTemplateOutlet]="content">
      <app-spinner-overlay *ngIf="loading"></app-spinner-overlay>
    </div>
  </app-panel>
</div>


<!-- TEMPLATES -->

<ng-template #header>
  <h2 *ngIf="isModal" style="display: inline-block">{{ 'modal_tag.add_new_tag' | translate }}</h2>
  <button mat-button (click)="closeDialog()" class="close" *ngIf="isModal">
    <img src="./assets/img/icons/delete-cross-black.svg" alt="">
  </button>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>

  <div>
    <div class="alert alert-primary" role="alert">
      {{ 'booking.all_changes_auto_saved' | translate }}
    </div>
    <div *ngIf="errorMessage && isModal" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <!-- SYSTEM GROUP TAGS -->
    <div class="mb-4">
      <div class="mb-3">
        <span class="title-blue">{{ 'modal_tag.system_tags' | translate }}</span>
      </div>
      <app-tag-group>
        <ng-container *ngFor="let tag of systemTags; let i = index">
          <app-tag [tag]="tag" [readOnly]="true" [hideRemove]="true">
          </app-tag>
        </ng-container>
      </app-tag-group>
    </div>

    <!-- USER GROUP TAGS -->
    <div class="mb-4">
      <div class="mb-3">
        <span class="title-blue">{{ 'modal_tag.user_groups' | translate }}</span>
      </div>
      <app-tag-group>
        <ng-container *ngFor="let tag of userGroupTags; let i = index">
          <app-tag [tag]="tag" (change)="tagValueChanged($event, TagGroup.UserGroup)" (appRemove)="onTagDelete(tag, TagGroup.UserGroup, i)" [readOnly]="!(hasAccessToModifyUserGroups | async)" [hideRemove]="!(hasAccessToModifyUserGroups | async)">
          </app-tag>
        </ng-container>
        <button [disabled]="(underEditProccessed | async) || !(hasAccessToModifyUserGroups | async)" (click)="onTagAdd(TagGroup.UserGroup)" type="button" class="btn btn-link btn-link-add">
          {{ 'modal_tag.add_user_group' | translate }}
        </button>
      </app-tag-group>
    </div>

    <!-- USER TAGS -->
    <div class="mb-4">
      <div class="mb-3">
        <span class="title-blue">{{ 'modal_tag.user_tag' | translate }}</span>
      </div>
      <app-tag-group>
        <ng-container *ngFor="let tag of userTags">
          <app-tag [tag]="tag" (change)="tagValueChanged($event, TagGroup.User)" (appRemove)="onTagDelete(tag, TagGroup.User)" [readOnly]="isSystemTag(tag) || !(hasAccessToModifyUserTags | async)" [hideRemove]="isSystemTag(tag) || !(hasAccessToModifyUserTags | async)">
          </app-tag>
        </ng-container>
        <button [disabled]="(underEditProccessed | async) || !(hasAccessToModifyUserTags | async)" (click)="onTagAdd(TagGroup.User)" type="button" class="btn btn-link btn-link-add">
          {{ 'modal_tag.add_user_tag' | translate }}
        </button>
      </app-tag-group>
    </div>

    <!-- AGENDA PLACE TAGS -->
    <div class="mb-4" [class.disabled]="!(hasAgendaComponent$ | async)">
      <div class="mb-3">
        <span class="title-blue">{{ 'modal_tag.agenda_places' | translate }}</span>
      </div>
      <app-tag-group>
        <ng-container *ngFor="let tag of agendaPlaceTags">
          <app-tag [tag]="tag" (change)="tagValueChanged($event, TagGroup.AgendaPlace)" (appRemove)="onTagDelete(tag, TagGroup.AgendaPlace)" [readOnly]="isSystemTag(tag) || !(hasAccessToModifyAgendaPlaces | async)" [hideRemove]="isSystemTag(tag) || !(hasAccessToModifyAgendaPlaces | async)" [maxLength]="30">
          </app-tag>
        </ng-container>
        <button [disabled]="(underEditProccessed | async) || !(hasAccessToModifyAgendaPlaces | async)" (click)="onTagAdd(TagGroup.AgendaPlace)" type="button" class="btn btn-link btn-link-add">
          {{ 'modal_tag.add_agenda_place' | translate }}
        </button>
      </app-tag-group>
    </div>

    <!-- AGENDA PATH TAGS -->
    <div class="mb-4" [class.disabled]="!(hasAgendaComponent$ | async)">
      <div class="mb-3">
        <span class="title-blue">{{ 'modal_tag.agenda_path' | translate }}</span>
      </div>
      <app-tag-group>
        <ng-container *ngFor="let tag of agendaPathTags">
          <app-tag [tag]="tag" (change)="tagValueChanged($event, TagGroup.AgendaPath)" (appRemove)="onTagDelete(tag, TagGroup.AgendaPath)" [readOnly]="isSystemTag(tag) || !(hasAccessToModifyAgendaPaths | async)" [hideRemove]="isSystemTag(tag) || !(hasAccessToModifyAgendaPaths | async)" [maxLength]="30">
          </app-tag>
        </ng-container>
        <button [disabled]="(underEditProccessed | async) || !(hasAccessToModifyAgendaPaths | async)" (click)="onTagAdd(TagGroup.AgendaPath)" type="button" class="btn btn-link btn-link-add">
          {{ 'modal_tag.add_agenda_path' | translate }}
        </button>
      </app-tag-group>
    </div>

    <!-- EXHIBITOR TAGS -->
    <div class="mb-4" [class.disabled]="!(hasExhibitorComponent$ | async)">
      <div class="mb-3">
        <span class="title-blue">{{ 'tag_database.exhibitor_tags' | translate }}</span>
      </div>
      <app-tag-group>
        <ng-container *ngFor="let tag of exhibitorTags">
          <app-tag [tag]="tag" (change)="tagValueChanged($event, TagGroup.ExhibitorTag)" (appRemove)="onTagDelete(tag, TagGroup.ExhibitorTag)" [readOnly]="isSystemTag(tag) || !(hasAccessToModifyExhibitorTags | async)" [hideRemove]="isSystemTag(tag) || !(hasAccessToModifyExhibitorTags | async)">
          </app-tag>
        </ng-container>
        <button [disabled]="(underEditProccessed | async) || !(hasAccessToModifyExhibitorTags | async)" (click)="onTagAdd(TagGroup.ExhibitorTag)" type="button" class="btn btn-link btn-link-add">
          {{ 'tag_database.add_exhibitor_tag' | translate }}
        </button>
      </app-tag-group>
    </div>
  </div>
</ng-template>

<ng-template #footer>
  <button mat-button (click)="closeDialog()" type="button" class="btn btn-light pl-4 pr-4 mr-4">{{ 'buttons.close' | translate }}</button>
</ng-template>

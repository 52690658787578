<div class="dropdown-container" [class.disabled-select]="disabled">
  <button type="button" class="btn dropdown-toggle btn-custom" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <div class="button-label-span" *ngIf="staticLabel">
      {{ label ? label : ('others.choose' | translate) }}
      <!-- <span class="required-star" *ngIf="required">*</span> -->
    </div>
    <div class="button-label-span" *ngIf="!staticLabel">
      {{ selectedLabel ? selectedLabel : ('others.choose' | translate) }}
      <!-- <span class="required-star" *ngIf="required">*</span> -->
    </div>
    <i class="fa fa-heart"></i>
  </button>

  <div class="dropdown-menu" cdkScrollable>
    <app-dropdown-item *ngIf="nullable" [value]="null" (selectChange)="select(null, i18n.instant('dropdown_with_search.not_selected'))">
      <span>{{ 'dropdown_with_search.not_selected' | translate }}</span>
    </app-dropdown-item>
    <ng-content>
      <!-- here should be injected dropdown-item components list -->
    </ng-content>
  </div>
</div>
import { ConsentDisplayConfigApiModel, ConsentDisplayConfigCreateApiModel, ConsentDisplayConfigUpdateApiModel, ConsentDisplayExpandableApiModel, ConsentDisplayExpandableCreateApiModel, ConsentDisplayExpandableUpdateApiModel, ConsentDisplayPlaceholderApiModel, ConsentDisplayPlaceholderCreateApiModel, ConsentDisplayPlaceholderUpdateApiModel } from "../api/consent-display.api-model";
import { ConsentDisplayConfigCreateModel, ConsentDisplayConfigModel, ConsentDisplayConfigUpdateModel, ConsentDisplayExpandableCreateModel, ConsentDisplayExpandableModel, ConsentDisplayExpandableUpdateModel, ConsentDisplayPlaceholderCreateModel, ConsentDisplayPlaceholderModel, ConsentDisplayPlaceholderUpdateModel } from "../consent-display.model";

export function mapConsentDisplayConfigModel(model: ConsentDisplayConfigApiModel): ConsentDisplayConfigModel {
  return {
    text: model.text,
    placeholders: model.placeholders.map(mapConsentDisplayPlaceholderModel),
    expandable: model.expandable ? mapConsentDisplayExpandableModel(model.expandable) : null,
  }
}

export function mapConsentDisplayPlaceholderModel(model: ConsentDisplayPlaceholderApiModel): ConsentDisplayPlaceholderModel {
  return {
    value: model.value,
    label: model.label,
  }
}

export function mapConsentDisplayExpandableModel(model: ConsentDisplayExpandableApiModel): ConsentDisplayExpandableModel {
  return {
    button: model.button,
    text: model.text,
    placeholders: model.placeholders.map(mapConsentDisplayPlaceholderModel),
  }
}

export function mapConsentDisplayPlaceholderCreateModel(model: ConsentDisplayPlaceholderCreateModel): ConsentDisplayPlaceholderCreateApiModel {
  return {
    value: model.value,
    label: model.label,
  }
}

export function mapConsentDisplayPlaceholderUpdateModel(model: ConsentDisplayPlaceholderUpdateModel): ConsentDisplayPlaceholderUpdateApiModel {
  return {
    value: model.value,
    label: model.label,
  }
}

export function mapConsentDisplayExpandableCreateModel(model: ConsentDisplayExpandableCreateModel): ConsentDisplayExpandableCreateApiModel {
  return {
    button: model.button,
    text: model.text,
    placeholders: model.placeholders.map(mapConsentDisplayPlaceholderCreateModel),
  }
}

export function mapConsentDisplayExpandableUpdateModel(model: ConsentDisplayExpandableUpdateModel): ConsentDisplayExpandableUpdateApiModel {
  return {
    button: model.button,
    text: model.text,
    placeholders: model.placeholders.map(mapConsentDisplayPlaceholderUpdateModel),
  }
}

export function mapConsentDisplayConfigCreateModel(model: ConsentDisplayConfigCreateModel): ConsentDisplayConfigCreateApiModel {
  return {
    text: model.text,
    placeholders: model.placeholders.map(mapConsentDisplayPlaceholderCreateModel),
    expandable: model.expandable ? mapConsentDisplayExpandableCreateModel(model.expandable) : null,
  }
}

export function mapConsentDisplayConfigUpdateModel(model: ConsentDisplayConfigUpdateModel): ConsentDisplayConfigUpdateApiModel {
  return {
    text: model.text,
    placeholders: model.placeholders.map(mapConsentDisplayPlaceholderUpdateModel),
    expandable: model.expandable ? mapConsentDisplayExpandableUpdateModel(model.expandable) : null,
  }
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeedWallApiService } from './feed-wall-api.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { FeedWallChannelModel } from './models/feed-wall-channel.model';

import {
  FeedWallThreadModel,
  FeedWallThreadPostModel,
  FeedWallThreadPatchModel
} from './models/feed-wall-thread.model';

@Injectable({
  providedIn: 'root'
})
export class FeedWallThreadAdapterService {
  constructor(private apiService: FeedWallApiService) {}

  loadFeedWallThreads(event: EventModel, component: ComponentModel, channel: FeedWallChannelModel): Observable<FeedWallThreadModel[]> {
    return this.apiService.getFeedWallChannel(event.id, component.id, channel.id).pipe(
      map(entireChannel => entireChannel.feed_wall_threads)
    );
  }

  createFeedWallThread(event: EventModel, component: ComponentModel, channel: FeedWallChannelModel,
    thread: FeedWallThreadModel): Observable<FeedWallThreadModel> {

    const threadData = mapFeedWallThreadPost(thread);
    return this.apiService.createFeedWallThread(event.id, component.id, channel.id, threadData);
  }

  updateFeedWallThread(event: EventModel, component: ComponentModel, channel: FeedWallChannelModel,
    thread: FeedWallThreadModel): Observable<FeedWallThreadModel> {

    const threadData = mapFeedWallThreadPatch(thread);
    return this.apiService.updateFeedWallThread(event.id, component.id, channel.id, thread.id, threadData);
  }

  deleteFeedWallThread(event: EventModel, component: ComponentModel, channel: FeedWallChannelModel,
    thread: FeedWallThreadModel): Observable<boolean> {

    return this.apiService.deleteFeedWallThread(event.id, component.id, channel.id, thread.id).pipe(
      map(({ success }) => success)
    );
  }
}

function mapFeedWallThreadPost(thread: FeedWallThreadModel): FeedWallThreadPostModel {
  return {
    message: thread.message, url: thread.url,
    image: thread.image && thread.image.id
  };
}

function mapFeedWallThreadPatch(thread: FeedWallThreadModel): FeedWallThreadPatchModel {
  return {
    message: thread.message, url: thread.url,
    image: thread.image && thread.image.id
  };
}

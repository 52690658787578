import { createAction, props } from '@ngrx/store';

import { VenueCategoryModel } from '@components/venue/models/venue.model';

// Categories are loaded with venue items
export const loadCategoriesSuccess = createAction(
  '[Venue/API] Load Categories Success',
  props<{ categories: VenueCategoryModel[] }>()
);

export const createCategory = createAction(
  '[Venue/Component] Create Category',
  props<{ category: VenueCategoryModel }>()
);

export const createCategorySuccess = createAction(
  '[Venue/API] Create Category Success',
  props<{ category: VenueCategoryModel }>()
);

export const createCategoryFailure = createAction(
  '[Venue/API] Create Category Failure',
  props<{ error: Error }>()
);

export const updateCategory = createAction(
  '[Venue/Component] Update Category',
  props<{ category: VenueCategoryModel }>()
);

export const updateCategorySuccess = createAction(
  '[Venue/API] Update Category Success',
  props<{ category: VenueCategoryModel }>()
);

export const updateCategoryFailure = createAction(
  '[Venue/API] Update Category Failure',
  props<{ error: Error }>()
);

export const reorderCategories = createAction(
  '[Venue/Component] Reorder Categories',
  props<{ from: number, to: number }>()
);

export const reorderCategoriesInplace = createAction(
  '[Venue/Effect] Reorder Categories Inplace',
  props<{ categories: VenueCategoryModel[] }>()
);

export const reorderCategoriesSuccess = createAction(
  '[Venue/API] Reorder Categories Success',
  props<{ categories: VenueCategoryModel[] }>()
);

export const reorderCategoriesFailure = createAction(
  '[Venue/API] Reorder Categories Failure',
  props<{ error: Error }>()
);

export const deleteCategory = createAction(
  '[Venue/Component] Delete Category',
  props<{ category: VenueCategoryModel }>()
);

export const deleteCategorySuccess = createAction(
  '[Venue/API] Delete Category Success',
  props<{ category: VenueCategoryModel }>()
);

export const deleteCategoryFailure = createAction(
  '[Venue/API] Delete Category Failure',
  props<{ error: Error }>()
);

export const selectCategory = createAction(
  '[Venue/Modal] Select Category',
  props<{ category: VenueCategoryModel }>()
);

/**
 * Category Placeholder
 */
export const focusPlaceholder = createAction(
  '[Venue/Component] Focus Placeholder',
  props<{ componentId: number }>()
);

export const blurPlaceholder = createAction(
  '[Venue/Component] Blur Placeholder',
  props<{ componentId: number }>()
);

export const hidePlaceholder = createAction(
  '[Venue/Effect] Hide Placeholder',
  props<{ componentId: number }>()
);

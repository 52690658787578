import { EventUserSetup, UsersTableColumn } from "./models/user.models";
import { EventUserApiModel } from "@shared/models/event-user.api-model";
import { EDGE_TICKET_ID } from "@env/environment";
import { arraysEqual } from "@utils/arrays-equal.util";
import { TicketEventModel } from "../tickets/models/ticket-event.models";
import { DropdownOption } from "@shared/components/dropdown-with-search/dropdown-with-search.component";

export class UserUtils {
  constructor() {}

  createQueryString(setup: EventUserSetup): string {
    const sort = setup.order_type && setup.order_field ? `order_type=${setup.order_type}&order_field=${setup.order_field}` : '';
    const pagination = setup.page && setup.limit ? `page=${setup.page}&limit=${setup.limit}` : 'page=1&limit=10';
    const filterByJoinDate = setup.join_date_from && setup.join_date_to ? `join_date_from=${setup.join_date_from}&join_date_to=${setup.join_date_to}` : '';
    const filterByUserGroupTagIds = setup.user_group_tag_ids ? setup.user_group_tag_ids.map(id => 'user_group_tag_ids[]=' + id).join('&') : '';
    const filterByUserStatus = setup.user_status ? `user_status=${setup.user_status}` : '';
    const filterByUserExhibitorsModerator = setup.exhibitors_moderator ? setup.exhibitors_moderator.map(id => 'exhibitors_moderators[]=' + id).join('&') : '';
    const isExhibitorModerator = setup.isExhibitorModerator === true ? `is_exhibitor_moderator=true` : setup.isExhibitorModerator === false ? 'is_exhibitor_moderator=false' : '';
    const specificField = setup.specific_field_name && setup.specific_field_value ? `specific_field_name=${setup.specific_field_name}&specific_field_value=${setup.specific_field_value}` : '';
    const search = setup.search ? `search=${setup.search}` : '';
    const searchBy = setup.search_by.length > 0 ? `search_filters[]=${setup.search_by.join(',')}` : '';
    return '?'.concat([sort, pagination, filterByJoinDate, filterByUserGroupTagIds, filterByUserStatus, filterByUserExhibitorsModerator, isExhibitorModerator, specificField, searchBy, search].filter(Boolean).join('&'));
  }

  get searchByOptions(): DropdownOption[] {
    return [
      { id: 0, name: 'application_users.search_first_name', value: 'first_name', i18n: true },
      { id: 1, name: 'application_users.search_last_name', value: 'last_name', i18n: true },
      { id: 2, name: 'application_users.search_company', value: 'company', i18n: true },
      { id: 3, name: 'application_users.search_email', value: 'email', i18n: true },
    ]
  }

  getColumnWidthCSSProperty(property: UsersTableColumnProperty): Object {
    const columnSetup = this.getAllColumns().find(col => col.property === property);
    if (columnSetup === undefined) {
      return { 'min-width': '200px' };
    }
    return { 'min-width': `${columnSetup.width}px` };
  }

  getAllColumns(): UsersTableColumn[] {
    return [
      { id: 1, label: 'label_user_group', sort: null, property: UsersTableColumnProperty.UserGroup, width: 200, isDefault: true },
      { id: 2, label: 'label_company', sort: 'company', property: UsersTableColumnProperty.Company, width: 200, isDefault: true },
      { id: 3, label: 'label_email', sort: 'email', property: UsersTableColumnProperty.Email, width: 300, isDefault: true },
      { id: 4, label: 'label_status', sort: null, property: UsersTableColumnProperty.Status, width: 100, isDefault: false },
      // optional columns
      { id: 6, label: 'label_created_at', sort: 'created_at', property: UsersTableColumnProperty.CreatedAt, width: 200, isDefault: false },
      { id: 7, label: 'label_joined_at', sort: 'joined_at', property: UsersTableColumnProperty.JoinedAt, width: 200, isDefault: true },
      { id: 9, label: 'label_exhibitors_moderator', sort: null, property: UsersTableColumnProperty.ExhibitorsModerator, width: 200, isDefault: false },
    ]
  }

  getAreFiltered(setup: EventUserSetup): boolean {
    const defaultSetup = this.getDefaultApplicationUsersSetup();

    for (const key in defaultSetup) {
      if (defaultSetup.hasOwnProperty(key)) {
        if (!(key in setup)) {
          return true;
        }

        if (Array.isArray(defaultSetup[key]) && Array.isArray(setup[key])) {
          if (!arraysEqual(defaultSetup[key], setup[key])) {
            return true;
          }
        } else if (defaultSetup[key] !== setup[key]) {
          return true;
        }
      }
    }

    return false;
  }

  getDefaultApplicationUsersSetup(): EventUserSetup {
    return {
      order_type: 'asc',
      order_field: 'last_name',
      page: 1,
      limit: 10,
      search: '',
      search_by: [],
      join_date_from: '',
      join_date_to: '',
      user_group_tag_ids: [],
      user_status: null,
      exhibitors_moderator: null,
      isExhibitorModerator: false,
      specific_field_name: null,
      specific_field_value: null,
    }
  }

  getAllSetup(): EventUserSetup {
    return {
      order_type: null,
      order_field: null,
      page: 1,
      limit: 100000,
      search: null,
      search_by: [],
      join_date_from: null,
      join_date_to: null,
      user_group_tag_ids: null,
      user_status: null,
      exhibitors_moderator: null,
      isExhibitorModerator: false,
      specific_field_name: null,
      specific_field_value: null,
    }
  }

  mapUnpinTrasactionTicketIdsToString(user: EventUserApiModel, tickets: TicketEventModel[]): string[] {
    if (user.user_unpaid_transaction_ticket_ids) {
      const waitingTicketsArray: string[] = user.user_unpaid_transaction_ticket_ids.split('/');

      const groupedArray: string[][] = [];

      waitingTicketsArray.forEach(id => {
        const existingGroup = groupedArray.find((group) => group[0] === id);
        if (existingGroup) {
          existingGroup.push(id);
        } else {
          groupedArray.push([id]);
        }
      });

      let stringArray = [];
      groupedArray.forEach(group => {
        let groupString = '';
        const ticket = tickets.find(t => String(t.id) === group[0]);
        if (group[0] === EDGE_TICKET_ID) {
          groupString = `${group.length / 2}x ${ticket ? ticket.name : group[0]}`;
        } else {
          groupString = `${group.length}x ${ticket ? ticket.name : group[0]}`;
        }
        stringArray.push(groupString);
      });

      return stringArray;
    } else {
      return [];
    }
  }

}

export enum UsersTableColumnProperty {
  Company = 'company',
  Email = 'email',
  Status = 'status',
  UserGroup = 'user_group',
  CreatedAt = 'created_at',
  JoinedAt = 'joined_at',
  ExhibitorsModerator = 'exhibitors_moderator',
}

export enum UserActionSuccessMessage {
  ResendUsersEventTicketEmail = 'application_users.resend_event_ticket_email_success',
  SendUsersInvitations = 'application_users.send_user_invitations_success',
  SendUsersMarketingEmail = 'application_users.send_users_marketing_email_success',
  CheckInUser = 'application_users.check_in_user_success',
  CheckInUsers = 'application_users.check_in_users_success',
  CancelCheckInUser = 'application_users.cancel_check_in_user_success',
  CancelCheckInUsers = 'application_users.cancel_check_in_users_success',
  AcceptUserRequest = 'application_users.accept_user_request_success',
  DownloadUserQrCodes = 'application_users.download_user_qr_codes_success',
  RemoveUser = 'application_users.remove_user_success',
}

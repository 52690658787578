import { Injectable } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { ImportSelector } from ".";
import { ImportConfigModel, ImportConfigPatchModel } from "./import.models";
import { loadImportConfig, setError, syncImport, syncImportFailure, syncImportSuccess, updateImportConfig, updateImportConfigFailure, updateImportConfigSuccess } from "./import.actions";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ImportProviderService {
  constructor(
    private store$: Store<any>,
    private actions$: Actions,
  ) {}

  getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ImportSelector.selectLoading));
  }

  getUpdating(): Observable<boolean> {
    return this.store$.pipe(select(ImportSelector.selectUpdating));
  }

  getError(): Observable<Error | null> {
    return this.store$.pipe(select(ImportSelector.selectError));
  }

  getConfig(): Observable<ImportConfigModel> {
    return this.store$.pipe(select(ImportSelector.selectConfig));
  }

  getImporting(): Observable<boolean> {
    return this.store$.pipe(select(ImportSelector.selectImporting));
  }

  getImportingError(): Observable<Error | null> {
    return this.store$.pipe(select(ImportSelector.selectImportingError));
  }

  load() {
    this.store$.dispatch(loadImportConfig());
  }

  sync() {
    this.store$.dispatch(syncImport());
  }

  update(payload: ImportConfigPatchModel): void {
    this.store$.dispatch(updateImportConfig({ payload }));
  }

  setError(error: Error | null): void {
    this.store$.dispatch(setError({ error }));
  }

  get onUpdateSuccess(): Observable<ImportConfigPatchModel> {
    return this.actions$.pipe(
      ofType(updateImportConfigSuccess),
      map(action => action.config)
    );
  }

  get onUpdateFailure(): Observable<Error> {
    return this.actions$.pipe(
      ofType(updateImportConfigFailure),
      map(action => action.error)
    );
  }

  get onSyncSuccess(): Observable<ImportConfigPatchModel> {
    return this.actions$.pipe(
      ofType(syncImportSuccess),
    );
  }

  get onSyncFailure(): Observable<Error> {
    return this.actions$.pipe(
      ofType(syncImportFailure),
      map(action => action.error)
    );
  }
  
}
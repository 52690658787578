import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as ConstructorActions from './actions/construct.actions';
import * as fromConstructor from './reducers';

@Injectable()
export class ConstructFacadeService {
  constructor(private store$: Store<any>) {}

  getCurrentStep(): Observable<number> {
    return this.store$.pipe(select(fromConstructor.selectCurrentStep));
  }

  // getLoading(): Observable<boolean> {
  //   return this.store$.pipe(select(fromConstructor.selectLoading));
  // }

  setCurrentStep(currentStep: number) {
    this.store$.dispatch(ConstructorActions.setCurrentStep({ currentStep }));
  }
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollectionBaseService } from '@shared/providers/collection-base.service';

import { AgendaQuestionModel } from '@components/agenda/models/agenda-question.model';

@Injectable({
  providedIn: 'root'
})
export class AgendaQuestionStoreService extends CollectionBaseService<AgendaQuestionModel> {
  constructor() {
    super([]);
  }

  getOrderedAgendaQuestionsFromSession(agendaSessionId: number): Observable<AgendaQuestionModel[]> {
    return this.getOrderedAgendaQuestions()
      .pipe(
        map(questions => questions.filter(question => question.agenda_session_id === agendaSessionId))
      )
  }

  getOrderedAgendaQuestions(): Observable<AgendaQuestionModel[]> {
    const sortByDate = (questions: AgendaQuestionModel[]) => {
      return questions.sort((a, b) => (createdAtTicks(b) - createdAtTicks(a)));
    };

    return this.getAgendaQuestions().pipe(
      map(questions => sortByDate(questions))
    );
  }

  getAgendaQuestions(): Observable<AgendaQuestionModel[]> {
    return this.getEntities();
  }

  updateEntity(entity: AgendaQuestionModel): void {
    super.updateEntity(entity, (a, b) => (a.id === b.id));
  }

  removeEntity(entity: AgendaQuestionModel): void {
    super.removeEntity(entity, (a, b) => (a.id === b.id));
  }
}

function createdAtTicks(question: AgendaQuestionModel): number {
  const date = new Date(question.created_at);
  return date.getTime();
}

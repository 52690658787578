
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeedWallApiService } from './feed-wall-api.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';

import {
  FeedWallChannelModel,
  FeedWallChannelPostModel,
  FeedWallChannelPatchModel
} from './models/feed-wall-channel.model';

@Injectable({
  providedIn: 'root'
})
export class FeedWallChannelAdapterService {
  constructor(private apiService: FeedWallApiService) {}

  loadFeedWallChannels(event: EventModel, component: ComponentModel): Observable<FeedWallChannelModel[]> {
    return this.apiService.getFeedWallChannels(event.id, component.id);
  }

  createFeedWallChannel(event: EventModel, component: ComponentModel, channel: FeedWallChannelModel): Observable<FeedWallChannelModel> {
    const channelData = mapFeedWallChannelPost(channel);
    return this.apiService.createFeedWallChannel(event.id, component.id, channelData);
  }

  updateFeedWallChannel(event: EventModel, component: ComponentModel, channel: FeedWallChannelModel): Observable<FeedWallChannelModel> {
    const channelData = mapFeedWallChannelPatch(channel);
    return this.apiService.updateFeedWallChannel(event.id, component.id, channel.id, channelData);
  }

  deleteFeedWallChannel(event: EventModel, component: ComponentModel, channel: FeedWallChannelModel): Observable<boolean> {
    return this.apiService.deleteFeedWallChannel(event.id, component.id, channel.id).pipe(
      map(({ success }) => success)
    );
  }
}

function mapFeedWallChannelPost(channel: FeedWallChannelModel): FeedWallChannelPostModel {
  const welcomeThread = channel.feed_wall_threads[0];

  return {
    name: channel.name, url: welcomeThread.url, message: welcomeThread.message,
    image: welcomeThread.image && welcomeThread.image.id
  };
}

function mapFeedWallChannelPatch(channel: FeedWallChannelModel): FeedWallChannelPatchModel {
  let welcomeThread = channel.feed_wall_threads[0] as any;

  if (welcomeThread === undefined) {
    welcomeThread = {
      image: null,
      message: "",
      url: ""
    }
  }

  return {
    name: channel.name, url: welcomeThread.url, message: welcomeThread.message,
    image: welcomeThread.image && welcomeThread.image.id, default: channel.default
  };
}

<div>
  <h2 mat-dialog-title>{{ 'others.cropper_dialog_title' | translate }}</h2>

  <mat-dialog-content>
    <div class="cropper" *ngIf="isImageLoaded">
      <image-cropper
        [disabled]="isLoading"
        [imageBase64]="imageBase64String"
        [maintainAspectRatio]="true"
        (imageCropped)="imageCropped($event)">
      </image-cropper>
    </div>
    <app-spinner-overlay *ngIf="isLoading"></app-spinner-overlay>
  </mat-dialog-content>

  <mat-dialog-actions class="action-buttons">
    <button mat-button class="btn mr-2" (click)="close()">{{ 'buttons.cancel' | translate }}</button>
    <button mat-button class="btn btn-primary pl-4 pr-4" (click)="save()">{{ 'buttons.save' | translate }}</button>
  </mat-dialog-actions>
</div>
import { createAction, props } from "@ngrx/store";
import { ImportConfigModel, ImportConfigPatchModel } from "./import.models";

export const loadImportConfig = createAction(
  '[Import] Load Import Config',
);

export const loadImportConfigSuccess = createAction(
  '[Import] Load Import Config Success',
  props<{ config: ImportConfigModel }>()
);

export const loadImportConfigFailure = createAction(
  '[Import] Load Import Config Failure',
  props<{ error: Error }>()
);

export const updateImportConfig = createAction(
  '[Import] Update Import Config',
  props<{ payload: ImportConfigPatchModel }>()
);

export const updateImportConfigSuccess = createAction(
  '[Import] Update Import Config Success',
  props<{ config: ImportConfigModel }>()
);

export const updateImportConfigFailure = createAction(
  '[Import] Update Import Config Failure',
  props<{ error: Error }>()
);

export const syncImport = createAction(
  '[Import] Sync Import',
);

export const syncImportSuccess = createAction(
  '[Import] Sync Import Success',
);

export const syncImportFailure = createAction(
  '[Import] Sync Import Failure',
  props<{ error: Error }>()
);

export const setError = createAction(
  '[Import] Set Error',
  props<{ error: Error | null }>()
);
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { CurrencyModel } from '@shared/models/currency.model';

export const currencies: CurrencyModel[] = [
  { code: 'AED', name: 'United Arab Emirates dirham', decimal_separator: 2 },
  { code: 'AFN', name: 'Afghan afghani', decimal_separator: 2 },
  { code: 'ALL', name: 'Albanian lek', decimal_separator: 2 },
  { code: 'AMD', name: 'Armenian dram', decimal_separator: 2 },
  { code: 'ANG', name: 'Netherlands Antillean guilder', decimal_separator: 2 },
  { code: 'AOA', name: 'Angolan kwanza', decimal_separator: 2 },
  { code: 'ARS', name: 'Argentine peso', decimal_separator: 2 },
  { code: 'AUD', name: 'Australian dollar', decimal_separator: 2 },
  { code: 'AWG', name: 'Aruban florin', decimal_separator: 2 },
  { code: 'AZN', name: 'Azerbaijani manat', decimal_separator: 2 },
  { code: 'BAM', name: 'Bosnia and Herzegovina convertible mark', decimal_separator: 2 },
  { code: 'BBD', name: 'Barbados dollar', decimal_separator: 2 },
  { code: 'BDT', name: 'Bangladeshi taka', decimal_separator: 2 },
  { code: 'BGN', name: 'Bulgarian lev', decimal_separator: 2 },
  { code: 'BHD', name: 'Bahraini dinar', decimal_separator: 3 },
  { code: 'BIF', name: 'Burundian franc', decimal_separator: 0 },
  { code: 'BMD', name: 'Bermudian dollar', decimal_separator: 2 },
  { code: 'BND', name: 'Brunei dollar', decimal_separator: 2 },
  { code: 'BOB', name: 'Boliviano', decimal_separator: 2 },
  { code: 'BOV', name: 'Bolivian Mvdol (funds code)', decimal_separator: 2 },
  { code: 'BRL', name: 'Brazilian real', decimal_separator: 2 },
  { code: 'BSD', name: 'Bahamian dollar', decimal_separator: 2 },
  { code: 'BTN', name: 'Bhutanese ngultrum', decimal_separator: 2 },
  { code: 'BWP', name: 'Botswana pula', decimal_separator: 2 },
  { code: 'BYN', name: 'Belarusian ruble', decimal_separator: 2 },
  { code: 'BZD', name: 'Belize dollar', decimal_separator: 2 },
  { code: 'CAD', name: 'Canadian dollar', decimal_separator: 2 },
  { code: 'CDF', name: 'Congolese franc', decimal_separator: 2 },
  { code: 'CHE', name: 'WIR Euro (complementary currency)', decimal_separator: 2 },
  { code: 'CHF', name: 'Swiss franc', decimal_separator: 2 },
  { code: 'CHW', name: 'WIR Franc (complementary currency)', decimal_separator: 2 },
  { code: 'CLF', name: 'Unidad de Fomento (funds code)', decimal_separator: 4 },
  { code: 'CLP', name: 'Chilean peso', decimal_separator: 0 },
  { code: 'CNY', name: 'Renminbi (Chinese) yuan[7]', decimal_separator: 2 },
  { code: 'COP', name: 'Colombian peso', decimal_separator: 2 },
  { code: 'COU', name: 'Unidad de Valor Real (UVR) (funds code)[8]', decimal_separator: 2 },
  { code: 'CRC', name: 'Costa Rican colon', decimal_separator: 2 },
  { code: 'CUC', name: 'Cuban convertible peso', decimal_separator: 2 },
  { code: 'CUP', name: 'Cuban peso', decimal_separator: 2 },
  { code: 'CVE', name: 'Cape Verdean escudo', decimal_separator: 2 },
  { code: 'CZK', name: 'Czech koruna', decimal_separator: 2 },
  { code: 'DJF', name: 'Djiboutian franc', decimal_separator: 0 },
  { code: 'DKK', name: 'Danish krone', decimal_separator: 2 },
  { code: 'DOP', name: 'Dominican peso', decimal_separator: 2 },
  { code: 'DZD', name: 'Algerian dinar', decimal_separator: 2 },
  { code: 'EGP', name: 'Egyptian pound', decimal_separator: 2 },
  { code: 'ERN', name: 'Eritrean nakfa', decimal_separator: 2 },
  { code: 'ETB', name: 'Ethiopian birr', decimal_separator: 2 },
  { code: 'EUR', name: 'Euro', decimal_separator: 2 },
  { code: 'FJD', name: 'Fiji dollar', decimal_separator: 2 },
  { code: 'FKP', name: 'Falkland Islands pound', decimal_separator: 2 },
  { code: 'GBP', name: 'Pound sterling', decimal_separator: 2 },
  { code: 'GEL', name: 'Georgian lari', decimal_separator: 2 },
  { code: 'GHS', name: 'Ghanaian cedi', decimal_separator: 2 },
  { code: 'GIP', name: 'Gibraltar pound', decimal_separator: 2 },
  { code: 'GMD', name: 'Gambian dalasi', decimal_separator: 2 },
  { code: 'GNF', name: 'Guinean franc', decimal_separator: 0 },
  { code: 'GTQ', name: 'Guatemalan quetzal', decimal_separator: 2 },
  { code: 'GYD', name: 'Guyanese dollar', decimal_separator: 2 },
  { code: 'HKD', name: 'Hong Kong dollar', decimal_separator: 2 },
  { code: 'HNL', name: 'Honduran lempira', decimal_separator: 2 },
  { code: 'HRK', name: 'Croatian kuna', decimal_separator: 2 },
  { code: 'HTG', name: 'Haitian gourde', decimal_separator: 2 },
  { code: 'HUF', name: 'Hungarian forint', decimal_separator: 2 },
  { code: 'IDR', name: 'Indonesian rupiah', decimal_separator: 2 },
  { code: 'ILS', name: 'Israeli new shekel', decimal_separator: 2 },
  { code: 'INR', name: 'Indian rupee', decimal_separator: 2 },
  { code: 'IQD', name: 'Iraqi dinar', decimal_separator: 3 },
  { code: 'IRR', name: 'Iranian rial', decimal_separator: 2 },
  { code: 'ISK', name: 'Icelandic kr', decimal_separator: 0 },
  { code: 'JMD', name: 'Jamaican dollar', decimal_separator: 2 },
  { code: 'JOD', name: 'Jordanian dinar', decimal_separator: 3 },
  { code: 'JPY', name: 'Japanese yen', decimal_separator: 0 },
  { code: 'KES', name: 'Kenyan shilling', decimal_separator: 2 },
  { code: 'KGS', name: 'Kyrgyzstani som', decimal_separator: 2 },
  { code: 'KHR', name: 'Cambodian riel', decimal_separator: 2 },
  { code: 'KMF', name: 'Comoro franc', decimal_separator: 0 },
  { code: 'KPW', name: 'North Korean won', decimal_separator: 2 },
  { code: 'KRW', name: 'South Korean won', decimal_separator: 0 },
  { code: 'KWD', name: 'Kuwaiti dinar', decimal_separator: 3 },
  { code: 'KYD', name: 'Cayman Islands dollar', decimal_separator: 2 },
  { code: 'KZT', name: 'Kazakhstani tenge', decimal_separator: 2 },
  { code: 'LAK', name: 'Lao kip', decimal_separator: 2 },
  { code: 'LBP', name: 'Lebanese pound', decimal_separator: 2 },
  { code: 'LKR', name: 'Sri Lankan rupee', decimal_separator: 2 },
  { code: 'LRD', name: 'Liberian dollar', decimal_separator: 2 },
  { code: 'LSL', name: 'Lesotho loti', decimal_separator: 2 },
  { code: 'LYD', name: 'Libyan dinar', decimal_separator: 3 },
  { code: 'MAD', name: 'Moroccan dirham', decimal_separator: 2 },
  { code: 'MDL', name: 'Moldovan leu', decimal_separator: 2 },
  { code: 'MGA', name: 'Malagasy ariary', decimal_separator: 2 },
  { code: 'MKD', name: 'Macedonian denar', decimal_separator: 2 },
  { code: 'MMK', name: 'Myanmar kyat', decimal_separator: 2 },
  { code: 'MNT', name: 'Mongolian t', decimal_separator: 2 },
  { code: 'MOP', name: 'Macanese pataca', decimal_separator: 2 },
  { code: 'MRU', name: 'Mauritanian ouguiya', decimal_separator: 2 },
  { code: 'MUR', name: 'Mauritian rupee', decimal_separator: 2 },
  { code: 'MVR', name: 'Maldivian rufiyaa', decimal_separator: 2 },
  { code: 'MWK', name: 'Malawian kwacha', decimal_separator: 2 },
  { code: 'MXN', name: 'Mexican peso', decimal_separator: 2 },
  { code: 'MXV', name: 'Mexican Unidad de Inversion (UDI) (funds code)', decimal_separator: 2 },
  { code: 'MYR', name: 'Malaysian ringgit', decimal_separator: 2 },
  { code: 'MZN', name: 'Mozambican metical', decimal_separator: 2 },
  { code: 'NAD', name: 'Namibian dollar', decimal_separator: 2 },
  { code: 'NGN', name: 'Nigerian naira', decimal_separator: 2 },
  { code: 'NIO', name: 'Nicaraguan c', decimal_separator: 2 },
  { code: 'NOK', name: 'Norwegian krone', decimal_separator: 2 },
  { code: 'NPR', name: 'Nepalese rupee', decimal_separator: 2 },
  { code: 'NZD', name: 'New Zealand dollar', decimal_separator: 2 },
  { code: 'OMR', name: 'Omani rial', decimal_separator: 3 },
  { code: 'PAB', name: 'Panamanian balboa', decimal_separator: 2 },
  { code: 'PEN', name: 'Peruvian sol', decimal_separator: 2 },
  { code: 'PGK', name: 'Papua New Guinean kina', decimal_separator: 2 },
  { code: 'PHP', name: 'Philippine peso[13]', decimal_separator: 2 },
  { code: 'PKR', name: 'Pakistani rupee', decimal_separator: 2 },
  { code: 'PLN', name: 'Polish złoty', decimal_separator: 2 },
  { code: 'PYG', name: 'Paraguayan guaran', decimal_separator: 0 },
  { code: 'QAR', name: 'Qatari riyal', decimal_separator: 2 },
  { code: 'RON', name: 'Romanian leu', decimal_separator: 2 },
  { code: 'RSD', name: 'Serbian dinar', decimal_separator: 2 },
  { code: 'RUB', name: 'Russian ruble', decimal_separator: 2 },
  { code: 'RWF', name: 'Rwandan franc', decimal_separator: 0 },
  { code: 'SAR', name: 'Saudi riyal', decimal_separator: 2 },
  { code: 'SBD', name: 'Solomon Islands dollar', decimal_separator: 2 },
  { code: 'SCR', name: 'Seychelles rupee', decimal_separator: 2 },
  { code: 'SDG', name: 'Sudanese pound', decimal_separator: 2 },
  { code: 'SEK', name: 'Swedish krona', decimal_separator: 2 },
  { code: 'SGD', name: 'Singapore dollar', decimal_separator: 2 },
  { code: 'SHP', name: 'Saint Helena pound', decimal_separator: 2 },
  { code: 'SLL', name: 'Sierra Leonean leone', decimal_separator: 2 },
  { code: 'SOS', name: 'Somali shilling', decimal_separator: 2 },
  { code: 'SRD', name: 'Surinamese dollar', decimal_separator: 2 },
  { code: 'SSP', name: 'South Sudanese pound', decimal_separator: 2 },
  { code: 'STN', name: 'São Tomé and Príncipe dobra', decimal_separator: 2 },
  { code: 'SVC', name: 'Salvadoran col', decimal_separator: 2 },
  { code: 'SYP', name: 'Syrian pound', decimal_separator: 2 },
  { code: 'SZL', name: 'Swazi lilangeni', decimal_separator: 2 },
  { code: 'THB', name: 'Thai baht', decimal_separator: 2 },
  { code: 'TJS', name: 'Tajikistani somoni', decimal_separator: 2 },
  { code: 'TMT', name: 'Turkmenistan manat', decimal_separator: 2 },
  { code: 'TND', name: 'Tunisian dinar', decimal_separator: 3 },
  { code: 'TOP', name: 'Tongan pa', decimal_separator: 2 },
  { code: 'TRY', name: 'Turkish lira', decimal_separator: 2 },
  { code: 'TTD', name: 'Trinidad and Tobago dollar', decimal_separator: 2 },
  { code: 'TWD', name: 'New Taiwan dollar', decimal_separator: 2 },
  { code: 'TZS', name: 'Tanzanian shilling', decimal_separator: 2 },
  { code: 'UAH', name: 'Ukrainian hryvnia', decimal_separator: 2 },
  { code: 'UGX', name: 'Ugandan shilling', decimal_separator: 0 },
  { code: 'USD', name: 'United States dollar', decimal_separator: 2 },
  { code: 'USN', name: 'United States dollar (next day) (funds code)', decimal_separator: 2 },
  { code: 'UYI', name: 'Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)', decimal_separator: 0 },
  { code: 'UYU', name: 'Uruguayan peso', decimal_separator: 2 },
  { code: 'UYW', name: 'Unidad previsional[15]', decimal_separator: 4 },
  { code: 'UZS', name: 'Uzbekistan som', decimal_separator: 2 },
  { code: 'VES', name: 'Venezuelan bol', decimal_separator: 2 },
  { code: 'VND', name: 'Vietnamese ', decimal_separator: 0 },
  { code: 'VUV', name: 'Vanuatu vatu', decimal_separator: 0 },
  { code: 'WST', name: 'Samoan tala', decimal_separator: 2 },
  { code: 'XAF', name: 'CFA franc BEAC', decimal_separator: 0 },
  { code: 'XAG', name: 'Silver (one troy ounce)', decimal_separator: 0 },
  { code: 'XAU', name: 'Gold (one troy ounce)', decimal_separator: 0 },
  { code: 'XBA', name: 'European Composite Unit (EURCO) (bond market unit)', decimal_separator: 0 },
  { code: 'XBB', name: 'European Monetary Unit (E', decimal_separator: 0 },
  { code: 'XBC', name: 'European Unit of Account 9 (E', decimal_separator: 0 },
  { code: 'XBD', name: 'European Unit of Account 17 (E', decimal_separator: 0 },
  { code: 'XCD', name: 'East Caribbean dollar', decimal_separator: 2 },
  { code: 'XDR', name: 'Special drawing rights', decimal_separator: 0 },
  { code: 'XOF', name: 'CFA franc BCEAO', decimal_separator: 0 },
  { code: 'XPD', name: 'Palladium (one troy ounce)', decimal_separator: 0 },
  { code: 'XPF', name: 'CFP franc (franc Pacifique)', decimal_separator: 0 },
  { code: 'XPT', name: 'Platinum (one troy ounce)', decimal_separator: 0 },
  { code: 'XSU', name: 'SUCRE', decimal_separator: 0 },
  { code: 'XTS', name: 'Code reserved for testing', decimal_separator: 0 },
  { code: 'XUA', name: 'ADB Unit of Account', decimal_separator: 0 },
  { code: 'XXX', name: 'No currency', decimal_separator: 0 },
  { code: 'YER', name: 'Yemeni rial', decimal_separator: 2 },
  { code: 'ZAR', name: 'South African rand', decimal_separator: 2 },
  { code: 'ZMW', name: 'Zambian kwacha', decimal_separator: 2 },
  { code: 'ZWL', name: 'Zimbabwean dollar', decimal_separator: 2 },
];

@Injectable({
  providedIn: 'root'
})
export class CurrencyProviderService {
  getCurrencies(): Observable<CurrencyModel[]> {
    return of(currencies);
  }
}

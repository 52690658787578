import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-fullscreen',
  templateUrl: './spinner-fullscreen.component.html',
  styleUrls: ['./spinner-fullscreen.component.scss']
})
export class SpinnerFullscreenComponent {

  @Input() background: string;
  @Input() float = false;

  @HostBinding('@fadeOut') get _fadeOut() {
    return true;
  }

  @HostBinding('style.background') get _backgroundColor() {
    return this.background;
  }

}

import { Component, Input, HostBinding } from '@angular/core';

import { AttachmentModel } from '@shared/models/attachment.model';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  // TODO: remove as this is unused input at all
  @Input() element: any;

  @Input() pictureUrl: string;
  @Input() picture: AttachmentModel;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() label: string;

  @HostBinding('style.backgroundImage') get _backgroundImage() {
    return this.picture && `url(${this.picture.file_thumbnail_200_url ? this.picture.file_thumbnail_200_url : this.picture})`;
  }

  get pictureExist(): boolean {
    return !!this.pictureUrl || !!this.picture;
  }

  get initials(): string {
    const first = (this.firstName && this.firstName[0]) || '';
    const last = (this.lastName && this.lastName[0]) || '';

    return `${first}${last}`.toUpperCase();
  }
}

import { TokenModel } from '@app/core/models/token.model';

export const enum TokenStatus {
  Unauthenticated = 'unauthenticated',
  Authenticated = 'authenticated',
  Retrieving = 'retrieving',
  Refreshing = 'refreshing',
}

export interface TokenState {
  tokenStatus: TokenStatus;
  token: TokenModel;
  error: Error;
}

export const initialState: TokenState = {
  tokenStatus: TokenStatus.Unauthenticated,
  token: null,
  error: null,
};

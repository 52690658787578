import {
  Action,
  createReducer,
  createSelector,
  on,
  combineReducers,
} from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import {
  VenueTranslationModel,
  VenueCategoryTranslationModel,
} from '@components/venue/models/venue.model';

import * as TranslationActions from '../actions/translation.actions';

export interface VenueTranslationState
  extends EntityState<VenueTranslationModel> {
  loading: boolean;
  error: Error;
}

export interface CategoryTranslationState
  extends EntityState<VenueCategoryTranslationModel> {
  loading: boolean;
  error: Error;
}

export interface State {
  venues: VenueTranslationState;
  categories: CategoryTranslationState;
}

export const venueAdapter = createEntityAdapter<VenueTranslationModel>({
  sortComparer: false,
});

export const initialVenueState: VenueTranslationState =
  venueAdapter.getInitialState({
    loading: false,
    error: null,
  });

export const venueTranslationReducer = createReducer(
  initialVenueState,
  on(TranslationActions.loadVenueTranslation, (state, { venue }) => ({
    ...state,
    loading: true,
  })),
  on(
    TranslationActions.loadVenueTranslationSuccess,
    (state, { venue, translation }) =>
      venueAdapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    TranslationActions.loadVenueTranslationFailure,
    (state, { venue, error }) => ({ ...state, loading: false, error })
  ),
  on(
    TranslationActions.updateVenueTranslationsSuccess,
    (state, { venue, translations }) =>
      venueAdapter.upsertMany(translations, state)
  ),
  on(
    TranslationActions.updateVenueTranslationsFailure,
    (state, { venue, error }) => ({ ...state, error })
  )
);

export const categoryAdapter =
  createEntityAdapter<VenueCategoryTranslationModel>({
    sortComparer: false,
  });

export const initialCategoryState: CategoryTranslationState =
  categoryAdapter.getInitialState({
    loading: false,
    error: null,
  });

export const categoryTranslationReducer = createReducer(
  initialCategoryState,
  on(
    TranslationActions.loadVenueCategoryTranslation,
    (state, { category }) => ({ ...state, loading: true })
  ),
  on(
    TranslationActions.loadVenueCategoryTranslationSuccess,
    (state, { category, translation }) =>
      categoryAdapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    TranslationActions.loadVenueCategoryTranslationFailure,
    (state, { category, error }) => ({ ...state, loading: false, error })
  ),
  on(
    TranslationActions.updateVenueCategoryTranslationsSuccess,
    (state, { category, translations }) =>
      categoryAdapter.upsertMany(translations, state)
  ),
  on(
    TranslationActions.updateVenueCategoryTranslationsFailure,
    (state, { category, error }) => ({ ...state, error })
  )
);

export const combinedReducers = combineReducers<State>({
  venues: venueTranslationReducer,
  categories: categoryTranslationReducer,
});

export function reducer(state: State, action: Action): State {
  return combinedReducers(state, action);
}

// VENUE SELECTORS

export const selectVenueTranslationState = (state: State) => state.venues;

const { selectAll: selectAllVenueTranslations } = venueAdapter.getSelectors(
  selectVenueTranslationState
);

export const selectVenueTranslations = createSelector(
  selectAllVenueTranslations,
  (venues: VenueTranslationModel[], { venueId, language }) =>
    venues.filter(venue => venue.venueId === venueId)
);

export const selectVenueTranslation = createSelector(
  selectVenueTranslations,
  (venues: VenueTranslationModel[], { language }) =>
    venues.find(venue => venue.language === language)
);

export const selectVenueTranslationLoading = createSelector(
  selectVenueTranslationState,
  state => state.loading
);

export const selectVenueTranslationError = createSelector(
  selectVenueTranslationState,
  state => state.error
);

// CATEGORY SELECTORS

export const selectCategoryTranslationState = (state: State) =>
  state.categories;

const { selectAll: selectAllCategoryTranslations } =
  categoryAdapter.getSelectors(selectCategoryTranslationState);

export const selectCategoryTranslations = () =>
  createSelector(
    selectAllCategoryTranslations,
    (categories: VenueCategoryTranslationModel[], { categoryId, language }) =>
      categories.filter(cat => cat.categoryId === categoryId)
  );

export const selectCategoryTranslation = () =>
  createSelector(
    selectCategoryTranslations(),
    (categories: VenueCategoryTranslationModel[], { language }) =>
      categories.find(cat => cat.language === language)
  );

export const selectCategoryTranslationLoading = createSelector(
  selectCategoryTranslationState,
  state => state.loading
);

export const selectCategoryTranslationError = createSelector(
  selectCategoryTranslationState,
  state => state.error
);

import { Pipe, PipeTransform } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { extractErrorWithLocalMessage } from '@utils/errors.util';

@Pipe({
  name: 'translatedErrorMessage'
})
export class TranslatedErrorMessagePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(err: Error | HttpErrorResponse, prefix?: string): string {
    const key = extractErrorWithLocalMessage(err, prefix);

    let translation = this.translateService.instant(key);
    return translation !== key ? translation : this.translateService.instant('error.default');
  }
}
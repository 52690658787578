<div class="dropdown dropdown-noborder dropdown-lang">
  <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
    <img src="{{ currentLang.img }}"> {{ currentLang.friendly_lang }}
  </button>

  <div class="dropdown-menu dropdown-menu-right">
    <button *ngFor="let lang of langs;" class="dropdown-item" [class.active]="currentLang.lang == lang.lang" type="button" (click)="setLang(lang)">
      <img src="{{ lang.img }}"> {{ lang.friendly_lang }}
    </button>
  </div>
</div>
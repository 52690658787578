import { Action, createReducer, on } from '@ngrx/store';

import * as ExhibitorModalActions from '../actions/exhibitor-modal.actions';

export const stateKey = 'exhibitorModal';

export interface State {
  saving: boolean;
  saved: boolean;
  error: Error;
}

export const initialState: State = {
  saving: false,
  saved: false,
  error: null,
};

export const exhibitorModalReducer = createReducer(
  initialState,
  on(
    ExhibitorModalActions.modalCreateExhibitor,
    ExhibitorModalActions.modalUpdateExhibitor,
    state => ({ ...initialState, saving: true })
  ),
  on(
    ExhibitorModalActions.modalCreateExhibitorSuccess,
    ExhibitorModalActions.modalUpdateExhibitorSuccess,
    state => ({ ...initialState, saved: true })
  ),
  on(
    ExhibitorModalActions.modalCreateExhibitorFailure,
    ExhibitorModalActions.modalUpdateExhibitorFailure,
    (state, { error }) => ({ ...initialState, error })
  ),
);

export function reducer(state: State, action: Action): State {
  return exhibitorModalReducer(state, action);
}

export const selectExhibitorModalSaving = (state: State) => state.saving;

export const selectExhibitorModalSaved = (state: State) => state.saved;

export const selectExhibitorModalError = (state: State) => state.error;

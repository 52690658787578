import { ExhibitorUpdateModel, ExhibitorUpdateQueries, ExhibitorUpdatesResponse, ExhibitorUpdatePatch, ExhibitorUpdatesStatus } from '../models/exhibitor-updates.model';
import { ComponentModel } from '@shared/models/component.model';
import { EventModel } from '@app/store/features/event/models/event.model';
import { createAction, props } from '@ngrx/store';

const loadExhibitorUpdates = createAction(
  '[Exhibitor/Updates] Load Exhibitor Updates',
  props<{ event: EventModel, component: ComponentModel, queries: ExhibitorUpdateQueries }>()
);

const loadExhibitorUpdatesSuccess = createAction(
  '[Exhibitor/Updates] Load Exhibitor Updates Success',
  props<{ data: ExhibitorUpdatesResponse, status: ExhibitorUpdatesStatus }>()
);

const loadExhibitorUpdatesFailure = createAction(
  '[Exhibitor/Updates] Load Exhibitor Updates Failure',
  props<{ error: Error }>()
);

const changeExhibitorUpdateStatus = createAction(
  '[Exhibitor/Updates] Change Exhibitor Update Status',
  props<{ event: EventModel, component: ComponentModel, update: ExhibitorUpdateModel, payload: ExhibitorUpdatePatch }>()
);

const changeExhibitorUpdateStatusSuccess = createAction(
  '[Exhibitor/Updates] Change Exhibitor Update Status Success',
  props<{ data: ExhibitorUpdateModel }>()
);

const changeExhibitorUpdateStatusFailure = createAction(
  '[Exhibitor/Updates] Change Exhibitor Update Status Failure',
  props<{ error: Error }>()
);

const setUpdatingId = createAction(
  '[Exhibitor/Updates] Set Exhibitor Updating Id',
  props<{ id: number | null }>()
);

const switchActiveTab = createAction(
  '[Exhibitor/Updates] Switch Active Tab',
  props<{ tab: ExhibitorUpdatesStatus }>()
)

export const ExhibitorUpdatesAction = {
  loadExhibitorUpdates,
  loadExhibitorUpdatesSuccess,
  loadExhibitorUpdatesFailure,

  changeExhibitorUpdateStatus,
  changeExhibitorUpdateStatusSuccess,
  changeExhibitorUpdateStatusFailure,

  setUpdatingId,
  switchActiveTab
}
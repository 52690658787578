import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { ExhibitorTranslationModel } from '@components/exhibitor/models/exhibitor-translation.model';

import * as ExhibitorTranslationActions from '../actions/exhibitor-translation.actions';
import * as ImportActions from '../actions/exhibitor-import.actions';

export const stateKey = 'exhibitorTranslation';

export interface State extends EntityState<ExhibitorTranslationModel> {
  loading: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<ExhibitorTranslationModel>({
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const exhibitorTranslationReducer = createReducer(
  initialState,
  on(
    ExhibitorTranslationActions.loadExhibitorTranslation,
    (state, { exhibitor }) => ({ ...initialState, loading: true })
  ),
  on(
    ExhibitorTranslationActions.loadExhibitorTranslationSuccess,
    (state, { exhibitor, translation }) =>
      adapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    ExhibitorTranslationActions.loadExhibitorTranslationFailure,
    (state, { exhibitor, error }) => ({ ...state, loading: false, error })
  ),
  on(
    ExhibitorTranslationActions.updateExhibitorTranslationsSuccess,
    (state, { exhibitor, translations }) =>
      adapter.upsertMany(translations, state)
  ),
  on(
    ExhibitorTranslationActions.updateExhibitorTranslationsFailure,
    (state, { exhibitor, error }) => ({ ...state, error })
  ),
  on(ImportActions.importExhibitorsSuccess, state => ({ ...initialState }))
);

export function reducer(state: State, action: Action): State {
  return exhibitorTranslationReducer(state, action);
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectExhibitorTranslationEntities = selectEntities;

export const selectAllExhibitorTranslations = selectAll;

export const selectExhibitorTranslations = createSelector(
  selectAllExhibitorTranslations,
  (exhibitors: ExhibitorTranslationModel[], { exhibitorId, language }) =>
    exhibitors.filter(exhibitor => exhibitor.exhibitorId === exhibitorId)
);

export const selectExhibitorTranslation = createSelector(
  selectExhibitorTranslations,
  (exhibitors: ExhibitorTranslationModel[], { language }) =>
    exhibitors.find(exhibitor => exhibitor.language === language)
);

export const selectExhibitorTranslationLoading = (state: State) =>
  state.loading;

export const selectExhibitorTranslationError = (state: State) => state.error;

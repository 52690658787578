import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { FormValidateDirective } from '@shared/directives/form-validate.directive';

@Injectable({
  providedIn: 'root'
})
export class FormContainerService {
  get validators(): FormValidateDirective[] {
    return this.validators$.value;
  }

  get submitted(): boolean {
    return this.submitted$.value;
  }

  private validators$: BehaviorSubject<FormValidateDirective[]>;
  private submitted$: BehaviorSubject<boolean>;

  constructor() {
    this.validators$ = new BehaviorSubject([]);
    this.submitted$ = new BehaviorSubject(false);
  }

  getValidators(): Observable<FormValidateDirective[]> {
    return this.validators$.asObservable();
  }

  getSubmitted(): Observable<boolean> {
    return this.submitted$.asObservable();
  }

  setValidators(validators: FormValidateDirective[]) {
    this.validators$.next(validators);
  }

  scrollIntoError(): boolean {
    for (const validator of this.validators) {
      const errors = Object.keys(validator.errors);

      if (errors.length) {
        validator.element.scrollIntoView({ behavior: 'smooth' });
        return true;
      }
    }

    return false;
  }

  submit() {
    if (!this.submitted$.value) {
      this.submitted$.next(true);
    }
  }

  reset() {
    if (this.submitted$.value) {
      this.submitted$.next(false);
    }
  }
}

import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-file-uploader-dialog',
  templateUrl: './file-uploader-dialog.component.html',
  styleUrls: ['./file-uploader-dialog.component.scss']
})
export class FileUploaderDialogComponent implements OnInit, OnDestroy {

  image = new FormControl(null);
  imgLink = new FormControl(null);

  @Output() onSubmit: EventEmitter<string>;

  uploaded = false;

  private subs = new Subscription();

  constructor() {
    this.onSubmit = new EventEmitter<string>();
  }

  ngOnInit(): void {
    this.subs.add(this.image.valueChanges.subscribe(value => {
      if(value === null) {
        this.uploaded = false;
        this.imgLink.enable();
      } else {
        this.uploaded = true;
        this.imgLink.disable();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onFileUploadStarted(): void {
    this.uploaded = false;
    this.imgLink.disable();
  }

  handleSubmit(): void {
    const uploadedImgUrl = this.image.value?.file_url.split('?')[0];
    if(this.image.value) {
      this.onSubmit.emit(`<img src='${uploadedImgUrl}' width='100%' />`);
    } else {
      this.onSubmit.emit(`<img src='${this.imgLink.value}' width='100%' />`);
    }
  }

}

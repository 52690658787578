import { Component, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, Validator, ValidationErrors, AbstractControl, FormControl, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @ViewChild(MatDatepicker, { static: true }) datePicker: MatDatepicker<any>;

  dateControl: FormControl;

  private onChange: (value: Date) => void;
  private onTouched: () => void;

  private subs = new Subscription();

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private i18n: TranslateService,
  ) {
    this.dateControl = new FormControl(null, Validators.required);

    this.dateAdapter.setLocale(this.i18n.currentLang);
    this.subs.add(this.i18n.onLangChange
      .subscribe(conf => {
        this.dateAdapter.setLocale(conf.lang)
      }));
  }

  ngOnInit() {
    this.dateControl.valueChanges.subscribe(
      value => this.propagateChanges(value)
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onDatePickerClick(ev: Event) {
    if (!this.dateControl.disabled) {
      this.datePicker.open();
    }
  }

  writeValue(date: any): void {
    this.dateControl.setValue(date);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateControl.disable();
    } else {
      this.dateControl.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.dateControl.valid ? null : { datePickerError: { valid: false } };
  }

  private propagateChanges(value: Date) {
    if (this.onChange) {
      this.onChange(value);
    }
  }
}

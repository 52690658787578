import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";
import { clearEventState } from "@store/features/event/actions";

function storesToClearAfterLogout() {
  return [
    clearEventState,
  ];
}

@Injectable({
  providedIn: 'root'
})
export class ClearStateUtil {
  constructor(private store: Store<any>) {}

  clearOnLogout(): void {
    storesToClearAfterLogout().forEach(fn => this.store.dispatch(fn()));
  }
}

const LC_KEY_BASE = 'CLEAR_STATE';

export function openClearStateRequest(types: LSClearState[]): void {
  types.forEach(type => {
    if (!getClearStateRequest(type)) {
      localStorage.setItem(`${LC_KEY_BASE}_${type}`, '1');
    }
  });
}

export function getClearStateRequest(type: LSClearState): boolean {
  if (localStorage.getItem(`${LC_KEY_BASE}_${type}`)) {
    return true;
  }
  return false;
}

export function closeClearStateRequest(type: LSClearState): void {
  localStorage.removeItem(`${LC_KEY_BASE}_${type}`);
}

export function closeAllClearStateRequests(): void {
  Object.values(LSClearState).forEach(type => {
    closeClearStateRequest(type);
  });
}

export enum LSClearState {
  DASHBOARD = 'DASHBOARD',
}
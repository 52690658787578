import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBookingSession from './booking-session.reducer';
import * as fromBookingDay from './booking-day.reducer';
import * as fromBookingBuilding from './booking-building.reducer';
import * as fromBookingRoom from './booking-room.reducer';
import * as fromBookingReservation from './booking-reservation.reducer';
import * as fromBookingFilter from './booking-filter.reducer';

export const featureKey = 'bookingFeature';

export interface State {
  [fromBookingSession.featureKey]: fromBookingSession.State;
  [fromBookingDay.featureKey]: fromBookingDay.State;
  [fromBookingBuilding.featureKey]: fromBookingBuilding.State;
  [fromBookingRoom.featureKey]: fromBookingRoom.State;
  [fromBookingReservation.featureKey]: fromBookingReservation.State;
  [fromBookingFilter.featureKey]: fromBookingFilter.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromBookingSession.featureKey]: fromBookingSession.reducer,
  [fromBookingDay.featureKey]: fromBookingDay.reducer,
  [fromBookingBuilding.featureKey]: fromBookingBuilding.reducer,
  [fromBookingRoom.featureKey]: fromBookingRoom.reducer,
  [fromBookingReservation.featureKey]: fromBookingReservation.reducer,
  [fromBookingFilter.featureKey]: fromBookingFilter.reducer,
}

export const selectFeatureKey = createFeatureSelector<State>(featureKey);

// booking days selectors

const selectBookingDayState = createSelector(
  selectFeatureKey,
  state => state[fromBookingDay.featureKey]
);

const selectAllBookingDays = createSelector(
  selectBookingDayState,
  fromBookingDay.selectAllBookingDays
);

const selectBookingDayById = (id: number) => createSelector(
  selectBookingDayState,
  state => fromBookingDay.selectBookingDayById(state, id)
);

const selectBookingDayActiveId = createSelector(
  selectBookingDayState,
  fromBookingDay.selectActiveBookingDayId,
);

const selectBookingDayActive = createSelector(
  selectBookingDayState,
  fromBookingDay.selectActiveBookingDay
);

// booking sessions selectors
const selectBookingSessionState = createSelector(
  selectFeatureKey,
  state => state[fromBookingSession.featureKey]
);

const selectAllBookingSessions = createSelector(
  selectBookingSessionState,
  fromBookingSession.selectAllBookingSessions
);

const selectVisibleAgendaSessions = createSelector(
  selectAllBookingSessions,
  selectBookingDayActiveId,
  (sessions, dayId) =>
    dayId ? sessions.filter(session => session.day && session.day.id === dayId) : null
);

const selectBookingSessionById = (id: number) => createSelector(
  selectBookingSessionState,
  state => fromBookingSession.selectBookingSessionById(state, id)
);

const selectBookingSessionSaving = createSelector(
  selectBookingSessionState,
  fromBookingSession.selectBookingSessionIsSaving
);

const selectBookingSessionError = createSelector(
  selectBookingSessionState,
  fromBookingSession.selectBookingSessionError
);

const selectActiveBookingSession = createSelector(
  selectBookingSessionState,
  fromBookingSession.selectActiveBookingSession
);

const selectBookingSessionIsLoading = createSelector(
  selectBookingSessionState,
  fromBookingSession.selectBookingSessionIsLoading
);

// booking buildings selectors
const selectBookingBuildingState = createSelector(
  selectFeatureKey,
  state => state[fromBookingBuilding.featureKey]
);

const selectAllBookingBuildings = createSelector(
  selectBookingBuildingState,
  fromBookingBuilding.selectAllBookingBuildings
);

const selectBookingBuildingById = (id: number) => createSelector(
  selectBookingBuildingState,
  state => fromBookingBuilding.selectBookingBuildingById(state, id)
);

const selectBookingBuildingSaving = createSelector(
  selectBookingBuildingState,
  fromBookingBuilding.selectBookingBuildingSaving
);

const selectBookingBuildingError = createSelector(
  selectBookingBuildingState,
  fromBookingBuilding.selectBookingBuildingError
);

const selectBookingBuildingUpdatedId = createSelector(
  selectBookingBuildingState,
  fromBookingBuilding.selectBookingBuildingUpdatedId
);

// booking room selectors
const selectBookingRoomState = createSelector(
  selectFeatureKey,
  state => state[fromBookingRoom.featureKey]
);

const selectBookingRoomSaving = createSelector(
  selectBookingRoomState,
  fromBookingRoom.selectBookingRoomSaving
);

const selectBookingRoomError = createSelector(
  selectBookingRoomState,
  fromBookingRoom.selectBookingRoomError
);

// booking reservation selectors
const selectBookingReservationState = createSelector(
  selectFeatureKey,
  state => state[fromBookingReservation.featureKey]
);

const selectAllBookingReservations = createSelector(
  selectBookingReservationState,
  fromBookingReservation.selectAllBookingReservations
);

const selectBookingReservationById = (id: number) => createSelector(
  selectBookingReservationState,
  state => fromBookingReservation.selectBookingReservationById(state, id)
);

const selectBookingReservationActiveStatus = createSelector(
  selectBookingReservationState,
  fromBookingReservation.selectBookingReservationActiveStatus
);

const selectBookingReservationIsLoading = createSelector(
  selectBookingReservationState,
  fromBookingReservation.selectBookingReservationIsLoading
);

const selectBookingReservationIsSaving = createSelector(
  selectBookingReservationState,
  fromBookingReservation.selectBookingReservationIsSaving
);

const selectBookingReservationError = createSelector(
  selectBookingReservationState,
  fromBookingReservation.selectBookingReservationError
);

const selectBookingReservationCreatorMode = createSelector(
  selectBookingReservationState,
  fromBookingReservation.selectBookingReservationCreatorMode
);

const selectBookingReservationSlots = createSelector(
  selectBookingReservationState,
  fromBookingReservation.selectBookingReservationSlots
);

const selectBookingReservationMeta = createSelector(
  selectBookingReservationState,
  fromBookingReservation.selectBookingReservationMeta
);

const selectBoookingReservationIsExporting = createSelector(
  selectBookingReservationState,
  fromBookingReservation.selectBoookingReservationIsExporting
);

// booking filter selectors
const selectBookingFilterState = createSelector(
  selectFeatureKey,
  state => state[fromBookingFilter.featureKey]
);

const selectAllBookingFilters = createSelector(
  selectBookingFilterState,
  fromBookingFilter.selectAllBookingFilters
);

const selectBookingFilterById = (id: number) => createSelector(
  selectBookingFilterState,
  state => fromBookingFilter.selectBookingFilterById(state, id)
);

const selectBookingFilterSaving = createSelector(
  selectBookingFilterState,
  fromBookingFilter.selectBookingFilterSaving
);

const selectBookingFilterError = createSelector(
  selectBookingFilterState,
  fromBookingFilter.selectBookingFilterError
);

export const bookingDaysSelector = {
  getState: selectBookingDayState,
  getAll: selectAllBookingDays,
  getById: selectBookingDayById,
  getActiveId: selectBookingDayActiveId,
  getActive: selectBookingDayActive
};

export const bookingSessionsSelector = {
  getState: selectBookingSessionState,
  getAll: selectAllBookingSessions,
  getById: selectBookingSessionById,
  getVisibleSessions: selectVisibleAgendaSessions,
  isSaving: selectBookingSessionSaving,
  getError: selectBookingSessionError,
  getActiveId: selectActiveBookingSession,
  isLoading: selectBookingSessionIsLoading
};

export const bookingBuildingSelector = {
  getState: selectBookingBuildingState,
  getAll: selectAllBookingBuildings,
  getById: selectBookingBuildingById,
  isSaving: selectBookingBuildingSaving,
  getError: selectBookingBuildingError,
  getUpdatedId: selectBookingBuildingUpdatedId
};

export const bookingRoomSelector = {
  getState: selectBookingRoomState,
  isSaving: selectBookingRoomSaving,
  getError: selectBookingRoomError
};

export const bookingReservationSelector = {
  getState: selectBookingReservationState,
  getAll: selectAllBookingReservations,
  getById: selectBookingReservationById,
  isLoading: selectBookingReservationIsLoading,
  getActiveStatus: selectBookingReservationActiveStatus,
  isSaving: selectBookingReservationIsSaving,
  getError: selectBookingReservationError,
  getCreatorMode: selectBookingReservationCreatorMode,
  getSlots: selectBookingReservationSlots,
  getMeta: selectBookingReservationMeta,
  getIsExporting: selectBoookingReservationIsExporting
}

export const bookingFiltersSelector = {
  getState: selectBookingFilterState,
  getAll: selectAllBookingFilters,
  getById: selectBookingFilterById,
  isSaving: selectBookingFilterSaving,
  getError: selectBookingFilterError
}
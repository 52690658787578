import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { IconModel } from '@shared/models/icon.model';

import * as IconActions from '../actions/icon.actions';

export interface State extends EntityState<IconModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<IconModel>({
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const iconReducer = createReducer(
  initialState,
  on(IconActions.loadIcons, state => ({ ...initialState, loading: true })),
  on(IconActions.loadIconsSuccess, (state, { icons }) =>
    adapter.setAll(icons, { ...state, loading: false, loaded: true })
  ),
  on(IconActions.loadIconsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function reducer(state: State, action: Action): State {
  return iconReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectIconIds = selectIds;

export const selectIconEntities = selectEntities;

export const selectAllIcons = selectAll;

export const selectIconLoading = (state: State) => state.loading;

export const selectIconLoaded = (state: State) => state.loaded;

export const selectIconError = (state: State) => state.error;

import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromWwwResource from './www-resource.reducer';
import * as fromComponent from './component.reducer';
import * as fromCategory from './category.reducer';
import * as fromPlaceholder from './placeholder.reducer';
import * as fromTranslation from './translation.reducer';
import * as fromModal from './modal.reducer';

import { WwwResourceCategoryModel } from '@components/www-resource/models/www-resource.model';

export const featureKey = 'wwwResourceComponent';

export interface State {
  wwwResources: fromWwwResource.State;
  components: fromComponent.State;
  categories: fromCategory.State;
  placeholders: fromPlaceholder.State;
  translations: fromTranslation.State;
  modal: fromModal.State;
}

export const combinedReducers = combineReducers({
  wwwResources: fromWwwResource.reducer,
  components: fromComponent.reducer,
  categories: fromCategory.reducer,
  placeholders: fromPlaceholder.reducer,
  translations: fromTranslation.reducer,
  modal: fromModal.reducer,
});

// Wrap combined reducers by function for AOT build compatibility
export function reducer(state: State, action: Action): State {
  return combinedReducers(state, action);
}

export const selectWwwResourceFeature = createFeatureSelector<State>(featureKey);

export const selectWwwResourceState = createSelector(
  selectWwwResourceFeature,
  state => state.wwwResources
);

export const selectWwwResources = createSelector(
  selectWwwResourceState,
  fromWwwResource.selectWwwResources
);

export const selectWwwResourceComponentState = createSelector(
  selectWwwResourceFeature,
  state => state.components
);

export const selectWwwResourceComponentLoading = createSelector(
  selectWwwResourceComponentState,
  fromComponent.selectComponentLoading
);

export const selectWwwResourceCategoryState = createSelector(
  selectWwwResourceFeature,
  state => state.categories
);

export const selectWwwResourceCategories = createSelector(
  selectWwwResourceCategoryState,
  fromCategory.selectCategories
);

export const selectActiveWwwResourceCategory = createSelector(
  selectWwwResourceCategoryState,
  fromCategory.selectActiveCategory
);

export const selectWwwResourcePlaceholderState = createSelector(
  selectWwwResourceFeature,
  state => state.placeholders
);

export const selectWwwResourcePlaceholderUuid = createSelector(
  selectWwwResourcePlaceholderState,
  fromPlaceholder.selectPlaceholderUuid
);

export const selectWwwResourcePlaceholderShown = createSelector(
  selectWwwResourcePlaceholderState,
  fromPlaceholder.selectPlaceholderShown
);

export const selectWwwResourcePlaceholderFocused = createSelector(
  selectWwwResourcePlaceholderState,
  fromPlaceholder.selectPlaceholderFocused
);

// Placeholder is a "faked" category entity
export const selectWwwResourceCategoriesWithPlaceholder = createSelector(
  selectWwwResourceCategories,
  selectWwwResourcePlaceholderUuid,
  selectWwwResourcePlaceholderShown,
  (categories, uuid, shown) => {
    const placeholder: WwwResourceCategoryModel = {
      id: 0, componentId: 0, name: '', order: categories.length,
      createdAt: '', updatedAt: '', uuid,
    };

    return [...categories].concat(shown ? placeholder : []);
  }
);

export const selectWwwResourceTranslationState = createSelector(
  selectWwwResourceFeature,
  state => state.translations
);

export const selectWwwResourceTranslation = createSelector(
  selectWwwResourceTranslationState,
  fromTranslation.selectWwwResourceTranslation
);

export const selectWwwResourceTranslationLoading = createSelector(
  selectWwwResourceTranslationState,
  fromTranslation.selectWwwResourceTranslationLoading
);

export const selectWwwResourceCategoryTranslationState = createSelector(
  selectWwwResourceFeature,
  state => state.translations
);

export const selectWwwResourceCategoryTranslation = () => createSelector(
  selectWwwResourceCategoryTranslationState,
  fromTranslation.selectCategoryTranslation()
);

export const selectWwwResourceCategoryTranslationLoading = createSelector(
  selectWwwResourceCategoryTranslationState,
  fromTranslation.selectWwwResourceTranslationLoading
);

export const selectWwwResourceModalState = createSelector(
  selectWwwResourceFeature,
  state => state.modal
);

export const selectWwwResourceModalSaving = createSelector(
  selectWwwResourceModalState,
  fromModal.selectSaving
);

export const selectWwwResourceModalSaved = createSelector(
  selectWwwResourceModalState,
  fromModal.selectSaved
);

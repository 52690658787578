<div class="live-preview">
  <div class="live-preview__tab">
    <span>Live Preview</span>
  </div>

  <div class="live-preview__body">
    <h4 class="text-center mb-3">{{ 'lp.title' | translate }}</h4>
    <div class="row align-items-end" *ngIf="event$ | async as event; else noEvent">
      <div class="col text-center">
        <a [href]="'https://qr.meetingapplication.com/?qr_url=' + (qrcode | async)" target="_blank">
          <img [src]="'https://qr.meetingapplication.com/?qr_url=' + (qrcode | async) + '&bgcolor=f8f8f8'" alt="" width="110">
          <br>QR-Code
        </a>
      </div>
      <div class="col text-center">
        <a [href]="landingPageUrl | async" target="_blank">
          <img src="./assets/img/priview-landing.png" alt="" width="110">
          <br>
          Landing Page
        </a>
      </div>
    </div>
    <ng-template #noEvent>
      <div class="row">
        <div class="col text-center">
          <img src="./assets/img/preview-qr.png" alt="">
          <br>QR-Code
        </div>
        <div class="col text-center">
          <img src="./assets/img/priview-landing.png" alt="">
          <br>
          {{ 'lp.title' | translate }}
        </div>
      </div>
    </ng-template>
  </div>
</div>
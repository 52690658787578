// src/app/core/initializers/token.initializer.ts
import { TokenService } from '../services/token.service';
import { first } from 'rxjs/operators';

export function initializeToken(tokenService: TokenService) {
  return () => new Promise<void>((resolve) => {
    tokenService.getToken()
      .pipe(first())
      .subscribe(token => {
        if (token) {
          tokenService.refreshWithCurrentToken();
          tokenService.onTokenRefreshSuccess()
            .pipe(first())
            .subscribe(() => {
              resolve();
            });
        } else {
          resolve();
        }
      });
  });
}

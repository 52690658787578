import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiService } from '@shared/providers/api.service';

import { API_BASE_URL } from '@env/environment';

import {
  FeedWallChannelModel,
  FeedWallChannelPostModel,
  FeedWallChannelPatchModel
} from './models/feed-wall-channel.model';

import {
  FeedWallThreadModel,
  FeedWallThreadPostModel,
  FeedWallThreadPatchModel
} from './models/feed-wall-thread.model';

import {
  FeedWallCommentApiModel,
  FeedWallCommentPostApiModel,
  FeedWallCommentPatchApiModel,
  FeedWallCommentLoaderApiModel
} from './models/feed-wall-comment.api-model';

@Injectable({
  providedIn: 'root'
})
export class FeedWallApiService {
  constructor(private apiService: ApiService, private httpClient: HttpClient) {}

  getFeedWallChannels(eventId: number, componentId: number): Observable<FeedWallChannelModel[]> {
    const url = `/events/${eventId}/components/${componentId}/feed_wall/channels`;
    return this.apiService.get<FeedWallChannelModel[]>(url);
  }

  getFeedWallChannel(eventId: number, componentId: number, channelId: number): Observable<FeedWallChannelModel> {
    const url = `/events/${eventId}/components/${componentId}/feed_wall/channel/${channelId}`;
    return this.apiService.get<FeedWallChannelModel>(url);
  }

  createFeedWallChannel(eventId: number, componentId: number, channel: FeedWallChannelPostModel): Observable<FeedWallChannelModel> {
    const url = `/events/${eventId}/components/${componentId}/feed_wall/channel`;
    return this.apiService.post<FeedWallChannelModel>(url, channel);
  }

  updateFeedWallChannel(eventId: number, componentId: number, channelId: number,
    channel: FeedWallChannelPatchModel): Observable<FeedWallChannelModel> {

    const url = `/events/${eventId}/components/${componentId}/feed_wall/channel/${channelId}`;
    return this.apiService.patch<FeedWallChannelModel>(url, channel);
  }

  deleteFeedWallChannel(eventId: number, componentId: number, channelId: number): Observable<{ success: boolean }> {
    const url = `/events/${eventId}/components/${componentId}/feed_wall/channel/${channelId}`;
    return this.apiService.delete<{ success: boolean }>(url);
  }

  createFeedWallThread(eventId: number, componentId: number, channelId: number,
    thread: FeedWallThreadPostModel): Observable<FeedWallThreadModel> {

    const url = `/events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/thread`;
    return this.apiService.post<FeedWallThreadModel>(url, thread);
  }

  updateFeedWallThread(eventId: number, componentId: number, channelId: number, threadId: number,
    thread: FeedWallThreadPatchModel): Observable<FeedWallThreadModel> {

    const url = `/events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/thread/${threadId}/admin`;
    return this.apiService.patch<FeedWallThreadModel>(url, thread);
  }

  deleteFeedWallThread(eventId: number, componentId: number, channelId: number, threadId: number): Observable<{ success: boolean }> {
    const url = `/events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/thread/${threadId}/admin`;
    return this.apiService.delete<{ success: boolean }>(url);
  }

  getFeedWallComments(eventId: number, componentId: number, channelId: number, threadId: number,
    limit?: number, timestamp?: number): Observable<FeedWallCommentLoaderApiModel> {

    let params = new HttpParams();

    if (typeof limit !== 'undefined') {
      params = params.append('limit', String(limit));
    }

    if (typeof timestamp !== 'undefined') {
      params = params.append('timestamp', String(timestamp));
    }

    const url = `${API_BASE_URL}/events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/${threadId}/comments`;
    return this.httpClient.get<FeedWallCommentLoaderApiModel>(url, { params });
  }

  createFeedWallComment(eventId: number, componentId: number, channelId: number, threadId: number,
    comment: FeedWallCommentPostApiModel): Observable<FeedWallCommentApiModel> {

    const url = `/events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/${threadId}/comment`;
    return this.apiService.post<FeedWallCommentApiModel>(url, comment);
  }

  updateFeedWallComment(eventId: number, componentId: number, channelId: number, threadId: number,
    commentId: number, comment: FeedWallCommentPatchApiModel): Observable<FeedWallCommentApiModel> {

    const url = `/events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/${threadId}/comment/${commentId}/admin`;
    return this.apiService.patch<FeedWallCommentApiModel>(url, comment);
  }

  deleteFeedWallComment(eventId: number, componentId: number, channelId: number, threadId: number,
    commentId: number): Observable<{ success: boolean }> {

    const url = `/events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/${threadId}/comment/${commentId}/admin`;
    return this.apiService.delete<{ success: boolean }>(url);
  }
}

<div class="dialog-container">
  <h2 mat-dialog-title>{{ 'import_sync_dialog.title' | translate }}</h2>

  <mat-dialog-content>
    <div class="instruction-container">
      <div class="instruction" *ngFor="let point of instruction; index as i">
        <span>{{ i + 1 }}</span>. {{ point }}
      </div>
    </div>

    <div *ngIf="(error$ | async) as error" class="alert alert-danger mb-4" role="alert">
      {{ error | translatedErrorMessage: 'agenda_import' }}
    </div>

    <div *ngIf="success" class="alert alert-success mb-4" role="alert">
      {{ 'import_sync_dialog.sync_success' | translate }}
    </div>

    <div *ngIf="!success && (syncError$ | async) as syncError" class="alert alert-danger mb-4" role="alert">
      {{ syncError | translatedErrorMessage: 'agenda_import' }}
    </div>


    <div class="form" [formGroup]="form">
      <div class="form-row switch-enabled-container">
        <div class="col-12">
          <input type="checkbox" class="switch-checkbox" id="enabled-toggle" [checked]="enabled" (change)="onEnabledChange($event.target.checked)">
          <label for="enabled-toggle" class="switch-label" title="Toggle Enabled">{{ 'import_sync_dialog.is_enabled' | translate }}</label>
        </div>
      </div>
      <div class="form-row spreadsheet-input-container">
        <div class="col-7 form-group">
          <label>{{ 'import_sync_dialog.field_spreadsheet_label' | translate }} <span class="required-star">*</span></label>
          <input formControlName="spreadsheetId" type="text" [class.readonly]="isDisabled" class="form-control" [placeholder]="'import_sync_dialog.field_spreadsheet_label' | translate">
        </div>
        <div class="col-5">
          <button class="btn btn-light mr-3 mb-3" type="button" tabindex="-1" (click)="createGoogleSheetTemplate()">{{ 'import_sync_dialog.get_import_template' | translate }}</button>
        </div>
      </div>
      <div *ngIf="form.controls['spreadsheetId'].invalid && submitted" class="form-control-feedback mb-4">
        {{ 'import_sync_dialog.field_spreadsheet_req' | translate }}
      </div>
      <div class="form-row">
        <div class="col-12">
          <label>{{ 'import_sync_dialog.end_date' | translate }}</label>
          <app-date-picker formControlName="endDate"></app-date-picker>
        </div>
      </div>
      <div class="form-row mt-4 mb-4">
        <div class="col-12">
          <button class="btn btn-primary w-100" [disabled]="!isSaveEnabled || (updating$ | async)" (click)="updateConfig()">{{ 'buttons.save_changes' | translate }}</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="btn" type="button" mat-dialog-close>{{ 'buttons.cancel' | translate }}</button>
    <button class="btn btn-primary ml-auto" [disabled]="(importing$ | async) || isSaveEnabled || isDisabled" (click)="sync()">{{ 'import_sync_dialog.synchronize' | translate }}</button>
  </mat-dialog-actions>

  <app-spinner-overlay *ngIf="loading$ | async"></app-spinner-overlay>
  <app-spinner-overlay *ngIf="updating$ | async" [message]="'import_sync_dialog.update_processing' | translate"></app-spinner-overlay>
  <app-spinner-overlay *ngIf="importing$ | async" [message]="'import_sync_dialog.import_processing' | translate"></app-spinner-overlay>
</div>
import { take } from 'rxjs/operators';
import { EventFacadeService } from '@store/features/event/event-facade.service';
import { EventModel } from '@store/features/event/models/event.model';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventLanguageModel } from '@store/features/event/models/event-language.model';
import { Observable, Subscription } from 'rxjs';
import { EventTranslationModel } from '@store/features/event/models/event-translation.model';
import { getFlagUrl } from '@utils/flagUrl.util';

@Component({
  selector: 'app-language-setting-modal',
  templateUrl: './language-setting-modal.component.html',
  styleUrls: ['./language-setting-modal.component.scss']
})
export class LanguageSettingModalComponent implements OnInit, OnDestroy {
  languages: UntypedFormArray;
  event$: Observable<EventModel>;
  isSaving$: Observable<boolean>;

  translations: EventTranslationModel[];

  eventUpdating: boolean;

  private subs = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<any>,
    private eventFacadeService: EventFacadeService,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {
    this.languages = new UntypedFormArray([]);
  }

  ngOnInit() {
    const languages = this.dialogData.languages;
    this.translations = this.dialogData.translations;
    this.event$ = this.dialogData.event$;
    this.populateLanguages(languages);

    this.isSaving$ = this.eventFacadeService.getEventSaving();

    this.subs.add(this.event$.subscribe(event => {
      if(!event) this.eventUpdating = false;
      else this.eventUpdating = true;
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getUrl(key: string) {
    return getFlagUrl(key);
  }

  onLanguageDefault(language: FormGroup) {
    const values = [];

    for (const control of this.languages.controls) {
      values.push({ default: control === language });
    }

    this.languages.patchValue(values);
  }

  onLanguageRemove(ev: Event, index: number) {
    if (!this.languages.at(index).value.default) {
      this.languages.removeAt(index);
    }
  }

  onSettingSave(ev: Event) {
    if(!this.eventUpdating) {
      this.dialogRef.close(this.languages.value);
      return; 
    };
    this.subs.add(this.event$.pipe(take(1)).subscribe(event => {
      this.eventFacadeService.updateEvent(event, this.languages.value, this.translations);
      this.subs.add(this.isSaving$.subscribe(state => state ? null : this.dialogRef.close(this.languages.value)));
    }));
  }

  private populateLanguages(languages: EventLanguageModel[]) {
    while (this.languages.length !== languages.length) {
      this.yieldLanguageForm(languages);
    }

    this.languages.setValue(languages);
  }

  private yieldLanguageForm(languages: EventLanguageModel[]) {
    if (this.languages.length < languages.length) {
      this.languages.push(this.createLanguageForm());
    } else {
      this.languages.removeAt(0);
    }
  }

  private createLanguageForm() {
    return new FormGroup({
      code: new FormControl(''),
      default: new FormControl(false),
      name: new FormControl(''),
    });
  }
}

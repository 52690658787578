export enum UserRoleEnum {
  APP_OWNER = 'app_owner',
  APP_ADMIN = 'app_admin',
  EVENT_ADMIN = 'event_admin',
  EVENT_MODERATOR = 'event_moderator',
}

export function mapStringToUserRoleEnum(role: string): UserRoleEnum {
  switch (role) {
    case 'app_owner':
      return UserRoleEnum.APP_OWNER;
    case 'app_admin':
      return UserRoleEnum.APP_ADMIN;
    case 'event_admin':
      return UserRoleEnum.EVENT_ADMIN;
    case 'event_moderator':
      return UserRoleEnum.EVENT_MODERATOR;
    default:
      return null;
  }
}
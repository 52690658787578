import { TranslateService } from '@ngx-translate/core';
import { Component, Input, Output, ViewChild, HostBinding, EventEmitter, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBarComponent),
      multi: true
    }
  ]
})
export class SearchBarComponent implements ControlValueAccessor {
  @Input() placeholder: string;

  @Output() blur: EventEmitter<Event>;

  @ViewChild('nativeInput', { static: true }) nativeInput: ElementRef<HTMLInputElement>;

  @HostBinding('class.input-group') _inputGroup = true;

  private onChange: (value) => {};
  private onTouched: () => {};

  constructor(private i18n: TranslateService) {
    this.placeholder = this.i18n.instant('others.search');
    this.blur = new EventEmitter<Event>();
  }

  onValueChange(ev: Event) {
    if (this.onChange) {
      this.onChange(this.nativeInput.nativeElement.value);
    }
  }

  onBlur(ev: Event) {
    this.blur.emit(ev);

    if (this.onTouched) {
      this.onTouched();
    }
  }

  onKeydown(ev: KeyboardEvent) {
    // eslint-disable-next-line import/no-deprecated
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      this.nativeInput.nativeElement.blur();
    }
  }

  writeValue(value: string): void {
    this.nativeInput.nativeElement.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.nativeInput.nativeElement.disabled = isDisabled;
  }
}

import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";
import { ProductVisibility } from "@store/features/product/utils/product-visibility-type";

export function linkVisibilityTokenValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const visibilityType = control.get('visibilityType')?.value;
    const token = control.get('linkVisibilityToken')?.value;

    if (visibilityType === ProductVisibility.LINK) {
      if (!token) {
        return { tokenRequired: true };
      }
      if (token.length < 7 || token.length > 32) {
        return { tokenLength: true };
      }
      if (/\s/.test(token)) {
        return { tokenNoSpaces: true };
      }
      if (!/^[a-z0-9]+$/.test(token)) {
        return { tokenLowercaseOnly: true };
      }
    }

    return null;
  };
}

export function visibilityTypeValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const visibilityType = control.get('visibilityType')?.value;
    const userGroups = control.get('userGroups') as FormControl;
    const linkVisibilityToken = control.get('linkVisibilityToken') as FormControl;

    if (visibilityType === ProductVisibility.USER_GROUPS && (!userGroups.value || userGroups.value.length === 0)) {
      return { groupRequired: true };
    }
    if (visibilityType === ProductVisibility.LINK && !linkVisibilityToken.value) {
      return { tokenRequired: true };
    }

    return null;
  };
}
import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';
import { AgendaAttendeeModel } from '@components/agenda/models/agenda-attendee.model';

export const loadAttendees = createAction(
  '[Agenda/Application] Load Attendees',
  props<{ event: EventModel, session: AgendaSessionModel }>()
);

export const loadAttendeesSuccess = createAction(
  '[Agenda/API] Load Attendees Success',
  props<{ attendees: AgendaAttendeeModel[], maxAttendees: number, hasMore: boolean }>()
);

export const loadAttendeesFailure = createAction(
  '[Agenda/API] Load Attendees Failure',
  props<{ error: Error }>()
);

export const searchAttendees = createAction(
  '[Agenda/Application] Search Attendees',
  props<{ event: EventModel, session: AgendaSessionModel, searchTerm: string }>()
);

export const searchAttendeesSuccess = createAction(
  '[Agenda/API] Search Attendees Success',
  props<{ attendees: AgendaAttendeeModel[], maxAttendees: number, hasMore: boolean }>()
);

export const searchAttendeesFailure = createAction(
  '[Agenda/API] Search Attendees Failure',
  props<{ error: Error }>()
);

export const loadNextAttendees = createAction(
  '[Agenda/Application Scroll] Load Next Attendees',
  props<{ event: EventModel, session: AgendaSessionModel }>()
);

export const loadNextAttendeesSuccess = createAction(
  '[Agenda/API] Load Next Attendees Success',
  props<{ attendees: AgendaAttendeeModel[], maxAttendees: number, hasMore: boolean }>()
);

export const loadNextAttendeesFailure = createAction(
  '[Agenda/API] Load Next Attendees Failure',
  props<{ error: Error }>()
);

/* DeepLink structure
Level   Event                       Global
0       event id                    global subcomponent name
1       event component type        global subcomponent id
2       event component id          ???
3       subcomponent name
4       subcomponent id
...
*/

export const DeepLinkConstants = Object.freeze({
  DEEP_LINK_SCHEME: "https",
  DEEP_LINK_HOST: "meetingapplication.com",

  APP_SEGMENT_NAME : "app",
  EVENT_SEGMENT_NAME : "event",
  COMPONENT_SEGMENT_NAME : "component",

  INVITATIONS_SEGMENT_NAME: "invitation",
  MY_FRIENDS_SEGMENT_NAME: "my_friends",
  FRIENDS_SEGMENT_NAME: "friends",
  INBOX_SEGMENT_NAME: "inbox",
  INBOX_THREAD_SEGMENT_NAME: "thread",
  NOTIFICATIONS_SEGMENT_NAME: "notifications",
  MY_PROFILE_SEGMENT_NAME: "profile",
  SETTINGS_SEGMENT_NAME: "settings",
  USER_SEGMENT_NAME: "user",
  USER_JITSI_ROOM_PREFIX_SEGMENT_NAME: "users_",

  //GLOBAL
  SCHEME_SEGMENT_POSITION: 0,
  HOST_SEGMENT_POSITION: 2,
  APP_NAME_SEGMENT_POSITION: 3,
  APP_ID_SEGMENT_POSITION: 4,

  //EVENT COMPONENTS
  EVENT_NAME_SEGMENT_POSITION : 5,
  EVENT_ID_SEGMENT_POSITION : 6,
  COMPONENT_SEGMENT_POSITION : 7,
  COMPONENT_ID_SEGMENT_POSITION : 8,
  COMPONENT_TYPE_SEGMENT_POSITION : 9,

  //AGENDA COMPONENT
  AGENDA_COMPONENT_SEGMENT_NAME : "agenda_component",
  AGENDA_SESSION_ID_SEGMENT_POSITION : 10,
  AGENDA_SESSION_QUESTION_SEGMENT_POSITION : 11,
  AGENDA_SESSION_QUESTION_SEGMENT_NAME : "questions",
  AGENDA_SESSION_QUESTION_ID_SEGMENT_POSITION : 12,

//EXHIBITORS
  EXHIBITORS_COMPONENT_SEGMENT_NAME : "exhibitor_component",
  EXHIBITOR_ID_SEGMENT_POSITION : 10,

//FORMS
  FORM_COMPONENT_SEGMENT_NAME : "form_component",
  FORM_ID_SEGMENT_POSITION : 10,

//FEEDWALL
  FEEDWALL_COMPONENT_SEGMENT_NAME : "feed_wall_component",
  FEEDWALL_CHANNEL_ID_SEGMENT_POSITION : 10,
  FEEDWALL_THREAD_SEGMENT_POSITION : 11,
  FEEDWALL_THREAD_SEGMENT_NAME : "threads",
  FEEDWALL_THREAD_ID_SEGMENT_POSITION : 12,

//VENUES
  VENUE_COMPONENT_SEGMENT_NAME : "venue_component",
  VENUE_CATEGORY_ID_SEGMENT_POSITION : 10,
  VENUE_LOCATION_SEGMENT_POSITION : 11,
  VENUE_LOCATION_SEGMENT_NAME : "venues",
  VENUE_LOCATION_ID_SEGMENT_POSITION : 12,

//SPEAKERS
  SPEAKER_COMPONENT_SEGMENT_NAME : "speaker_component",
  SPEAKER_ID_SEGMENT_POSITION : 10,

//ATTENDEES
  ATTENDEES_COMPONENT_SEGMENT_NAME : "attendees_component",
  ATTENDEES_ID_SEGMENT_POSITION : 10,

//PARTNERS
  PARTNER_COMPONENT_SEGMENT_NAME : "partner_component",
  PARTNER_ID_SEGMENT_POSITION : 10,

//RESOURCES
  RESOURCE_COMPONENT_SEGMENT_NAME : "www_resources_component",
  RESOURCE_CATEGORY_ID_SEGMENT_POSITION : 10,
  RESOURCE_SEGMENT_NAME : "resources",
  RESOURCE_SEGMENT_POSITION : 11,
  RESOURCE_ID_SEGMENT_POSITION : 12,

// SOCIAL MEDIA
  SOCIAL_MEDIA_COMPONENT_SEGMENT_NAME : "news_social_media_component",

//BUSINESS MATCHING
  BUSINESS_MATCHING_COMPONENT_SEGMENT_NAME : "business_matching_component",
  BUSINESS_MATCHING_TAB_SEGMENT_POSITION : 10,
  BUSINESS_MATCHING_ACCEPTED_TAB_SEGMENT_NAME : "accepted",
  BUSINESS_MATCHING_PENDING_TAB_SEGMENT_NAME : "pending",
  BUSINESS_MATCHING_MEETING_ID_SEGMENT_POSITION : 11,
  BUSINESS_MATCHING_MEETING_ROOM_SEGMENT_POSITION : 11,
  BUSINESS_MATCHING_MEETING_ROOM_SEGMENT_NAME : "room",
  BUSINESS_MATCHING_MEETING_ROOM_ID_SEGMENT_POSITION : 12,


//QUIZZES & SURVEYS
  QUIZ_COMPONENT_SEGMENT_NAME : "survey_quiz_component",
  QUIZ_ID_SEGMENT_POSITION : 10,

//INTERACTIVE MAP
  INTERACTIVE_MAP_COMPONENT_SEGMENT_NAME : "interactive_map_component",
  INTERACTIVE_MAP_ID_SEGMENT_POSITION : 10,
  INTERACTIVE_MAP_LOCATION_SEGMENT_NAME : "locations",
  INTERACTIVE_LOCATION_SEGMENT_POSITION : 11,
  INTERACTIVE_LOCATION_ID_SEGMENT_POSITION : 12,

//AUDIO VISUAL
  AUDIO_VISUAL_COMPONENT_SEGMENT_NAME : "audio_visual_component",
  AUDIO_VISUAL_ID_SEGMENT_POSITION : 10,
  AUDIO_VISUAL_FILE_SEGMENT_NAME : "files",
  AUDIO_VISUAL_FILE_SEGMENT_POSITION : 11,
  AUDIO_VISUAL_FILE_ID_SEGMENT_POSITION : 12,

//QR CODE WITH USER GROUPS
  QR_CODE_USER_GROUPS_COMPONENT_SEGMENT_NAME : "qr_code_component",
  QR_CODE_USER_GROUPS_UUID_SEGMENT_POSITION : 10,

//EVENT & AGENDA SESSION TICKET RESERVATIONS
  TICKET_RESERVATIONS_SEGMENT_NAME : "reservations",
  EVENT_TICKET_RESERVATIONS_SEGMENT_POSITION : 7,
  EVENT_TICKET_RESERVATION_ID_SEGMENT_POSITION : 8,
  EVENT_TICKET_RESERVATION_UUID_SEGMENT_POSITION : 9,
  AGENDA_TICKET_SESSION_ID_SEGMENT_POSITION : 10,
  AGENDA_TICKET_RESERVATIONS_SEGMENT_POSITION : 11,
  AGENDA_TICKET_RESERVATION_ID_SEGMENT_POSITION : 12,
  AGENDA_TICKET_RESERVATION_UUID_SEGMENT_POSITION : 13,

//BOOKING
  BOOKING_COMPONENT_SEGMENT_NAME : "booking_component",
  BOOKING_TAB_SEGMENT_POSITION : 10,
  BOOKING_ACCEPTED_TAB_SEGMENT_NAME : "accepted",
  BOOKING_PENDING_TAB_SEGMENT_NAME : "pending",
  BOOKING_RESERVATION_ID_SEGMENT_POSITION : 11,

//TAXI
  TAXI_COMPONENT_SEGMENT_NAME : "taxi_component",
  TAXI_DESTINATION_SEGMENT_POSITION : 10,
  TAXI_DESTINATION_SEGMENT_NAME : "destinations",
  TAXI_DESTINATION_ID_SEGMENT_POSITION : 11,
})

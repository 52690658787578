import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { LanguageModel } from '../models/language.model';

import * as LanguageActions from '../actions/language.actions';

export interface State extends EntityState<LanguageModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<LanguageModel>({
  selectId: language => language.code,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: State = {
  ...adapter.getInitialState(),
  loading: false,
  loaded: false,
  error: null,
};

export const languageReducer = createReducer(
  initialState,
  on(LanguageActions.loadLanguages, state => ({
    ...initialState,
    loading: true,
  })),
  on(LanguageActions.loadLanguagesSuccess, (state, { languages }) =>
    adapter.setAll(languages, { ...state, loading: false, loaded: true })
  ),
  on(LanguageActions.loadLanguagesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function reducer(state: State, action: Action): State {
  return languageReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectLanguageIds = selectIds;

export const selectLanguageEntities = selectEntities;

export const selectAllLanguages = selectAll;

export const selectLanguageLoading = (state: State) => state.loading;

export const selectLanguageLoaded = (state: State) => state.loaded;

export const selectLanguageError = (state: State) => state.error;

import { createReducer, on, Action } from '@ngrx/store';

import * as ModalActions from '../actions/modal.actions';
import { selectEvent } from '@store/features/event/actions';

export interface State {
  saving: boolean;
  saved: boolean;
  error: Error;
}

export const initialState: State = {
  saving: false,
  saved: false,
  error: null,
};

export const modalReducer = createReducer(
  initialState,
  on(
    ModalActions.modalCreateWwwResource,
    ModalActions.modalUpdateWwwResource,
    state => ({ ...initialState, saving: true })
  ),
  on(
    ModalActions.modalCreateWwwResourceSuccess,
    ModalActions.modalUpdateWwwResourceSuccess,
    state => ({ ...state, saving: false, saved: true })
  ),
  on(
    ModalActions.modalCreateWwwResourceFailure,
    ModalActions.modalUpdateWwwResourceFailure,
    (state, { error }) => ({ ...initialState, error })
  ),
  
  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return modalReducer(state, action);
}

export const selectSaving = (state: State) => state.saving;

export const selectSaved = (state: State) => state.saved;

export const selectError = (state: State) => state.error;

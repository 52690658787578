import { Action, createReducer, on } from "@ngrx/store";
import { AccessGroupResponse } from "@store/features/tags/access-group-api.service";
import { AccessGroupActions } from "@store/features/tags/actions/access-group.actions";
import { TagApiModel } from "../tags.models";
import { selectEvent } from "@store/features/event/actions";
import { selectComponent } from "@store/features/component/actions";

export const featureKey = 'accessTags';

export interface State {
  loading: boolean;
  error: Error | null;
  accessGroups: AccessGroupResponse;

  itemAccessGroups: AccessGroupResponse;
  
  authorizedUserAccessGroups: TagApiModel[];
  authorizedUserAccessGroupsLoading: boolean;
  authorizedUserAccessGroupsError: Error | null;
}

export const initialState: State = {
  loading: false,
  error: null,
  accessGroups: {},
  itemAccessGroups: {},

  authorizedUserAccessGroups: [],
  authorizedUserAccessGroupsLoading: false,
  authorizedUserAccessGroupsError: null,
}

export const accessGroupReducer = createReducer(
  initialState,
  on(
    AccessGroupActions.loadComponents,
    (state) => ({ ...state, loading: true })
  ),
  on(
    AccessGroupActions.loadComponentsSuccess,
    (state, { groups }) => ({ ...state, loading: false, accessGroups: groups })
  ),
  on(
    AccessGroupActions.loadComponentsFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),

  on(
    AccessGroupActions.addComponentAccessGroup,
    (state) => ({ ...state, loading: true })
  ),
  on(
    AccessGroupActions.addComponentAccessGroupSuccess,
    (state, { data, id }) => {
      const accessGroups = {
        ...state.accessGroups,
        [id]: [...state.accessGroups[id], data],
      };
      return { ...state, loading: false, accessGroups };
    }
  ),
  on(
    AccessGroupActions.addComponentAccessGroupFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),

  on(
    AccessGroupActions.removeComponentAccessGroup,
    (state) => ({ ...state, loading: true })
  ),
  on(
    AccessGroupActions.removeComponentAccessGroupSuccess,
    (state, { data }) => {
      const accessGroups = {
        ...state.accessGroups,
        [data.component_id]: [...state.accessGroups[data.component_id].filter(t => t.id !== data.tag_id)],
      };
      return { ...state, loading: false, accessGroups };
    }
  ),
  on(
    AccessGroupActions.removeComponentAccessGroupFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  
  // ITEMS

  on(
    AccessGroupActions.loadItems,
    AccessGroupActions.loadItem,
    (state) => ({ ...state, loading: true })
  ),
  on(
    AccessGroupActions.loadItemsSuccess,
    (state, { groups }) => ({ ...state, loading: false, itemAccessGroups: groups })
  ),
  on(
    AccessGroupActions.loadItemsFailure,
    AccessGroupActions.loadItemFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    AccessGroupActions.loadItemSuccess,
    (state, { groups }) => ({ ...state, loading: false, itemAccessGroups: { ...state.itemAccessGroups, ...groups } })
  ),

  on(
    AccessGroupActions.addItemAccessGroup,
    (state) => ({ ...state, loading: true })
  ),
  on(
    AccessGroupActions.addItemAccessGroupSuccess,
    (state, { data, id }) => {
      const modifiedItemGroupsArray = state.itemAccessGroups[id] ? [...state.itemAccessGroups[id], data] : [data];
      const itemAccessGroups = {
        ...state.itemAccessGroups,
        [id]: removeDuplicatesByPropertyId(modifiedItemGroupsArray),
      };
      return { ...state, loading: false, itemAccessGroups };
    }
  ),
  on(
    AccessGroupActions.addItemAccessGroupFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),

  on(
    AccessGroupActions.removeItemAccessGroup,
    (state) => ({ ...state, loading: true })
  ),
  on(
    AccessGroupActions.removeItemAccessGroupSuccess,
    (state, { data }) => {
      const itemAccessGroups = {
        ...state.itemAccessGroups,
        [data.item_id]: [...state.itemAccessGroups[data.item_id].filter(t => t.id !== data.tag_id)],
      };
      return { ...state, loading: false, itemAccessGroups };
    }
  ),
  on(
    AccessGroupActions.removeItemAccessGroupFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    AccessGroupActions.refreshComponentAccessGroups,
    (state, { component, tags }) => ({ ...state, accessGroups: { ...state.accessGroups, [component.id]: tags } })
  ),
  on(
    AccessGroupActions.setItemsError,
    (state, { error }) => ({ ...state, error })
  ),

  on(
    AccessGroupActions.loadAuthorizedUserAccessGroups,
    (state) => ({
      ...state,
      loadingAuthorizedUser: true,
      authorizedUserAccessGroupsLoading: true,
    })
  ),
  on(
    AccessGroupActions.loadAuthorizedUserAccessGroupsSuccess,
    (state, { tags }) => ({
      ...state,
      authorizedUserAccessGroups: [...tags],
      loadingAuthorizedUser: false,
      authorizedUserAccessGroupsError: null,
      authorizedUserAccessGroupsLoading: false,
    })
  ),
  on(
    AccessGroupActions.loadAuthorizedUserAccessGroupsFailure,
    (state, { error }) => ({
      ...state,
      loadingAuthorizedUser: false,
      authorizedUserAccessGroupsError: error,
      authorizedUserAccessGroupsLoading: false,
    })
  ),

  on(
    selectEvent,
    () => ({ ...initialState }),
  ),
  on(
    selectComponent,
    (state) => ({ ...state, itemAccessGroups: {} }),
  )
);

export function reducer(state: State, action: Action): State {
  return accessGroupReducer(state, action);
}

export const selectComponents = (state: State) => state.accessGroups;
export const selectItems = (state: State) => state.itemAccessGroups;

export const selectComponentById = (id: number) => (state: State) => state.accessGroups[id];
export const selectItemById = (id: number) => (state: State) => state.itemAccessGroups[id];

export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;

export const selectAuthorizedUserAccessGroups = (state: State) => state.authorizedUserAccessGroups;
export const selectAuthorizedUserAccessGroupsLoading = (state: State) => state.authorizedUserAccessGroupsLoading;
export const selectAuthorizedUserAccessGroupsError = (state: State) => state.authorizedUserAccessGroupsError;

function removeDuplicatesByPropertyId<T>(array: T[]) {
  const uniqueItemGroupsArray = array.reduce((acc, current) => {
    const itemExists = acc.find(item => item.id === current['id']);
    if (!itemExists) {
      acc.push(current);
    }
    return acc;
  }, []);
  return uniqueItemGroupsArray;
}
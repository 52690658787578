export enum ConsentType {
  PRIVACY_POLICY = 'privacy_policy',
  TERMS_OF_SERVICE = 'terms_of_service',
  EVENT_SPECIFIC = 'event_specific',
  EVENT_TERMS_OF_SALES = 'event_terms_of_sales',
}

export enum EventConsentType {
  EVENT_PRIVACY_POLICY = 'event_privacy_policy',
  EVENT_ATTENDEE_TERMS = 'event_attendee_terms',
  SALES = 'sales',
  OTHER = 'other',
}

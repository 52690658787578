import { Pipe, PipeTransform } from '@angular/core';
import { TicketModel, TicketTranslationModel } from '@store/features/product/models/ticket.model';

@Pipe({
  name: 'translateProduct'
})
export class TranslateProductPipe implements PipeTransform {

  transform(entity: TicketModel, lang: string): TicketTranslationModel {
    return entity.translations.find(t => t.locale === lang) || entity.translations[0];
  }

}

// Example of usage:
// <div *ngIf="product | translateProduct: currentLang as translatedProduct">
//   <h1>{{ translatedProduct.name }}</h1>
//   <p>{{ translatedProduct.description }}</p>
// </div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AgendaDay } from '../../components/business-matching/models/AgendaDay';
import { SpeakerApiModel } from '../../components/agenda/models/speaker.api-model';
import { AddSpeaker } from '../../components/agenda/models/AddSpeaker';
import { catchError } from 'rxjs/operators';
import { PushNotification } from '../../components/push-notification/models/PushNotification';
import { AddPush } from '../../components/push-notification/models/AddPush';

import { SurveyQuizModel, SurveyQuizPostModel, SurveyQuizPatchModel } from '@components/survey-quiz/models/survey-quiz.model';
import { SurveyQuizQuestionModel, SurveyQuizQuestionPostModel, SurveyQuizQuestionPatchModel } from '@components/survey-quiz/models/survey-quiz-question.model';
import { SurveyQuizAnswerModel, SurveyQuizAnswerPostModel, SurveyQuizAnswerPatchModel } from '@components/survey-quiz/models/survey-quiz-answer.model';
import { SurveyQuizResponsesModel } from '@components/survey-quiz/models/survey-quiz-responses.model';
import { PhotoBoothModel, PhotoBoothPostModel } from '@components/photo-booth/models/photo-booth.model';
import { PartnerCategoryApiModel, PartnerCategoryPostModel, PartnerCategoryPatchModel } from '@components/partner/models/partner-category.api-model';
import { PartnerApiModel, PartnerPostModel, PartnerPatchModel } from '@components/partner/models/partner.api-model';

import {API_BASE_URL, API_MAIL_BASE_URL} from '@env/environment';
import { PartnerContactPersonApiModel, PartnerContactPersonPostModel, PartnerContactPersonPatchModel } from '@components/partner/models/partner-contact-person.api-model';
import { SocialMediumPostModel, SocialMediumModel, SocialMediumPatchModel } from '@components/social-medium/models/social-medium.model';
import { SocialMediumItemPostModel, SocialMediumItemModel, SocialMediumItemPatchModel } from '@components/social-medium/models/social-medium-item.model';
import { ExhibitorModel, ExhibitorPostModel, ExhibitorPatchModel } from '@components/interactive-map/models/exhibitor.model';
import { AgendaQuestionModel, AgendaQuestionPostModel } from '@components/agenda/models/agenda-question.model';
import { AgendaRatingModel } from '@components/agenda/models/agenda-rating.model';
import { AgendaAttendanceModel } from '@components/agenda/models/agenda-attendance.model';
import { AttachmentModel, AttachmentPostModel, AttachmentPatchModel } from '@shared/models/attachment.model';
import { VenueCategoryModel, VenueCategoryPostModel, VenueCategoryPatchModel } from '@components/venue/models/venue-category.model';
import { VenuePlacePostModel, VenuePlaceModel, VenuePlacePatchModel } from '@components/venue/models/venue-place.model';
import { BusinessMatchingSessionApiModel, BusinessMatchingSessionPostModel, BusinessMatchingSessionPatchModel } from '@components/business-matching/models/business-matching-session.api-model';
import { BusinessMatchingMeetingApiModel } from '@components/business-matching/models/business-matching-meeting.api-model';
import { LoadAllExhibitorsResponse } from '@components/exhibitor/providers/exhibitor-provider.service';

@Injectable({
  providedIn: 'root'
})
export class FullApiService {

  constructor(private http: HttpClient) {}

  passwordForgot({ email }): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(API_BASE_URL + '/user/recovery_account/generate_new_password', { email });
  }

  passwordReset({ token, newPassword, confirmNewPassword }): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(API_BASE_URL + '/user/recovery_account/change_password', {
      token, password: newPassword, password_confirmation: confirmNewPassword
    });
  }

  attachment(body: FormData): Observable<any> {
    return this.http.post<any>(API_BASE_URL + '/attachments', body, {
      reportProgress: true,
      observe: 'events'
    });
  }

  mailAttachment(body: FormData): Observable<any> {
    return this.http.post<any>(API_MAIL_BASE_URL + '/admin/attachment', body, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getAgendaQuestions2(eventId: number, componentId: number, sessionId: number): Observable<AgendaQuestionModel[]> {
    return this.http.get<AgendaQuestionModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/agenda_session/${sessionId}/discussion/questions`
    );
  }

  createAgendaQuestion(eventId: number, componentId: number, sessionId: number,
    question: AgendaQuestionPostModel): Observable<AgendaQuestionModel> {

    return this.http.post<AgendaQuestionModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/agenda_session/${sessionId}/discussion/question`, question
    );
  }

  deleteAgendaQuestion(eventId: number, componentId: number, sessionId: number, questionId: number): Observable<{ deleted: boolean }> {
    return this.http.delete<{ deleted: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/agenda_session/${sessionId}/discussion/question/${questionId}/delete/admin`
    );
  }

  getAgendaRatings2(eventId: number, componentId: number, sessionId: number): Observable<AgendaRatingModel[]> {
    return this.http.get<AgendaRatingModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/agenda_sessions/${sessionId}/rating/admin`
    );
  }

  deleteAgendaRating(eventId: number, componentId: number, sessionId: number, ratingId: number): Observable<{ deleted: boolean }> {
    return this.http.delete<{ deleted: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/agenda_sessions/${sessionId}/rating/${ratingId}`
    );
  }

  getAgendaAttendance(eventId: number, sessionId: number, limit?: number, maxId?: string): Observable<AgendaAttendanceModel> {
    let params: HttpParams = new HttpParams();

    if (typeof limit !== 'undefined') {
      params = params.append('limit', limit.toString());
    }

    if (typeof maxId !== 'undefined') {
      params = params.append('max_id', maxId.toString());
    }

    return this.http.get<AgendaAttendanceModel>(
      API_BASE_URL + `/events/${eventId}/attendance/agenda_sessions/${sessionId}`, { params }
    );
  }

  getAgendaDays(eventId: number): Observable<Array<AgendaDay>> {
    return this.http.get<Array<AgendaDay>>(API_BASE_URL + `/events/${eventId}/days`);
  }

  createAgendaDay(eventId: number, body: { date }): Observable<AgendaDay> {
    return this.http.post<AgendaDay>(API_BASE_URL + `/events/${eventId}/days`, body);
  }

  removeAgendaDay(eventId: number, id: number): Observable<any> {
    return this.http.delete<any>(API_BASE_URL + `/events/${eventId}/days/${id}`);
  }

  getPushList(event_id: number, id: number): Observable<Array<PushNotification>> {
    return this.http.get<Array<PushNotification>>(API_BASE_URL + `/events/${event_id}/components/${id}/push`);
  }

  addPush(event_id: number, id: number, data: AddPush): Observable<Array<PushNotification>> {
    return this.http.post<Array<PushNotification>>(API_BASE_URL + `/events/${event_id}/components/${id}/push`, data);
  }

  updatePush(event_id: number, component_id: number, push_id: number, data: AddPush): Observable<Array<PushNotification>> {
    return this.http.patch<Array<PushNotification>>(API_BASE_URL + `/events/${event_id}/components/${component_id}/push/${push_id}`, data);
  }

  removePush(event_id: number, component_id: number, id: number): Observable<Boolean> {
    return this.http.delete<Boolean>(API_BASE_URL + `/events/${event_id}/components/${component_id}/push/${id}`);
  }

  getSpeakers(event_id: number, id: number): Observable<Array<SpeakerApiModel>> {
    return this.http.get<Array<SpeakerApiModel>>(API_BASE_URL + `/events/${event_id}/components/${id}/speakers`);
  }

  addSpeaker(event_id: number, id: number, data: AddSpeaker): Observable<Array<SpeakerApiModel>> {
    return this.http.post<Array<SpeakerApiModel>>(API_BASE_URL + `/events/${event_id}/components/${id}/speaker`, data);
  }

  updateSpeaker(event_id: number, component_id: number, speaker_id: number, data: AddSpeaker): Observable<Array<SpeakerApiModel>> {
    return this.http.patch<Array<SpeakerApiModel>>(API_BASE_URL + `/events/${event_id}/components/${component_id}/speaker/${speaker_id}`, data);
  }

  removeSpeaker(event_id: number, component_id: number, id: number): Observable<Boolean> {
    return this.http.delete<Boolean>(API_BASE_URL + `/events/${event_id}/components/${component_id}/speaker/${id}`);
  }

  getSurveys(eventId: number, componentId: number): Observable<SurveyQuizModel[]> {
    return this.http.get<SurveyQuizModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quizzes`
    );
  }

  addSurvey(eventId: number, componentId: number, survey: SurveyQuizPostModel): Observable<SurveyQuizModel> {
    return this.http.post<SurveyQuizModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quizes`, survey
    );
  }

  updateSurvey(eventId: number, componentId: number, surveyId: number, survey: SurveyQuizPatchModel): Observable<SurveyQuizModel> {
    return this.http.patch<SurveyQuizModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}`, survey
    );
  }

  removeSurvey(eventId: number, componentId: number, surveyId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}`
    );
  }

  exportSurvey(eventId: number, componentId: number, surveyId: number): Observable<string> {
    return this.http.get(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/export`, { responseType: 'text' }
    );
  }

  getSurveyQuestions(eventId: number, componentId: number, surveyId: number): Observable<SurveyQuizQuestionModel[]> {
    return this.http.get<SurveyQuizQuestionModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/questions`
    );
  }

  addSurveyQuestion(eventId: number, componentId: number, surveyId: number,
    question: SurveyQuizQuestionPostModel): Observable<SurveyQuizQuestionModel> {

    return this.http.post<SurveyQuizQuestionModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/question`, question
    );
  }

  updateSurveyQuestion(eventId: number, componentId: number, surveyId: number, questionId: number,
    question: SurveyQuizQuestionPatchModel): Observable<SurveyQuizQuestionModel> {

    return this.http.patch<SurveyQuizQuestionModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/question/${questionId}`, question
    );
  }

  removeSurveyQuestion(eventId: number, componentId: number, surveyId: number, questionId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/question/${questionId}`
    );
  }

  getSurveyQuestionAnswers(eventId: number, componentId: number, surveyId: number,
    questionId: number): Observable<SurveyQuizAnswerModel[]> {

    return this.http.get<SurveyQuizAnswerModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/question/${questionId}/answers`
    );
  }

  addSurveyQuestionAnswer(eventId: number, componentId: number, surveyId: number,
    questionId: number, answer: SurveyQuizAnswerPostModel): Observable<SurveyQuizAnswerModel> {

    return this.http.post<SurveyQuizAnswerModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/question/${questionId}/answer`, answer
    );
  }

  updateSurveyQuestionAnswer(eventId: number, componentId: number, surveyId: number,
    questionId: number, answerId: number, answer: SurveyQuizAnswerPatchModel): Observable<SurveyQuizAnswerModel> {

    return this.http.patch<SurveyQuizAnswerModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/question/${questionId}/answer/${answerId}`, answer
    );
  }

  removeSurveyQuestionAnswer(eventId: number, componentId: number, surveyId: number,
    questionId: number, answerId: number): Observable<{ success: boolean }> {

    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/question/${questionId}/answer/${answerId}`
    );
  }

  getSurveyAdminResponses(eventId: number, componentId: number, surveyId: number): Observable<SurveyQuizResponsesModel[]> {
    return this.http.get<SurveyQuizResponsesModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/survey_quiz/${surveyId}/admin_responses`
    );
  }

  getPhotoBooths(eventId: number, componentId: number): Observable<PhotoBoothModel[]> {
    return this.http.get<PhotoBoothModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/photo_booth/all`
    );
  }

  createPhotoBooth(eventId: number, componentId: number, photoBooth: PhotoBoothPostModel): Observable<PhotoBoothModel> {
    return this.http.post<PhotoBoothModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/photo_booth`, photoBooth
    );
  }

  deletePhotoBooth(eventId: number, componentId: number, photoBoothId: number): Observable<string> {
    return this.http.delete(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/photo_booth/${photoBoothId}`, { responseType: 'text' }
    );
  }

  likePhotoBooth(eventId: number, componentId: number, photoBoothId: number): Observable<{ rates: number }> {
    return this.http.post<{ rates: number }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/photo_booth/${photoBoothId}/like`, null
    );
  }

  unlikePhotoBooth(eventId: number, componentId: number, photoBoothId: number): Observable<{ rates: number }> {
    return this.http.post<{ rates: number }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/photo_booth/${photoBoothId}/unlike`, null
    );
  }

  hidePhotoBooth(eventId: number, componentId: number, photoBoothId: number): Observable<string> {
    return this.http.patch(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/photo_booth/${photoBoothId}/hide_photo`, null, { responseType: 'text' }
    );
  }

  unhidePhotoBooth(eventId: number, componentId: number, photoBoothId: number): Observable<string> {
    return this.http.patch(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/photo_booth/${photoBoothId}/unhide_photo`, null, { responseType: 'text' }
    );
  }

  getPartnerCategories(eventId: number, componentId: number): Observable<PartnerCategoryApiModel[]> {
    return this.http.get<PartnerCategoryApiModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partners`
    );
  }

  createPartnerCategory(eventId: number, componentId: number,
    partnerCategory: PartnerCategoryPostModel): Observable<PartnerCategoryApiModel> {

    return this.http.post<PartnerCategoryApiModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partner/category`, partnerCategory
    );
  }

  updatePartnerCategory(eventId: number, componentId: number, partnerCategoryId: number,
    partnerCategory: PartnerCategoryPatchModel): Observable<PartnerCategoryApiModel> {

    return this.http.patch<PartnerCategoryApiModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partner/category/${partnerCategoryId}`, partnerCategory
    );
  }

  deletePartnerCategory(eventId: number, componentId: number, partnerCategoryId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partner/category/${partnerCategoryId}`
    );
  }

  createPartner(eventId: number, componentId: number, partner: PartnerPostModel): Observable<PartnerApiModel> {
    return this.http.post<PartnerApiModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partner`, partner
    );
  }

  updatePartner(eventId: number, componentId: number, partnerId: number, partner: PartnerPatchModel): Observable<PartnerApiModel> {
    return this.http.patch<PartnerApiModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partner/${partnerId}`, partner
    );
  }

  deletePartner(eventId: number, componentId: number, partnerId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partner/${partnerId}`
    );
  }

  createPartnerContactPerson(eventId: number, componentId: number, partnerId: number,
    contactPerson: PartnerContactPersonPostModel): Observable<PartnerContactPersonApiModel> {

    return this.http.post<PartnerContactPersonApiModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partner/${partnerId}/contact_person`, contactPerson
    );
  }

  updatePartnerContactPerson(eventId: number, componentId: number, partnerId: number,
    contactPersonId: number, contactPerson: PartnerContactPersonPatchModel): Observable<PartnerContactPersonApiModel> {

    return this.http.patch<PartnerContactPersonApiModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partner/${partnerId}/contact_people/${contactPersonId}`, contactPerson
    );
  }

  deletePartnerContactPerson(eventId: number, componentId: number, partnerId: number,
    contactPersonId: number): Observable<{ success: boolean }> {

    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/partner/${partnerId}/contact_people/${contactPersonId}`
    );
  }

  getSocialMedia(eventId: number, componentId: number): Observable<SocialMediumModel[]> {
    return this.http.get<SocialMediumModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/news_social_media`
    );
  }

  createSocialMedium(eventId: number, componentId: number, socialMedium: SocialMediumPostModel): Observable<SocialMediumModel> {
    return this.http.post<SocialMediumModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/news_social_medium`, socialMedium
    );
  }

  updateSocialMedium(eventId: number, componentId: number, socialMediumId: number,
    socialMedium: SocialMediumPatchModel): Observable<SocialMediumModel> {

    return this.http.patch<SocialMediumModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/news_social_medium/${socialMediumId}`, socialMedium
    );
  }

  deleteSocialMedium(eventId: number, componentId: number, socialMediumId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/news_social_medium/${socialMediumId}`
    );
  }

  getSocialMediumItems(eventId: number, componentId: number, socialMediumId: number): Observable<SocialMediumItemModel[]> {
    const params = {
      social_medium_id: socialMediumId.toString()
    };

    return this.http.get<SocialMediumItemModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/news_social_media/get_category_items`, { params }
    );
  }

  createSocialMediumItem(eventId: number, componentId: number, socialMediumId: number,
    socialMediumItem: SocialMediumItemPostModel): Observable<SocialMediumItemModel> {

    return this.http.post<SocialMediumItemModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/news_social_medium/${socialMediumId}/social_media_item`, socialMediumItem
    );
  }

  updateSocialMediumItem(eventId: number, componentId: number, socialMediumId: number,
    socialMediumItemId: number, socialMediumItem: SocialMediumItemPatchModel): Observable<SocialMediumItemModel> {

    return this.http.patch<SocialMediumItemModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/news_social_medium/${socialMediumId}/social_media_item/${socialMediumItemId}`, socialMediumItem
    );
  }

  deleteSocialMediumItem(eventId: number, componentId: number, socialMediumId: number,
    socialMediumItemId: number): Observable<{ success: boolean }> {

    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/news_social_medium/${socialMediumId}/social_media_item/${socialMediumItemId}`
    );
  }

  getExhibitors(eventId: number, componentId: number): Observable<ExhibitorModel[]> {
    return this.http.get<ExhibitorModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/exhibitors`
    );
  }

  getAllExhibitors(eventId: number, maxId: number, limit: number, query: string): Observable<LoadAllExhibitorsResponse> {
    let params: any = {
      limit: limit || 10
    };

    if(query) params.query = query;
    if(maxId) params.max_id = maxId;

    return this.http.get<LoadAllExhibitorsResponse>(
      API_BASE_URL + `/events/${eventId}/components/exhibitors`, { params },
    );
  }

  createExhibitor(eventId: number, componentId: number, exhibitor: ExhibitorPostModel): Observable<ExhibitorModel> {
    return this.http.post<ExhibitorModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/exhibitor`, exhibitor
    );
  }

  updateExhibitor(eventId: number, componentId: number, exhibitorId: number, exhibitor: ExhibitorPatchModel): Observable<ExhibitorModel> {
    return this.http.patch<ExhibitorModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/exhibitor/${exhibitorId}`, exhibitor
    );
  }

  deleteExhibitor(eventId: number, componentId: number, exhibitorId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/exhibitor/${exhibitorId}`
    );
  }

  createAttachment(attachment: AttachmentPostModel): Observable<AttachmentModel> {
    const formData = new FormData();
    formData.append('file', attachment.data as Blob, attachment.filename);

    return this.http.post<AttachmentModel>(API_BASE_URL + '/attachments', formData);
  }

  updateAttachment2(attachmentId: number, attachment: AttachmentPatchModel): Observable<AttachmentModel> {
    const formData = new FormData();
    formData.append('file', attachment.data as Blob, attachment.filename);

    return this.http.put<AttachmentModel>(API_BASE_URL + `/attachments/${attachmentId}`, formData);
  }

  deleteAttachment(attachmentId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/attachments/${attachmentId}`
    );
  }

  createAttachmentEvent(attachment: AttachmentPostModel): Observable<HttpEvent<AttachmentModel>> {
    const formData = new FormData();
    formData.append('file', attachment.data as Blob, attachment.filename);

    return this.http.post<AttachmentModel>(API_BASE_URL + '/attachments', formData, {
      reportProgress: true, observe: 'events'
    });
  }

  updateAttachmentEvent(attachmentId: number, attachment: AttachmentPatchModel): Observable<HttpEvent<AttachmentModel>> {
    const formData = new FormData();
    formData.append('file', attachment.data as Blob, attachment.filename);

    // use casting as HttpClient's PUT does not accept generic type for `observe: 'events'`
    return this.http.put(API_BASE_URL + `/attachments/${attachmentId}`, formData, {
      reportProgress: true, observe: 'events'
    }) as Observable<HttpEvent<AttachmentModel>>;
  }

  getVenueCategories(eventId: number, componentId: number): Observable<VenueCategoryModel[]> {
    return this.http.get<VenueCategoryModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/venues`
    );
  }

  createVenueCategory(eventId: number, componentId: number, category: VenueCategoryPostModel): Observable<VenueCategoryModel> {
    return this.http.post<VenueCategoryModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/venue`, category
    );
  }

  updateVenueCategory(eventId: number, componentId: number, categoryId: number,
    category: VenueCategoryPatchModel): Observable<VenueCategoryModel> {

    return this.http.patch<VenueCategoryModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/venue/${categoryId}`, category
    );
  }

  deleteVenueCategory(eventId: number, componentId: number, categoryId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/venue/${categoryId}`
    );
  }

  reorderVenueCategories(eventId: number, componentId: number, categoryIds: number[]): Observable<VenueCategoryModel[]> {
    return this.http.patch<VenueCategoryModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/venues/order`, { orders: categoryIds }
    );
  }

  createVenuePlace(eventId: number, componentId: number, categoryId: number, place: VenuePlacePostModel): Observable<VenuePlaceModel> {
    return this.http.post<VenuePlaceModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/venue/${categoryId}/place`, place
    );
  }

  updateVenuePlace(eventId: number, componentId: number, categoryId: number, placeId: number,
    place: VenuePlacePatchModel): Observable<VenuePlaceModel> {

    return this.http.patch<VenuePlaceModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/venue/${categoryId}/place/${placeId}`, place
    );
  }

  deleteVenuePlace(eventId: number, componentId: number, categoryId: number, placeId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/venue/${categoryId}/place/${placeId}`
    );
  }

  getBusinessMatchingSessions(eventId: number, componentId: number): Observable<BusinessMatchingSessionApiModel[]> {
    return this.http.get<BusinessMatchingSessionApiModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/business_matching`
    );
  }

  createBusinessMatchingSession(eventId: number, componentId: number,
    session: BusinessMatchingSessionPostModel): Observable<BusinessMatchingSessionApiModel> {

    return this.http.post<BusinessMatchingSessionApiModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/business_matching`, session
    );
  }

  updateBusinessMatchingSession(eventId: number, componentId: number, sessionId: number,
    session: BusinessMatchingSessionPatchModel): Observable<BusinessMatchingSessionApiModel> {

    return this.http.patch<BusinessMatchingSessionApiModel>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/business_matching/${sessionId}`, session
    );
  }

  deleteBusinessMatchingSession(eventId: number, componentId: number, sessionId: number): Observable<{ status: string }> {
    return this.http.delete<{ status: string }>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/business_matching/${sessionId}`
    );
  }

  getBusinessMatchingMeetings(eventId: number, componentId: number): Observable<BusinessMatchingMeetingApiModel[]> {
    return this.http.get<BusinessMatchingMeetingApiModel[]>(
      API_BASE_URL + `/events/${eventId}/components/${componentId}/business_matching/meetings`
    );
  }

  getLiveVideoStreaming(eventId: number): Observable<{ access_url: string }> {
    return this.http.get<any>(
      API_BASE_URL + `/events/${eventId}/get_live_video_streaming`
    );
  }
}

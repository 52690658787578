import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter, Update, Dictionary } from '@ngrx/entity';

import { ExhibitorModel } from '@components/exhibitor/models/exhibitor.model';

import * as ExhibitorActions from '../actions/exhibitor.actions';

export const stateKey = 'exhibitor';

export interface ComponentState {
  loading: boolean;
  loaded: boolean;
  error: Error;
  updating: boolean;
  updatedId: number;
}

export const componentInitialState: ComponentState = {
  loading: false,
  loaded: false,
  error: null,
  updating: false,
  updatedId: 0,
};

export interface State extends EntityState<ExhibitorModel> {
  component: Dictionary<ComponentState>;
}

export const adapter = createEntityAdapter<ExhibitorModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = adapter.getInitialState({
  component: {},
});

export const exhibitorReducer = createReducer(
  initialState,
  on(
    ExhibitorActions.loadExhibitors,
    (state, { component }) => {
      const mutated = substate(state, component.id, { ...componentInitialState, loading: true });
      return adapter.removeMany(exhibitor => exhibitor.componentId === component.id, mutated);
    }
  ),
  on(
    ExhibitorActions.loadExhibitorsSuccess,
    (state, { component, exhibitors }) => {
      const mutated = substate(state, component.id, { loading: false, loaded: true });
      return adapter.upsertMany(exhibitors, mutated);
    }
  ),
  on(
    ExhibitorActions.loadExhibitorsFailure,
    (state, { component, error }) => substate(state, component.id, { loading: false, error })
  ),
  on(
    ExhibitorActions.createExhibitor,
    ExhibitorActions.updateExhibitor,
    (state, { component }) => substate(state, component.id, { updating: true })
  ),
  on(
    ExhibitorActions.createExhibitorSuccess,
    ExhibitorActions.updateExhibitorSuccess,
    (state, { component, exhibitor }) => {
      const mutated = substate(state, component.id, { updating: false, updatedId: exhibitor.id });
      return adapter.upsertOne(exhibitor, mutated)
    }
  ),
  on(
    ExhibitorActions.createExhibitorFailure,
    ExhibitorActions.updateExhibitorFailure,
    (state, { component, error }) => substate(state, component.id, { updating: false, error })
  ),
  on(
    ExhibitorActions.reorderExhibitors,
    (state, { exhibitors }) => {
      const updates: Update<ExhibitorModel>[] = exhibitors.map((exhibitor, order) => ({
        id: exhibitor.id, changes: { order }
      }));

      return adapter.updateMany(updates, state);
    }
  ),
  on(
    ExhibitorActions.reorderExhibitorsSuccess,
    ExhibitorActions.sortExhibitorsAscendingSuccess,
    ExhibitorActions.sortExhibitorsDescendingSuccess,
    (state, { exhibitors }) => adapter.upsertMany(exhibitors, state)
  ),
  on(
    ExhibitorActions.deleteExhibitorsSuccess,
    (state, { exhibitor }) => adapter.removeOne(exhibitor.id, state)
  ),
  on(
    ExhibitorActions.reorderExhibitorsFailure,
    ExhibitorActions.sortExhibitorsAscendingFailure,
    ExhibitorActions.sortExhibitorsDescendingFailure,
    ExhibitorActions.deleteExhibitorsFailure,
    (state, { component, error }) => substate(state, component.id, { error })
  ),
);

function substate(state: State, componentId: number, changes: Partial<ComponentState>): State {
  const componentState = { ...state.component[componentId], ...changes };
  const component = { ...state.component, [componentId]: componentState };

  return { ...state, component };
}

export function reducer(state: State, action: Action): State {
  return exhibitorReducer(state, action);
}

const {
  selectEntities,
  selectAll,
} = adapter.getSelectors();

export const selectExhibitorEntities = selectEntities;

export const selectAllExhibitors = selectAll;

export const selectExhibitors = createSelector(
  selectAllExhibitors,
  (exhibitors: ExhibitorModel[], { componentId }) =>
    exhibitors.filter(exhibitor => exhibitor.componentId === componentId)
);

export const selectExhibitorComponent = (state: State, { componentId }) => state.component[componentId];

export const selectExhibitorLoading = createSelector(
  selectExhibitorComponent,
  component => component && component.loading
);

export const selectExhibitorLoaded = createSelector(
  selectExhibitorComponent,
  component => component && component.loaded
);

export const selectExhibitorError = createSelector(
  selectExhibitorComponent,
  component => component && component.error
);

export const selectUpdatedExhibitorId = createSelector(
  selectExhibitorComponent,
  component => component && component.updatedId
);

export const selectUpdatedExhibitor = createSelector(
  selectExhibitorEntities,
  selectUpdatedExhibitorId,
  (exhibitors, updatedId) => exhibitors[updatedId]
);

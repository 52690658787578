import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-conditional',
  templateUrl: './spinner-conditional.component.html',
  styleUrls: ['./spinner-conditional.component.scss']
})
export class SpinnerConditionalComponent {
  @Input() showSpinner: boolean;
}

import { OperatorFunction, of, pipe } from "rxjs";
import { SpeakerCategoryApiModel, SpeakerCategoryUpdateApiModel } from "./speaker-category.api-model";
import { SpeakerCategoryModel, SpeakerCategoryTranslationModel, SpeakerCategoryUpdateModel } from "./speaker-category.model";
import { concatAll, concatMap, map, toArray } from "rxjs/operators";

export function mapSpeakerCategoryModel(model: SpeakerCategoryApiModel): SpeakerCategoryModel {
  return {
    id: model.id,
    title: model.title,
    order: model.order,
    visibleInDetails: model.visible_in_details,
    visibleOnList: model.visible_on_list,
  }
}

export function adaptSpeakerCategoryModel(): OperatorFunction<SpeakerCategoryApiModel, SpeakerCategoryModel> {
  return pipe(
    map(model => mapSpeakerCategoryModel(model))
  );
}

export function adaptSpeakerCategoryModels(): OperatorFunction<SpeakerCategoryApiModel[], SpeakerCategoryModel[]> {
  return pipe(
    concatMap(models => of(models).pipe(
      concatAll(),
      adaptSpeakerCategoryModel(),
      toArray()
    ))
  );
}

export function mapSpeakerCategoryUpdateApiModel(model: SpeakerCategoryUpdateModel): SpeakerCategoryUpdateApiModel {
  return {
    id: model.id,
    title: model.title,
    visible_in_details: model.visibleInDetails,
    visible_on_list: model.visibleOnList,
  }
}

export function mapSpeakerCategoryToTranslationModel(model: SpeakerCategoryModel): SpeakerCategoryTranslationModel {
  return {
    id: model.id,
    title: model.title,
  }
}

export function mapSpeakerCategoryTranslationToModel(category: SpeakerCategoryModel, model: SpeakerCategoryTranslationModel): SpeakerCategoryModel {
  return {
    ...category,
    id: model.id,
    title: model.title,
  }
}

import { ProfileModel } from "@app/backend/models/profile.model";
import { EventModel } from "@store/features/event/models/event.model";

export function hideForEmail(event: EventModel, profile: ProfileModel): boolean {
  const eventId = 1343;
  const userEmails = ['test@lignumsoft.pl'];
  if (event.id === eventId && userEmails.find(e => e === profile.email) !== undefined) {
    return true;
  }
  return false;
  
}
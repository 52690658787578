import { ExhibitorUpdatesAction } from './../actions/exhibitor-updates.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ExhibitorUpdateModel, ExhibitorUpdatesStatus, ExhibitorUpdatesMeta, ExhibitorUpdatesResponse } from './../models/exhibitor-updates.model';

export const stateKey = 'exhibitorUpdates';

export interface ExhibitorUpdatesState {
  pending: {
    meta: ExhibitorUpdatesMeta,
    updates: ExhibitorUpdateModel[]
  },
  accepted: {
    meta: ExhibitorUpdatesMeta,
    updates: ExhibitorUpdateModel[]
  },
  rejected: {
    meta: ExhibitorUpdatesMeta,
    updates: ExhibitorUpdateModel[]
  }
}

export interface State {
  loading: boolean;
  updating: boolean;
  updatingId: number | null;
  error: Error | null;
  exhibitorUpdates: ExhibitorUpdatesState;
  currentStatus: ExhibitorUpdatesStatus;
}

export const exhibitorUpdatesInitialState: State = {
  loading: false,
  updating: false,
  updatingId: null,
  error: null,
  exhibitorUpdates: {
    pending: {
      meta: {
        total: null,
        limit: 10,
        total_pages: null,
        page: 1
      },
      updates: []
    },
    accepted: {
      meta: {
        total: null,
        limit: 10,
        total_pages: null,
        page: 1
      },
      updates: []
    },
    rejected: {
      meta: {
        total: null,
        limit: 10,
        total_pages: null,
        page: 1
      },
      updates: []
    }
  },
  currentStatus: ExhibitorUpdatesStatus.PENDING,
}

export const exhibitorUpdatesReducer = createReducer(
  exhibitorUpdatesInitialState,
  on(
    ExhibitorUpdatesAction.loadExhibitorUpdates,
    (state) => ({ ...state, loading: true })
  ),
  on(
    ExhibitorUpdatesAction.loadExhibitorUpdatesSuccess,
    (state, { data, status }) => ({ ...handleExhibitorUpdatesLoad(state, data, status), loading: false, error: null, })
  ),
  on(
    ExhibitorUpdatesAction.loadExhibitorUpdatesFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),

  on(
    ExhibitorUpdatesAction.changeExhibitorUpdateStatus,
    (state) => ({ ...state, updating: true })
  ),

  on(
    ExhibitorUpdatesAction.changeExhibitorUpdateStatus,
    (state, { update }) => ({ ...state, updating: true, updatingId: update.id })
  ),
  on(
    ExhibitorUpdatesAction.changeExhibitorUpdateStatusSuccess,
    (state, { data }) => ({
      ...handleChangedExhibitorUpdate(state, data),
      updating: false,
      error: null,
      updatingId: null,
    })
  ),
  on(
    ExhibitorUpdatesAction.changeExhibitorUpdateStatusFailure,
    (state, { error }) => ({ ...state, updating: false, error })
  ),

  on(
    ExhibitorUpdatesAction.setUpdatingId,
    (state, { id }) => ({ ...state, updatingId: id, error: null })
  ),
  on(
    ExhibitorUpdatesAction.switchActiveTab,
    (state, { tab }) => ({ ...state, currentStatus: tab })
  )
);

export function reducer(state: State, action: Action): State {
  return exhibitorUpdatesReducer(state, action);
}


// state modify helpers

function handleChangedExhibitorUpdate(state: State, exhibitor: ExhibitorUpdateModel): State {
  if(exhibitor.status !== state.currentStatus) {
    return {
      ...state,
      exhibitorUpdates: {
        ...state.exhibitorUpdates,
        [exhibitor.status]: {
          ...state.exhibitorUpdates[exhibitor.status],
          updates: [...state.exhibitorUpdates[exhibitor.status].updates, exhibitor]
        },
        pending: {
          ...state.exhibitorUpdates.pending,
          updates: state.exhibitorUpdates.pending.updates.filter(u => u.id !== exhibitor.id),
          meta: {
            ...state.exhibitorUpdates.pending.meta,
            total: state.exhibitorUpdates.pending.meta.total > 0 ? state.exhibitorUpdates.pending.meta.total - 1 : 0,
          }
        }
      }
    };
  }
}

function handleExhibitorUpdatesLoad(state: State, update: ExhibitorUpdatesResponse, status: ExhibitorUpdatesStatus): State {
  return {
    ...state,
    exhibitorUpdates: {
      ...state.exhibitorUpdates,
      [status]: {
        meta: update.meta,
        updates: update.exhibitors_updates ? [...update.exhibitors_updates] : [...update.exhibitor_updates],
      }
    }
  }
}


// selectors

export const selectLoading = (state: State) => state.loading;
export const selectUpdating = (state: State) => state.updating;
export const selectUpdatingId = (state: State) => state.updatingId;
export const selectError = (state: State) => state.error;
export const selectExhibitorUpdates = (state: State) => state.exhibitorUpdates;
export const selectCurrentStatus = (state: State) => state.currentStatus;



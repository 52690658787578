import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import {
  createConsentEventSchema,
  createConsentEventSchemaFailure,
  createConsentEventSchemaSuccess,
  createConsentSchemaTranslation,
  createConsentSchemaTranslationFailure,
  createConsentSchemaTranslationSuccess,
  loadConsentEventSchemas,
  loadConsentEventSchemasFailure,
  loadConsentEventSchemasSuccess,
  loadConsentSchemaTranslations,
  loadConsentSchemaTranslationsFailure,
  loadConsentSchemaTranslationsSuccess,
  publishConsentSchema,
  publishConsentSchemaFailure,
  publishConsentSchemaSuccess,
  unPublishConsentSchema, unPublishConsentSchemaFailure,
  unPublishConsentSchemaSuccess,
  updateConsentEventSchema,
  updateConsentEventSchemaFailure,
  updateConsentEventSchemaSuccess,
  updateConsentSchemaTranslation,
  updateConsentSchemaTranslationFailure,
  updateConsentSchemaTranslationSuccess
} from "../actions/consent-event.actions";
import { ConsentEventAdapterService } from "../adapters/consent-event-adapter.service";

import * as fromEvent from '@store/features/event/reducers';
import { Store, select } from "@ngrx/store";
import { createEventSuccess, loadEventSuccess } from "@store/features/event/actions";
import { ConsentEventHelper } from "../utils/consent-event.helper";

@Injectable()
export class ConsentEventEffects {

  load$ = createEffect(() => this.actions$.pipe(
    ofType(loadEventSuccess, createEventSuccess),
    map(() => loadConsentEventSchemas()),
  ));

  loadConsentEventSchemas$ = createEffect(() => this.actions$.pipe(
    ofType(loadConsentEventSchemas),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    switchMap(([_, event]) => this.adapter.getEventConsentSchemas(event).pipe(
      map(entities => loadConsentEventSchemasSuccess({ entities })),
      catchError(error => {
        return of(loadConsentEventSchemasFailure({ error }));
      })
    ))
  ));

  createConsentEventSchema$ = createEffect(() => this.actions$.pipe(
    ofType(createConsentEventSchema),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    switchMap(([action, event]) => this.adapter.createEventConsentSchema(event, action.payload).pipe(
      map(entity => createConsentEventSchemaSuccess({ entity })),
      catchError(error => {
        return of(createConsentEventSchemaFailure({ error }));
      })
    ))
  ));

  updateConsentEventSchema$ = createEffect(() => this.actions$.pipe(
    ofType(updateConsentEventSchema),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    switchMap(([action, event]) => this.adapter.updateEventConsentSchema(event, action.schema, action.payload).pipe(
      map(entity => updateConsentEventSchemaSuccess({ entity })),
      catchError(error => {
        return of(updateConsentEventSchemaFailure({ error }));
      })
    ))
  ));

  loadConsentSchemaTranslations$ = createEffect(() => this.actions$.pipe(
    ofType(loadConsentSchemaTranslations),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    concatMap(([action, event]) => this.adapter.loadConsentSchemaTranslations(event, action.schema, action.version).pipe(
      map(entities => loadConsentSchemaTranslationsSuccess({ schema: action.schema, version: action.version, entities })),
      catchError(error => {
        return of(loadConsentSchemaTranslationsFailure({ error }));
      })
    ))
  ));

  createConsentSchemaTranslation$ = createEffect(() => this.actions$.pipe(
    ofType(createConsentSchemaTranslation),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    concatMap(([action, event]) => this.adapter.createConsentSchemaTranslations(event, action.schema, action.version, action.payload).pipe(
      map(entity => createConsentSchemaTranslationSuccess({ schema: action.schema, version: action.version, entity })),
      catchError(error => {
        return of(createConsentSchemaTranslationFailure({ error }));
      })
    ))
  ));

  updateConsentSchemaTranslation$ = createEffect(() => this.actions$.pipe(
    ofType(updateConsentSchemaTranslation),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    concatMap(([action, event]) => this.adapter.updateConsentSchemaTranslations(event, action.schema, action.version, action.translation, action.payload).pipe(
      map(entity => updateConsentSchemaTranslationSuccess({ schema: action.schema, version: action.version, entity })),
      catchError(error => {
        return of(updateConsentSchemaTranslationFailure({ error }));
      })
    ))
  ));

  publishConsentSchema$ = createEffect(() => this.actions$.pipe(
    ofType(publishConsentSchema),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    switchMap(([action, event]) => {
      return this.adapter.publishConsentSchema(event, action.consentSchemaId).pipe(
        map(() => publishConsentSchemaSuccess({ consentSchemaId: action.consentSchemaId })),
        catchError(error => of(publishConsentSchemaFailure({
          consentSchemaId: action.consentSchemaId,
          error
        })))
      );
    })
  ));

  unpublishConsentSchema$ = createEffect(() => this.actions$.pipe(
    ofType(unPublishConsentSchema),
    withLatestFrom(this.store$.pipe(select(fromEvent.selectActiveEvent))),
    filter(([_, event]) => event !== null),
    switchMap(([action, event]) => {
      return this.adapter.unpublishConsentSchema(event, action.consentSchemaId).pipe(
        map(() => unPublishConsentSchemaSuccess({ consentSchemaId: action.consentSchemaId })),
        catchError(error => of(unPublishConsentSchemaFailure({
          consentSchemaId: action.consentSchemaId,
          error
        })))
      );
    })
  ));

  constructor(
    private store$: Store<any>,
    private actions$: Actions,
    private adapter: ConsentEventAdapterService,
  ) {}
}

<app-dropdown-with-search
  [label]="label ? label : 'application_users.table_exhibitors_moderator' | translate"
  [options]="exhibitorsDropdownOptions"
  [loading]="loading"
  [apiSearch]="loadOnScroll"
  [staticLabel]="static"
  [required]="!nullable"
  (onSelect)="select($event)"
  (onScrollToBottom)="load()"
  (onSearchValueChanged)="onSearch($event)">
</app-dropdown-with-search>